/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/folders.thunk";
import { FetchStatus, Folder } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { foldersAdapter } from "app/store/adapters/adapters";

interface FolderState {
  foldersStatus: FetchStatus;
  folderContentStatus: FetchStatus;
  getFolderStatus: FetchStatus;
  createFolderStatus: FetchStatus;
  editFolderStatus: FetchStatus;
  moveToFolderStatus: FetchStatus;
  currentFolder: Folder;
  deleteFolderStatus: FetchStatus;
}

export const foldersSlice = createSlice({
  name: "Folders",
  initialState: foldersAdapter.getInitialState<FolderState>({
    currentFolder: {},
    foldersStatus: fetchingStatus.idle as FetchStatus,
    folderContentStatus: fetchingStatus.idle as FetchStatus,
    createFolderStatus: fetchingStatus.idle as FetchStatus,
    editFolderStatus: fetchingStatus.idle as FetchStatus,
    moveToFolderStatus: fetchingStatus.idle as FetchStatus,
    deleteFolderStatus: fetchingStatus.idle as FetchStatus,
    getFolderStatus: fetchingStatus.idle as FetchStatus
  }),
  reducers: {
    setCreateFolderStatusToIdle(state) {
      state.createFolderStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setDeleteFolderStatusToIdle(state) {
      state.deleteFolderStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setEditFolderStatusToIdle(state) {
      state.editFolderStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setMoveToFolderStatusToIdle(state) {
      state.moveToFolderStatus = fetchingStatus.idle as FetchStatus;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getRootFoldersRequest.fulfilled, (state, action) => {
      state.foldersStatus = fetchingStatus.succeeded as FetchStatus;
      foldersAdapter.setAll(state, action.payload);
    });
    builder.addCase(asyncThunks.getRootFoldersRequest.pending, (state) => {
      state.foldersStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getRootFoldersRequest.rejected, (state) => {
      state.foldersStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getFolderContentRequest.fulfilled, (state, action) => {
      state.folderContentStatus = fetchingStatus.succeeded as FetchStatus;
      state.currentFolder = action.payload;
    });
    builder.addCase(asyncThunks.getFolderContentRequest.pending, (state) => {
      state.folderContentStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getFolderContentRequest.rejected, (state) => {
      state.folderContentStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.createFolderRequest.fulfilled, (state, action) => {
      state.createFolderStatus = fetchingStatus.succeeded as FetchStatus;
      foldersAdapter.addOne(state, action.payload);
    });
    builder.addCase(asyncThunks.createFolderRequest.pending, (state) => {
      state.createFolderStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createFolderRequest.rejected, (state) => {
      state.createFolderStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.editFolderNameRequest.fulfilled, (state) => {
      state.editFolderStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.editFolderNameRequest.pending, (state, action) => {
      state.editFolderStatus = fetchingStatus.loading as FetchStatus;

      foldersAdapter.updateOne(state, {
        id: action.meta.arg.folderId,
        changes: { name: action.meta.arg.name }
      });
    });
    builder.addCase(asyncThunks.editFolderNameRequest.rejected, (state) => {
      state.editFolderStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.moveToFolderRequest.fulfilled, (state) => {
      state.moveToFolderStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.moveToFolderRequest.pending, (state) => {
      state.moveToFolderStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.moveToFolderRequest.rejected, (state) => {
      state.moveToFolderStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.deleteFolderRequest.fulfilled, (state) => {
      state.deleteFolderStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteFolderRequest.pending, (state, action) => {
      state.deleteFolderStatus = fetchingStatus.loading as FetchStatus;
      const folderId = (action.meta.arg as Folder).id as string;
      foldersAdapter.removeOne(state, folderId);
    });
    builder.addCase(asyncThunks.deleteFolderRequest.rejected, (state, action) => {
      state.deleteFolderStatus = fetchingStatus.failed as FetchStatus;
      const folderData = action.meta.arg as Folder;
      foldersAdapter.addOne(state, folderData);
    });
    builder.addCase(asyncThunks.getFolderByIdRequest.pending, (state) => {
      state.getFolderStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getFolderByIdRequest.rejected, (state) => {
      state.getFolderStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.getFolderByIdRequest.fulfilled, (state, action) => {
      state.getFolderStatus = fetchingStatus.succeeded;
      state.currentFolder = action.payload;
    });
  }
});

export default foldersSlice.reducer;

export const foldersActions = {
  ...asyncThunks,
  ...foldersSlice.actions
};
