import React, { useEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import styled, { css, useTheme } from "styled-components";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import TitledInput from "app/components/common/TitledInput";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Tabs, TabsProps, Tooltip } from "antd";
import { charactersActions } from "app/store/slices/characters.slice";
import { charactersGlobalSelectors, voicesGlobalSelectors } from "app/store/adapters/adapters";
import UploadFileContent from "app/components/common/UploadFileContent";
import { CustomVoice, FeatureFlag, Gender, Location, MimeType, PlanEnum, Voice } from "app/types";
import UseFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import {
  addPolicyToUrlIfFileStackMedia,
  fetchingStatus,
  isMediaPlaying,
  onImageError
} from "app/utils/helpers";
import { voicesActions } from "app/store/slices/voices.slice";
import TextArea from "antd/lib/input/TextArea";
import { v4 as uuidv4 } from "uuid";
import { playgroundActions } from "app/store/slices/playground.slice";
import {
  DubbingLanguages,
  DubbingRequestSourceTypes,
  PlayGroundRequest,
  PlaygroundVideoTypeTabs
} from "app/types/playground";
import ConditionalRender from "app/components/common/ConditionalRender";
import { validatePlayGround } from "app/pages/PlayGround/helpers";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import PlaygroundTopBar from "app/pages/PlayGround/PlaygroundTopBar";
import ButtonBox from "./ButtonBox";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import RecordCharacterModal from "app/pages/PlayGround/RecordCharacterModal";
import { useNavigate } from "react-router-dom";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import UploadArea from "app/components/common/UploadArea";
import "./playground.scss";
import PlaygroundVideosList from "app/pages/PlayGround/PlaygroundVideosList";
import { getAllVideosSortedByDate } from "app/store/selectorsV2/playground.selectors";
import PlaygroundPresenter from "app/pages/PlayGround/PlaygroundPresenter";
import PlaygroundVoiceSelection from "app/pages/PlayGround/PlaygroundVoiceSelection";
import { BlackButton } from "app/pages/PlayGround/BlackButton";
import TranscriptAudioUploadPlaygroundModal from "app/pages/PlayGround/TranscriptAudioUploadPlaygroundModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import PlaygroundSelectExistingVideo from "app/pages/PlayGround/PlaygroundSelectExistingVideo";
import PlaygroundFtuModal from "app/pages/PlayGround/PlaygroundFtuModal";
import { userActions } from "app/store/slices/user.slice";
import PlaygroundTargetLanguage from "app/pages/PlayGround/PlaygroundTargetLanguage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button } from "@nextui-org/react";

const VIDEO_TITLE = "HourOne Video";
const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background: ${({ theme }) => theme.gray9};
  height: 100vh;
  overflow: hidden;
`;

const MainFlexColumn = styled(H1_FlexColumn)`
  z-index: 5;
`;

const CenterFlexColumn = styled(H1_FlexColumn)<{ $isBottom: boolean }>`
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, calc(-50vh + 186px));
  ${({ $isBottom }) =>
    $isBottom &&
    css`
      transform: translate(-50%, 0);
    `};
`;

const HiddenAudioPlayer = styled(H1_FlexRow)`
  display: none;
`;

const ContentFlexColumn = styled(H1_FlexColumn)`
  border-radius: 5px;
  background: rgba(44, 45, 64, 0.4);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
`;

const TabIconContainer = styled(H1_FlexRow)`
  width: 29px;
  height: 29px;
  background-color: rgba(170, 170, 255, 0.12);
`;

const StyledTabs = styled(Tabs)`
  && .ant-tabs > .ant-tabs-nav {
    padding: 0 23px;
  }
  &&& .ant-tabs-nav-more {
    padding: 0;
  }
  .ant-tabs-ink-bar {
    background-color: #383544;
  }
  .ant-tabs-tab {
    margin: 0;
    padding: 12px 12px 12px 12px;
  }
  .ant-tabs-nav-list {
    width: auto;
  }
  &&.ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
    color: ${(props) => props.theme.gray5};
    span {
      color: ${(props) => props.theme.gray5};
    }
    fill: ${(props) => props.theme.gray5};
  }

  &&.ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: ${(props) => props.theme.blue2};
    span {
      color: ${(props) => props.theme.blue2};
    }
    fill: ${(props) => props.theme.blue2};
  }

  && .ant-tabs-tab .ant-tabs-tab-btn {
    color: ${(props) => props.theme.gray5};
    span {
      color: ${(props) => props.theme.gray5};
    }
    fill: ${(props) => props.theme.gray5};
  }
  && .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.gray1};
    span {
      color: ${(props) => props.theme.gray1};
    }
    fill: ${(props) => props.theme.gray1};
  }
  && .ant-tabs-tab:hover {
    color: ${(props) => props.theme.gray3};
    span {
      color: ${(props) => props.theme.gray3};
    }
    fill: ${(props) => props.theme.gray3};
  }

  &&& .ant-tabs-nav::before {
    border-bottom: 1px solid #383544;
  }

  && .ant-tabs-ink-bar {
    background-color: ${(props) => props.theme.gray1};
  }
`;
const UploadIcon = styled.i`
  font-size: 30px;
  color: ${({ theme }) => theme.gray5};
`;

const UploadVideoImage = styled.img`
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const StyledTextArea = styled(TextArea)<{ $error?: boolean }>`
  resize: none;
  overflow: auto;
  flex: 1 0 148px;
  border-radius: 4px;
  border: ${({ $error }) => ($error ? "1px solid red" : "none")};
  background: transparent;
  color: ${({ theme }) => theme.gray5};
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-size: 16px;
  line-height: 25px;
`;

const FlexColumnFormContainer = styled(H1_FlexColumn)`
  max-width: 600px;
  align-self: center;
`;

const HiddenFutureUseItems = styled(H1_FlexRow)`
  min-height: 0px;
  display: none;
`;
const PlayGroundPage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<DubbingLanguages>(
    DubbingLanguages.ENGLISH
  );
  const [showFtuModal, setShowFtuModal] = useState(false);
  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const [voiceIdLoading, setVoiceIdLoading] = useState<string>("");
  const [voiceIdPlaying, setVoiceIdPlaying] = useState<string>("");
  const [previewVoice, setPreviewVoice] = useState<Partial<Voice> | undefined>(undefined);
  const [isFullTranscriptPreviewPlaying, setIsFullTranscriptPreviewPlaying] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<PlaygroundVideoTypeTabs>(
    PlaygroundVideoTypeTabs.AI_Video
  );
  const [showCharacterUploadModal, setShowCharacterUploadModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    transcript?: string;
    selectedVoice?: string;
    selectedPresenter?: string;
  }>({});
  const audioPreviewRef = useRef<HTMLAudioElement>(null);
  const customAudioPreviewRef = useRef<HTMLAudioElement>(null);
  const dispatch = useAppDispatch();
  const { currentPlan } = useCurrentPlan();
  const theme = useTheme();
  const navigate = useNavigate();
  const flags = useFlags();

  const isTabTranslate = activeTab === PlaygroundVideoTypeTabs.Translate;

  const {
    file: voiceFile,
    reset: resetVoiceRecord,
    onSelectFile: onSelectFileVoice,
    importLoading: importLoadingVoice,
    fileStoreResult: fileStoreResultVoice,
    executeImport
  } = UseFileUploadFilestack({ autoUpload: false });
  const preferences = useAppSelector((state) => state.user.preferences);
  const videos = useAppSelector(getAllVideosSortedByDate);
  const voicePreviewUrl = useAppSelector((state) => state.playground.voicePreviewUrl);
  const selectedPresenterId = useAppSelector((state) => state.playground.selectedPresenterId);
  const selectedVoiceId = useAppSelector((state) => state.playground.selectedVoiceId);
  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voicesGlobalSelectors.selectById(state, selectedVoiceId || "")
  );
  const generateStatus = useAppSelector((state) => state.playground.generateStatus);
  const getVideosStatus = useAppSelector((state) => state.playground.getVideosStatus);
  const selectedGender = useAppSelector((state) => state.playground.selectedGender);
  const previewTranscriptStatus = useAppSelector(
    (state) => state.playground.previewTranscriptStatus
  );
  const transcript = useAppSelector((state) => state.playground.transcript);
  const customPresenter = useAppSelector((state) => state.playground.customPresenter);
  const customVoice = useAppSelector((state) => state.playground.customVoice);
  const characters = useAppSelector(charactersGlobalSelectors.selectAll);
  const voices: Voice[] = useAppSelector(voicesGlobalSelectors.selectAll);
  const filestackReadPolicy = useAppSelector((state) => state.media.filestackReadPolicy);

  const limits = useAppSelector(getLimits);

  const isCustomPresenterSet =
    !!customPresenter && customPresenter.id && activeTab === PlaygroundVideoTypeTabs.Speaking_Video;
  const isCustomVoiceSet = !!customVoice && customVoice.id === selectedVoiceId;
  const pageLoading = getVideosStatus === fetchingStatus.loading;
  const previewTranscriptLoading = previewTranscriptStatus === fetchingStatus.loading;
  const isOnBoarding = currentPlan === PlanEnum.onBoarding;

  const {
    onSelectFile: onSelectFilePresenter,
    importLoading: importLoadingPresenter,
    fileStoreResult: fileStoreResultPresenter,
    thumbnail,
    progress
  } = UseFileUploadFilestack({ thumbnail: true, autoUpload: true });

  const generateButtonLoading = importLoadingPresenter || generateStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(charactersActions.getCharactersListRequest());
    dispatch(voicesActions.getVoicesListRequest());
    dispatch(playgroundActions.playgroundGetVideosRequest());
  }, []);

  useEffect(() => {
    if (preferences?.playground_ftu) {
      dispatch(userActions.updateUserPreferencesRequest({ playground_ftu: false }));
      setShowFtuModal(true);
    }
  }, [preferences?.playground_ftu]);

  useEffect(() => {
    if (previewTranscriptStatus === fetchingStatus.succeeded) {
      dispatch(playgroundActions.updatePreviewTranscriptStatusToIdle());
    }
    if (previewTranscriptStatus === fetchingStatus.failed) {
      dispatch(playgroundActions.updatePreviewTranscriptStatusToIdle());
    }
  }, [previewTranscriptStatus]);
  useEffect(() => {
    if (fileStoreResultVoice) {
      setShowVoiceModal(false);
      dispatch(
        playgroundActions.updatePlayGroundDetails({
          customVoice: {
            id: fileStoreResultVoice.handle,
            display_name: "User audio",
            image: "https://flagcdn.com/us.svg",
            url: fileStoreResultVoice.url
          },
          selectedVoice: fileStoreResultVoice.handle
        })
      );
    }
  }, [fileStoreResultVoice]);

  useEffect(() => {
    if (videos.length && !selectedPresenterId) {
      const lastVideo = videos.at(-1);
      if (!lastVideo?.source_video && !lastVideo?.voice_url) {
        dispatch(playgroundActions.updateCurrentFormStateByVideo(lastVideo));
      }
    }
  }, [videos, selectedPresenterId]);

  useEffect(() => {
    if (generateStatus === fetchingStatus.succeeded) {
      dispatch(playgroundActions.setGenerateToIdle());
    } else if (generateStatus === fetchingStatus.failed) {
      dispatch(playgroundActions.setGenerateToIdle());
    }
  }, [generateStatus]);

  useEffect(() => {
    if (fileStoreResultPresenter && thumbnail) {
      dispatch(
        playgroundActions.updatePlayGroundDetails({
          customPresenter: {
            id: fileStoreResultPresenter.handle,
            name: "User video",
            image: addPolicyToUrlIfFileStackMedia(thumbnail, filestackReadPolicy),
            url: fileStoreResultPresenter.url
          }
        })
      );
    }
  }, [fileStoreResultPresenter, thumbnail]);

  const voiceFileAsUrl = useMemo(() => {
    if (voiceFile) {
      return URL.createObjectURL(voiceFile);
    }
    return undefined;
  }, [voiceFile]);

  const charactersOptions = useMemo(() => {
    const result = characters.map((c) => {
      return {
        name: c.title,
        image: c.image,
        id: c.character_id,
        url: "",
        gender: c.gender
      };
    });
    return result;
  }, [characters]);

  useEffect(() => {
    if (voicePreviewUrl) {
      // Voice url changes, so the audio element is now being loaded, we want to prevent the loading icon to be over
      setIsFullTranscriptPreviewPlaying(true);
    }
  }, [voicePreviewUrl]);

  const onCloseVoiceModal = () => {
    setShowVoiceModal(false);
  };

  const onVoicePreviewClicked = (previewVoiceClicked: Partial<Voice>) => {
    if (previewVoice?.id === previewVoiceClicked.id) {
      const audio = audioPreviewRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        setVoiceIdPlaying("");
        audio.pause();
      } else {
        setVoiceIdPlaying(previewVoiceClicked.id as string);
        audio.play();
      }
    } else {
      setPreviewVoice(previewVoiceClicked);
      setVoiceIdLoading(previewVoiceClicked.id as string);
    }
  };

  const onGenderChange = (gender: Gender) => {
    dispatch(playgroundActions.updatePlayGroundDetails({ selectedGender: gender }));
  };
  const onSelectLanguage = (target: DubbingLanguages) => {
    setSelectedLanguage(target);
  };
  const onScriptChange = (evt: any) => {
    const { value } = evt.target;
    dispatch(googleEvents.playgroundTranscriptChange({ value }));
    dispatch(playgroundActions.updatePlayGroundDetails({ transcript: value || "" }));
    dispatch(playgroundActions.cleanPlaygroundAudio());
  };
  const executeValidation = () => {
    const validationResult = validatePlayGround(
      transcript || "",
      limits.chars_per_scene,
      isCustomVoiceSet,
      selectedVoiceId,
      selectedPresenterId
    );
    setValidationErrors(validationResult);
    return validationResult;
  };

  const onPreviewText = () => {
    dispatch(
      googleEvents.playgroundPreviewTranscript({ transcript, selectedVoiceId, isCustomVoiceSet })
    );
    setPreviewVoice(undefined);
    if (isCustomVoiceSet) {
      const audio = customAudioPreviewRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        audio.pause();
        setVoiceIdPlaying("");
        setIsFullTranscriptPreviewPlaying(false);
      } else {
        audio.play();
        setIsFullTranscriptPreviewPlaying(true);
      }
    } else if (voicePreviewUrl) {
      const audio = audioPreviewRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        audio.pause();
        setVoiceIdPlaying("");
        setIsFullTranscriptPreviewPlaying(false);
      } else {
        audio.play();
        setIsFullTranscriptPreviewPlaying(true);
      }
    } else {
      dispatch(
        playgroundActions.previewTranscriptRequest({
          orderId: uuidv4(),
          voiceId: selectedVoiceId as string,
          sml: transcript as string
        })
      );
    }
  };

  const onGenerate = async () => {
    const orderId = uuidv4();
    const request: PlayGroundRequest = { order_id: orderId };
    request.title = VIDEO_TITLE;

    if (isTabTranslate) {
      dispatch(
        googleEvents.playgroundGenerateVideo({
          translateLanguage: selectedLanguage,
          customPresenter
        })
      );
      request.source_video = customPresenter?.url;
      request.voice_url = customPresenter?.url;
      request.dubbing = {
        source_type: DubbingRequestSourceTypes.file,
        target_lang: selectedLanguage
      };
      dispatch(playgroundActions.generateRequest(request));
      return;
    }
    const validationResult = executeValidation();
    if (Object.keys(validationResult).length) {
      return;
    }

    const voice: Partial<CustomVoice> = isCustomVoiceSet ? customVoice : (selectedVoice as Voice);
    const presenter = isCustomPresenterSet
      ? customPresenter
      : charactersOptions.find((presenter) => presenter.id === selectedPresenterId);
    dispatch(
      googleEvents.playgroundGenerateVideo({
        transcript,
        selectedVoiceId,
        isCustomVoiceSet,
        isCustomPresenterSet,
        customPresenter
      })
    );
    if (voice.url) {
      request.voice_url = voice.url;
    } else {
      request.transcript = transcript;
      request.voice_id = selectedVoiceId;
    }
    if (presenter?.url) {
      request.source_video = presenter?.url;
    } else {
      request.character_id = presenter?.id;
    }

    dispatch(playgroundActions.generateRequest(request));
  };

  const onCanPlayCustomVoice = () => {
    customAudioPreviewRef.current?.play();
    setIsFullTranscriptPreviewPlaying(true);
  };

  const onCanPlayPreview = () => {
    audioPreviewRef.current?.play();
    if (previewVoice) {
      setVoiceIdPlaying(previewVoice.id as string);
    } else {
      setIsFullTranscriptPreviewPlaying(true);
    }
    setVoiceIdLoading("");
  };

  const onEndedAudio = () => {
    setIsFullTranscriptPreviewPlaying(false);
    setVoiceIdPlaying("");
  };

  const onCloseUploadCharacterModal = () => {
    setShowCharacterUploadModal(false);
  };
  const onRecordPresenter = () => {
    setShowCharacterUploadModal(true);
  };

  const onBack = () => {
    navigate(Location.Home);
  };

  const onChangeTabs = (active: string) => {
    setActiveTab(active as PlaygroundVideoTypeTabs);
  };

  const items: TabsProps["items"] = [
    {
      key: PlaygroundVideoTypeTabs.AI_Video,
      label: (
        <H1_FlexRow gap="10px" align="center">
          <TabIconContainer justify="center" align="center">
            <i className="fas fa-user" />
          </TabIconContainer>
          <H1_TextXs>Speaking Avatar</H1_TextXs>
        </H1_FlexRow>
      )
    },
    {
      key: PlaygroundVideoTypeTabs.Speaking_Video,
      label: (
        <H1_FlexRow gap="10px" align="center">
          <TabIconContainer justify="center" align="center">
            <i className="fas fa-lips" />
          </TabIconContainer>
          <H1_TextXs>Speaking Video</H1_TextXs>
          <Tooltip
            color={theme.blue4}
            placement="right"
            title="Transform your videos effortlessly to speaking videos with our AI. Make sure to upload video with a person looking at the camera the entire video clip."
          >
            <i className="fa-regular fa-info-circle" />
          </Tooltip>
        </H1_FlexRow>
      )
    },
    flags[FeatureFlag.dubbing] && {
      key: PlaygroundVideoTypeTabs.Translate,
      label: (
        <H1_FlexRow gap="10px" align="center">
          <TabIconContainer justify="center" align="center">
            <i className="fas fa-language" />
          </TabIconContainer>
          <H1_TextXs>Translate</H1_TextXs>
        </H1_FlexRow>
      )
    }
  ];

  return (
    <BackgroundFlexColumn flex="0 0 auto" overflow="auto" position="relative" padding="0 0 43px 0">
      <PlaygroundFtuModal visible={showFtuModal} onClose={() => setShowFtuModal(false)} />
      <RecordCharacterModal
        open={showCharacterUploadModal}
        closeModal={onCloseUploadCharacterModal}
        onSelectFile={onSelectFilePresenter}
      />
      <PlaygroundTopBar onBack={onBack} />
      <ConditionalRender condition={videos.length > 0}>
        <PlaygroundVideosList />
      </ConditionalRender>
      <MainFlexColumn position="relative" align="center" justify="center" flex="1 0 314px">
        <CenterFlexColumn $isBottom={videos.length > 0} align="center" justify="center" flex="1">
          <ContentFlexColumn
            padding="4px 20px 25px"
            flex="0 0 314px"
            width="1200px"
            position="relative"
          >
            <StyledTabs
              onChange={onChangeTabs}
              activeKey={activeTab}
              items={items}
              moreIcon={null}
            />
            <H1_FlexRow align="center" gap="39px">
              <H1_FlexColumn width="270px" flex="0 0 270px" gap="10px" align="center">
                <ConditionalRender condition={!charactersOptions?.length || !voices.length}>
                  <CircleLoader />
                </ConditionalRender>
                <ConditionalRender
                  condition={
                    activeTab === PlaygroundVideoTypeTabs.AI_Video &&
                    characters.length > 0 &&
                    voices.length > 0
                  }
                >
                  <PlaygroundPresenter />
                </ConditionalRender>
                <ConditionalRender
                  condition={activeTab === PlaygroundVideoTypeTabs.Speaking_Video || isTabTranslate}
                >
                  <ConditionalRender condition={isOnBoarding}>
                    <PlaygroundSelectExistingVideo />
                  </ConditionalRender>
                  <ConditionalRender condition={!isOnBoarding}>
                    <H1_FlexRow height="148px" width="270px">
                      <UploadFileContent
                        fileTypes={[MimeType.mp4]}
                        onSelectFile={(files) => onSelectFilePresenter(files[0])}
                        disabled={importLoadingPresenter}
                      >
                        <ConditionalRender condition={!!customPresenter && !importLoadingPresenter}>
                          <H1_FlexRow width="270px" height="148px">
                            <UploadVideoImage
                              onError={onImageError}
                              src={customPresenter?.image}
                              alt={customPresenter?.name}
                              width="auto"
                              height={148}
                            />
                          </H1_FlexRow>
                        </ConditionalRender>
                        <ConditionalRender condition={!customPresenter || importLoadingPresenter}>
                          <UploadArea
                            width="270px"
                            height="148px"
                            text="Upload video"
                            progress={progress}
                            showValueLabel
                            disabled={importLoadingPresenter}
                            icon={
                              importLoadingPresenter ? (
                                <UploadIcon className="fas fa-spinner-third fa-spin" />
                              ) : (
                                <UploadIcon className="far fa-video-plus" />
                              )
                            }
                          />
                        </ConditionalRender>
                      </UploadFileContent>
                    </H1_FlexRow>
                  </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender
                  condition={characters.length > 0 && voices.length > 0 && !isTabTranslate}
                >
                  <PlaygroundVoiceSelection
                    importLoadingVoice={importLoadingVoice}
                    onShowVoiceModal={setShowVoiceModal}
                    onVoicePreviewClicked={onVoicePreviewClicked}
                    voiceIdLoading={voiceIdLoading}
                    voiceIdPlaying={voiceIdPlaying}
                    isExternalVideo={activeTab === PlaygroundVideoTypeTabs.Speaking_Video}
                  />
                </ConditionalRender>
              </H1_FlexColumn>
              <H1_FlexColumn
                padding="1px 0 0 0"
                width="100%"
                height="100%"
                flex="1 1 100%"
                gap="18px"
              >
                <ConditionalRender condition={!isTabTranslate}>
                  {/* Transcript */}
                  <StyledTextArea
                    maxLength={limits.chars_per_scene}
                    onBlur={onScriptChange}
                    defaultValue={transcript}
                    value={transcript}
                    onChange={onScriptChange}
                    $error={!!validationErrors.transcript}
                  />
                </ConditionalRender>
                <ConditionalRender condition={isTabTranslate}>
                  <PlaygroundTargetLanguage
                    selectedLanguage={selectedLanguage}
                    onSelect={onSelectLanguage}
                  />
                </ConditionalRender>
                <H1_FlexRow
                  alignSelf="flex-end"
                  align="center"
                  height="38px"
                  justify={isTabTranslate ? "flex-end" : "space-between"}
                  width="100%"
                >
                  <ConditionalRender condition={!isTabTranslate}>
                    <H1_TextSmall color={theme.gray7}>
                      {transcript?.length || 0}/{limits.chars_per_scene}
                    </H1_TextSmall>
                  </ConditionalRender>
                  <H1_FlexRow gap="18px">
                    <ConditionalRender condition={!isTabTranslate}>
                      <BlackButton
                        isDisabled={!transcript}
                        variant="light"
                        startContent={
                          <i
                            className={
                              previewTranscriptLoading
                                ? "fas fa-spinner-third fa-spin"
                                : isFullTranscriptPreviewPlaying
                                ? "fas fa-pause"
                                : "fas fa-volume"
                            }
                          />
                        }
                        onClick={onPreviewText}
                      >
                        Listen
                      </BlackButton>
                    </ConditionalRender>
                    <Button color="primary" isLoading={generateButtonLoading} onClick={onGenerate}>
                      {isTabTranslate ? "Translate" : "Generate"}
                    </Button>
                  </H1_FlexRow>
                </H1_FlexRow>
              </H1_FlexColumn>
            </H1_FlexRow>
          </ContentFlexColumn>
          <HiddenAudioPlayer>
            <audio
              autoPlay={false}
              ref={audioPreviewRef}
              src={previewVoice ? previewVoice.sample_url : (voicePreviewUrl as string)}
              onCanPlay={onCanPlayPreview}
              onEnded={onEndedAudio}
            >
              <track kind="captions" />
            </audio>
          </HiddenAudioPlayer>
        </CenterFlexColumn>
        <ConditionalRender condition={!!isCustomVoiceSet && !!voiceFileAsUrl}>
          <audio
            autoPlay={false}
            ref={customAudioPreviewRef}
            src={voiceFileAsUrl as string}
            onCanPlay={onCanPlayCustomVoice}
            onEnded={onEndedAudio}
          >
            <track kind="captions" />
          </audio>
        </ConditionalRender>
      </MainFlexColumn>
      <TranscriptAudioUploadPlaygroundModal
        file={voiceFile}
        open={showVoiceModal}
        closeModal={onCloseVoiceModal}
        reset={resetVoiceRecord}
        onSelectFile={onSelectFileVoice}
        importLoading={importLoadingVoice}
        executeImport={executeImport}
      />
      <HiddenFutureUseItems justify={"center"} flex={"1"}>
        <H1_FlexColumn flex={"0 0 50%"}>
          <FlexColumnFormContainer flex={"1 0 auto"} gap={"20px"}>
            <ConditionalRender condition={!pageLoading}>
              <Button variant="light" onClick={onRecordPresenter}>
                Record Presenter
              </Button>

              <ConditionalRender condition={!!isCustomPresenterSet}>
                <TitledInput gap={"5px"} title={"Gender"}>
                  <H1_FlexRow gap={"8px"}>
                    <ButtonBox
                      onClick={() => onGenderChange(Gender.male)}
                      icon={<i className="fa-solid fa-mars"></i>}
                      title={"Male"}
                      selected={selectedGender === Gender.male}
                    />
                    <ButtonBox
                      onClick={() => onGenderChange(Gender.female)}
                      icon={<i className="fa-solid fa-venus"></i>}
                      title={"Female"}
                      selected={selectedGender === Gender.female}
                    />
                  </H1_FlexRow>
                </TitledInput>
              </ConditionalRender>
            </ConditionalRender>
          </FlexColumnFormContainer>
        </H1_FlexColumn>
      </HiddenFutureUseItems>
    </BackgroundFlexColumn>
  );
};

export default PlayGroundPage;
