import { useAppSelector } from "app/hooks";
import { useEffect, useMemo, useState } from "react";
import { capitalize } from "lodash-es";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { CreditsData } from "app/types";

const initialObject = {
  authorized: 0,
  available: 0,
  spent: 0,
  spent_from_reset: 0,
  account: "",
  credits_type: ""
} as CreditsData;

const useCredits = () => {
  const [videoPreviewCredits, setVideoPreviewCredits] = useState<CreditsData>(initialObject);
  const [componentData, setComponentData] = useState<CreditsData>(initialObject);
  const [componentLlmData, setComponentLlmData] = useState<CreditsData>(initialObject);
  const { currentPlan: plan } = useCurrentPlan();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const creditsStatusData = useAppSelector(
    (state: any) => state.payments.creditsStatus
  ) as CreditsData[];

  const currentPlan: string = useMemo(() => {
    if (plan === "legacy") {
      return "Standard";
    }
    if (plan === "on-boarding") {
      return "Free";
    }
    return (plan && capitalize(plan)) || "";
  }, [plan]);

  useEffect(() => {
    const companyCredits = creditsStatusData.filter(
      (credits: CreditsData) => credits.account === "company"
    );
    const videoCredits = companyCredits.find((credits) => credits.credits_type === "VIDEO_PUBLISH");
    const llmCredits = companyCredits.find((credits) => credits.credits_type === "LLM");
    if (videoPreviewCredits) {
      const result = { ...videoPreviewCredits };
      setVideoPreviewCredits(result as CreditsData);
    }
    if (videoCredits) {
      const result = { ...videoCredits };
      setComponentData(result as CreditsData);
    }
    if (llmCredits) {
      const result = { ...llmCredits };
      setComponentLlmData(result as CreditsData);
    }
  }, [creditsStatusData]);

  const videoTotalNumber = componentData.authorized + componentData.available + componentData.spent;
  const videoTotalNumberInMinutes = videoTotalNumber / 60;
  const videoPercent = Number(
    videoTotalNumber ? (componentData.available * 100) / videoTotalNumber : 0
  ).toFixed(0);
  const videoPercentRemained = useMemo(() => 100 - parseInt(videoPercent, 10), [videoPercent]);

  const availableLlmCredits = componentLlmData.available;
  const llmCreditsTotalNumber =
    componentLlmData.authorized + componentLlmData.available + componentLlmData.spent_from_reset;
  const llmCreditsPercent = Number(
    llmCreditsTotalNumber ? componentLlmData.spent_from_reset / llmCreditsTotalNumber : 0
  ).toFixed(2);
  const llmCreditsPercentRemained = useMemo(
    () => parseFloat(llmCreditsPercent) * 100,
    [llmCreditsPercent]
  );
  const available = componentData.available ? Number(componentData.available) : 0;
  const availableInMinutes = available / 60;

  const usedInMinutes = videoTotalNumberInMinutes - availableInMinutes;

  const spent = componentData.spent ? Number(componentData.spent) : 0;
  const isUnlimited = !!currentWorkspace?.metadata?.unlimited;
  const llmEnable = currentWorkspace?.metadata?.llm_payment_enable;

  return {
    currentPlan,
    available,
    availableInMinutes,
    usedInMinutes,
    videoPercent,
    videoPercentRemained,
    videoTotalNumber,
    videoTotalNumberInMinutes,
    spent,
    isUnlimited,
    llmEnable,
    llmCreditsPercentRemained,
    llmCreditsTotalNumber,
    availableLlmCredits
  };
};

export default useCredits;
