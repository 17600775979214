/* eslint-disable camelcase */

// export interface LayoutAssetProperty {
//   key: string;
//   title: string;
//   type: string;
//   restrictions: any;
//   // icon: string;
//   group: string;
// }

import { PlanEnum } from "app/types/userData";
import { FontLevel } from "app/types/font";

export enum LayoutAssetTypeOptions {
  solid = "solid",
  media = "media",
  gradient = "gradient"
}
export interface LayoutAssetRestictions {
  required?: boolean;
  ratio_h?: number;
  ratio_w?: number;
  max_length?: number;
  allowed_char_pattern?: string;
  type_options?: LayoutAssetTypeOptions[];
}

export enum LayoutTextType {
  heading = "heading",
  subHeading = "sub-heading",
  body = "body",
  quote = "quote"
}

export enum LayoutColor {
  B = "B",
  W = "W",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3"
}

export enum LayoutOverflow {
  truncate = "truncate",
  scaleToFit = "scale_to_fit"
}

export enum LayoutElementType {
  background = "background",
  foreground = "foreground"
}

export interface LayoutAssetPreset {
  has_character?: boolean;
  has_text?: boolean;
  has_visual?: boolean;
  font_level?: FontLevel;
  text_type?: LayoutTextType;
  text_color?: LayoutColor;
  text_color_type?: LayoutAssetTypeOptions;
  text_color_gradient?: LayoutColor[];
  box_color_gradient?: LayoutColor[];
  box_color_type?: LayoutAssetTypeOptions;
  box_color?: LayoutColor;
  media_url?: string;
  solid_color?: LayoutColor;
  gradient_colors?: LayoutColor[];
  mockup?: boolean;
  alignment_horizontal?: TextAlignment;
  alignment_vertical?: TextAlignment;
  overflow?: LayoutOverflow;
  box?: boolean;
  in_transition?: string;
  out_transition?: string;
  in_transition_interpolation?: string;
  out_transition_interpolation?: string;
  type: string;
}

export interface LayoutAsset {
  key: string;
  title: string;
  type: string;
  restrictions?: LayoutAssetRestictions;
  preset?: LayoutAssetPreset;
  element_type?: LayoutElementType;
  hidden?: boolean;
  index: string;
  // icon: string;
  group: string;
  // properties: LayoutAssetProperty[];
  card_id?: string;
}

export type MediaCategory = "media" | "visual";
export type LayoutCategory = "text" | MediaCategory | "voice" | "character" | "color" | "float";

export interface LayoutCard {
  id: string;
  name: string;
}
export interface Layout {
  id: string;
  description: string;
  name: "my layout default";
  thumbnail_image: string;
  thumbnail_video: string;
  updated_at: string;
  created_at: string;
  version: string;
  archived: false;
  assets: Record<LayoutCategory, LayoutAsset[] | undefined>; // Should be LayoutAsset | LayoutAsset[] but it forces many changes
  plans?: PlanEnum[];
  cards?: LayoutCard[];
}

export interface TemplateLayout extends Layout {
  image: string;
  video: string;
  available: boolean;
}

export enum TitleProperty {
  textColor = "textColor",
  textBackgroundColor = "textBackgroundColor",
  textAlignment = "textAlignment",
  textFont = "textFont",
  textOverflow = "textOverflow"
}

export enum TextAlignment {
  left = "left",
  right = "right",
  center = "center",
  top = "top",
  middle = "middle",
  bottom = "bottom"
}
