import styled, { css } from "styled-components";

import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useDrawer from "app/hooks/useDrawer";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { Preferences } from "app/types/preferences";
import { useAppSelector } from "app/hooks";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const StyledIconContainer = styled(H1_FlexRow)`
  display: none;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
const Icon = styled.i`
  color: ${({ theme }) => theme.gray7};
`;

const Container = styled(H1_FlexColumn)`
  position: absolute;
  overflow-x: hidden;
  height: 100%;
  flex: 1 1 auto;
  transition: width 0.3s ease-in, opacity 0.3s ease-in, height 0.3s ease-in;
  ${({
    active,
    $right,
    $left,
    $top,
    $width,
    borderLeft,
    borderRight,
    $position
  }: {
    active: boolean;
    $right?: string;
    $left?: string;
    $top?: string;
    $width: string;
    borderLeft: string;
    borderRight: string;
    $position: string;
  }) => css`
    width: ${active ? $width : "0"};
    opacity: ${active ? 1 : 0};
    right: ${$right};
    left: ${$left};
    top: ${$top};
    border-left: ${borderLeft};
    border-right: ${borderRight};
    position: ${$position};
    z-index: ${active ? 2 : 1};
    max-height: inherit;
    @media (max-width: ${SMALL_SCREEN_PX}) {
      width: 100%;
      height: ${active ? "100%" : "0"};
      position: ${active ? "relative" : "absolute"};
      right: unset;
      top: unset;
      left: unset;
    }
  `};
`;

export interface FlexDrawerProps {
  right?: string;
  left?: string;
  active?: boolean;
  top?: string;
  padding?: string;
  width: string;
  borderLeft?: string;
  borderRight?: string;
  position?: string;
  className?: string;
  children?: React.ReactNode;
}

const FlexDrawer = ({
  right,
  left,
  active,
  top,
  width,
  padding,
  borderLeft,
  borderRight,
  position,
  className,
  children
}: FlexDrawerProps) => {
  const { closeDrawer } = useDrawer();
  const preferences: Preferences = useAppSelector((state) => state.user.preferences);
  const isReversedEditor = preferences.is_reversed_editor;
  return (
    <Container
      $right={isReversedEditor ? left : right || "0"}
      $left={isReversedEditor ? right : left}
      active={active || false}
      $top={top || "auto"}
      $width={width}
      padding={padding || "20px 0 20px 24px"}
      borderLeft={borderLeft || "none"}
      borderRight={borderRight || "none"}
      $position={position || "absolute"}
      className={className}
    >
      <ConditionalRender condition={active}>{children}</ConditionalRender>
      <StyledIconContainer onClick={() => closeDrawer()}>
        <Icon className="far fa-close" />
      </StyledIconContainer>
    </Container>
  );
};

export default FlexDrawer;
