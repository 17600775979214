import { defineMessages } from "react-intl";

export const importDocumentsModalMessages = defineMessages({
  DocumentHeadlineTitle: {
    id: `documents.modal.document-headline-title`,
    defaultMessage: "Start from a document"
  },
  ScriptHeadlineTitle: {
    id: `documents.modal.script-headline-title`,
    defaultMessage: "Start from a script"
  },
  UrlHeadlineTitle: {
    id: `documents.modal.url-headline-title`,
    defaultMessage: "Start from a url"
  },
  PresentationHeadlineTitle: {
    id: `documents.modal.presentation-headline-title`,
    defaultMessage: "Start from a presentation"
  },
  FirstStepDocument: {
    id: `documents.modal.first-step-document`,
    defaultMessage: "Upload Document"
  },
  FirstStepUrl: {
    id: `documents.modal.first-step-url`,
    defaultMessage: "Enter Your Url"
  },
  FirstStepScript: {
    id: `documents.modal.first-step-script`,
    defaultMessage: "Paste Script"
  },
  FirstStepPresentation: {
    id: `documents.modal.first-step-presentation`,
    defaultMessage: "Upload presentation"
  },
  SecondStepPresentation: {
    id: `documents.modal.second-step-presentation`,
    defaultMessage: "Settings"
  },
  SecondStepDocument: {
    id: `documents.modal.second-step-document`,
    defaultMessage: "Preview"
  },
  SecondStepUrl: {
    id: `documents.modal.second-step-url`,
    defaultMessage: "Settings"
  },
  ThirdStepDocument: {
    id: `documents.modal.third-step-document`,
    defaultMessage: "Style"
  },
  CreateVideoButton: {
    id: `documents.modal.create-video-button`,
    defaultMessage: "Create my video"
  },
  wishToDoTitle: {
    id: `documents.modal.wish-to-do-title`,
    defaultMessage: "What do you wish to do?"
  },
  videoLengthTitle: {
    id: `documents.modal.video-length-title`,
    defaultMessage: "Video length (in seconds)"
  },
  ChangeButton: {
    id: `documents.modal.change-button`,
    defaultMessage: "Change"
  },
  CreateScriptFrom: {
    id: `documents.modal.create-script-from`,
    defaultMessage: "Create script from"
  },
  Notes: {
    id: `documents.modal.notes`,
    defaultMessage: "Notes"
  },
  Slides: {
    id: `documents.modal.slides`,
    defaultMessage: "Text in slides"
  },
  Paste: {
    id: `documents.modal.paste`,
    defaultMessage: "Paste"
  },
  Summarize: {
    id: `documents.modal.summarize`,
    defaultMessage: "Summarize"
  },
  validationTextLengthError: {
    id: "documents.modal.validation-text-length-error",
    defaultMessage: "Text exceeded {MAX_LENGTH} characters limit."
  }
});

export const documentFileTypeErrors = defineMessages({
  documentTypeErrorHeadline: {
    id: `documents.modal-uploader.type.error.headline`,
    defaultMessage: "Whoops... this is the wrong file format"
  },
  documentsTypeErrorDescription: {
    id: `documents.modal-uploader.type.error.description`,
    defaultMessage: "We're sorry but we currently only support { acceptableTypes }"
  }
});

export const documentFileSizeErrors = defineMessages({
  documentSizeErrorHeadline: {
    id: `documents.modal-uploader.size.error.headline`,
    defaultMessage: "Whoops... the file size exceeds the limit"
  },
  documentsSizeErrorDescription: {
    id: `documents.modal-uploader.size.error.description`,
    defaultMessage: "We're sorry but we currently only support { limitSize } MB"
  }
});

export const documentModalUploaderMessages = defineMessages({
  mainDescription: {
    id: `documents.modal-uploader.main-description`,
    defaultMessage: "Upload document or Drag and drop"
  },
  description: {
    id: `documents.modal-uploader.description`,
    defaultMessage: ".docx / .pdf formats are supported"
  },
  browseButton: {
    id: `documents.modal-uploader.browse-button`,
    defaultMessage: "Choose file"
  },
  changeFileButton: {
    id: `documents.modal-uploader.change-file-button`,
    defaultMessage: "Change file"
  },
  uploadingDocument: {
    id: `documents.modal-uploader.uploading-document`,
    defaultMessage: "Uploading your document"
  }
});

export const videoLoaderModalMessages = defineMessages({
  HeadlineTitle: {
    id: `documents.modal-video-loader.headline-title`,
    defaultMessage: "Creating video"
  },
  SubTitle: {
    id: `documents.modal-video-loader.sub-title`,
    defaultMessage: "Sit tight! your {startFromType} is being turned into an amazing video!"
  }
});

export const PresentationLoaderModalMessages = defineMessages({
  HeadlineTitle: {
    id: `documents.modal-presentation-loader.headline-title`,
    defaultMessage: "Uploading your presentation"
  },
  SubTitle: {
    id: `documents.modal-presentation-loader.sub-title`,
    defaultMessage: "Sit tight! this may take a few seconds"
  }
});

export const urlModalUploaderMessages = defineMessages({
  mainDescription: {
    id: `documents.modal-uploader.url-main-description`,
    defaultMessage: "Enter Url"
  },
  description: {
    id: `documents.modal-uploader.url-description`,
    defaultMessage: "We will get data from url and summarize it"
  },
  uploadingUrl: {
    id: `documents.modal-uploader.uploading-url`,
    defaultMessage: "Uploading your url"
  },
  urlInput: {
    id: `documents.modal-uploader.url-input`,
    defaultMessage: "http://"
  },
  nextButton: {
    id: `documents.modal-uploader.next-button`,
    defaultMessage: "Next"
  },
  changeUrlButton: {
    id: `documents.modal-uploader.change-url-button`,
    defaultMessage: "Change Url"
  },
  videoLength: {
    id: `documents.modal.video-length`,
    defaultMessage: "Video length (in seconds)"
  },
  seconds15: {
    id: `documents.modal.15-seconds`,
    defaultMessage: "15s"
  },
  seconds30: {
    id: `documents.modal.30-seconds`,
    defaultMessage: "30s"
  },
  seconds45: {
    id: `documents.modal.45-seconds`,
    defaultMessage: "45s"
  },
  seconds60: {
    id: `documents.modal.60-seconds`,
    defaultMessage: "60s"
  },
  seconds75: {
    id: `documents.modal.75-seconds`,
    defaultMessage: "75s"
  }
});

export const urlTypeErrors = defineMessages({
  urlTypeErrorHeadline: {
    id: `documents.modal-uploader.type.error.url-headline`,
    defaultMessage: "Url is not valid"
  }
});
