/* eslint-disable no-param-reassign */
import { forEach, every } from "lodash-es";

import { IntlShape } from "react-intl";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import {
  Scene,
  ValidationErrors,
  Draft,
  Template,
  SceneValidationErrors,
  illegalCharacterFormat,
  MetadataLimits,
  illegalCharacterFormatGlobal,
  DraftType,
  Webhook
} from "app/types";
import LinkButton from "app/components/common/Layout/LinkButton";
import { knowledgeBaseUrlModeration, knowledgeBaseUrlModerationEthics } from "app/utils/urls";
import styled from "styled-components";
import { Preferences } from "app/types/preferences";
import { ReactNode } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { getTextOutOfSML } from "app/utils/helpers";
import messages from "app/components/editor/validations-messages";

const StyledLinkButton = styled(LinkButton)`
  display: inline-flex;
  font-size: 12px;
  color: #5a5aff;
`;

const BlockTextXs = styled(H1_TextXs)`
  display: inline-block;
`;

const DescriptionFlexColumn = styled(H1_FlexColumn)`
  ul {
    padding-left: 0;
    list-style: none;
    li::before {
      content: "•";
      color: #8c8c8c;
      display: inline-block;
      width: 1em;
    }
    span {
      display: inline-flex;
    }
  }
`;

export const validateIllegalCharsTranscript = (text: string) => {
  return !illegalCharacterFormat.test(text);
};

export const illegalCharactersInText = (text: string) => {
  return text.matchAll(illegalCharacterFormatGlobal);
};

export const removeIllegalCharactersInText = (text: string) => {
  return text.replace(illegalCharacterFormatGlobal, "");
};

function validateTranscriptOnScenes(
  scenes: Scene[],
  errors: ValidationErrors,
  sceneIdsErrors: SceneValidationErrors,
  intl: IntlShape,
  headerMessage: string,
  limits: MetadataLimits,
  draftType?: DraftType
) {
  const emptyTranscript: number[] = [];
  const badCharacters: number[] = [];
  forEach(scenes, (scene, index) => {
    if (scene.attributes.media?.transcript?.url) {
      // skipping on transcript text when there is audio
      return true;
    }

    const transcript =
      getTextOutOfSML(scene?.attributes?.text?.transcript?.synthesis_markup_language?.nodes) ||
      String(scene?.attributes?.text?.transcript?.text).trim();
    const isTranscriptFound = !!transcript;
    const itHasVariable =
      draftType === "workflow" &&
      scene?.attributes?.text?.transcript?.synthesis_markup_language?.nodes.some(
        (node) => node.type === "variable"
      );
    if (!isTranscriptFound && !itHasVariable) {
      emptyTranscript.push(index);
    } else if (!validateIllegalCharsTranscript(transcript)) {
      badCharacters.push(index);
    }

    return true;
  });
  const transcriptMaxLengthCheck = every(scenes, (scene) => {
    const transcript =
      getTextOutOfSML(scene?.attributes?.text?.transcript?.synthesis_markup_language?.nodes) ||
      String(scene?.attributes?.text?.transcript?.text).trim();
    return scene.attributes.media?.transcript?.url || transcript.length <= limits.chars_per_scene;
  });
  if (!transcriptMaxLengthCheck) {
    errors.transcriptLength = {
      type: "alert",
      message: "Oops, Scene Too Long!",
      description: (
        <H1_TextXs color="#8C8C8C" whiteSpace="normal">
          Lets keep it within {limits.chars_per_scene} characters for the best results.
        </H1_TextXs>
      )
    };
  }
  if (emptyTranscript.length) {
    errors.transcript = {
      type: "alert",
      message: headerMessage,
      description: (
        <DescriptionFlexColumn>
          <H1_TextXs color="#8C8C8C">
            {intl.formatMessage(
              emptyTranscript.length > 1 ? messages.transcriptDescPlurals : messages.transcriptDesc
            )}
          </H1_TextXs>
          <ul>
            {emptyTranscript.map((num) => (
              <li key={num}>
                <H1_TextXs color="#8C8C8C">
                  {intl.formatMessage(messages.transcriptSceneNumber, { number: num + 1 })}
                </H1_TextXs>
              </li>
            ))}
          </ul>
        </DescriptionFlexColumn>
      )
    };

    emptyTranscript.forEach((index) => {
      sceneIdsErrors[scenes[index].id] = {
        ...sceneIdsErrors[scenes[index].id],
        transcript: errors.transcript
      };
    });
  }
  if (badCharacters.length) {
    errors.transcript = {
      type: "alert",
      message: intl.formatMessage(messages.transcriptDescBadCharsHeader),
      description: (
        <DescriptionFlexColumn>
          <H1_TextXs whiteSpace="normal" color="#8C8C8C">
            {intl.formatMessage(
              badCharacters.length > 1
                ? messages.transcriptDescBadCharsPlurals
                : messages.transcriptBadCharsDesc
            )}
          </H1_TextXs>
          <ul>
            {badCharacters.map((num) => (
              <li key={num}>
                <H1_TextXs color="#8C8C8C">
                  {intl.formatMessage(messages.transcriptSceneNumber, { number: num + 1 })}
                </H1_TextXs>
              </li>
            ))}
          </ul>
        </DescriptionFlexColumn>
      )
    };

    badCharacters.forEach((index) => {
      sceneIdsErrors[scenes[index].id] = {
        ...sceneIdsErrors[scenes[index].id],
        transcript: errors.transcript
      };
    });
  }
}

export const validateCreateVideo = (
  draft: Draft,
  scenes: Scene[],
  template: Template,
  intl: IntlShape,
  limits: MetadataLimits,
  preferences?: Preferences
) => {
  const sceneIdsErrors: SceneValidationErrors = {};
  const errors: ValidationErrors = {};
  const videoErrorHeader = intl.formatMessage(messages.cannotCreateVideo);
  validateDraftAssets(errors, draft, template);
  validateCharacter(errors, draft, intl, preferences);
  validateTranscriptOnScenes(
    scenes,
    errors,
    sceneIdsErrors,
    intl,
    videoErrorHeader,
    limits,
    draft.type
  );

  let mediaCheck = true;
  const transcriptSymbolsCheck = true;
  let sceneErrorIndex = -1;
  forEach(scenes, (scene, index) => {
    sceneErrorIndex = index;
    mediaCheck =
      mediaCheck &&
      every(scene.layout.assets.media, (media) => {
        return !!scene.attributes.media?.[media.key]?.url;
      });

    return mediaCheck;
  });

  if (!transcriptSymbolsCheck) {
    errors.transcript = BuildErrorNotification(
      intl.formatMessage(messages.cannotCreateVideo),
      intl.formatMessage(messages.symbolDesc, { number: sceneErrorIndex + 1 })
    );
    sceneIdsErrors[scenes[sceneErrorIndex].id] = {
      ...sceneIdsErrors[scenes[sceneErrorIndex].id],
      transcript: errors.transcript
    };
  }

  if (!mediaCheck) {
    errors.media = BuildErrorNotification(
      intl.formatMessage(messages.cannotCreateVideo),
      intl.formatMessage(messages.mediaDesc, { number: sceneErrorIndex + 1 })
    );
    sceneIdsErrors[scenes[sceneErrorIndex].id] = {
      ...sceneIdsErrors[scenes[sceneErrorIndex].id],
      media: errors.media
    };
  }
  return { errors, sceneIdsErrors };
};

export const BuildErrorNotification = (message: string, description: string) => {
  return {
    type: "alert",
    message,
    description: (
      <DescriptionFlexColumn>
        <H1_TextXs color="#8C8C8C">{description}</H1_TextXs>
      </DescriptionFlexColumn>
    )
  };
};

const validateCharacter = (
  errors: ValidationErrors,
  draft: Draft,
  intl: IntlShape,
  preferences?: Preferences
) => {
  const presenterCheck =
    !!draft.attributes?.character?.character?.character_id || !!preferences?.default_character_id;

  const voiceCheck = !!draft.attributes?.voice?.voice?.voice_id || !!preferences?.default_voice_id;
  if (!presenterCheck) {
    errors.character_id = BuildErrorNotification(
      intl.formatMessage(messages.cannotCreateVideo),
      intl.formatMessage(messages.characterDesc)
    );
  }
  if (!voiceCheck) {
    errors.voice_id = BuildErrorNotification(
      intl.formatMessage(messages.cannotCreateVideo),
      intl.formatMessage(messages.voiceDesc)
    );
  }
  return errors;
};

export const validateAudioPreview = (
  draft: Draft,
  scenes: Scene[],
  intl: IntlShape,
  limits: MetadataLimits,
  preferences?: Preferences
) => {
  const errors: ValidationErrors = {};
  const sceneIdsErrors: SceneValidationErrors = {};
  validateCharacter(errors, draft, intl, preferences);
  const videoErrorHeader = intl.formatMessage(messages.cannotAudioPreview);
  validateTranscriptOnScenes(
    scenes,
    errors,
    sceneIdsErrors,
    intl,
    videoErrorHeader,
    limits,
    draft.type
  );

  return { errors, sceneIdsErrors };
};

export const validateServerError = (error = "", intl: IntlShape) => {
  const errors: ValidationErrors = {};
  const modId = error ? error.match(/%(.*?)%/i) : undefined;
  if (error.includes("Didn't pass media moderation test")) {
    errors.moderation = {
      type: "alert",
      message: intl.formatMessage(messages.moderationMediaMsg),
      description: (
        <BlockTextXs whiteSpace="normal" color="#8c8c8c">
          {
            // @ts-ignore handels intl formatting with elements issue
            intl.formatMessage(messages.moderationMediaDesc, {
              link: (text: string) => (
                <StyledLinkButton
                  $fontSize="12px"
                  $color="var(--blue-06)"
                  $disabledColor="var(--gray-05)"
                  onClick={() => window.open(knowledgeBaseUrlModerationEthics, "_blank")}
                >
                  {text}
                </StyledLinkButton>
              )
            }) as ReactNode
          }
        </BlockTextXs>
      ),
      modId: modId ? modId[1] : undefined,
      duration: 0
    };
  } else if (
    ["Didn't pass voice moderation test", "Didn't pass transcript moderation test"]
      .map((message) => error.includes(message))
      .includes(true)
  ) {
    errors.moderation = {
      type: "alert",
      message: intl.formatMessage(messages.moderationTxtMsg),
      description: (
        <BlockTextXs whiteSpace="normal" color="#8c8c8c">
          {
            // @ts-ignore handels intl formatting with elements issue
            intl.formatMessage(messages.moderationTxtDesc, {
              link: (text: string) => (
                <StyledLinkButton
                  $fontSize="12px"
                  $color="var(--blue-06)"
                  $disabledColor="var(--gray-05)"
                  onClick={() => window.open(knowledgeBaseUrlModeration, "_blank")}
                >
                  {text}
                </StyledLinkButton>
              )
            }) as ReactNode
          }
        </BlockTextXs>
      ),
      modId: modId ? modId[1] : undefined,
      duration: 0
    };
  } else if (error.includes("This text can't include")) {
    errors.transcript = {
      type: "alert",
      message: "There are transcript issues",
      description: (
        <H1_TextXs whiteSpace="normal" color="#8c8c8c">
          {error}
        </H1_TextXs>
      ),
      modId: modId ? modId[1] : undefined
    };
  } else if (error.includes("asset is not available, please select another")) {
    const subject = error.split(" ")[0];
    errors.transcript = {
      type: "alert",
      message: `${subject} unavailable`,
      description: (
        <H1_TextXs whiteSpace="normal" color="#8c8c8c">
          Please select another {subject.toLowerCase()}
        </H1_TextXs>
      ),
      modId: modId ? modId[1] : undefined
    };
  } else {
    errors.general = buildGeneralError(error, intl);
  }
  return errors;
};
function validateDraftAssets(errors: ValidationErrors, draft: Draft, template: Template) {
  const missingFieldsByGroup: Record<string, string[]> = {};
  Object.entries(template.assets).forEach(([key, asset]) => {
    if (!asset.restrictions?.required || asset.type === "color") {
      return;
    }
    let value;

    switch (asset.type) {
      case "text":
        value = draft.attributes?.text?.[key]?.text;

        break;

      case "media":
        value = draft.attributes?.media?.[key]?.url;
        break;
      default:
        return;
    }

    if (!asset.group) {
      //  todo temp for skip on character and voice we have explicit validation for that
      return;
    }
    if (!value) {
      if (missingFieldsByGroup[asset.group]) {
        missingFieldsByGroup[asset.group].push(asset.title);
      } else {
        missingFieldsByGroup[asset.group] = [asset.title];
      }
    }
  });

  Object.entries(missingFieldsByGroup).forEach(([style, asset]) => {
    errors[style] = {
      type: "alert",
      message: `Missing ${style} assets`,
      description: (
        <H1_TextXs whiteSpace="normal" color="#8c8c8c">
          assets: {asset.join(",")}
        </H1_TextXs>
      )
    };
  });

  return errors;
}

export const validateWebHook = (webhook: Partial<Webhook> | undefined) => {
  const errors: Record<keyof Webhook, string> = {} as Record<keyof Webhook, string>;
  if (!validateHttpsUrl(webhook?.url || "url")) {
    errors["url"] = "A valid https url required";
  }
  if (!webhook?.events?.length) {
    errors["events"] = "At least one event should be chosen";
  }
  if (!webhook?.name || webhook?.name.length > 256) {
    errors["name"] = "Name is required and max 256 characters are allowed";
  }

  return errors;
};
export const validateHttpsUrl = (url: string) => {
  // Regular expression to match URLs with "https" scheme
  const httpsPattern =
    /^https:\/\/[A-Za-z0-9.-]+(:\d{1,5})?(\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

  return httpsPattern.test(url);
};
