import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MediaFileType, Video, VideoResolution } from "app/types";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";
import { useIntl } from "react-intl";
import { videoModalsMessages } from "app/pages/editor/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/circle-upgrade-icon.svg";
import useDownload from "app/hooks/useDownload";
import { NotSupportedPlansPerFeatures, PlanFeature } from "app/config/planFeature";
import { videosActions } from "app/store/slices/videos.slice";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextSmall,
  H1_TextSubtitle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { Button, Select, SelectItem, RadioGroup, Radio, Selection } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const LargeButton = styled(Button)`
  height: 40px;
  width: 100%;
  gap: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioFlexRow = styled(H1_FlexRow)`
  label {
    height: 22px;
    margin-right: 0;
  }
`;

const RadioGroupFlexRow = styled(H1_FlexRow)`
  margin-bottom: 19px;
`;

const DownloadVideoModal = () => {
  const [fileType, setFileType] = useState<MediaFileType>(MediaFileType.mp4);
  const [resolution, setResolution] = useState<VideoResolution>(VideoResolution["1080p"]);
  const { downloadVideo } = useDownload({ resolution });
  const { returnToModal, editorModalOpen } = useModal();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { currentPlan: plan } = useCurrentPlan();
  const { videoId, source } = editorModalOpen?.context || {};
  const selectedVideo: Video | undefined = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId as string)
  );
  const downloadVideoFormat = useAppSelector((state) => state.videos.downloadVideoFormat);
  const draftId: string | undefined = selectedVideo?.draft_id;
  const isLoading: boolean =
    downloadVideoFormat[videoId]?.status === PusherMessagesStatusEnum.processing;
  const visible = editorModalOpen?.open === ModalName.downloadVideo;

  const shouldShowUpgradeIcon =
    !selectedVideo?.paid &&
    NotSupportedPlansPerFeatures[PlanFeature.DownloadVideoFullHd].includes(plan) &&
    resolution === VideoResolution["1080p"];
  const theme = useTheme();

  useEffect(() => {
    if (!visible) {
      setFileType(MediaFileType.mp4);
      setResolution(VideoResolution["1080p"]);
    }
  }, [visible]);
  const onCloseModal = () => {
    if (downloadVideoFormat[videoId]) {
      dispatch(
        videosActions.setDownloadVideoFormat({
          videoId,
          orderId: downloadVideoFormat[videoId].orderId,
          status: PusherMessagesStatusEnum.ready
        })
      );
    }
    returnToModal();
  };

  const onResolutionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResolution(e.target.value as VideoResolution);
  };

  const onChangeFileType = (selection: Selection) => {
    const value = Array.from(selection)[0] as MediaFileType;
    setFileType(value);
  };

  const onDownload = () => {
    downloadVideo({
      source,
      video: selectedVideo as Video,
      fileType,
      backModal: ModalName.downloadVideo,
      draftId: draftId as string
    });
  };

  return (
    <StyledModal
      width="385px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "295px" }}
      onCancel={onCloseModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="31px 38px 45px"
      $alignItems="center"
    >
      <H1_FlexColumn align="flex-start" gap="17px" width="100%">
        <H1_FlexRow>
          <H1_TextSubtitle color={theme.blue4}>
            {formatMessage(videoModalsMessages.downloadThisVideo)}
          </H1_TextSubtitle>
        </H1_FlexRow>
        <H1_FlexColumn gap="8px" width="100%">
          <H1_TextXs color={theme.gray8}>{formatMessage(videoModalsMessages.fileType)}</H1_TextXs>
          <Select
            labelPlacement="outside"
            size="sm"
            defaultSelectedKeys={[fileType]}
            onSelectionChange={onChangeFileType}
          >
            <SelectItem
              color="primary"
              key={MediaFileType.mp4}
              textValue={formatMessage(videoModalsMessages.fileTypeMp4)}
              value={MediaFileType.mp4}
            >
              <H1_TextSmall>{formatMessage(videoModalsMessages.fileTypeMp4)}</H1_TextSmall>
            </SelectItem>
            <SelectItem
              color="primary"
              key={MediaFileType.webm}
              textValue={formatMessage(videoModalsMessages.fileTypeWebm)}
              value={MediaFileType.webm}
            >
              <H1_TextSmall>{formatMessage(videoModalsMessages.fileTypeWebm)}</H1_TextSmall>
            </SelectItem>
            <SelectItem
              color="primary"
              key={MediaFileType.mov}
              textValue={formatMessage(videoModalsMessages.fileTypeMov)}
              value={MediaFileType.mov}
            >
              <H1_TextSmall>{formatMessage(videoModalsMessages.fileTypeMov)}</H1_TextSmall>
            </SelectItem>
          </Select>
        </H1_FlexColumn>
        <RadioGroupFlexRow height="22px" width="100%">
          <RadioGroup onChange={onResolutionChange} value={resolution}>
            <RadioFlexRow gap="28px">
              <Radio value={VideoResolution["1080p"]}>
                <H1_TextSmall>{formatMessage(videoModalsMessages.highRes)}</H1_TextSmall>
              </Radio>
              <Radio value={VideoResolution["720p"]}>
                <H1_TextSmall>{formatMessage(videoModalsMessages.mediumRes)}</H1_TextSmall>
              </Radio>
            </RadioFlexRow>
          </RadioGroup>
        </RadioGroupFlexRow>
        <LargeButton
          color="primary"
          onClick={onDownload}
          isLoading={isLoading}
          startContent={shouldShowUpgradeIcon ? <UpgradeIcon /> : ""}
        >
          {formatMessage(videoModalsMessages.downloadButton)}
        </LargeButton>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default DownloadVideoModal;
