import { Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesActions } from "app/store/slices/scenes.slice";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { FeatureFlag } from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { Button } from "@nextui-org/react";

export const messages = defineMessages({
  visible: {
    id: "editor.scene-list.skip-button-show",
    defaultMessage: "Show scene"
  },
  hide: {
    id: "editor.scene-list.skip-button-hide",
    defaultMessage: "Hide scene"
  }
});

const HoverButton = styled(Button)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  &:disabled {
    opacity: 0.3;
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
`;

interface SkipButtonProps {
  sceneId: string;
  disabled: boolean;
}
const SkipButton = ({ sceneId, disabled }: SkipButtonProps) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const flags = useFlags();
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const scene = useAppSelector((state) => scenesGlobalSelectors.selectById(state, sceneId));

  const onSkip = () => {
    const value = !scene?.skip;
    dispatch(
      scenesActions.patchSceneRequest({
        draftId: currentDraft.id as string,
        sceneId,
        operations: [{ op: "replace", path: "skip", value }]
      })
    );
  };

  if (!flags[FeatureFlag.skipScene]) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      title={scene?.skip ? formatMessage(messages.visible) : formatMessage(messages.hide)}
    >
      <>
        <HoverButton
          variant="light"
          isIconOnly
          onClick={onSkip}
          isdisabled={disabled}
          startContent={<i className={scene?.skip ? "far fa-eye-slash" : "far fa-eye"} />}
        />
      </>
    </Tooltip>
  );
};

export default SkipButton;
