import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { Channel } from "pusher-js";
import { DraftGeneratorMsg } from "app/types/pusherMessages";
import { useAppDispatch } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import * as Sentry from "@sentry/react";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import useErrors from "app/hooks/useErrors";
import { useIntl } from "react-intl";
import { draftsActions } from "app/store/slices/drafts.slice";

const PusherDraftGenerator = () => {
  const intl = useIntl();
  const { user } = useAuth();
  const { notifyError } = useErrors();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleDraftGenerator = (message: DraftGeneratorMsg) => {
      const { status, draft_id, order_id } = message;
      if (status === "ready") {
        dispatch(analyticsEvents.DraftGeneratorResult({ status: "success" }));
        dispatch(
          draftsActions.setDraftGenerator({
            orderId: order_id,
            draftId: draft_id,
            status: status
          })
        );
      } else if (status === "failed") {
        dispatch(
          draftsActions.setDraftGenerator({
            orderId: order_id,
            status: status
          })
        );
        Sentry.captureException("draft_generator failed", {
          extra: { description: "draft_generator failed" }
        });
        dispatch(analyticsEvents.DraftGeneratorResult({ status: "fail" }));
        dispatch(
          analyticsEvents.somethingWentWrongEvent({
            message: "Failed import document"
          })
        );
        notifyError({ general: buildGeneralError("Failed importing document", intl) });
      }
    };
    if (user?.sub) {
      const userId = user.sub.split("|")[1];
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("draft_generator", handleDraftGenerator);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherDraftGenerator;
