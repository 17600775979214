import React, { ReactNode, useState } from "react";
import { Affix, Row } from "antd";
import EditingCharacterDiscover from "app/components/editor/sideDrawers/EditingCharacterDiscover/EditingCharacterDiscover";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import * as voiceSelectors from "app/store/selectorsV2/voices.selectors";
import "app/components/editor/Editor.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { DevModeSize, Draft, FeatureFlag, PatchOperation, Voice } from "app/types";
import { defineMessages, useIntl } from "react-intl";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { scenesActions } from "app/store/slices/scenes.slice";
import { Character } from "app/types/character";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import styled from "styled-components";
import usePermissions from "app/hooks/usePermissions";
import VideoWithHover from "app/components/common/VideoWithHover";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import DevModePopover from "app/components/DevModePopover";
import { voicesActions } from "app/store/slices/voices.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import useDrawer, { Drawer } from "app/hooks/useDrawer";

export const messages = defineMessages({
  title: {
    id: "editor.drawer.character.title",
    defaultMessage: "<b>Pick a</b> Presenter"
  }
});

const StyledAffix = styled(Affix)`
  padding-bottom: 20px;
  video {
    background-color: ${(props) => props.theme.gray3};
    max-width: 335px;
    max-height: calc(335px * 9 / 16);
  }
`;

const PaddingFlexColumn = styled(H1_FlexColumn)`
  padding-right: 22px;
  overflow: hidden;
`;

const ScrollingRow = styled(Row)`
  overflow-y: auto;
  margin-right: -22px;
  padding-right: 15px;
`;

export interface EditorCharacterDrawerProps {
  right: string;
  width: string;
}

const EditingCharacterCustomizeDrawer = ({ right, width }: EditorCharacterDrawerProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { isWriteWorkspaceAllow } = usePermissions();
  const flags = useFlags();
  const { editorDrawerOpen } = useDrawer();

  const sceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const character = useAppSelector(characterSelectors.getDrawerCharacterBySelectedScene);
  const voice: Voice | undefined = useAppSelector(voiceSelectors.getSelectedVoiceForDrawer);
  const draftId = draft?.id as string;
  const isDrawerActive = !flags[FeatureFlag.avatarPanel] && editorDrawerOpen === Drawer.Presenter;

  const onCharacterChange = ({
    characterId,
    voiceId
  }: {
    characterId: string;
    voiceId?: string;
  }) => {
    if (!isWriteWorkspaceAllow) {
      return;
    }

    const operations: PatchOperation[] = [
      { op: "replace", path: "attributes.character.character.character_id", value: characterId }
    ];
    if (voiceId) {
      // todo this is for the case that there is no default voiceId.
      operations.push({
        op: "replace",
        path: "attributes.voice.voice.voice_id",
        value: voiceId
      } as PatchOperation);
      dispatch(voicesActions.cleanFooterAudio());
    }
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations
      })
    );
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId: sceneId as string,
        operations,
        skipRePreview: true
      })
    );
  };

  return (
    <FlexDrawer right={right} active={isDrawerActive} width={width}>
      <PaddingFlexColumn
        className="side-drawer character-drawer"
        width="auto !important"
        overflow="auto"
      >
        <TitleFlexRow height="52px">
          {
            // @ts-ignore handels formmating issue with html tags params
            intl.formatMessage(messages.title, {
              b: (str) => (
                <H1_TextMiddle fontSize="18px" fontWeight={300}>
                  {str}
                </H1_TextMiddle>
              )
            }) as ReactNode
          }
        </TitleFlexRow>
        <H1_FlexColumn flex="1 1 auto" ref={setContainer}>
          <StyledAffix offsetTop={20} target={() => container}>
            <DevModePopover
              size={DevModeSize.large}
              placement="left"
              value={{ character_id: character?.id as string }}
              path={"root"}
            />
            <VideoWithHover video={character?.video || ""} image={character?.image} disableIcon />
          </StyledAffix>
          <ScrollingRow>
            <EditingCharacterDiscover
              voice={voice}
              character={character as Character}
              onCharacterChange={onCharacterChange}
            />
          </ScrollingRow>
        </H1_FlexColumn>
      </PaddingFlexColumn>
    </FlexDrawer>
  );
};

export default EditingCharacterCustomizeDrawer;
