import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Draft, FeatureFlag } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { Switch } from "antd";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexRow = styled(H1_FlexRow)<{ $isTransparent: boolean }>`
  background-color: ${({ theme, $isTransparent }) =>
    $isTransparent ? "transparent" : theme.gray4};
  border-radius: 4px;
`;

const BlueSwitch = styled(Switch)`
  &.ant-switch {
    background-color: ${({ theme, checked }) =>
      checked ? theme.blue4 : theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray4};
  }
`;

const ApplyToScenes = ({
  isTransparent = false,
  element,
  shouldAddRightPadding = false,
  padding
}: {
  isTransparent?: boolean;
  element: string;
  shouldAddRightPadding?: boolean;
  padding: string;
}) => {
  const intl = useIntl();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { scene } = useSelectedScene();
  const draftGlobalCharacterStatus = useAppSelector(
    (state) => state.drafts.draftGlobalCharacterStatus
  );
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft) as Draft;
  const isApplyAllScenes = currentDraft?.global_character;
  const layoutCharacter = scene?.layout?.assets?.character;

  useEffect(() => {
    if (draftGlobalCharacterStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateDraftGlobalCharacterStatusToIdle());
    }
    if (draftGlobalCharacterStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateDraftGlobalCharacterStatusToIdle());
    }
  }, [draftGlobalCharacterStatus]);

  const onChangeApplySettings = (checked: boolean) => {
    dispatch(
      analyticsEvents.changeMultipleCharacterSettings({
        clickAllScenes: !checked,
        draftId: currentDraft.id as string
      })
    );
    dispatch(
      draftsActions.updateDraftGlobalCharacterRequest({
        globalCharacter: !checked
      })
    );
  };

  return (
    <ConditionalRender condition={flags[FeatureFlag.multiplePresenters] && !!layoutCharacter}>
      <BackgroundFlexRow
        height="50px"
        width={shouldAddRightPadding ? "calc(100% - 22px)" : "100%"}
        padding={padding}
        justify="space-between"
        align="center"
        flex={shouldAddRightPadding ? "0 0 50px" : "0 1 auto"}
        margin={shouldAddRightPadding ? "0 0 15px 0" : "0"}
        $isTransparent={isTransparent}
      >
        <H1_TextXs>
          {intl.formatMessage(characterDrawerMessages.applyOneScene, { element })}
        </H1_TextXs>
        <BlueSwitch size="small" checked={!isApplyAllScenes} onChange={onChangeApplySettings} />
      </BackgroundFlexRow>
    </ConditionalRender>
  );
};

export default ApplyToScenes;
