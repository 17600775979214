import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ListenStatus } from "app/store/slices/voices.slice";
import { useAppSelector } from "app/hooks";
import styled from "styled-components";
import { Button, Tooltip } from "antd";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import * as voicesSelector from "app/store/selectorsV2/voices.selectors";
import useTranscriptPlay from "app/hooks/useTranscriptPlay";
import { TranscriptAudioPlayType } from "app/types";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import { ThemeMode } from "app/utils/theme";

export const messages = defineMessages({
  createAudioScene: {
    id: "editor.scene.create-audio",
    defaultMessage: "Audio Preview"
  },
  createAudioSceneDisabled: {
    id: "editor.scene.create-audio.disabled",
    defaultMessage: "Choose voice and insert transcript to play scene audio"
  },
  pauseButton: {
    id: "editor.scene.create-audio.pause-button",
    defaultMessage: "Pause"
  },
  listenButton: {
    id: "editor.scene.create-audio.listen-button",
    defaultMessage: "Listen"
  },
  symbolErrorMsg: {
    id: "editor.scene.create-audio.symbol-error",
    defaultMessage: "Failed process audio file"
  },
  symbolErrorDesc: {
    id: "editor.scene.create-audio.symbol-error-description",
    defaultMessage: "Unrecognized symbols detected"
  }
});

export const ListenButton = styled(Button)<{
  $selected: boolean;
  disabled: boolean;
  $isCurrentlyPlaying: boolean;
}>`
  opacity: ${({ $selected, $isCurrentlyPlaying }) => ($selected || $isCurrentlyPlaying ? 1 : 0)};
  z-index: ${({ $selected, $isCurrentlyPlaying }) => ($selected || $isCurrentlyPlaying ? 2 : -1)};
  pointer-events: ${({ disabled }) => disabled && "none"};
  padding: 4px;
  height: 41px;
  width: 41px;
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) => (disabled ? `${theme.gray5} !important` : theme.blue2)};
  i {
    font-size: 14px;
    transition: 0.3s ease-in-out all;
    color: ${({ disabled, theme }) => (disabled ? `${theme.gray5} !important` : theme.blue2)};
  }
  &&&,
  &:focus {
    transition: 0.3s ease-in-out all;
    border: 1px solid
      ${({ $isCurrentlyPlaying, theme }) => ($isCurrentlyPlaying ? theme.blue2 : "transparent")};
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 12px 0
      ${({ theme }) =>
        theme.mode === ThemeMode.Light ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.1)"};
    background-color: ${({ theme, $selected, $isCurrentlyPlaying }) =>
      $selected || $isCurrentlyPlaying
        ? theme.mode === ThemeMode.Light
          ? theme.gray1
          : theme.gray2
        : "transparent"};
    i {
      color: ${({ disabled, theme }) => (disabled ? `${theme.gray5} !important` : theme.blue2)};
    }
  }
  &&:hover {
    i {
      color: ${({ disabled, theme }) => (disabled ? `${theme.gray5} !important` : theme.blue4)};
    }
    border: 1px solid
      ${({ $isCurrentlyPlaying, theme }) => ($isCurrentlyPlaying ? theme.blue4 : "transparent")};
  }
  &&:active {
    transform: scale(0.9);
    box-shadow: none;
  }
`;

interface TranscriptPreviewProps {
  sceneId: string;
  transcript?: string;
  idx: number;
}

const TranscriptPreview = ({ sceneId, transcript, idx }: TranscriptPreviewProps) => {
  const { scene: selectedScene } = useSelectedScene();
  const { formatMessage } = useIntl();

  const scenesChanged = useAppSelector((state) => state.scenes.scenesChanged);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
  const scene = useAppSelector((state) => scenesGlobalSelectors.selectById(state, sceneId));
  const draftVoiceId = useAppSelector(voicesSelector.getDraftVoiceId);
  const voiceId = draftVoiceId || (scene?.attributes.voice?.voice?.voice_id as string);
  // todo fix validation ilegal
  const transcriptAudio = scene?.attributes?.media?.transcript?.url;
  const isPlayingAudio =
    footerAudioPlayer.status === ListenStatus.playing && sceneId === footerAudioPlayer.sceneId;
  const isSceneSelected = selectedScene?.id === sceneId;

  const { onTogglePlayPause, isCurrentAudioLoading, isCurrentSceneLoaded, isAnyAudioLoading } =
    useTranscriptPlay({
      sceneId,
      autoPlay: true,
      type: TranscriptAudioPlayType.scene
    });
  const disabled =
    !voiceId ||
    (!transcript && !transcriptAudio) ||
    (isAnyAudioLoading && !isCurrentAudioLoading) ||
    scenesChanged;

  return (
    <Tooltip title={formatMessage(sceneMessages.listenButton)}>
      <>
        <ListenButton
          $selected={isSceneSelected}
          $isCurrentlyPlaying={isPlayingAudio && isCurrentSceneLoaded}
          disabled={disabled}
          onClick={onTogglePlayPause}
          data-auto-id={
            footerAudioPlayer.status === ListenStatus.idle
              ? `editor-listen-button-scene-${idx}`
              : ``
          }
          loading={isCurrentAudioLoading}
          icon={
            <i
              className={
                isPlayingAudio && isCurrentSceneLoaded ? "fa-solid fa-pause" : "fa-solid fa-volume"
              }
            />
          }
        />
      </>
    </Tooltip>
  );
};
export default TranscriptPreview;

TranscriptPreview.defaultProps = {
  transcript: undefined
};
