import { useAppSelector } from "app/hooks";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { useMemo } from "react";

const useSelectedScene = () => {
  const sceneId = useAppSelector((state) => state.scenes.selectedSceneId) as string;
  const scene = useAppSelector((state) => scenesGlobalSelectors.selectById(state, sceneId));
  const scenes = useAppSelector(scenesSelectors.getScenesWithCharacterAndVoice);

  const selectedSceneIndex = useMemo(() => {
    if (scenes && scene) {
      return scenes.findIndex(({ id }) => id === sceneId);
    }

    return -1;
  }, [scenes, sceneId]);

  return { scene, sceneId, selectedSceneIndex };
};

export default useSelectedScene;
