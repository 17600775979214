import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { defineMessages, useIntl } from "react-intl";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useCopy from "app/hooks/useCopy";
import { fetchingStatus } from "app/utils/helpers";
import useModal, { ModalName } from "app/hooks/useModal";
import { playgroundGlobalSelectors } from "app/store/adapters/adapters";
import { GlobalOutlined, LinkOutlined } from "@ant-design/icons";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import Config from "app/config/Config";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextSubtitle
} from "app/components/_Infrastructure/Typography";
import { PlayGroundResponse, PlaygroundStatus } from "app/types/playground";
import { playgroundActions } from "app/store/slices/playground.slice";
import { StyledModal } from "app/components/common/StyledModal";
import { Button, Input } from "@nextui-org/react";

const ShareInput = styled(Input)`
  width: 100%;
  margin-right: 10px;
`;

const LargeButton = styled(Button)`
  height: 40px;
`;

const WhiteGlobalOutlined = styled(GlobalOutlined)`
  color: ${(props) => props.theme.gray1};
  svg {
    width: 18px;
    height: 18px;
  }
`;

const WhiteLinkOutlined = styled(LinkOutlined)`
  color: ${(props) => props.theme.gray1};
`;

const BlueOrbFlexRow = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.gray5};
  border-radius: 50%;
`;

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    height: auto;
  }
`;

const playgroundPublishVideoModalMessages = defineMessages({
  copyLinkTitle: {
    id: `playground-publish-video-modal.copy-link-title`,
    defaultMessage: `Copy Link`
  },
  anyoneWithTheLink: {
    id: `playground-publish-video-modal.anyone-with-the-link`,
    defaultMessage: `Anyone with the link`
  },
  publiclyAvailable: {
    id: `playground-publish-video-modal.publicly-available`,
    defaultMessage: `This design will be publicly available, anybody with the link can access it.`
  },
  copyLinkButton: {
    id: `playground-publish-video-modal.copy-link-button`,
    defaultMessage: `Copy Link`
  }
});

const PlaygroundPublishVideoModal = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { returnToModal, editorModalOpen } = useModal();
  const { formatMessage } = useIntl();
  const { triggerCopy } = useCopy();
  const { videoId } = editorModalOpen?.context ?? {};
  const selectedVideo: PlayGroundResponse | undefined = useAppSelector((state) =>
    playgroundGlobalSelectors.selectById(state, videoId as string)
  );
  const shareVideoStatus = useAppSelector((state) => state.playground.shareVideoStatus);

  const visible = editorModalOpen?.open === ModalName.playgroundPublishVideo;
  const currentReqId: string | undefined = selectedVideo?.req_id;
  const isPublishLoading = shareVideoStatus === fetchingStatus.loading;
  const isVideoPublished = selectedVideo?.status === PlaygroundStatus.published;
  const url = `${Config.ogPlayerUrl}/${currentReqId}`;

  useEffect(() => {
    if (visible && shareVideoStatus) {
      if (shareVideoStatus === fetchingStatus.succeeded) {
        onCopy();
        dispatch(playgroundActions.updateShareStatusToIdle());
      } else if (shareVideoStatus === fetchingStatus.failed) {
        dispatch(playgroundActions.updateShareStatusToIdle());
      }
    }
  }, [visible, shareVideoStatus]);

  useEffect(() => {
    if (!selectedVideo) {
      return;
    }
    if (visible) {
      if (selectedVideo?.status === PlaygroundStatus.ready) {
        dispatch(
          playgroundActions.playgroundShareVideoRequest({
            videoId: selectedVideo?.id as string
          })
        );
        dispatch(
          googleEvents.shareVideoCopy({
            source: "playground",
            req_id: selectedVideo?.req_id,
            publish: false
          })
        );
      }
    }
  }, [visible, selectedVideo]);

  const onCopy = () => {
    triggerCopy({ copyContent: url, shouldNotify: true });
    dispatch(
      googleEvents.shareVideoCopy({
        source: "playground",
        req_id: currentReqId,
        publish: true
      })
    );
  };

  return (
    <StyledModal
      width="673px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "267px" }}
      onCancel={returnToModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="31px 41px 52px 47px"
      $alignItems="center"
    >
      <H1_FlexColumn gap="24px" width="100%">
        <H1_FlexRow>
          <H1_TextSubtitle color={theme.gray9}>
            {formatMessage(playgroundPublishVideoModalMessages.copyLinkTitle)}
          </H1_TextSubtitle>
        </H1_FlexRow>
        <StyledFlexRow height="63px" gap="25px">
          <BlueOrbFlexRow justify="center" align="center" width="40px" height="40px">
            <WhiteGlobalOutlined color="white" width="18px" height="18px" />
          </BlueOrbFlexRow>
          <H1_FlexColumn gap="14px">
            <H1_TextMiddle fontWeight={600}>
              {formatMessage(playgroundPublishVideoModalMessages.anyoneWithTheLink)}
            </H1_TextMiddle>
            <H1_TextSmall fontSize="13px" color={theme.gray8} fontWeight={500} whiteSpace="normal">
              {formatMessage(playgroundPublishVideoModalMessages.publiclyAvailable)}
            </H1_TextSmall>
          </H1_FlexColumn>
        </StyledFlexRow>
        <H1_FlexRow height="40px" gap="21px" width="100%">
          <ShareInput
            isDisabled
            defaultValue={isVideoPublished ? url : ""}
            size="sm"
            labelPlacement="outside"
          />
          <LargeButton
            size="sm"
            className="secondary-btn"
            isLoading={isPublishLoading}
            startContent={<WhiteLinkOutlined />}
            onClick={onCopy}
          >
            {formatMessage(playgroundPublishVideoModalMessages.copyLinkButton)}
          </LargeButton>
        </H1_FlexRow>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default PlaygroundPublishVideoModal;
