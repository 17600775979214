import { defineMessages } from "react-intl";

export const createVideoModalMessages = defineMessages({
  yourVideoCreated: {
    id: `create-video-modal.your-video-created`,
    defaultMessage: `Your video is being created`
  },
  cancelRendering: {
    id: `create-video-modal.cancel-rendering`,
    defaultMessage: `Cancel Render`
  },
  tip: {
    id: `create-video-modal.tip`,
    defaultMessage: `TIP`
  },
  translateContent: {
    id: `create-video-modal.translate-content`,
    defaultMessage: `Instantly translate your video`
  },
  translateSubContent: {
    id: `create-video-modal.translate-sub-content`,
    defaultMessage: `Easily translate your videos in to 12 different languages in one click. <span></span>`
  },
  translateUpgradeText: {
    id: `create-video-modal.translate-upgrade-text`,
    defaultMessage: `Upgrade to translate your videos instantly`
  },
  cloneVoiceContent: {
    id: `create-video-modal.clone-voice-content`,
    defaultMessage: `Clone your voice for your voiceover`
  },
  cloneVoiceSubContent: {
    id: `create-video-modal.clone-voice-sub-content`,
    defaultMessage: `Add a personal touch to your videos by replicating your voice and using it in your content. <span></span>`
  },
  cloneVoiceUpgradeText: {
    id: `create-video-modal.clone-voice-upgrade-text`,
    defaultMessage: `Upgrade to clone your voice`
  },
  scenesContent: {
    id: `create-video-modal.scenes-content`,
    defaultMessage: `Create More Scenes`
  },
  scenesSubContent: {
    id: `create-video-modal.scenes-sub-content`,
    defaultMessage: `Sometimes, more is really more. Elevate your videos by adding additional scenes, even beyond your plan's limit. Unleash your full storytelling potential. <span></span>`
  },
  scenesUpgradeText: {
    id: `create-video-modal.scenes-upgrade-text`,
    defaultMessage: `Upgrade to add more scenes`
  },
  videoRenderContent: {
    id: `create-video-modal.video-render-content`,
    defaultMessage: `Improve your video render time`
  },
  videoRenderSubContent: {
    id: `create-video-modal.video-render-sub-content`,
    defaultMessage: `Easily boosting your video render to speed up your video creation, taking you from an idea to a video in minutes. <span></span>`
  },
  videoRenderUpgradeText: {
    id: `create-video-modal.video-render-upgrade-text`,
    defaultMessage: `Upgrade to boost your video rendering`
  },
  brandKitContent: {
    id: `create-video-modal.brand-kit-content`,
    defaultMessage: `Customize Your Videos with Your Brand Kit`
  },
  brandKitSubContent: {
    id: `create-video-modal.brand-kit-sub-content`,
    defaultMessage: `Easily add your logos, intro video, color palettes to your videos, for a consistent and professional brand presence. <span></span>`
  },
  brandKitUpgradeText: {
    id: `create-video-modal.brand-kit-upgrade-text`,
    defaultMessage: `Upgrade to add brand kit content`
  },
  subtitlesContent: {
    id: `create-video-modal.subtitles-content`,
    defaultMessage: `Quickly export subtitles for your video`
  },
  subtitlesSubContent: {
    id: `create-video-modal.subtitles-sub-content`,
    defaultMessage: `Export and share subtitles on popular video platforms, expanding your audience and influence. <span></span>`
  },
  subtitlesUpgradeText: {
    id: `create-video-modal.subtitles-upgrade-text`,
    defaultMessage: `Upgrade to export subtitles`
  },
  upgradeNow: {
    id: `create-video-modal.upgrade-now`,
    defaultMessage: `Upgrade Now`
  }
});
