import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  addPolicyToUrlsIfFileStackMedia,
  convertAssetsUrlIfUnsplash,
  getMediaTypes
} from "app/utils/helpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MediaType } from "app/types/media";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { getThumbnailUrl } from "app/services/filestackClient";

export interface MediaUrl {
  url: string;
  assetType: MediaType;
  fileName?: string;
  thumbnail?: string;
  thumbnailType?: MediaType;
}
export interface DisplayUrl {
  [original: string]: MediaUrl;
}

const useDisplayUrls = (urls: (string | undefined)[], active = true) => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const cachedDisplayUrls = useAppSelector((state) => state.media.cachedDisplayUrls);

  useEffect(() => {
    if (!active) {
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const filteredUrls: string[] = urls.filter((url) => {
          return url && !cachedDisplayUrls[url];
        }) as string[];

        if (filteredUrls.length) {
          const mediaTypes = await getMediaTypes(filteredUrls, filestackPolicy);
          const newUrls = addPolicyToUrlsIfFileStackMedia(
            (await Promise.all(convertAssetsUrlIfUnsplash(filteredUrls))) as string[],
            filestackPolicy
          );
          const fileStackUrls = filteredUrls.filter((url) => url.includes("filestack"));

          const thumbnails = (
            await Promise.all(
              fileStackUrls.map(async (url, index) => {
                const isVideo =
                  getAssetTypeByMimetype(mediaTypes[index]?.mimetype) === MediaType.video;
                const thumbnail = await getThumbnailUrl(url, isVideo, filestackPolicy);
                return { key: url, thumbnail };
              })
            )
          ).reduce(
            (acc, cur) => {
              acc[cur.key] = cur.thumbnail;
              return acc;
            },
            {} as Record<
              string,
              {
                url: string;
                type: MediaType;
              }
            >
          );
          const result = filteredUrls.map((cur, index) => {
            return {
              key: cur,
              media: {
                url: newUrls[index],
                assetType: getAssetTypeByMimetype(mediaTypes[index]?.mimetype),
                fileName: mediaTypes[index]?.filename,
                thumbnail: thumbnails[cur]?.url,
                thumbnailType: thumbnails[cur]?.type
              } as MediaUrl
            };
          });
          dispatch(mediaActions.setUrlDisplayCache(result));
        }
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [JSON.stringify(urls), active]);
  // JSON.stringify(urls) for not trigger on every render
  return { displayUrls: cachedDisplayUrls, loading };
};

function getAssetTypeByMimetype(asset = "") {
  if (asset.includes(MediaType.video)) {
    return MediaType.video;
  }
  if (asset.includes(MediaType.audio)) {
    return MediaType.audio;
  }
  return MediaType.image;
}

export default useDisplayUrls;
