import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import EmptyState from "app/components/common/EmptyState";
import { charactersActions } from "app/store/slices/characters.slice";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import { charactersGlobalSelectors, scenesGlobalSelectors } from "app/store/adapters/adapters";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import CharacterDrawerElement from "app/components/editor/sideDrawers/CharacterDrawer/CharacterDrawerElement";

interface CharacterDrawerProps {
  query: string;
  onClearSearch: () => void;
}

const CharacterDrawerList = ({ query, onClearSearch }: CharacterDrawerProps) => {
  const [gridHeight, setGridHeight] = useState(window.innerHeight);
  const ref = useRef<HTMLDivElement>(null);
  const [isCompletedLoading, setIsCompletedLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const characterFetchingStatus = useAppSelector((state) => state.characters.charactersStatus);
  const templateFetchingStatus = useAppSelector((state) => state.drafts.deleteDraftStatus);
  const currentTemplate = useAppSelector(CharacterSelectors.template);
  const filteredCharacters = useAppSelector((state) =>
    CharacterSelectors.getFilteredCharactersByProperties(state, query)
  );
  const allCharacters = useAppSelector(charactersGlobalSelectors.selectAll);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);

  useEffect(() => {
    setGridHeight(ref.current?.offsetHeight || 0);
    const handleResize = () => {
      setGridHeight(ref.current?.offsetHeight || 0);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loading =
    !currentTemplate ||
    characterFetchingStatus === fetchingStatus.loading ||
    templateFetchingStatus === fetchingStatus.loading;

  useEffect(() => {
    if (allCharacters.length > 0 && scenes.length > 0 && currentDraft) {
      setIsCompletedLoading(true);
    }
  }, [allCharacters.length, currentDraft, scenes]);

  useEffect(() => {
    if (isCompletedLoading) {
      dispatch(charactersActions.initializeSelectedEmotions());
    }
  }, [isCompletedLoading]);

  const onResetAll = () => {
    dispatch(charactersActions.resetFilters());
    onClearSearch();
  };

  if (loading) {
    return <CircleLoader />;
  }
  return (
    <H1_FlexRow
      ref={ref}
      height="100%"
      wrap="wrap"
      gap="14px"
      justify="flex-start"
      overflow="hidden auto"
    >
      <ConditionalRender condition={filteredCharacters.length === 0}>
        <EmptyState
          text={formatMessage(characterDrawerMessages.emptyText)}
          textSize="16px"
          description={formatMessage(characterDrawerMessages.emptyDescription)}
          icon={<H1_Icon icon="fal fa-user" size="20px" />}
          withButton
          buttonText={formatMessage(characterDrawerMessages.resetAll)}
          onClickButton={onResetAll}
        />
      </ConditionalRender>
      <ConditionalRender condition={filteredCharacters.length > 0}>
        <CharacterDrawerElement height={gridHeight} query={query} />
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default CharacterDrawerList;
