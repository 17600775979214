import React, { useState, useEffect, useMemo, useRef, ChangeEvent, SyntheticEvent } from "react";
import { Input, Alert } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import LinkButton from "app/components/common/Layout/LinkButton";
import styled from "styled-components";
import { validateIllegalCharsTranscript } from "app/components/editor/validations";
import { knowledgeBaseUrlIllegalCharacters } from "app/utils/urls";
import { TextAreaProps } from "antd/lib/input";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { TextAreaRef } from "antd/lib/input/TextArea";

const { TextArea } = Input;

const StyledTextArea = styled(TextArea)`
  && {
    display: block;
    height: 100%;
    line-height: 1.5;
    margin: 0;
    padding: 5px;
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
  }
  &&:focus {
    box-shadow: none;
  }
`;
const StyledFlexRow = styled(H1_FlexRow)`
  white-space: pre;
`;
const TOO_LONG_ERROR = (limit: number) =>
  `This text is too long, Please keep your text under ${limit} characters.`;

export enum UpdateTextEvents {
  enter = "enter",
  blur = "blur"
}
export interface TextInputProps extends Omit<TextAreaProps, "onPaste" | "onChange"> {
  value?: string;
  useWordCounter?: boolean;
  placeholder?: string;
  updateTextFunc?: (text: string) => void;
  onEsc?: () => void;
  onChange?: (text: string, event: SyntheticEvent<HTMLTextAreaElement, ChangeEvent>) => void;
  onPaste?: (text: string) => void;
  updateTextOn?: UpdateTextEvents;
  isTranscript?: boolean;
  maxLength?: number;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
}
const TextInput = ({
  className,
  value,
  onChange,
  onPaste,
  onBlur,
  onFocus,
  updateTextFunc,
  placeholder,
  maxLength,
  useWordCounter,
  disabled,
  rows,
  onEsc,
  isTranscript = true,
  updateTextOn = UpdateTextEvents.blur,
  autoSize = true,
  autoFocus
}: TextInputProps) => {
  const [currentText, setCurrentText] = useState<string>("");
  const inputTagRef = useRef<TextAreaRef>(null);

  const isTextLengthValid = maxLength ? currentText.length < maxLength : true;

  useEffect(() => {
    if (autoFocus && !!inputTagRef.current) {
      const { focus } = inputTagRef.current;
      focus();
    }
  }, [inputTagRef.current, autoFocus]);

  useEffect(() => {
    if (value !== undefined) {
      setCurrentText(value);
    }
  }, [value]);

  const isCharactersValid = useMemo(() => {
    return !isTranscript || validateIllegalCharsTranscript(currentText);
  }, [currentText, isTranscript]);

  const updateText = (event: any) => {
    if (updateTextFunc && !disabled) {
      updateTextFunc(event.target.value);
    }
  };

  const noBreakLine = (event: any) => {
    if (event.keyCode == 13) event.preventDefault();
  };

  const actOnEvent = (event: any, updateTextEvent: UpdateTextEvents) => {
    if (updateTextOn === updateTextEvent) updateText(event);
  };

  const onInternalBlur = (event: any) => {
    actOnEvent(event, UpdateTextEvents.blur);
    if (onBlur) onBlur(event);
  };

  const onInternalFocus = (event: any) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const onPressEnter = (event: any) => {
    actOnEvent(event, UpdateTextEvents.enter);
  };

  const onPasteEvent = (event: any) => {
    if (onPaste) {
      const { clipboardData } = event;
      const pastedData = clipboardData.getData("Text") as string;
      const pastedDataOlder = clipboardData.getData("text/plain") as string;
      onPaste(pastedData || pastedDataOlder);
    }
  };

  const onChangeHandler = (event: any) => {
    if (!disabled) {
      noBreakLine(event);
      if (onChange) {
        onChange(event.target.value, event);
      }
      setCurrentText(event.target.value);
    }
  };

  const actIfEsc = (event: any) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode == 27 && onEsc) {
      onEsc();
    }
  };

  return (
    <>
      <StyledTextArea
        autoSize={autoSize}
        rows={rows}
        ref={inputTagRef}
        className={className}
        placeholder={placeholder}
        onChange={onChangeHandler}
        onBlur={onInternalBlur}
        onFocus={onInternalFocus}
        onPaste={onPasteEvent}
        onPressEnter={onPressEnter}
        value={currentText}
        onKeyDown={actIfEsc}
      />
      {useWordCounter && (
        <>
          <ConditionalRender condition={!isTextLengthValid}>
            <Alert message={TOO_LONG_ERROR(maxLength as number)} banner type="error" />
          </ConditionalRender>

          <ConditionalRender condition={!isCharactersValid}>
            <Alert message={<IllegalCharacter />} banner type="error" />
          </ConditionalRender>
        </>
      )}
    </>
  );
};

const IllegalCharacter = () => {
  return (
    <StyledFlexRow wrap="wrap">
      <span>Please remove any </span>
      <LinkButton
        $fontSize="14px"
        $color="var(--blue-06, #1890ff)"
        onClick={(e) => {
          e.stopPropagation();
          window.open(knowledgeBaseUrlIllegalCharacters);
        }}
      >
        invalid
      </LinkButton>
      <span> characters from your text</span>
    </StyledFlexRow>
  );
};

export default TextInput;
