import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesActions } from "app/store/slices/scenes.slice";
import { MediaProperties, MediaType } from "app/types/media";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { MediaCategory } from "app/types";

const useUpdateSceneWithMediaTrim = (
  sceneId: string,
  assetKey: string,
  attributeType: MediaCategory
) => {
  const dispatch = useAppDispatch();
  const scene = useAppSelector((state) => scenesGlobalSelectors.selectById(state, sceneId));
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);

  const updateSceneWithMediaTrim = (mediaUrl: string, properties?: MediaProperties) => {
    let value: any = {
      url: mediaUrl,
      type: MediaType.video,
      properties
    };

    if (attributeType === "visual") {
      value = {
        ...value,
        preset_override: {
          ...scene?.attributes?.visual?.[assetKey]?.preset_override,
          media_url: mediaUrl,
          type: "media"
        }
      };
    }
    dispatch(
      scenesActions.patchSceneRequest({
        draftId: currentDraft.id as string,
        sceneId,
        operations: [
          {
            op: "replace",
            path: `attributes.${attributeType}.${assetKey}`,
            value
          }
        ]
      })
    );
  };

  return {
    updateSceneWithMediaTrim
  };
};
export default useUpdateSceneWithMediaTrim;
