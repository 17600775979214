import { useAppDispatch, useAppSelector } from "app/hooks";
import UiActions from "app/store/actions/ui.actions";

export enum ModalName {
  templateSelect = "templateSelect",
  globalSearch = "globalSearch",
  noEnoughCredits = "noEnoughCredits",
  maximumWorkspaceSeats = "maximumWorkspaceSeats",
  noAccessToWorkspace = "noAccessToWorkspace",
  renameDraft = "renameDraft",
  createFolder = "createFolder",
  moveToFolder = "moveToFolder",
  videoReady = "videoReady",
  scormLoading = "scormLoading",
  embeddedVideo = "embeddedVideo",
  publishVideo = "publishVideo",
  playgroundPublishVideo = "playgroundPublishVideo",
  downloadVideo = "downloadVideo",
  videoFailed = "videoFailed",
  createVideoModalV2 = "createVideoModalV2",
  mobileQrModal = "mobileQrModal",
  deleteDraft = "deleteDraft",
  renameFolder = "renameFolder",
  screenRecording = "screenRecording",
  mediaLibraryModal = "mediaLibraryModal",
  templatePreview = "templatePreview",
  deleteFolder = "deleteFolder",
  templateFlowChoosingModal = "templateFlowChoosingModal",
  presentationUploader = "presentationUploader",
  videoWizard = "videoWizard",
  recommendedTemplatesModal = "recommendedTemplatesModal",
  transcriptAudioUploadModal = "transcriptAudioUploadModal",
  paymentModal = "paymentModal", // Obsolete
  paymentModalV2 = "paymentModalV2", // In use
  characterModal = "characterModal",
  enterpriseUpgradeModal = "enterpriseUpgradeModal",
  slackBindModal = "slackBindModal",
  slackShareChannel = "slackShareChannel",
  addOnPaymentModal = "addOnPaymentModal",
  cloneVoice = "cloneVoice",
  scenesPlayer = "scenesPlayer",
  startFromDocumentModal = "startFromDocumentModal",
  startFromUrlModal = "startFromUrlModal",
  startFromScriptModal = "startFromScriptModal",
  createVirtualTwin = "createVirtualTwin",
  summaryModal = "summaryModal"
}

const useModal = () => {
  const dispatch = useAppDispatch();
  const editorModalOpen = useAppSelector((state: any) => state.userUi.editorModalOpen);

  const openModal = (
    modalName: ModalName,
    extraContext?: Record<string, any>,
    backModal?: ModalName
  ) => {
    const context = extraContext ? { ...extraContext } : {};
    dispatch(UiActions.setEditorModalOpen(modalName, context, backModal));
  };

  const closeModal = () => {
    dispatch(UiActions.setEditorModalOpen(null, null, null));
  };

  const closeModalIfOpen = (modalName: ModalName) => {
    if (editorModalOpen.open === modalName) {
      dispatch(UiActions.setEditorModalOpen(null, null, null));
    }
  };

  const returnToModal = () => {
    if (editorModalOpen && editorModalOpen.backModal) {
      openModal(editorModalOpen.backModal, editorModalOpen.context);
    } else {
      closeModal();
    }
  };

  return { openModal, closeModal, editorModalOpen, returnToModal, closeModalIfOpen };
};

export default useModal;
