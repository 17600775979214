import { Button } from "@nextui-org/react";
import styled from "styled-components";

export const BlackButton = styled(Button)`
  border-radius: 5px;
  background-color: #1d1d1b;
  color: ${({ theme }) => theme.gray7};
  span {
    color: ${({ theme }) => theme.gray7};
  }
  min-width: 89px;
  svg {
    fill: ${({ theme }) => theme.gray7};
  }
  &:hover {
    background-color: #1d1d1b;
    color: ${({ theme }) => theme.gray5};
    span {
      color: ${({ theme }) => theme.gray5};
    }
    i {
      color: ${({ theme }) => theme.gray5};
    }
    svg {
      fill: ${({ theme }) => theme.gray5};
    }
  }
  &:active {
    background-color: #1d1d1b;
    color: ${({ theme }) => theme.gray3};
    span {
      color: ${({ theme }) => theme.gray3};
    }
    svg {
      fill: ${({ theme }) => theme.gray3};
    }
    i {
      color: ${({ theme }) => theme.gray5};
    }
  }
`;
