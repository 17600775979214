import Scene from "app/components/editor/scene/Scene";
import * as scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { fetchingStatus } from "app/utils/helpers";
import {
  Draft,
  EditorView,
  FeatureFlag,
  FetchStatus,
  InternalScene,
  PartialScene,
  SynthesisMarkupLanguageType
} from "app/types";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesActions } from "app/store/slices/scenes.slice";
import "app/components/editor/Editor.scss";
import { defineMessages, useIntl } from "react-intl";
import styled, { css, useTheme } from "styled-components";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneActionOrigin } from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import ConditionalRender from "app/components/common/ConditionalRender";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { AnimatePresence, motion } from "framer-motion";
import useModal, { ModalName } from "app/hooks/useModal";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { DEFAULT_MAX_SCENES } from "app/config/planFeature";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import React, { useEffect, useState } from "react";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { draftPageMessages } from "app/pages/EditDraftPage/messages";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import SortableContainer from "app/pages/editor/SortableContainer";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import SceneSortableItem from "app/components/editor/scene/SceneSortiableItem";
import { OutsideButtons } from "app/components/editor/scene/SceneOutsideToolbar";
import { ListenButton } from "app/components/editor/scene/TranscriptPreview";
import ListenAllButton from "app/pages/EditDraftPage/ListenAllButton";
import { Tooltip } from "antd";
import GlobalPronunciation from "app/components/editor/scene/GlobalPronunciation";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledButton = styled(Button)<{ $canUpgrade?: boolean }>`
  color: ${({ theme }) => theme.gray6};
  i {
    transition: 0.3s all ease-in-out;
    color: ${({ theme, $canUpgrade }) => ($canUpgrade ? theme.gray6 : theme.orange4)};
  }
  transition: 0.3s all ease-in-out;
  min-width: 147px;
  background-color: ${({ theme }) => theme.gray3};
  border-radius: 50px;
  &:hover {
    color: ${({ theme }) => theme.gray10};
    background: ${({ theme }) =>
      `linear-gradient(93deg, ${theme.gray4} -32%, ${theme.gray5} 131.42%)`};
    i {
      color: ${({ theme, $canUpgrade }) => ($canUpgrade ? theme.gray10 : theme.orange4)};
    }
  }
  &:active {
    color: ${({ theme }) => theme.gray10};
    span {
      color: ${({ theme }) => theme.gray10};
    }
    background-color: ${({ theme }) => theme.gray4};
    i {
      color: ${({ theme, $canUpgrade }) => ($canUpgrade ? theme.gray10 : theme.orange4)};
    }
  }
`;

const RoundButton = styled(Button)<{ $canUpgrade?: boolean }>`
  border-radius: 50%;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  width: 22px;
  min-width: 22px;
  height: 22px;
  && {
    background-color: ${({ theme }) => theme.blue4};
    color: ${({ theme }) => theme.gray1};
    span {
      color: ${({ theme }) => theme.gray1};
    }
  }
  i {
    font-size: 14px;
    color: ${({ theme }) => theme.gray1};
  }
  &:hover {
    background-color: ${({ theme }) => theme.blue2};
    i {
      color: ${({ theme, $canUpgrade }) => ($canUpgrade ? theme.orange2 : theme.blue1)};
    }
  }
`;
const AddSceneFlexRow = styled(H1_FlexRow)`
  position: absolute;
  height: 22px;
  bottom: -23px;
  left: 39px;
  width: calc(100% - 108px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 1;
    z-index: 16;
  }
`;

const StyledLine = styled(H1_FlexRow)`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.blue2};
`;
const SceneSeparator = styled(H1_FlexColumn)<{ $overScene: boolean; $isBottom?: boolean }>`
  position: absolute;
  width: 100%;
  left: -20px;
  ${({ $isBottom, $overScene, theme }) =>
    $isBottom
      ? css`
          bottom: -11px;
          border-bottom: ${$overScene && `1px solid ${theme.blue4}`};
          padding-bottom: ${$overScene && "22px"};
        `
      : css`
          top: -11px;
          border-top: ${$overScene && `1px solid ${theme.blue4}`};
          padding-top: ${$overScene && "22px"};
        `};
`;
const SceneContainer = styled(H1_FlexColumn)<{
  $opacity: boolean;
  $dragging?: boolean;
}>`
  margin: 0 auto 0 0;
  opacity: ${({ $opacity, $dragging }) => ($opacity ? 0.6 : $dragging ? 0.3 : 1)};
  &&&:hover ${OutsideButtons} {
    opacity: 1;
    z-index: 2;
  }
  &&&:hover ${ListenButton} {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    opacity: 1;
    z-index: 2;
  }
`;

const DraggableFlexRow = styled(H1_FlexRow)`
  background-color: transparent;
  width: fit-content;
`;

const ScenesContainerFlexColumn = styled(H1_FlexColumn)<{ $visible: boolean; $scrollTop: number }>`
  mask-image: ${({ $scrollTop }) =>
    $scrollTop > 20 &&
    "linear-gradient(#000,#000,transparent 0,#000 40px,#000 calc(100% - 40px),transparent)"};
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 8px;
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
    transition: background-color 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ $visible, theme }) => ($visible ? theme.gray6 : theme.gray1)};
    transition: background-color 0.3s ease-in-out;
  }
`;

const OpacFlexColumn = styled(H1_FlexColumn)`
  opacity: ${(props: { $opacity: boolean }) => (props.$opacity ? 0.6 : 1)};
  width: clamp(500px, 100%, 906px);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 11.33px 0 rgba(0, 0, 0, 0.05);

  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

export const messages = defineMessages({
  retrieveRowError: {
    id: "editor.scene-list.row.retrieve.error",
    defaultMessage: "Error"
  },
  maxScenes: {
    id: "editor.scene-list.limit.scenes",
    defaultMessage: "Maximum number of scenes reached. Upgrade to add more."
  },
  maxScenesShort: {
    id: "editor.scene-list.limit.scenes-short",
    defaultMessage: "<link>Upgrade </link> to add more scenes"
  },
  loadingScene: {
    id: "editor.scene-list.loading-scene",
    defaultMessage: " Add scene"
  },
  addScene: {
    id: "editor.scene-list.add",
    defaultMessage: " Add scene"
  },
  videoEst: {
    id: "editor.scene-list.video-est",
    defaultMessage: "EST. video duration is: {videoEST} minutes"
  },
  sceneNumber: {
    id: "editor.scene-list.number",
    defaultMessage: "Scene {number} - "
  },
  moveUp: {
    id: "editor.scene-list.move-up",
    defaultMessage: "Move up"
  },
  moveDown: {
    id: "editor.scene-list.move-down",
    defaultMessage: "Move down"
  },
  duplicateScene: {
    id: "editor.scene-list.duplicate-scene",
    defaultMessage: "Duplicate scene"
  },
  useVoice: {
    id: "editor.scene-list.use-voice",
    defaultMessage: "Use Voice"
  },
  deleteScene: {
    id: "editor.scene-list.delete-scene",
    defaultMessage: "Delete scene"
  },
  newScene: {
    id: "editor.scene-list.new-scene",
    defaultMessage: "Create new scene"
  }
});

const AddSceneContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  right: 13px;
`;

const ScenesList = () => {
  const [hoverId, setHoverId] = useState<string | undefined>(undefined);
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);
  const [overId, setOverId] = useState<string | undefined>(undefined);
  const [scrollTop, setScrollTop] = useState(0);
  const [draggingScene, setDraggingScene] = useState<
    { scene: InternalScene; id: string; idx: number } | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const { formatMessage } = useIntl();
  const { selectedSceneIndex, sceneId, scene } = useSelectedScene();
  const { openDrawer } = useDrawer();
  const theme = useTheme();
  const { openModal } = useModal();
  const { isWriteWorkspaceAllow } = usePermissions();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, {
      // Example for MouseSensor
      activationConstraint: {
        delay: 250, // Delay in milliseconds before activation.
        tolerance: 5 // Movement tolerance in pixels.
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const refreshDraftAndScenesStatus = useAppSelector(
    (state) => state.drafts.refreshDraftAndScenesStatus
  );
  const scenes = useAppSelector(scenesSelectors.getScenesWithCharacterAndVoice);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const limits = useAppSelector(getLimits);
  const createStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const topicToDraftStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.topicToDraftStatus
  );

  const revertDraftStatus: FetchStatus = useAppSelector((state) => state.drafts.revertDraftStatus);
  const draftRecipeStatus = useAppSelector((state) => state.drafts.draftRecipeStatus);
  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);

  const createLoading = createStatus === fetchingStatus.loading;
  const addSceneLoading = createSceneStatus === fetchingStatus.loading;

  const videoEST = useAppSelector(scenesSelectors.getVideoEST);
  const isVideoWizardLoading =
    topicToDraftStatus === fetchingStatus.loading ||
    revertDraftStatus === fetchingStatus.loading ||
    refreshDraftAndScenesStatus === fetchingStatus.loading;

  const isLoading = scenesStatus === fetchingStatus.loading;
  const isRecipeStatusLoading = draftRecipeStatus === fetchingStatus.loading;
  const maxScenesLimit: number = limits?.scenes ? limits?.scenes : DEFAULT_MAX_SCENES;
  const disableAddScene = maxScenesLimit <= scenes.length;
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);

  const viewOnly = !isWriteWorkspaceAllow || workflowLiveMode;

  useEffect(() => {
    if (deleteSceneStatus === fetchingStatus.succeeded) {
      dispatch(scenesActions.updateDeleteStatusToIdle());
    }
    if (deleteSceneStatus === fetchingStatus.failed) {
      dispatch(scenesActions.updateDeleteStatusToIdle());
    }
  }, [deleteSceneStatus]);

  const onClickScene = (selectedSceneId: string) => {
    const currentScene = scenes.find(({ id }: InternalScene) => id === selectedSceneId);
    dispatch(
      analyticsEvents.selectScene({
        selectedScene: {
          name: currentScene?.name || "",
          index: selectedSceneIndex + 1,
          id: selectedSceneId
        },
        view: EditorView.Multiple
      })
    );
    dispatch(scenesActions.setSelectedSceneId(selectedSceneId));
    openDrawer(Drawer.Scene);
  };

  const handleDragStart = (event: any) => {
    if (!isWriteWorkspaceAllow) {
      return;
    }
    const targetScene = scenes.find(
      (currentScene) => currentScene.id === event.active.id
    ) as InternalScene;
    const targetSceneIndex = scenes.findIndex(
      (currentScene) => currentScene.id === event.active.id
    ) as number;
    setDraggingScene({ scene: targetScene, id: event.active.id, idx: targetSceneIndex });
  };

  const handleDragOver = ({ active, over }: any) => {
    if (over && active.id !== over.id) {
      setOverId(over.id);
    } else {
      setOverId(undefined);
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      onClickScene(active.id);
    } else {
      const idx = scenes.findIndex((currentScene) => currentScene.id === active.id);
      const targetIndex = scenes.findIndex((currentScene) => currentScene.id === over.id);

      const isMoveDirectionUp = idx > targetIndex;
      let originalParentIndex = targetIndex;
      let parentId: string | undefined = scenes[targetIndex].id;
      if (isMoveDirectionUp) {
        originalParentIndex = targetIndex > 0 ? targetIndex - 1 : 0;
        parentId = targetIndex > 0 ? scenes[targetIndex - 1].id : undefined;
      }
      dispatch(
        scenesActions.moveScene({
          originalSceneIndex: idx,
          originalParentIndex,
          draftId: draft.id as string,
          sceneId: active.id,
          parentId
        })
      );
    }
    setOverId(undefined);
    setDraggingScene(undefined);
  };

  const onUpgradeScenes = (e?: React.MouseEvent) => {
    const upgradeText = formatMessage(paymentModalMessages.upgradeSceneTitle);
    const source = "addScene";
    if (e) {
      e.stopPropagation();
    }
    dispatch(analyticsEvents.productUpgradeCta({ cta: source }));
    openModal(ModalName.paymentModalV2, { source, upgradeText });
  };

  const onAddScene = () => {
    const newSceneText = "My new scene";
    dispatch(
      analyticsEvents.addScene({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        action: SceneActionOrigin.Scene
      })
    );
    const newScene: PartialScene = {
      name: "New scene",
      attributes: {
        text: {
          transcript: {
            synthesis_markup_language: {
              nodes: [{ text: newSceneText, version: 1, type: SynthesisMarkupLanguageType.text }]
            },
            text: newSceneText
          }
        }
      }
    };
    dispatch(scenesActions.addDraftSceneRequest({ draftId: draft.id as string, scene: newScene }));
    openDrawer(Drawer.Scene);
  };

  const onMouseEnter = () => {
    setIsScrollVisible(true);
  };

  const onMouseLeave = () => {
    setIsScrollVisible(false);
  };

  const onScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  const onAddSceneBetween = (currentScene: InternalScene, idx: number) => {
    if (disableAddScene) {
      onUpgradeScenes();
      return;
    }
    dispatch(
      analyticsEvents.addScene({
        selectedScene: {
          name: currentScene.name,
          id: currentScene.id,
          index: idx
        },
        action: SceneActionOrigin.Scene
      })
    );
    const newScene: PartialScene = {
      name: "new scene",
      attributes: {
        text: {
          transcript: {
            text: "My new scene"
          }
        }
      }
    };
    dispatch(
      scenesActions.addDraftSceneRequest({
        draftId: draft.id as string,
        scene: newScene,
        parentId: currentScene.id
      })
    );
  };

  return (
    <>
      <OpacFlexColumn
        flex="1 1 auto"
        padding="22px 0 0 35px"
        $opacity={isVideoWizardLoading || isRecipeStatusLoading}
        position="relative"
      >
        <ConditionalRender condition={workflowLiveMode}>
          Live mode is enabled. Edit scene is locked.
        </ConditionalRender>
        <H1_FlexRow padding="0 48px 15px 0" align="center" justify="space-between" width="100%">
          <H1_TextSmall color={theme.gray7}>
            {formatMessage(draftPageMessages.videoDuration, { time: videoEST })}
          </H1_TextSmall>
          <ConditionalRender condition={!viewOnly}>
            <H1_FlexRow align="center" gap="10px">
              <ConditionalRender condition={flags[FeatureFlag.globalPronunciation]}>
                <GlobalPronunciation />
              </ConditionalRender>
              <ListenAllButton />
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexRow>
        <ScenesContainerFlexColumn
          $visible={isScrollVisible}
          $scrollTop={scrollTop}
          onScroll={onScroll}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
          padding="20px 0 0 0"
          gap="24px"
          overflow="auto"
          position="relative"
          flex="1"
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={scenes} strategy={verticalListSortingStrategy}>
              <AnimatePresence>
                {scenes.map((currentScene: InternalScene, idx) => (
                  <motion.div
                    key={currentScene.id}
                    layout="position"
                    initial={{ scale: 0.5 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{
                      default: {
                        duration: 0.5
                      }
                    }}
                  >
                    <H1_FlexRow
                      padding="0 43px 0 0"
                      position="relative"
                      gap="15px"
                      justify="center"
                      onMouseEnter={() => setHoverId(currentScene.id)}
                      onMouseLeave={() => setHoverId(undefined)}
                    >
                      <ConditionalRender condition={idx < scenes.length - 1 && !viewOnly}>
                        <AddSceneFlexRow
                          align="center"
                          onClick={() => onAddSceneBetween(currentScene, idx)}
                        >
                          <StyledLine padding="0 10px 0 0" flex="1 1 auto" />
                          <Tooltip
                            title={formatMessage(
                              disableAddScene ? messages.maxScenes : messages.addScene
                            )}
                          >
                            <>
                              <RoundButton
                                isIconOnly
                                radius="full"
                                $canUpgrade={disableAddScene}
                                onClick={() => onAddSceneBetween(currentScene, idx)}
                                startContent={
                                  <i
                                    className={
                                      addSceneLoading
                                        ? "far fa-spinner-third fa-spin"
                                        : "far fa-plus"
                                    }
                                  />
                                }
                                variant="light"
                              />
                            </>
                          </Tooltip>
                          <StyledLine padding="0 0 0 10px" flex="1 1 auto" />
                        </AddSceneFlexRow>
                      </ConditionalRender>
                      <SceneSortableItem
                        isVisible={hoverId === currentScene.id}
                        idx={idx + 1}
                        id={currentScene.id}
                        isSelected={sceneId === currentScene.id}
                      />
                      <SceneContainer
                        position="relative"
                        $opacity={!!currentScene?.skip}
                        $dragging={currentScene?.id === draggingScene?.id}
                      >
                        <ConditionalRender condition={draggingScene && idx < draggingScene.idx}>
                          <SceneSeparator $overScene={overId === currentScene.id} />
                        </ConditionalRender>
                        <Scene idx={idx} key={currentScene.id} scene={currentScene} />
                        <ConditionalRender condition={draggingScene && idx > draggingScene.idx}>
                          <SceneSeparator $isBottom $overScene={overId === currentScene.id} />
                        </ConditionalRender>
                      </SceneContainer>
                    </H1_FlexRow>
                  </motion.div>
                ))}
              </AnimatePresence>
            </SortableContext>
            <DragOverlay>
              {draggingScene && (
                <SortableContainer key={draggingScene?.id} elementId={draggingScene?.id as string}>
                  <DraggableFlexRow
                    height="100%"
                    padding="0 0 0 0"
                    position="relative"
                    gap="15px"
                    justify="center"
                    width="871px"
                  >
                    <SceneSortableItem
                      isDragging
                      isVisible
                      idx={draggingScene?.idx + 1}
                      id={draggingScene?.id}
                      isSelected={sceneId === draggingScene?.id}
                    />
                    <Scene
                      idx={draggingScene?.idx as number}
                      key={draggingScene?.id as string}
                      scene={draggingScene?.scene as InternalScene}
                      isDragging={!!draggingScene?.id}
                    />
                  </DraggableFlexRow>
                </SortableContainer>
              )}
            </DragOverlay>
          </DndContext>
          {!isLoading && !viewOnly && (
            <AddSceneContainer
              alignSelf="center"
              width="763px"
              padding="0 0 42px 0"
              align="center"
              position="relative"
              justify="center"
            >
              <Tooltip title={disableAddScene ? formatMessage(messages.maxScenes) : ""}>
                <>
                  <StyledButton
                    $canUpgrade={!disableAddScene}
                    data-auto-id="editor-scenes-list-add-new-scene"
                    startContent={
                      <i
                        className={
                          createLoading
                            ? "far fa-spinner-third fa-spin"
                            : disableAddScene
                            ? "fas fa-crown "
                            : "far fa-plus"
                        }
                      />
                    }
                    onClick={disableAddScene ? onUpgradeScenes : onAddScene}
                  >
                    {formatMessage(messages.addScene)}
                  </StyledButton>
                </>
              </Tooltip>
            </AddSceneContainer>
          )}
        </ScenesContainerFlexColumn>
      </OpacFlexColumn>
    </>
  );
};

export default ScenesList;
