import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import { ReviewStatus } from "app/types";
import { Button, Divider, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { useIntl } from "react-intl";
import { reviewStatusMessages } from "app/components/common/ReviewStatus/messages";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useState } from "react";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";

const StyledButton = styled(Button)<{ $color: string; $textColor: string }>`
  background-color: ${({ $color }) => $color};
  color: ${({ $textColor }) => $textColor};
  height: 24px;
  justify-content: flex-start;
  padding-left: 8px;
`;
interface ReviewStatusButtonProps {
  draftId: string;
}

const ReviewStatusButton = ({ draftId }: ReviewStatusButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const draft = useAppSelector((state) => draftsGlobalSelectors.selectById(state, draftId));
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const draftReviewStatus = draft?.review_status || currentDraft?.review_status;

  const icon = (theStatus?: ReviewStatus) => {
    const status = theStatus || draftReviewStatus;
    switch (status) {
      case ReviewStatus.Draft:
        return "far fa-file";
      case ReviewStatus.InReview:
        return "fas fa-magnifying-glass";
      case ReviewStatus.Pending:
        return "fas fa-hourglass-start";
      case ReviewStatus.Approved:
        return "far fa-circle-check";
      case ReviewStatus.Rejected:
        return "far fa-circle-xmark";
      default:
        return "far fa-file";
    }
  };

  const color = (theStatus?: ReviewStatus) => {
    const status = theStatus || draftReviewStatus;
    switch (status) {
      case ReviewStatus.Draft:
        return "#DFDFDF";
      case ReviewStatus.InReview:
        return "rgba(104, 183, 255, 1)";
      case ReviewStatus.Pending:
        return "rgba(0, 185, 151, 1)";
      case ReviewStatus.Approved:
        return "rgba(26, 185, 0, 1)";
      case ReviewStatus.Rejected:
        return "rgba(185, 0, 0, 1)";
      default:
        return "#DFDFDF";
    }
  };

  const textColor = (theStatus?: ReviewStatus) => {
    const status = theStatus || draftReviewStatus;
    switch (status) {
      case ReviewStatus.Draft:
        return "#27272A";
      case ReviewStatus.InReview:
        return "white";
      case ReviewStatus.Pending:
        return "white";
      case ReviewStatus.Approved:
        return "white";
      case ReviewStatus.Rejected:
        return "white";
      default:
        return "#27272A";
    }
  };

  const text = (theStatus?: ReviewStatus) => {
    const status = theStatus || draftReviewStatus;
    switch (status) {
      case ReviewStatus.Draft:
        return formatMessage(reviewStatusMessages.draft);
      case ReviewStatus.InReview:
        return formatMessage(reviewStatusMessages.inReview);
      case ReviewStatus.Pending:
        return formatMessage(reviewStatusMessages.pending);
      case ReviewStatus.Approved:
        return formatMessage(reviewStatusMessages.approved);
      case ReviewStatus.Rejected:
        return formatMessage(reviewStatusMessages.rejected);
      default:
        return formatMessage(reviewStatusMessages.draft);
    }
  };

  const onClickReviewStatus = (newStatus: ReviewStatus) => {
    dispatch(
      draftsActions.patchDraftRequest({
        draftId,
        operations: [{ op: "replace", path: "review_status", value: newStatus }]
      })
    );
    setIsOpen(false);
  };

  return (
    <Popover
      placement="bottom-start"
      showArrow={false}
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      classNames={{ content: "p-0" }}
    >
      <PopoverTrigger>
        <StyledButton
          $color={color(draftReviewStatus)}
          startContent={
            <H1_Icon
              icon={icon(draftReviewStatus)}
              isCursorPointer
              color={textColor(draftReviewStatus)}
            />
          }
          $textColor={textColor(draftReviewStatus)}
        >
          {text(draftReviewStatus)}
        </StyledButton>
      </PopoverTrigger>
      <PopoverContent>
        <H1_FlexColumn gap="10px" padding="10px 0">
          <H1_TextMiddle color={theme.gray7} margin="0 20px">
            {formatMessage(reviewStatusMessages.updateStatus)}
          </H1_TextMiddle>
          <Divider />
          <H1_FlexColumn gap="10px" padding="10px 20px">
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Draft)}
              $color={color(ReviewStatus.Draft)}
              $textColor={textColor(ReviewStatus.Draft)}
              startContent={
                <H1_Icon
                  icon={icon(ReviewStatus.Draft)}
                  isCursorPointer
                  color={textColor(ReviewStatus.Draft)}
                />
              }
            >
              {text(ReviewStatus.Draft)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Pending)}
              $color={color(ReviewStatus.Pending)}
              $textColor={textColor(ReviewStatus.Pending)}
              startContent={
                <H1_Icon
                  icon={icon(ReviewStatus.Pending)}
                  isCursorPointer
                  color={textColor(ReviewStatus.Pending)}
                />
              }
            >
              {text(ReviewStatus.Pending)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.InReview)}
              $color={color(ReviewStatus.InReview)}
              $textColor={textColor(ReviewStatus.InReview)}
              startContent={
                <H1_Icon
                  icon={icon(ReviewStatus.InReview)}
                  isCursorPointer
                  color={textColor(ReviewStatus.InReview)}
                />
              }
            >
              {text(ReviewStatus.InReview)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Approved)}
              $color={color(ReviewStatus.Approved)}
              $textColor={textColor(ReviewStatus.Approved)}
              startContent={
                <H1_Icon
                  icon={icon(ReviewStatus.Approved)}
                  isCursorPointer
                  color={textColor(ReviewStatus.Approved)}
                />
              }
            >
              {text(ReviewStatus.Approved)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Rejected)}
              $color={color(ReviewStatus.Rejected)}
              $textColor={textColor(ReviewStatus.Rejected)}
              startContent={
                <H1_Icon
                  icon={icon(ReviewStatus.Rejected)}
                  isCursorPointer
                  color={textColor(ReviewStatus.Rejected)}
                />
              }
            >
              {text(ReviewStatus.Rejected)}
            </StyledButton>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </PopoverContent>
    </Popover>
  );
};

export default ReviewStatusButton;
