import { ReactComponent as HourOneSvg } from "app/assets/realsme/hourone-large.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import BackButton from "app/pages/PlayGround/BackButton";
import useCredits from "app/hooks/useCredits";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import usePermissions from "app/hooks/usePermissions";
import React from "react";
import { useUpgradeByPlan } from "app/hooks/useUpgradeModal";
import { apiDocsUrl } from "app/utils/urls";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";
import { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";

interface PlaygroundTopBarProps {
  onBack?: () => void;
}
const PlaygroundTopBar = ({ onBack }: PlaygroundTopBarProps) => {
  const theme = useTheme();
  const { available, isUnlimited } = useCredits();
  const { shouldShowUpgradeButton } = usePermissions();
  const upgradeModal = useUpgradeByPlan();

  const onUpgrade = () => {
    upgradeModal({ source: `upgrade_playground_header` });
  };

  const onClickApiDocs = () => {
    window.open(apiDocsUrl, "_blank");
  };

  return (
    <H1_FlexRow justify="space-between" align="center" padding="23px">
      <H1_FlexRow align="center" gap="12px">
        <ConditionalRender condition={!!onBack}>
          <BackButton onBack={onBack} />
        </ConditionalRender>
        <HourOneSvg height={33} width={30} />
      </H1_FlexRow>
      <H1_FlexRow align="center" gap="16px">
        <ConditionalRender condition={!isUnlimited}>
          <H1_TextSmall color={theme.gray7}>{available}s Left</H1_TextSmall>
        </ConditionalRender>
        <ConditionalRender condition={shouldShowUpgradeButton}>
          <OppositeUpgradeButton source="playground" onClick={onUpgrade} />
        </ConditionalRender>
        <Button color="primary" size="sm" onClick={onClickApiDocs}>
          API Docs
        </Button>
      </H1_FlexRow>
    </H1_FlexRow>
  );
};
export default PlaygroundTopBar;
