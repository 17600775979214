import { defineMessages } from "react-intl";

export const leftNavMessages = defineMessages({
  newVideo: {
    id: `left-nav.new-video`,
    defaultMessage: "New Video"
  },
  home: {
    id: `left-nav.home`,
    defaultMessage: "Home"
  },
  projects: {
    id: `left-nav.projects`,
    defaultMessage: "Videos"
  },
  workflows: {
    id: `left-nav.workflows`,
    defaultMessage: "Workflows"
  },
  beta: {
    id: `left-nav.beta-tag`,
    defaultMessage: "Beta"
  },
  brandHub: {
    id: `left-nav.brand-hub`,
    defaultMessage: "Brand Hub"
  },
  mediaLibrary: {
    id: `left-nav.media`,
    defaultMessage: "Media Library"
  },
  templates: {
    id: `left-nav.templates`,
    defaultMessage: "Templates"
  },
  briefs: {
    id: `left-nav.briefs`,
    defaultMessage: "Briefs"
  },
  playlists: {
    id: `left-nav.playlists`,
    defaultMessage: "Playlists"
  },
  trash: {
    id: `left-nav.trash`,
    defaultMessage: "Trash"
  },
  team: {
    id: `left-nav.team`,
    defaultMessage: "Workspace"
  },
  manageWorkspace: {
    id: `left-nav.manage-workspace`,
    defaultMessage: "Manage Workspace"
  },
  createTeam: {
    id: `left-nav.create-team`,
    defaultMessage: "Create a workspace"
  },
  inviteMembers: {
    id: `left-nav.invite-members`,
    defaultMessage: "Invite members"
  },
  avatars: {
    id: `left-nav.avatars`,
    defaultMessage: "Avatars"
  },
  realsPlan: {
    id: `left-nav.reals-plan`,
    defaultMessage: "REALS PRO"
  },
  "on-boarding": {
    id: `left-nav.free-plan-text`,
    defaultMessage: "Free Plan"
  },
  basic: {
    id: `left-nav.basic-plan-text`,
    defaultMessage: "Lite Plan"
  },
  pro: {
    id: `left-nav.pro-plan-text`,
    defaultMessage: "Lite Plan"
  },
  advanced: {
    id: `left-nav.advanced-plan-text`,
    defaultMessage: "Advanced Plan"
  },
  personal: {
    id: `left-nav.personal-plan-text`,
    defaultMessage: "Lite Plan"
  },
  business: {
    id: `left-nav.business-plan-text`,
    defaultMessage: "Business Plan"
  },
  enterprise: {
    id: `left-nav.enterprise-plan-text`,
    defaultMessage: "Enterprise Plan"
  },
  legacy: {
    id: `left-nav.legacy-plan-text`,
    defaultMessage: "Legacy Plan"
  },
  upgradeButton: {
    id: `left-nav.upgrade-button`,
    defaultMessage: "Upgrade"
  },
  upgradeHeadline: {
    id: `left-nav.upgrade-headline`,
    defaultMessage: "Upgrade to Enterprise"
  },
  unlimitedMinutes: {
    id: `left-nav.unlimited-minutes`,
    defaultMessage: "Unlimited - {minutes} minutes used"
  },
  minutesLeft: {
    id: `left-nav.minutes-left`,
    defaultMessage: "{available} of {totalMinutes} minutes used"
  },
  playground: {
    id: `left-nav.playground`,
    defaultMessage: "Playground"
  },
  integrations: {
    id: `left-nav.integrations`,
    defaultMessage: "Integrations"
  },
  apiAccess: {
    id: `left-nav.api-access`,
    defaultMessage: "API Access"
  },
  whatsNew: {
    id: `left-nav.whats-new`,
    defaultMessage: "What's New"
  },
  bookACall: {
    id: `left-nav.book-a-call`,
    defaultMessage: "Contact Sales"
  },
  addNew: {
    id: `left-nav.add-new`,
    defaultMessage: "Add New"
  }
});

export const leftNavChannelsMessages = defineMessages({
  titleAddChannel: {
    id: "channels.add-channel",
    defaultMessage: "Subscribe to channel"
  },
  descriptionAddChannel: {
    id: "channels.add-channel-description",
    defaultMessage: "Select Hashtags to Customize Your Video Feed"
  },
  channelName: {
    id: "channels.channel-name",
    defaultMessage: "Channel Name"
  },
  channelNamePlaceholder: {
    id: "channels.channel-name-placeholder",
    defaultMessage: "Enter channel name..."
  },
  addButton: {
    id: "channels.add-button",
    defaultMessage: "Create"
  },
  cancelButton: {
    id: "channels.cancel-button",
    defaultMessage: "Cancel"
  },
  noChannels: {
    id: "channels.no-channels",
    defaultMessage: "- No channels -"
  },
  channels: {
    id: "channels.channels",
    defaultMessage: "Video channels"
  },
  addChannels: {
    id: `channels.add-channels`,
    defaultMessage: "Add Channels"
  },
  recent: {
    id: `channels.recent`,
    defaultMessage: "My Videos"
  },
  community: {
    id: `channels.community`,
    defaultMessage: "Community"
  }
});
