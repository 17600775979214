import { useAppSelector } from "app/hooks";
import { ListenStatus } from "app/store/slices/voices.slice";
import { fetchingStatus } from "app/utils/helpers";
import messages from "app/pages/EditDraftPage/messages";

import { useIntl } from "react-intl";
import useTranscriptPlay from "app/hooks/useTranscriptPlay";
import { TranscriptAudioPlayType } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import styled from "styled-components";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const BlueButton = styled(Button)`
  color: ${({ theme }) => theme.blue2};
  transition: color 0.3s ease-in-out;
  span,
  i {
    transition: color 0.3s ease-in-out;
    color: ${({ theme }) => theme.blue2};
  }
  &:hover {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};
    span,
    i {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};
    }
  }
`;
const ListenAllButton = () => {
  const { formatMessage } = useIntl();

  const { onTogglePlayPause, isCurrentDraftLoaded } = useTranscriptPlay({
    autoPlay: true,
    type: TranscriptAudioPlayType.draft
  });

  const scenesChanged = useAppSelector((state) => state.scenes.scenesChanged);
  const footerLoading = useAppSelector((state) => state.voices.footerLoading);
  const createVoiceStatus = useAppSelector((state) => state.voices.createVoiceStatus);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
  const audioLoading =
    footerLoading ||
    createVoiceStatus === fetchingStatus.loading ||
    footerAudioPlayer.status === ListenStatus.loading;
  const isPlayingAudio = footerAudioPlayer.status === ListenStatus.playing && isCurrentDraftLoaded;

  const onAudioFullPreview = () => {
    onTogglePlayPause();
  };
  return (
    <BlueButton
      size="sm"
      color="primary"
      variant="light"
      isDisabled={scenesChanged}
      data-auto-id="listen-all-button"
      onClick={onAudioFullPreview}
    >
      <H1_FlexRow align="center" gap="7px">
        {formatMessage(
          isPlayingAudio ? messages.actionsAudioButtoPaused : messages.actionsAudioButton
        )}
        <H1_Icon
          icon={
            audioLoading
              ? "fa-solid fa-spinner-third fa-spin"
              : isPlayingAudio
              ? "fas fa-pause"
              : "fas fa-volume"
          }
          isCursorPointer
        />
      </H1_FlexRow>
    </BlueButton>
  );
};

export default ListenAllButton;
