import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import {
  FeatureFlag,
  FetchStatus,
  PaymentPeriod,
  PlanAndPriceModel,
  PlanEnum,
  PlanToUpgrade,
  PRICING_PAGE_SOURCE,
  SubscriptionPlanEnum
} from "app/types";
import { paymentModalMessages, pricingPageMessages } from "app/pages/pricing/messages";
import PricingPagePlan from "app/pages/pricing/PricingPagePlan";
import { useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchingStatus } from "app/utils/helpers";
import { currentWorkspaceSubscriptionPlan } from "app/store/selectorsV2/workspaces.selectors";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { bookDemoUrl } from "app/utils/urls";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextLargeHeadline,
  H1_TextMiddle,
  H1_TextSubBanner
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Switch } from "antd";
import { getBusinessYearlyPlan } from "app/store/selectorsV2/payments.selectors";

const PlansFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
  }
`;

interface PricingContentProps {
  onBoardingPricingMode?: boolean;
  onFinish?: () => void;
}
const PricingContent = ({ onBoardingPricingMode = false, onFinish }: PricingContentProps) => {
  const [pricingFetchError, setPricingFetchError] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState(PlanEnum.onBoarding);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const theme = useTheme();
  const flags = useFlags();
  const { paymentPeriod } = useAppSelector((state) => state.payments.pricingModalProperties);
  const subscriptionPlan: SubscriptionPlanEnum = useAppSelector((state) =>
    currentWorkspaceSubscriptionPlan(state)
  );
  const pricingStatus: FetchStatus = useAppSelector((state) => state.payments.pricingStatus);
  const pricingAndPlans: Partial<PlanAndPriceModel>[] = useAppSelector(
    (state) => state.payments.pricingAndPlans
  );
  const businessYearlyPlan = useAppSelector(getBusinessYearlyPlan);
  const yearlyDiscount = businessYearlyPlan?.discount_title;
  const isPriceMonthly = paymentPeriod === PaymentPeriod.Monthly;

  useEffect(() => {
    if (pricingStatus === fetchingStatus.failed) {
      setPricingFetchError(true);
    }
  }, [pricingStatus]);

  const onSelectedPlan = (plan: string) => {
    const source = "upgrade_pricing_page";
    dispatch(googleEvents.pricingUpgrade({ plan }));
    dispatch(googleEvents.productUpgradeCta({ cta: source }));
    if (onFinish) {
      onFinish();
    }

    if (plan.includes("enterprise")) {
      dispatch(googleEvents.pricingMeetingRequest({ source }));
      window.open(bookDemoUrl(source), "_blank");
      return;
    }

    if (plan === PlanEnum.onBoarding) {
      return;
    }

    openModal(ModalName.paymentModalV2, {
      source: PRICING_PAGE_SOURCE,
      targetPlan: plan,
      paymentPeriod
    });
  };

  useEffect(() => {
    if (pricingAndPlans && flags[FeatureFlag.responsiveBannerCode]) {
      const getCurrentPlans = pricingAndPlans.filter(
        (pricingPlan) => pricingPlan.subscription_plan === subscriptionPlan
      );
      // Should add slider in the pricing page and then address this
      if (getCurrentPlans.length > 0) {
        dispatch(
          paymentsActions.verifyCouponRequest({
            coupon: flags[FeatureFlag.responsiveBannerCode],
            planId: getCurrentPlans[0]?.id as string
          })
        );
      }
    }
  }, [pricingAndPlans]);

  useEffect(() => {
    const parsedPlan = String(subscriptionPlan).split("_");
    if (parsedPlan.length === 2) {
      setSelectedPlan(parsedPlan[0] as PlanEnum);
    } else {
      setSelectedPlan(PlanEnum.onBoarding);
    }
  }, [subscriptionPlan]);

  const onChangePrice = (checked: boolean) => {
    const newPaymentPeriod = checked ? PaymentPeriod.Monthly : PaymentPeriod.Yearly;
    dispatch(analyticsEvents.togglePaymentPeriod({ paymentPeriod: newPaymentPeriod }));
    dispatch(
      paymentsActions.updatePricingModalProperties({
        paymentPeriod: newPaymentPeriod
      })
    );
  };

  if (pricingFetchError) {
    return (
      <H1_FlexColumn flex="1">
        <H1_FlexColumn flex="1" gap="10px" justify="center" align="center">
          <H1_TextSubBanner fontSize="46px" color={theme.blue4}>
            {formatMessage(paymentModalMessages.paymentErrorHeader)}
          </H1_TextSubBanner>
          <H1_TextLargeHeadline fontSize="36px" color={theme.blue4}>
            {formatMessage(paymentModalMessages.paymentErrorDescription)}
          </H1_TextLargeHeadline>
        </H1_FlexColumn>
      </H1_FlexColumn>
    );
  }

  return (
    <H1_FlexColumn padding="0 30px 30px" flex={onBoardingPricingMode ? "1 1 auto" : "1 0 auto"}>
      <H1_FlexRow justify="center" gap="15px" padding="0 20px 20px 20px" align="center">
        <ConditionalRender condition={!!yearlyDiscount}>
          <H1_TextMiddle
            fontSize="18px"
            lineHeight="25px"
            color={isPriceMonthly ? "#0f0c1d" : theme.blue4}
          >
            {formatMessage(pricingPageMessages.togglerTextYearlyWithDiscount, {
              discount: yearlyDiscount
            })}
          </H1_TextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={!yearlyDiscount}>
          <H1_TextMiddle
            fontSize="18px"
            lineHeight="25px"
            color={isPriceMonthly ? "#0f0c1d" : theme.blue4}
          >
            {formatMessage(pricingPageMessages.togglerTextYearly)}
          </H1_TextMiddle>
        </ConditionalRender>
        <Switch checked={isPriceMonthly} onChange={onChangePrice} />
        <H1_TextMiddle
          fontSize="18px"
          lineHeight="25px"
          color={isPriceMonthly ? theme.blue4 : "#0f0c1d"}
        >
          {formatMessage(pricingPageMessages.togglerTextMonthly)}
        </H1_TextMiddle>
      </H1_FlexRow>
      <PlansFlexRow gap="30px" padding="30px 0">
        <PricingPagePlan
          plan={PlanToUpgrade["on-boarding"]}
          showPriceDescription
          disabled={!onBoardingPricingMode}
          isSelected={!onBoardingPricingMode && selectedPlan === PlanEnum.onBoarding}
          onSelectedPlan={onSelectedPlan}
          paymentType={paymentPeriod}
          onBoardingPricingMode={onBoardingPricingMode}
        />
        <PricingPagePlan
          plan={PlanToUpgrade.personal}
          showPriceDescription
          showUpgradeIcon
          disabled={selectedPlan === PlanEnum.business}
          isSelected={selectedPlan === PlanEnum.personal}
          onSelectedPlan={onSelectedPlan}
          isPriceMonthly={isPriceMonthly}
          paymentType={paymentPeriod}
          onBoardingPricingMode={onBoardingPricingMode}
        />
        <PricingPagePlan
          plan={PlanToUpgrade.business}
          showPriceDescription
          showUpgradeIcon
          showMostPopular
          isSelected={selectedPlan === PlanEnum.business}
          disabled={selectedPlan === PlanEnum.enterprise}
          onSelectedPlan={onSelectedPlan}
          isPriceMonthly={isPriceMonthly}
          paymentType={paymentPeriod}
          onBoardingPricingMode={onBoardingPricingMode}
        />
        <PricingPagePlan
          plan={PlanToUpgrade.enterprise}
          showPriceDescription
          isSelected={selectedPlan === PlanEnum.enterprise}
          showContactSalesPlan
          onSelectedPlan={onSelectedPlan}
          paymentType={paymentPeriod}
        />
      </PlansFlexRow>
    </H1_FlexColumn>
  );
};
export default PricingContent;
