import React, { ReactNode } from "react";
import styled from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

interface ButtonBoxProps {
  icon: ReactNode;
  title: string | ReactNode;
  description?: string | ReactNode;
  onClick?: () => void;
  width?: string;
  selected?: boolean;
}
//
const Wrapper = styled(H1_FlexColumn)<{ $selected?: boolean }>`
  border: ${({ $selected }) => ($selected ? "1px solid #b9b9b9;" : "1px dashed #b9b9b9;")} 
  border-radius: 2px;
  min-width: 210px;
`;

const IconWrapepr = styled("div")`
  font-size: 34px;
  color: ${(props) => props.theme.gray8};
`;
const ButtonBox = ({ icon, title, description, onClick, width, selected }: ButtonBoxProps) => {
  return (
    <Wrapper
      $selected={selected}
      width={width}
      align="center"
      onClick={onClick}
      gap="5px"
      padding="22px"
    >
      <IconWrapepr>{icon}</IconWrapepr>
      <H1_TextXs textAlign="center">{title}</H1_TextXs>
      <ConditionalRender condition={!!description}>
        <H1_TextXs textAlign="center" whiteSpace="normal" color="#AAAAAA" fontSize="11px">
          {description}
        </H1_TextXs>
      </ConditionalRender>
    </Wrapper>
  );
};

export default ButtonBox;
