import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftPageMessages } from "app/pages/EditDraftPage/messages";
import styled, { useTheme } from "styled-components";
import CreateVideoButton from "app/components/editor/CreateVideoButton";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import useModal, { ModalName } from "app/hooks/useModal";
import { FeatureFlag, Video } from "app/types";
import * as videosSelector from "app/store/selectorsV2/videos.selectors";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import usePermissions from "app/hooks/usePermissions";
import TopVideoActionsIconMenuItem from "app/pages/EditDraftPage/TopVideoActions/TopVideoActionsIconMenuItem";
import React, { useState } from "react";
import TopVideoActionsSharePopover from "app/pages/EditDraftPage/TopVideoActions/TopVideoActionsSharePopover";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { useFlags } from "launchdarkly-react-client-sdk";
import PreviewButton from "app/pages/EditDraftPage/PreviewButton";
import PublishButton from "app/pages/EditDraftPage/workflow/PublishButton";
import WorkflowLiveButton from "app/pages/EditDraftPage/workflow/WorklfowLiveButton";
import ShareWorkflowModal from "app/pages/EditDraftPage/workflow/ShareWorkflowModal";
import WorkflowRevertButton from "app/pages/EditDraftPage/workflow/WorkflowRevertButton";
import { Button } from "@nextui-org/react";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { ThemeMode } from "app/utils/theme";

const EDITOR_HEADER = "editor_header";

const RoundButton = styled(Button)`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.gray4};
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  span {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  }
  i {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  }
  &:hover,
  &:active {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    span {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    i {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    background-color: ${({ theme }) => theme.gray5};
  }
`;

const CodeIcon = styled.i`
  font-size: 15px;
`;

const PlayTriangle = styled.i`
  font-size: 20px;
  color: ${({ theme }) => theme.gray1};
  margin-left: 2px;
`;

const StyledShare = styled(Button)`
  width: 103px;
  height: 40px;
  background: #2c285c;
  align-self: end;
`;

interface TopVideoActionsProps {
  draftId: string;
}
const TopVideoActions = ({ draftId }: TopVideoActionsProps) => {
  const [isShareWorkflowVisible, setIsShareWorkflowVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const theme = useTheme();
  const { isWriteWorkspaceAllow } = usePermissions();
  const flags = useFlags();

  const readyToPlayVideos: Video[] = useAppSelector(videosSelector.getReadyToPlayVideos);
  const videosStatus = useAppSelector((state) => state.videos.videosStatus);
  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const draftType = useAppSelector((state) => state.drafts.currentDraft?.type);
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);

  const isWorkflow = draftType === "workflow";
  const isLoading =
    draftsStatus === fetchingStatus.loading || videosStatus === fetchingStatus.loading;
  const lastReadyVideo = readyToPlayVideos[0];

  const onOpenVersions = () => {
    dispatch(googleEvents.showOlderVersions({ source: EDITOR_HEADER }));
    openDrawer(Drawer.Versions);
  };

  const watchLatestVideo = () => {
    dispatch(googleEvents.watchLatest({ source: EDITOR_HEADER }));
    openModal(ModalName.videoReady, { reqId: lastReadyVideo.req_id });
  };

  const onOpenShareWorkflow = () => {
    // Todo add event
    setIsShareWorkflowVisible(true);
  };
  const onCloseShareWorkflow = () => {
    // Todo add event
    setIsShareWorkflowVisible(false);
  };

  return (
    <H1_FlexRow gap="10px" align={"center"}>
      <ShareWorkflowModal visible={isShareWorkflowVisible} onClose={onCloseShareWorkflow} />
      <WorkflowLiveButton />
      <WorkflowRevertButton />
      <ConditionalRender condition={!!lastReadyVideo && !workflowLiveMode && !isWorkflow}>
        <NextDropdown
          items={[
            {
              isDisabled: true,
              key: "watch_again",
              label: (
                <H1_TextXs fontWeight={600} color={theme.pink4}>
                  {formatMessage(draftPageMessages.watchLatestVideoHeadline)}
                </H1_TextXs>
              )
            },
            {
              className: "video-share-menu clickable",
              key: "watch_latest_video",
              label: (
                <TopVideoActionsIconMenuItem
                  keyItem="watch_latest_video"
                  onClick={watchLatestVideo}
                  icon={<CodeIcon className="far fa-play-circle" />}
                  text={formatMessage(draftPageMessages.watchLatest)}
                />
              )
            },
            {
              className: "video-share-menu clickable",
              key: "show_older_versions",
              label: (
                <TopVideoActionsIconMenuItem
                  keyItem="show_older_versions"
                  onClick={onOpenVersions}
                  icon={<CodeIcon className="fas fa-history" />}
                  text={formatMessage(draftPageMessages.showOlderVersions)}
                />
              )
            }
          ]}
          withWhiteBackground
        >
          <RoundButton
            data-auto-id="watch-latest-video-button"
            startContent={<PlayTriangle className="fa-solid fa-play" />}
            isDisabled={isLoading}
            isIconOnly
          />
        </NextDropdown>
        <TopVideoActionsSharePopover draftId={draftId} />
      </ConditionalRender>
      <ConditionalRender condition={flags[FeatureFlag.previewButton] && !workflowLiveMode}>
        <PreviewButton />
      </ConditionalRender>
      <ConditionalRender condition={isWriteWorkspaceAllow && !isWorkflow}>
        <CreateVideoButton source="editorTopBar" />
      </ConditionalRender>
      <ConditionalRender condition={isWorkflow && !workflowLiveMode}>
        <PublishButton />
      </ConditionalRender>
      <ConditionalRender condition={isWorkflow && workflowLiveMode}>
        <StyledShare color="primary" onClick={onOpenShareWorkflow}>
          {formatMessage(draftPageMessages.shareHeadline)}
        </StyledShare>
      </ConditionalRender>
    </H1_FlexRow>
  );
};
export default TopVideoActions;
