import styled, { css } from "styled-components";
import React, { MouseEventHandler, ReactNode } from "react";
import { motion } from "framer-motion";

const LinkButtonContainer = styled(motion.div)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  svg {
    fill: ${(props) => props.theme.gray9};
  }

  &:focus {
    border: none;
  }
  ${({
    $disabled,
    $fontSize,
    $color,
    $disabledColor,
    $width,
    $height,
    $display
  }: {
    $disabled: boolean;
    $fontSize?: string;
    $color?: string;
    $disabledColor: string;
    $width: string;
    $height: string;
    $display?: string;
  }) => css`
    color: ${$disabled ? $disabledColor : $color};
    font-size: ${$fontSize};
    width: ${$width};
    height: ${$height};
    display: ${$display};
    svg {
      fill: ${$disabled ? $disabledColor : $color};
      .background {
        fill: transparent;
      }
    }
    pointer-events: ${$disabled ? "none" : "auto"};
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
      transform: scale(0.95);
    }
  `};
`;

export interface LinkButtonProps<T> {
  className?: string;
  $fontSize?: string;
  $disabled?: boolean;
  $color?: string;
  $disabledColor?: string;
  background$color?: string;
  $height?: string;
  $width?: string;
  $display?: string;
  animate?: object;
  transition?: object;
  icon?: ReactNode;
  onClick?: MouseEventHandler<T>;
  children?: React.ReactNode;
}

const LinkButton = ({
  className,
  $fontSize,
  $disabled,
  $color,
  $disabledColor,
  $height,
  $width,
  $display,
  icon,
  onClick,
  animate,
  transition,
  children
}: LinkButtonProps<HTMLDivElement>) => {
  return (
    <LinkButtonContainer
      $disabled={$disabled || false}
      $fontSize={$fontSize || "16px"}
      $height={$height || "auto"}
      $width={$width || "auto"}
      $color={$color || "#0f0c1d"}
      $disabledColor={$disabledColor || "#d9d9d9"}
      $display={$display || "flex"}
      className={className}
      animate={animate}
      transition={transition}
      onClick={onClick}
    >
      {!!icon && icon}
      {children}
    </LinkButtonContainer>
  );
};
export default LinkButton;
