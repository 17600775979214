import React, { useMemo } from "react";
import { Draft, InternalScene, Scene } from "app/types";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneActionOrigin } from "app/store/thunks/analyticsEvents.thunk";
import { scenesActions } from "app/store/slices/scenes.slice";
import { Button, Dropdown } from "antd";
import { leftMenuMessages } from "app/pages/editor/messages";
import styled, { css, useTheme } from "styled-components";
import { EllipsisOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { useIntl } from "react-intl";
import ScenePreview from "app/components/editor/scene/ScenePreview";
import * as scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const StyledButton = styled(Button)`
  height: 12px;
  width: 21px;
  padding: 0;
  border-radius: 3px;
`;

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 12px;
`;

const MenuContainerFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  z-index: 2;
`;

const BoxFlexColumn = styled(H1_FlexColumn)<{ isSelected: boolean; redBorder?: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  width: 123px;
  border: 2px solid transparent;
  padding: 5px;
  &:hover {
    border-color: ${(props) => props.theme.gray5};
  }
  ${({ isSelected, redBorder, theme }) =>
    css`
      && {
        border-color: ${isSelected && theme.blue4};
        border-color: ${redBorder && theme.pink4};
      }
    `}
  &:hover ${MenuContainerFlexRow} {
    display: inherit;
  }
`;

const StyledText = styled(H1_TextXs)`
  padding: 0 5px;
`;

const FlexRowScenePreview = styled(H1_FlexRow)`
  width: 109px;
  min-height: 62px;
  border-radius: 5px;
`;

interface SortableSceneItemProps {
  currentSceneId: string;
}

const SceneItem = ({ currentSceneId }: SortableSceneItemProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const { selectedSceneIndex, sceneId, scene } = useSelectedScene();
  const dispatch = useAppDispatch();
  const scenes = useAppSelector(scenesSelectors.getScenesWithCharacterAndVoice);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  // @ts-ignore handels userUI store typing
  const sceneIdsErrors = useAppSelector((state) => state.userUi.sceneIdsError);
  const sceneName = scene?.name as string;

  const draftId = draft.id as string;

  const { currentScene, idx } = useMemo(() => {
    let findActiveScene = null;
    let findActiveSceneIndex = -1;
    if (scenes && currentSceneId) {
      findActiveSceneIndex = scenes.findIndex(({ id }) => id === currentSceneId);
      findActiveScene = scenes.find(({ id }) => id === currentSceneId);
    }

    return { currentScene: findActiveScene, idx: findActiveSceneIndex };
  }, [scenes, currentSceneId]);

  const redBorder = useMemo(() => {
    return Object.keys(sceneIdsErrors[currentSceneId] ?? {}).length > 0;
  }, [currentScene, sceneIdsErrors]);

  const onDeleteScene = (deletedScene: Scene) => {
    analyticsEvents.deleteScene({
      selectedScene: {
        name: sceneName,
        id: sceneId,
        index: selectedSceneIndex
      },
      action: SceneActionOrigin.NavigationBar
    });
    dispatch(
      scenesActions.deleteDraftSceneRequest({
        draftId,
        sceneId: deletedScene.id
      })
    );
  };
  const onDuplicate = (duplicatedScene: Scene) => {
    analyticsEvents.duplicateScene({
      selectedScene: {
        name: sceneName,
        id: sceneId,
        index: selectedSceneIndex
      },
      action: SceneActionOrigin.NavigationBar
    });
    dispatch(
      scenesActions.addDraftSceneRequest({
        draftId,
        parentId: duplicatedScene.id,
        scene: duplicatedScene
      })
    );
  };

  const menu = (selectedScene: Scene) => ({
    items: [
      {
        key: "duplicate-scene",
        icon: <i className="far fa-copy" />,
        onClick: () => onDuplicate(selectedScene),
        label: intl.formatMessage(leftMenuMessages.duplicateScene)
      },
      {
        key: "delete-scene",
        icon: <i className="far fa-trash" />,
        onClick: () => onDeleteScene(selectedScene),
        label: intl.formatMessage(leftMenuMessages.deleteScene)
      }
    ]
  });

  return (
    <BoxFlexColumn
      redBorder={redBorder}
      data-auto-id={`editor-single-scene-scene-${idx}`}
      key={currentSceneId}
      isSelected={sceneId === currentSceneId}
      flex="0 0 auto"
    >
      <MenuContainerFlexRow justify="flex-end">
        <Dropdown placement="bottomRight" menu={menu(currentScene as InternalScene)}>
          <StyledButton icon={<StyledEllipsisOutlined />} />
        </Dropdown>
      </MenuContainerFlexRow>
      <FlexRowScenePreview overflow="hidden" height="100%">
        <ScenePreview
          disabledPreview
          height="55"
          sceneId={currentSceneId}
          url={currentScene?.last_preview_url}
        />
      </FlexRowScenePreview>
      <StyledText color={theme.gray8}>{idx + 1}</StyledText>
    </BoxFlexColumn>
  );
};

export default SceneItem;
