/* eslint-disable camelcase */
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  styleTitle: {
    id: `editor.drawers.global.style.title`,
    defaultMessage: "Select Colors"
  },
  colorsTitle: {
    id: `editor.drawers.global.colors.title`,
    defaultMessage: "Colors"
  },
  imageTitle: {
    id: `editor.drawers.global.image.title`,
    defaultMessage: "Images"
  },
  videoTitle: {
    id: `editor.drawers.global.video.title`,
    defaultMessage: "Video Settings"
  },
  logoTitle: {
    id: `editor.drawers.global.logo.title`,
    defaultMessage: "Logo"
  },
  brandNameInputPlaceholder: {
    id: `editor.drawers.global.brand.name.input.placeholder`,
    defaultMessage: "BrandKit name"
  },
  replaceLogoTooltip: {
    id: `editor.drawers.global.logo.replace-logo-tooltip`,
    defaultMessage: "Replace Logo"
  },
  removeLogoTooltip: {
    id: `editor.drawers.global.logo.remove-logo-tooltip`,
    defaultMessage: "Remove Logo"
  },
  addYourLogo: {
    id: `editor.drawers.global.logo.add-your-logo`,
    defaultMessage: "Add Logo"
  },
  logoUploadText: {
    id: `editor.drawers.global.logo.upload-text`,
    defaultMessage: "Insert Logo"
  },
  globalFieldBadge: {
    id: `editor.drawers.global.badge`,
    defaultMessage: "Global"
  },
  voiceDrawerTitle: {
    id: `editor.drawers.voice.title`,
    defaultMessage: "<b>Pick a</b> Voice"
  },
  voiceSupportPause: {
    id: `editor.drawers.voice.supports-pause`,
    defaultMessage: "Supports Pause"
  },
  voiceDrawerPaymentUpgrade: {
    id: `editor.drawers.voice.payment-upgrade`,
    defaultMessage: "Upgrade to use this voice"
  },
  voiceButtonSelectedLabel: {
    id: `editor.drawers.voice.button.selected.label`,
    defaultMessage: "Selected"
  },
  voiceButtonSelectLabel: {
    id: `editor.drawers.voice.button.select.label`,
    defaultMessage: "Select"
  },
  voiceTextSeeAll: {
    id: `editor.drawers.voice.button.see-all`,
    defaultMessage: "See All"
  },
  voiceTextSeeRecommended: {
    id: `editor.drawers.voice.button.see-recommended`,
    defaultMessage: "See Recommended"
  },
  voiceTextRecommendedVoices: {
    id: `editor.drawers.voice.button.recommended-voices`,
    defaultMessage: "Recommended"
  },
  voiceTextAllVoices: {
    id: `editor.drawers.voice.button.all-voices`,
    defaultMessage: "All"
  },
  voiceDrawerNoResultsFound: {
    id: `editor.drawers.voice.clear-search`,
    defaultMessage: `Sorry we could not find any voices for "{query}". Try searching something else or related, or <link>clear search</link>`
  },
  voiceDrawerSearchPlaceholder: {
    id: `editor.drawers.voice.search-placeholder`,
    defaultMessage: "Search language"
  },
  backToScene: {
    id: `editor.drawer.voice.back-to-scene`,
    defaultMessage: "Back to scene"
  },
  voiceDrawerLanguageName_us: {
    id: `editor.drawers.voice.language-name.us`,
    defaultMessage: "English (US)"
  },
  voiceDrawerLanguageName_us_sp: {
    id: `editor.drawers.voice.language-name.us-sp`,
    defaultMessage: "Spanish (US)"
  },
  voiceDrawerLanguageName_be: {
    id: `editor.drawers.voice.language-name.be`,
    defaultMessage: "Flemish (BE)"
  },
  voiceDrawerLanguageName_un: {
    id: `editor.drawers.voice.language-name.un`,
    defaultMessage: "Global (UN)"
  },
  voiceDrawerLanguageName_hr: {
    id: `editor.drawers.voice.language-name.hr`,
    defaultMessage: "Croatian (HR)"
  },
  voiceDrawerLanguageName_al: {
    id: `editor.drawers.voice.language-name.al`,
    defaultMessage: "Albanian (AL)"
  },
  voiceDrawerLanguageName_am: {
    id: `editor.drawers.voice.language-name.am`,
    defaultMessage: "Armenian (AM)"
  },
  voiceDrawerLanguageName_pr: {
    id: `editor.drawers.voice.language-name.pr`,
    defaultMessage: "Spanish (PR)"
  },
  voiceDrawerLanguageName_az: {
    id: `editor.drawers.voice.language-name.az`,
    defaultMessage: "Azerbaijani (AZ)"
  },
  voiceDrawerLanguageName_ba: {
    id: `editor.drawers.voice.language-name.ba`,
    defaultMessage: "Bosnian (BA)"
  },
  voiceDrawerLanguageName_by: {
    id: `editor.drawers.voice.language-name.by`,
    defaultMessage: "Belarusian (BY)"
  },
  voiceDrawerLanguageName_cm: {
    id: `editor.drawers.voice.language-name.cm`,
    defaultMessage: "French (CM)"
  },
  voiceDrawerLanguageName_np: {
    id: `editor.drawers.voice.language-name.np`,
    defaultMessage: "Nepali (NP)"
  },
  voiceDrawerLanguageName_cr: {
    id: `editor.drawers.voice.language-name.cr`,
    defaultMessage: "Spanish (CR)"
  },
  voiceDrawerLanguageName_ee: {
    id: `editor.drawers.voice.language-name.ee`,
    defaultMessage: "Estonian (EE)"
  },
  voiceDrawerLanguageName_be_fr: {
    id: `editor.drawers.voice.language-name.be-fr`,
    defaultMessage: "French (BE)"
  },
  voiceDrawerLanguageName_hk: {
    id: `editor.drawers.voice.language-name.hk`,
    defaultMessage: "English (HK)"
  },
  voiceDrawerLanguageName_hk_en: {
    id: `editor.drawers.voice.language-name.hk-en`,
    defaultMessage: "English (HK)"
  },
  voiceDrawerLanguageName_hk_cn: {
    id: `editor.drawers.voice.language-name.hk-cn`,
    defaultMessage: "Cantonese (HK)"
  },
  voiceDrawerLanguageName_is: {
    id: `editor.drawers.voice.language-name.is`,
    defaultMessage: "Icelandic (IS)"
  },
  voiceDrawerLanguageName_kh: {
    id: `editor.drawers.voice.language-name.kh`,
    defaultMessage: "Khmer (KH)"
  },
  voiceDrawerLanguageName_la: {
    id: `editor.drawers.voice.language-name.la`,
    defaultMessage: "Lao (LA)"
  },
  voiceDrawerLanguageName_uz: {
    id: `editor.drawers.voice.language-name.uz`,
    defaultMessage: "Uzbek (UZ)"
  },
  voiceDrawerLanguageName_kg: {
    id: `editor.drawers.voice.language-name.kg`,
    defaultMessage: "Kyrgyz (KG)"
  },
  voiceDrawerLanguageName_tj: {
    id: `editor.drawers.voice.language-name.tj`,
    defaultMessage: "Tajiki (TJ)"
  },
  voiceDrawerLanguageName_lt: {
    id: `editor.drawers.voice.language-name.lt`,
    defaultMessage: "Lithuanian (LT)"
  },
  voiceDrawerLanguageName_me: {
    id: `editor.drawers.voice.language-name.me`,
    defaultMessage: "Montenegrin (ME)"
  },
  voiceDrawerLanguageName_ir: {
    id: `editor.drawers.voice.language-name.ir`,
    defaultMessage: "Persian (IR)"
  },
  voiceDrawerLanguageName_mk: {
    id: `editor.drawers.voice.language-name.mk`,
    defaultMessage: "Macedonian (MK)"
  },
  voiceDrawerLanguageName_mn: {
    id: `editor.drawers.voice.language-name.mn`,
    defaultMessage: "Mongolian (MN)"
  },
  voiceDrawerLanguageName_mt: {
    id: `editor.drawers.voice.language-name.mt`,
    defaultMessage: "Maltese (MT)"
  },
  voiceDrawerLanguageName_gb_sct: {
    id: `editor.drawers.voice.language-name.gb-sct`,
    defaultMessage: "Scottish (GB-SCT)"
  },
  voiceDrawerLanguageName_gb_wls: {
    id: `editor.drawers.voice.language-name.gb-wls`,
    defaultMessage: "Welsh (GB-WLS)"
  },
  voiceDrawerLanguageName_my: {
    id: `editor.drawers.voice.language-name.my`,
    defaultMessage: "Malay (MY)"
  },
  voiceDrawerLanguageName_gb: {
    id: `editor.drawers.voice.language-name.gb`,
    defaultMessage: "English (GB)"
  },
  voiceDrawerLanguageName_ph: {
    id: `editor.drawers.voice.language-name.ph`,
    defaultMessage: "Tagalog (PH)"
  },
  voiceDrawerLanguageName_sa: {
    id: `editor.drawers.voice.language-name.sa`,
    defaultMessage: "Arabic (SA)"
  },
  voiceDrawerLanguageName_cn: {
    id: `editor.drawers.voice.language-name.cn`,
    defaultMessage: "Mandarin (CN)"
  },
  voiceDrawerLanguageName_cn_mn: {
    id: `editor.drawers.voice.language-name.cn-mn`,
    defaultMessage: "Mandarin (CN)"
  },
  voiceDrawerLanguageName_cn_cn: {
    id: `editor.drawers.voice.language-name.cn-cn`,
    defaultMessage: "Cantonese (CN)"
  },
  voiceDrawerLanguageName_hu: {
    id: `editor.drawers.voice.language-name.hu`,
    defaultMessage: "Hungarian (HU)"
  },
  voiceDrawerLanguageName_za: {
    id: `editor.drawers.voice.language-name.za`,
    defaultMessage: "Zulu (ZA)"
  },
  voiceDrawerLanguageName_za_za: {
    id: `editor.drawers.voice.language-name.za-za`,
    defaultMessage: "Zulu (ZA)"
  },
  voiceDrawerLanguageName_za_af: {
    id: `editor.drawers.voice.language-name.za_af`,
    defaultMessage: "Afrikaans (ZA)"
  },
  voiceDrawerLanguageName_za_en: {
    id: `editor.drawers.voice.language-name.za_en`,
    defaultMessage: "English (ZA)"
  },
  voiceDrawerLanguageName_ae: {
    id: `editor.drawers.voice.language-name.ae`,
    defaultMessage: "Arabic (AE)"
  },
  voiceDrawerLanguageName_iq: {
    id: `editor.drawers.voice.language-name.iq`,
    defaultMessage: "Arabic (IQ)"
  },
  voiceDrawerLanguageName_ma: {
    id: `editor.drawers.voice.language-name.ma`,
    defaultMessage: "Arabic (MA)"
  },
  voiceDrawerLanguageName_dk: {
    id: `editor.drawers.voice.language-name.dk`,
    defaultMessage: "Danish (DK)"
  },
  voiceDrawerLanguageName_uy: {
    id: `editor.drawers.voice.language-name.uy`,
    defaultMessage: "Spanish (UY)"
  },
  voiceDrawerLanguageName_sk: {
    id: `editor.drawers.voice.language-name.sk`,
    defaultMessage: "Slovak (SK)"
  },
  voiceDrawerLanguageName_si: {
    id: `editor.drawers.voice.language-name.si`,
    defaultMessage: "Slovenian (SI)"
  },
  voiceDrawerLanguageName_rs: {
    id: `editor.drawers.voice.language-name.rs`,
    defaultMessage: "Serbian (RS)"
  },
  voiceDrawerLanguageName_th: {
    id: `editor.drawers.voice.language-name.th`,
    defaultMessage: "Thai (TH)"
  },
  voiceDrawerLanguageName_tr: {
    id: `editor.drawers.voice.language-name.tr`,
    defaultMessage: "Turkish (TR)"
  },
  voiceDrawerLanguageName_ua: {
    id: `editor.drawers.voice.language-name.ua`,
    defaultMessage: "Ukrainian (UA)"
  },
  voiceDrawerLanguageName_ca: {
    id: `editor.drawers.voice.language-name.ca`,
    defaultMessage: "French (CA)"
  },
  voiceDrawerLanguageName_ca_en: {
    id: `editor.drawers.voice.language-name.ca-en`,
    defaultMessage: "English (CA)"
  },
  voiceDrawerLanguageName_ca_fr: {
    id: `editor.drawers.voice.language-name.ca-fr`,
    defaultMessage: "French (CA)"
  },
  voiceDrawerLanguageName_au: {
    id: `editor.drawers.voice.language-name.au`,
    defaultMessage: "English (AU)"
  },
  voiceDrawerLanguageName_ke: {
    id: `editor.drawers.voice.language-name.ke`,
    defaultMessage: "English (KE)"
  },
  voiceDrawerLanguageName_ke_sw: {
    id: `editor.drawers.voice.language-name.ke-sw`,
    defaultMessage: "Swahili (KE)"
  },
  voiceDrawerLanguageName_tz_sw: {
    id: `editor.drawers.voice.language-name.tz-sw`,
    defaultMessage: "Swahili (TZ)"
  },
  voiceDrawerLanguageName_do: {
    id: `editor.drawers.voice.language-name.do`,
    defaultMessage: "Spanish (DO)"
  },
  voiceDrawerLanguageName_cl: {
    id: `editor.drawers.voice.language-name.cl`,
    defaultMessage: "Spanish (CL)"
  },
  voiceDrawerLanguageName_cu: {
    id: `editor.drawers.voice.language-name.cu`,
    defaultMessage: "Spanish (CU)"
  },
  voiceDrawerLanguageName_gq: {
    id: `editor.drawers.voice.language-name.gq`,
    defaultMessage: "Spanish (GQ)"
  },
  voiceDrawerLanguageName_mx: {
    id: `editor.drawers.voice.language-name.mx`,
    defaultMessage: "Spanish (MX)"
  },
  voiceDrawerLanguageName_bo: {
    id: `editor.drawers.voice.language-name.bo`,
    defaultMessage: "Spanish (BO)"
  },
  voiceDrawerLanguageName_ec: {
    id: `editor.drawers.voice.language-name.ec`,
    defaultMessage: "Spanish (EC)"
  },
  voiceDrawerLanguageName_sv: {
    id: `editor.drawers.voice.language-name.sv`,
    defaultMessage: "Spanish (SV)"
  },
  voiceDrawerLanguageName_gt: {
    id: `editor.drawers.voice.language-name.gt`,
    defaultMessage: "Spanish (GT)"
  },
  voiceDrawerLanguageName_pe: {
    id: `editor.drawers.voice.language-name.pe`,
    defaultMessage: "Peru (PE)"
  },
  voiceDrawerLanguageName_pt: {
    id: `editor.drawers.voice.language-name.pt`,
    defaultMessage: "Portuguese (PT)"
  },
  voiceDrawerLanguageName_ch: {
    id: `editor.drawers.voice.language-name.ch`,
    defaultMessage: "German (CH)"
  },
  voiceDrawerLanguageName_eg: {
    id: `editor.drawers.voice.language-name.eg`,
    defaultMessage: "Arabic (EG)"
  },
  voiceDrawerLanguageName_at: {
    id: `editor.drawers.voice.language-name.at`,
    defaultMessage: "German (AT)"
  },
  voiceDrawerLanguageName_id: {
    id: `editor.drawers.voice.language-name.id`,
    defaultMessage: "Indonesian (ID)"
  },
  voiceDrawerLanguageName_ro: {
    id: `editor.drawers.voice.language-name.ro`,
    defaultMessage: "Romanian (RO)"
  },
  voiceDrawerLanguageName_lv: {
    id: `editor.drawers.voice.language-name.lv`,
    defaultMessage: "Latvian (LV)"
  },
  voiceDrawerLanguageName_pk: {
    id: `editor.drawers.voice.language-name.pk`,
    defaultMessage: "Punjabi (PK)"
  },
  voiceDrawerLanguageName_de: {
    id: `editor.drawers.voice.language-name.de`,
    defaultMessage: "German (DE)"
  },
  voiceDrawerLanguageName_gr: {
    id: `editor.drawers.voice.language-name.gr`,
    defaultMessage: "Greek (GR)"
  },
  voiceDrawerLanguageName_fr: {
    id: `editor.drawers.voice.language-name.fr`,
    defaultMessage: "French (FR)"
  },
  voiceDrawerLanguageName_il: {
    id: `editor.drawers.voice.language-name.il`,
    defaultMessage: "Hebrew (IL)"
  },
  voiceDrawerLanguageName_in: {
    id: `editor.drawers.voice.language-name.in`,
    defaultMessage: "Hindi (IN)"
  },
  voiceDrawerLanguageName_in_en: {
    id: `editor.drawers.voice.language-name.in-en`,
    defaultMessage: "English (IN)"
  },
  voiceDrawerLanguageName_in_bn: {
    id: `editor.drawers.voice.language-name.in-bn`,
    defaultMessage: "Bengali (IN)"
  },
  voiceDrawerLanguageName_in_gu: {
    id: `editor.drawers.voice.language-name.in-gu`,
    defaultMessage: "Gujarati (IN)"
  },
  voiceDrawerLanguageName_in_hi: {
    id: `editor.drawers.voice.language-name.in_hi`,
    defaultMessage: "Hindi (IN)"
  },
  voiceDrawerLanguageName_in_kn: {
    id: `editor.drawers.voice.language-name.in_kn`,
    defaultMessage: "Kannada (IN)"
  },
  voiceDrawerLanguageName_in_ml: {
    id: `editor.drawers.voice.language-name.in_ml`,
    defaultMessage: "Malayalam (IN)"
  },
  voiceDrawerLanguageName_in_mr: {
    id: `editor.drawers.voice.language-name.in_mr`,
    defaultMessage: "Marathi (IN)"
  },
  voiceDrawerLanguageName_in_pa: {
    id: `editor.drawers.voice.language-name.in_pa`,
    defaultMessage: "Punjabi (IN)"
  },
  voiceDrawerLanguageName_in_ta: {
    id: `editor.drawers.voice.language-name.in_ta`,
    defaultMessage: "Tamil (IN)"
  },
  voiceDrawerLanguageName_in_te: {
    id: `editor.drawers.voice.language-name.in_te`,
    defaultMessage: "Telugu (IN)"
  },
  voiceDrawerLanguageName_in_ur: {
    id: `editor.drawers.voice.language-name.in_ur`,
    defaultMessage: "Urdu (IN)"
  },
  voiceDrawerLanguageName_it: {
    id: `editor.drawers.voice.language-name.it`,
    defaultMessage: "Italian (IT)"
  },
  voiceDrawerLanguageName_jp: {
    id: `editor.drawers.voice.language-name.jp`,
    defaultMessage: "Japanese (JP)"
  },
  voiceDrawerLanguageName_kr: {
    id: `editor.drawers.voice.language-name.kr`,
    defaultMessage: "Korean (KR)"
  },
  voiceDrawerLanguageName_nl: {
    id: `editor.drawers.voice.language-name.nl`,
    defaultMessage: "Dutch (NL)"
  },
  voiceDrawerLanguageName_qa: {
    id: `editor.drawers.voice.language-name.qa`,
    defaultMessage: "Arabic (QA)"
  },
  voiceDrawerLanguageName_pl: {
    id: `editor.drawers.voice.language-name.pl`,
    defaultMessage: "Polish (PL)"
  },
  voiceDrawerLanguageName_bg: {
    id: `editor.drawers.voice.language-name.bg`,
    defaultMessage: "Bulgarian (BG)"
  },
  voiceDrawerLanguageName_fi: {
    id: `editor.drawers.voice.language-name.fi`,
    defaultMessage: "Finnish (FI)"
  },
  voiceDrawerLanguageName_cz: {
    id: `editor.drawers.voice.language-name.cz`,
    defaultMessage: "Czech (CZ)"
  },
  voiceDrawerLanguageName_ru: {
    id: `editor.drawers.voice.language-name.ru`,
    defaultMessage: "Russian (RU)"
  },
  voiceDrawerLanguageName_ie: {
    id: `editor.drawers.voice.language-name.ie`,
    defaultMessage: "Irish (IE)"
  },
  voiceDrawerLanguageName_so: {
    id: `editor.drawers.voice.language-name.so`,
    defaultMessage: "Somali (SO)"
  },
  voiceDrawerLanguageName_se: {
    id: `editor.drawers.voice.language-name.se`,
    defaultMessage: "Swedish (SE)"
  },
  voiceDrawerLanguageName_es: {
    id: `editor.drawers.voice.language-name.es`,
    defaultMessage: "Spanish (ES)"
  },
  voiceDrawerLanguageName_ar: {
    id: `editor.drawers.voice.language-name.ar`,
    defaultMessage: "Spanish (AR)"
  },
  voiceDrawerLanguageName_bd: {
    id: `editor.drawers.voice.language-name.bd`,
    defaultMessage: "Bengali (BD)"
  },
  voiceDrawerLanguageName_kz: {
    id: `editor.drawers.voice.language-name.kz`,
    defaultMessage: "Kazakh (KZ)"
  },
  voiceDrawerLanguageName_no: {
    id: `editor.drawers.voice.language-name.no`,
    defaultMessage: "Norwegian (NO)"
  },
  voiceDrawerLanguageName_co: {
    id: `editor.drawers.voice.language-name.co`,
    defaultMessage: "Spanish (CO)"
  },
  voiceDrawerLanguageName_vn: {
    id: `editor.drawers.voice.language-name.vn`,
    defaultMessage: "Vietnamese (VN)"
  },
  voiceDrawerLanguageName_br: {
    id: `editor.drawers.voice.language-name.br`,
    defaultMessage: "Portuguese (BR)"
  },
  voiceSettings: {
    id: `editor.drawers.voice.settings-title`,
    defaultMessage: "Voice settings"
  },
  voiceSettingsSpeed: {
    id: `editor.drawers.voice.settings-speed`,
    defaultMessage: "Speed"
  },
  voiceSettingsPitch: {
    id: `editor.drawers.voice.settings-pitch`,
    defaultMessage: "Pitch"
  },
  voiceSettingsReset: {
    id: `editor.drawers.voice.settings-reset`,
    defaultMessage: "Reset"
  },
  voiceDrawerCloneButtonLabel: {
    id: `editor.drawers.voice.button.clone.label`,
    defaultMessage: "Clone your voice"
  },
  cloneButtonUpgradeText: {
    id: `editor.drawers.voice.button.clone.upgrade-text`,
    defaultMessage: "Upgrade to clone your voice"
  },
  uploadYourMusicButtonUpgradeText: {
    id: `editor.drawers.music.button.upload-your-music.upgrade-text`,
    defaultMessage: "Upgrade to upload your music"
  },
  renderingProgress: {
    id: `editor.drawers.rendering.progress`,
    defaultMessage: "Rendering Progress"
  },
  restoreModalTitle: {
    id: `editor.drawers.rendering.restore-modal-title`,
    defaultMessage: "Are you sure you want to restore this version?"
  },
  restoreModalDescription: {
    id: `editor.drawers.rendering.restore-modal-description`,
    defaultMessage: "Restoring this version will replace the current edited version."
  },
  export: {
    id: `editor.drawers.export.title`,
    defaultMessage: "Export"
  },
  voiceElement: {
    id: `editor.drawers.voice-element`,
    defaultMessage: "voice"
  }
});

export const exportVideoDrawerMessages = defineMessages({
  export: {
    id: `editor.drawers.export.title`,
    defaultMessage: "Export"
  },
  exportBackButton: {
    id: `editor.drawers.export.back-button`,
    defaultMessage: "Back"
  },
  exportEmbedButton: {
    id: `editor.drawers.export.embed-button`,
    defaultMessage: "Embed"
  },
  exportDownloadButton: {
    id: `editor.drawers.export.download-button`,
    defaultMessage: "Download"
  },
  exportDownloadTitle: {
    id: `editor.drawers.export.download-title`,
    defaultMessage: "Get your files here"
  },
  exportShareTitle: {
    id: `editor.drawers.export.share-title`,
    defaultMessage: "Share"
  }
});

export const musicDrawerMessages = defineMessages({
  searchPlaceholder: {
    id: `editor.drawers.music.search-placeholder`,
    defaultMessage: "Search music..."
  },
  switch: {
    id: `editor.drawer.music.switch.label-true`,
    defaultMessage: "Music"
  },
  noMusicSelected: {
    id: `editor.drawer.music.no-music-selected`,
    defaultMessage: "No music selected"
  },
  uploadYourMusic: {
    id: `editor.drawer.music.upload-your-music`,
    defaultMessage: "Upload your music"
  },
  editTitle: {
    id: `editor.drawer.music.edit-title`,
    defaultMessage: "Edit title"
  },
  deleteMusic: {
    id: `editor.drawer.music.delete-music`,
    defaultMessage: "Delete"
  },
  upgradeText: {
    id: `editor.drawer.music.upgrade-text`,
    defaultMessage: "Upload your own music"
  },
  upgradeSubText: {
    id: `editor.drawer.music.upgrade-sub-text`,
    defaultMessage: "Please contact our support in order to upload your own music"
  }
});

export const voiceDrawerFilterMessages = defineMessages({
  filter: {
    id: "editor.voice-drawer.filters.filter",
    defaultMessage: "Filter"
  },
  resetAll: {
    id: "editor.voice-drawer.filters.reset-all",
    defaultMessage: "Reset all"
  },
  age: {
    id: "editor.voice-drawer.filters.age",
    defaultMessage: "Age"
  },
  tone: {
    id: "editor.voice-drawer.filters.tone",
    defaultMessage: "Tone"
  },
  pause: {
    id: "editor.voice-drawer.filters.pause",
    defaultMessage: "Pause"
  },
  useCase: {
    id: "editor.voice-drawer.filters.use-case",
    defaultMessage: "Use case"
  },
  applyFilters: {
    id: "editor.voice-drawer.filters.apply-filters",
    defaultMessage: "Apply filters"
  }
});
