import { DubbingLanguages, DubbingLanguagesFull } from "app/types/playground";
import styled from "styled-components";
import { Divider, Select } from "antd";
import { onImageError } from "app/utils/helpers";
import React, { useMemo, useState } from "react";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const FlagImg = styled.img`
  width: 30px;
  margin-right: 8px;
`;
const BlackSelect = styled(Select)`
  width: 270px;
  height: 100%;
  && .ant-select-selector {
    border-radius: 4px;
    border: none;
    height: 44px;
    width: 100%;
    background: ${({ theme }) => `${theme.blue2}1f`};
    align-items: center;
    color: ${({ theme }) => theme.gray1};
    .ant-select-selection-item {
      height: 100%;
      align-items: center;
      display: flex;
    }
  }
`;

const BlackSearch = styled(H1_Input)`
  div,
  input {
    background: #343443;
    color: ${({ theme }) => theme.gray1};
    &::placeholder,
    ::placeholder {
      color: ${(props) => props.theme.gray3};
      font-weight: 400;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
    }
  }
  i {
    background: #343443;
    color: ${({ theme }) => theme.gray1};
  }
`;

interface PlayGroundTargetLanguageProps {
  onSelect: (target: DubbingLanguages) => void;
  selectedLanguage: DubbingLanguages;
}
interface OptionType {
  id: string;
  displayName: string;
  imageUrl: string;
}

const PlaygroundTargetLanguage = ({
  onSelect,
  selectedLanguage
}: PlayGroundTargetLanguageProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const languageOptions: OptionType[] = useMemo(() => {
    const list = Object.keys(DubbingLanguages).map((key) => {
      const languageKey = key as keyof typeof DubbingLanguages;
      return {
        id: DubbingLanguages[languageKey],
        displayName:
          key.toLowerCase().charAt(0).toUpperCase() + key.toLowerCase().slice(1).replace("_", " "),
        imageUrl: `https://flagcdn.com/${
          DubbingLanguagesFull[DubbingLanguages[languageKey] as keyof typeof DubbingLanguagesFull]
            ?.flag
        }.svg`
      };
    });
    return list.filter((elem) => elem.displayName.toLowerCase().includes(searchValue));
  }, [searchValue]);

  const currentLanguage = useMemo(
    () => languageOptions.find((lang) => lang.id === selectedLanguage)?.id,
    [selectedLanguage]
  );

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onClickClearSearch = () => {
    setSearchValue("");
  };

  const onSelectLanguage = (elem: any) => {
    onSelect(elem as DubbingLanguages);
  };

  if (!currentLanguage) {
    return null;
  }

  return (
    <H1_FlexColumn gap="6px">
      <H1_TextSmall color="white" lineHeight="25px">
        Target Language
      </H1_TextSmall>
      <BlackSelect
        placement="topRight"
        dropdownMatchSelectWidth={417}
        placeholder="Select a language"
        optionFilterProp="children"
        onSelect={onSelectLanguage}
        value={currentLanguage}
        popupClassName="playground-black-select-dropdown"
        suffixIcon={null}
        dropdownStyle={{ width: "270px", maxWidth: "270px", height: "300px" }}
        dropdownRender={(menu) => (
          <>
            <H1_FlexRow gap="14px" align="center" width="270px">
              <BlackSearch
                isFixedWidth
                allowClear
                onChange={onSearchChanged}
                onClear={onClickClearSearch}
                initialValue={searchValue}
                minWidth="236px"
                placeholder="Search language..."
              />
            </H1_FlexRow>
            <Divider />
            {menu}
          </>
        )}
      >
        {languageOptions.map((language) => (
          <Select.Option key={language.id as string} value={language.id as string}>
            <H1_FlexRow gap="15px" width="100%" align="center">
              <H1_FlexRow align="center">
                <FlagImg onError={onImageError} src={language.imageUrl} alt="" />
                <H1_TextSmall color="white">{language?.displayName}</H1_TextSmall>
              </H1_FlexRow>
            </H1_FlexRow>
          </Select.Option>
        ))}
      </BlackSelect>
    </H1_FlexColumn>
  );
};

export default PlaygroundTargetLanguage;
