import { defineMessages } from "react-intl";

export const pusherMessages = defineMessages({
  downloadSucceeded: {
    id: "pusher-message.download-video.download-succeeded",
    defaultMessage: "Video Download successfully!"
  }
});

export const llmPusherMessages = defineMessages({
  headLineTitleLlmWarning: {
    id: "llm-pusher-message.headline-title-llm-warning",
    defaultMessage: "AI Wizard Credits Warning"
  },
  headLineTitleLlm: {
    id: "llm-pusher-message.headline-title-llm",
    defaultMessage: "AI Wizard Credits"
  },
  subTitleLlmWarning: {
    id: "llm-pusher-message.sub-title-llm-warning",
    defaultMessage:
      "You have used {creditsPercent}% of the AI Wizard credits in your Hour One account."
  },
  subTitleLlm: {
    id: "llm-pusher-message.sub-title-llm",
    defaultMessage: "You have used all your AI Wizard credits this month."
  },
  upgradeButton: {
    id: "llm-pusher-message.upgrade-button",
    defaultMessage: "Upgrade"
  },
  upgradeLlmText: {
    id: "llm-pusher-message.upgrade-button-text",
    defaultMessage: "Upgrade to increase your monthly limit"
  }
});
