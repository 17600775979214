import { SocialMedia, Video } from "app/types";
import { MenuProps } from "antd";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { defineMessages, useIntl } from "react-intl";
import usePermissions from "app/hooks/usePermissions";
import { videoReadyModalMessages } from "app/pages/editor/messages";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import usePlaygroundShareSocialMedia from "app/pages/PlayGround/hooks/usePlaygroundShareSocialMedia";

const StyledDropdown = styled(H1_Dropdown)<{ $disabled: boolean }>`
  i {
    color: ${(props) => props.theme.gray1};
  }
  &:hover {
    background-color: #343445;
    color: ${(props) => props.theme.blue4};
    i {
      color: ${(props) => props.theme.blue4};
    }
    .share-menu i {
      color: ${({ theme }) => theme.gray1};
    }
  }
  &&& {
    padding: 8px 12px;
    background-color: #343445;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    box-shadow: none;
    border: none;
  }
  &.overlay-dropdown {
    box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
    .ant-dropdown {
      width: 250px;
    }
    max-height: 370px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray4};
    font-size: 12px;
    i {
      font-size: 16px;
    }
    .ant-dropdown-menu-item {
      padding: 11px 12px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.blue4};
        color: ${({ theme }) => theme.gray1};
        i {
          color: ${({ theme }) => theme.gray1};
        }
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 11px 12px;
    }
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      i {
        color: ${({ theme }) => theme.gray8};
      }
      &&:hover {
        i {
          color: ${({ theme }) => theme.gray8};
        }
      }
    `};
`;

const CircleIconContainer = styled(H1_FlexRow)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-flex;
  color: white;
`;
const TwitterCircle = styled(CircleIconContainer)`
  background-color: rgb(29, 155, 240);
`;

const FacebookCircle = styled(CircleIconContainer)`
  background-color: #365896;
`;
const WhatsappCircle = styled(CircleIconContainer)`
  background-color: #25d366;
`;
const LinkedInCircle = styled(CircleIconContainer)`
  background-color: #0a66c2;
`;

const BrandIcon = styled.i`
  color: white;
  font-size: 14px;
`;

const shareVideoMessages = defineMessages({
  embedLink: {
    id: `editor.video-ready-modal.embed-link`,
    defaultMessage: "Embed"
  },
  copyToEmail: {
    id: `editor.video-ready-modal.copy-to-email`,
    defaultMessage: "Embed in email"
  },
  shareToTwitter: {
    id: `editor.video-ready-modal.share-to-twitter`,
    defaultMessage: "Share to Twitter"
  },
  shareToFacebook: {
    id: `editor.video-ready-modal.share-to-facebook`,
    defaultMessage: "Share to Facebook"
  },
  shareToWhatsapp: {
    id: `editor.video-ready-modal.share-to-whatsapp`,
    defaultMessage: "Share to WhatsApp"
  },
  shareToLinkedIn: {
    id: `editor.video-ready-modal.share-to-linked-in`,
    defaultMessage: "Share to LinkedIn"
  }
});

interface PlaygroundShareVideoProps {
  disabled?: boolean;
  loading?: boolean;
  video: Video;
  source: string;
  className?: string;
  withText?: boolean;
  withLinkedIn?: boolean;
  withWhatsApp?: boolean;
  withFacebook?: boolean;
  withTwitter?: boolean;
}
const PlaygroundShareVideoMenu = ({
  disabled = false,
  loading = false,
  withText = true,
  video,
  source,
  className,
  withLinkedIn = true,
  withWhatsApp = true,
  withFacebook = true,
  withTwitter = true
}: PlaygroundShareVideoProps) => {
  const { formatMessage } = useIntl();
  const { shareLink } = usePlaygroundShareSocialMedia();
  const { isPublishAllow } = usePermissions();

  const openEmbed = isPublishAllow || video?.paid;

  const onClickTwitter = () => {
    if (video) {
      shareLink(
        `${source}_twitter`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.twitter
      );
    }
  };

  const onClickFacebook = () => {
    if (video) {
      shareLink(
        `${source}_facebook`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.facebook
      );
    }
  };

  const onClickLinkedIn = () => {
    if (video) {
      shareLink(
        `${source}_linkedin`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.linkedin
      );
    }
  };

  const onClickWhatsapp = () => {
    if (video) {
      shareLink(
        `${source}_whatsapp`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.whatsapp
      );
    }
  };

  const items: MenuProps["items"] = useMemo(() => {
    const linkedItem = {
      className: "share-menu clickable",
      key: "shareOnLinkedIn",
      icon: (
        <LinkedInCircle>
          <BrandIcon className="fa-brands fa-linkedin-in" />
        </LinkedInCircle>
      ),
      onClick: onClickLinkedIn,
      label: formatMessage(shareVideoMessages.shareToLinkedIn)
    };

    const whatsappItem = {
      className: "share-menu clickable",
      key: "shareOnWhatsapp",
      icon: (
        <WhatsappCircle>
          <BrandIcon className="fa-brands fa-whatsapp" />
        </WhatsappCircle>
      ),
      onClick: onClickWhatsapp,
      label: formatMessage(shareVideoMessages.shareToWhatsapp)
    };

    const facebookItem = {
      className: "share-menu clickable",
      key: "shareOnFacebook",
      icon: (
        <FacebookCircle>
          <BrandIcon className="fa-brands fa-facebook-f" />
        </FacebookCircle>
      ),
      onClick: onClickFacebook,
      label: formatMessage(shareVideoMessages.shareToFacebook)
    };

    const twitterItem = {
      className: "share-menu clickable",
      key: "shareOnTwitter",
      icon: (
        <TwitterCircle>
          <BrandIcon className="fa-brands fa-twitter" />
        </TwitterCircle>
      ),
      onClick: onClickTwitter,
      label: formatMessage(shareVideoMessages.shareToTwitter)
    };

    const initialItems: MenuProps["items"] = [];
    if (withLinkedIn) {
      initialItems.push(linkedItem);
    }
    if (withTwitter) {
      initialItems.push(twitterItem);
    }
    if (withFacebook) {
      initialItems.push(facebookItem);
    }
    if (withWhatsApp) {
      initialItems.push(whatsappItem);
    }

    return initialItems;
  }, [openEmbed, video]);

  return (
    <StyledDropdown
      withChevron={false}
      $disabled={disabled}
      icon={
        loading ? (
          <i className="fa-solid fa-spinner-third fa-spin" />
        ) : (
          <i className="fa-solid fa-share" />
        )
      }
      title={withText ? formatMessage(videoReadyModalMessages.shareButton) : ""}
      destroyPopupOnHide
      menu={{ items }}
      trigger={["click", "hover"]}
      className={className + " overlay-dropdown"}
      getPopupContainer={() => document.querySelector(`.overlay-dropdown`) as HTMLElement}
    ></StyledDropdown>
  );
};

export default PlaygroundShareVideoMenu;
