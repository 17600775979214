/* eslint @typescript-eslint/naming-convention: 0 */
import React, { useState, useEffect, useRef, ForwardedRef } from "react";
import styled from "styled-components";
import { H1_InputSize, H1_SearchType } from "./types";
import { BaseThemeSchema } from "app/utils/theme";

const handleFontSize = (size: H1_InputSize) => {
  switch (size) {
    case "small":
      return "0.875rem";
    case "large":
      return "1rem";
    default:
      return "0.875rem";
  }
};

const handleLineHeightSize = (size: H1_InputSize) => {
  switch (size) {
    case "small":
      return "1.375rem";
    case "large":
      return "1.5rem";
    default:
      return "1.375rem";
  }
};

const handleMaxWidth = (props: {
  $size: H1_InputSize;
  allowClear: boolean;
  minWidth?: string;
  maxWidth?: string;
  searchWidth: number;
  theme: BaseThemeSchema;
}) => {
  const borderWidth = 0.125;
  let paddingWidth;
  switch (props.$size) {
    case "small":
      paddingWidth = props.allowClear ? 2 : 1;
      break;
    case "large":
      paddingWidth = props.allowClear ? 2.5 : 1.5;
      break;
    default:
      paddingWidth = props.allowClear ? 2.5 : 1.5;
      break;
  }
  const maxWidth = props.maxWidth ? parseInt(props.maxWidth, 10) : 0;
  return maxWidth === 0
    ? "none"
    : `${maxWidth - paddingWidth - borderWidth - props.searchWidth}rem`;
};

const handlePadding = (size: H1_InputSize, allowClear: boolean) => {
  switch (size) {
    case "small":
      return allowClear ? "0 1.5rem 0 0.5rem" : "0 0.5rem";
    case "large":
      return allowClear ? "0.4375rem 1.75rem 0.4375rem 0.625rem" : "0.4375rem 0.75rem";
    default:
      return allowClear ? "0.25rem 1.75rem 0.25rem 0.75rem" : "0.25rem 0.75rem";
  }
};

const handleSearchSize = (size: H1_InputSize) => {
  switch (size) {
    case "small":
      return "1.375rem";
    case "large":
      return "2.375rem";
    default:
      return "2rem";
  }
};

const handleSize = (size: H1_InputSize) => {
  switch (size) {
    case "small":
      return "1.5rem";
    case "large":
      return "2.5rem";
    default:
      return "2rem";
  }
};

const StyledInput = styled.input<{
  $size: H1_InputSize;
  allowClear: boolean;
  minWidth?: string;
  searchWidth: number;
  maxWidth?: string;
  theme: BaseThemeSchema;
}>`
  position: relative;
  z-index: 1;
  border: ${(props) => `solid 1px ${props.theme.gray5}`};
  padding: ${(props) => handlePadding(props.$size, props.allowClear)};
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-size: ${(props) => handleFontSize(props.$size)};
  line-height: ${(props) => handleLineHeightSize(props.$size)};
  min-width: calc(${(props) => props.minWidth} - 2px); /* Border - 2px */
  max-width: ${(props) => handleMaxWidth(props)};
  &:focus {
    border: ${(props) => `solid 1px ${props.theme.blue4}`};
    outline: none;
  }
  &:hover {
    border: ${(props) => `solid 1px ${props.theme.blue2}`};
    outline: none;
  }
  &:disabled {
    border: ${(props) => `solid 1px ${props.theme.gray5}`};
    color: ${(props) => props.theme.gray5};
    background-color: ${(props) => props.theme.gray3};
  }
`;

const HiddenSpan = styled.span<{ size: H1_InputSize }>`
  appearance: none;
  z-index: -1;
  opacity: 0 !important;
  height: 0 !important;
  position: absolute;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-size: ${(props) => handleFontSize(props.size)};
  width: max-content;
`;

const ExternalContainer = styled.div<{ size: H1_InputSize }>`
  position: relative;
  display: inline-flex;
  height: ${(props) => handleSize(props.size)};
  background-color: ${(props) => props.theme.gray1};
`;

const InputContainer = styled.div<{ size: H1_InputSize; minWidth?: string }>`
  position: relative;
  display: inline-flex;
  height: ${(props) => handleSize(props.size)};
  width: 100%;
  min-width: ${(props) => props.minWidth};
  flex: 1 1 100%;
  align-items: center;
`;

const StyledDiv = styled.div<{ size: H1_InputSize; searchText?: string; searchType?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => `0 0 ${handleSearchSize(props.size)}`};
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  height: ${(props) => handleSize(props.size)};
  width: calc(${(props) => (props.searchText ? "100%" : handleSize(props.size))} - 2px);
  padding: ${(props) => props.searchText && "0 0.3125rem"};
  border: ${(props) => `solid ${props.theme.gray5}`};
  border-width: 1px 1px 1px 0;
  background-color: ${(props) =>
    props.searchType === "warning" ? props.theme.orange4 : props.theme.gray1};
  color: ${(props) => (props.searchType === "warning" ? props.theme.gray1 : props.theme.gray11)};
  cursor: pointer;
  svg {
    fill: ${(props) => (props.searchType === "warning" ? props.theme.gray1 : props.theme.gray11)};
  }
`;

const ClearIconFontAwesomeIcon = styled.i`
  cursor: pointer;
  position: absolute;
  right: 0.625rem;
  z-index: 1;
`;

export type H1_InputProps = {
  className?: string;
  size?: string;
  isFixedWidth?: boolean;
  minWidth?: string;
  maxWidth?: string;
  placeholder?: string;
  initialValue?: string;
  search?: boolean;
  autoFocus?: boolean;
  allowClear?: boolean;
  searchType?: H1_SearchType;
  searchText?: string;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onSearch?: (value: string) => void;
  onClear?: () => void;
};

export const H1_Input = React.forwardRef<HTMLInputElement, H1_InputProps>(
  (
    {
      className,
      size = "default",
      isFixedWidth,
      minWidth,
      maxWidth,
      placeholder,
      disabled,
      initialValue,
      search = false,
      autoFocus = false,
      allowClear = false,
      searchType,
      searchText,
      onFocus,
      onChange,
      onBlur,
      onSearch,
      onClear,
      ...rest
    }: H1_InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [currentWidth, setCurrentWidth] = useState<string | undefined>(minWidth);
    const [value, setValue] = useState<string | undefined>(initialValue);
    const spanRef = useRef<HTMLSpanElement>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    const searchWidth = searchRef.current?.offsetWidth;
    const actualMaxWidth = isFixedWidth ? minWidth : maxWidth;

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      const spanRect = spanRef.current?.getBoundingClientRect();
      if (spanRect && actualMaxWidth) {
        const addedOffset = 12 + 12 + 1; // padding-left + right + border
        const spanWidth =
          maxWidth !== "none"
            ? Math.min(spanRect.width + addedOffset, parseInt(actualMaxWidth, 10))
            : spanRect.width + addedOffset;
        setCurrentWidth(`${spanWidth}px`);
      }
    }, [value]);

    const onClearValue = () => {
      setValue("");
      if (onClear) {
        onClear();
      }
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
      setValue(e.target.value);
    };

    const onBlurChange = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
    };

    const onKeyDownChange = (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && onSearch) {
        // @ts-ignore - on search
        onSearch(e.target.value);
      }
    };

    const onFocusChange = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
    };

    const onClickSearch = () => {
      if (onSearch) {
        onSearch(value || "");
      }
    };

    return (
      <ExternalContainer className={className} size={size as H1_InputSize}>
        <InputContainer size={size as H1_InputSize}>
          <HiddenSpan ref={spanRef} size={size as H1_InputSize}>
            {value}
          </HiddenSpan>
          <StyledInput
            ref={ref}
            searchWidth={searchWidth || 0}
            value={value}
            style={{ width: currentWidth }}
            minWidth={minWidth}
            maxWidth={actualMaxWidth}
            onFocus={onFocusChange}
            onChange={onInputChange}
            onBlur={onBlurChange}
            onKeyDown={onKeyDownChange}
            $size={size as H1_InputSize}
            allowClear={allowClear}
            placeholder={placeholder}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            disabled={disabled}
            {...rest}
          />
          {!!allowClear && value && (
            <ClearIconFontAwesomeIcon className="fas fa-circle-xmark" onClick={onClearValue} />
          )}
        </InputContainer>
        {!!search && (
          <StyledDiv
            ref={searchRef}
            onClick={onClickSearch}
            searchText={searchText}
            size={size as H1_InputSize}
            searchType={searchType}
          >
            {!!searchText && <span>{searchText}</span>}
            {!searchText && (
              <ClearIconFontAwesomeIcon
                className="fas fa-magnifying-glass"
                onClick={onClearValue}
              />
            )}
          </StyledDiv>
        )}
      </ExternalContainer>
    );
  }
);
H1_Input.displayName = "H1_Input";
H1_Input.defaultProps = {
  size: "default",
  isFixedWidth: false,
  minWidth: "250px",
  maxWidth: "500px",
  initialValue: "",
  placeholder: undefined,
  searchText: undefined,
  search: false
};
