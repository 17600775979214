import { createSelector } from "reselect";
import { RootState } from "app/store/store";

const currentPalettes = (state: RootState) => state.templates.currentPalettes;

const currentTemplate = (state: RootState) => state.templates.currentTemplate;
const currentDraft = (state: RootState) => state.drafts.currentDraft;

const isCustomPaletteExist = createSelector([currentPalettes], (palettes) => {
  return !!palettes.find((palette) => palette.custom);
});

const getSystemPalettes = createSelector([currentPalettes], (palettes) => {
  return palettes.filter((palette) => {
    return !palette.custom;
  });
});

const getCustomPalette = createSelector([currentPalettes], (palettes) => {
  return palettes.find((palette) => {
    return palette.custom;
  });
});

const getPaletteById = createSelector(
  [currentPalettes, (state, paletteId?: string) => paletteId],
  (palettes, paletteId) => {
    return palettes.find((palette) => {
      return palette.id === paletteId;
    });
  }
);
const getAppliedPalette = createSelector([currentPalettes, currentDraft], (palettes, draft) => {
  if (draft.palette) {
    return draft.palette;
  }
  return palettes.find((palette) => {
    return palette.id === draft.palette_id;
  })?.colors;
});

const getAspectRatio = createSelector([currentTemplate], (template) => {
  const renderConfig = template?.render_config;
  // default as 0 so the aspect ratio will have opacity 0 and then fade in when the data arrives
  // (# TODO: make it work with other false / css value)
  return renderConfig ? renderConfig.width / (renderConfig.height || 1) : 0;
});

export default {
  isCustomPaletteExist,
  getSystemPalettes,
  getCustomPalette,
  getPaletteById,
  getAspectRatio,
  getAppliedPalette
};
