import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import PlaygroundSingleVideo from "app/pages/PlayGround/PlaygroundSingleVideo";
import { getAllVideosSortedByDate } from "app/store/selectorsV2/playground.selectors";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { fetchingStatus } from "app/utils/helpers";
import SwiperCore from "swiper";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";

const SwiperArrow = styled.div<{ $disabled: boolean }>`
  cursor: pointer;
  z-index: 10;
  left: calc(100% - 112px);
  top: calc(100% - 60px);
  position: absolute;
  &:hover {
    border-color: #d9d9d9;
  }
  &:active {
    transform: scale(0.95);
  }
  border: 1px solid #8c8c8c;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: #8c8c8c;
  background-color: #343445;
  border-radius: 50%;
  text-align: center;
  transition-property: border-color, color;
  transition-duration: 0.2s;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 16px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12);
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  opacity: ${({ $disabled }) => ($disabled ? 0.35 : 1)};
`;
const StyledNavigationSwiper = styled(NavigationSwiper)`
  height: 100%;
  padding-bottom: 20px;
  .swiper-button-prev,
  .swiper-button-next {
    border: 1px solid #8c8c8c;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: #8c8c8c;
    background-color: #343445;
    svg {
      fill: #8c8c8c;
    }
  }
  .swiper-button-prev {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-left-gray.svg");
  }

  .swiper-button-next {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-right-gray.svg");
  }
  > .swiper-wrapper {
    height: 100%;
    aspect-ratio: 16 / 9;
    .swiper-slide > div {
      width: auto;
      height: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  > .swiper-button-prev {
    left: calc(100% - 100px);
    top: 20px;
    transform: rotate(90deg);
    transform-origin: left center;
    &:active {
      transform: rotate(90deg) scale(0.95);
    }
  }
  > .swiper-button-next {
    left: calc(100% - 100px);
    top: calc(100% - 120px);
    transform: rotate(90deg);
    transform-origin: left center;
    &:active {
      transform: rotate(90deg) scale(0.95);
    }
  }
  .swiper-button-disabled {
    opacity: 0.35;
  }
`;

const PlaygroundVideosList = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [currentVideoId, setCurrentVideoId] = useState<string>();
  const dispatch = useAppDispatch();
  const videos = useAppSelector(getAllVideosSortedByDate);
  const generateStatus = useAppSelector((state) => state.playground.generateStatus);

  useEffect(() => {
    if (generateStatus === fetchingStatus.succeeded && swiperInstance) {
      swiperInstance.slideTo(swiperInstance.slides.length - 1);
    }
  }, [generateStatus, swiperInstance]);
  useEffect(() => {
    if (!currentVideoId && videos?.length > 0) {
      setCurrentVideoId(videos[0].id);
    }
  }, [videos]);

  const onVideoChange = (swiper: any) => {
    const currentIndex = swiper.activeIndex;
    dispatch(googleEvents.playgroundVideoScroll({ videoId: videos[currentIndex].id }));
    setCurrentVideoId(videos[currentIndex].id);
  };

  const onClickMoveToLast = () => {
    swiperInstance?.slideTo(swiperInstance.slides.length - 1);
  };

  return (
    <H1_FlexColumn padding="8px 0 0 0" gap="10px">
      <AnimatePresence mode="wait">
        <StyledNavigationSwiper
          onSlideChange={onVideoChange}
          onSwiper={setSwiperInstance}
          direction="vertical"
          centeredSlides
          allowTouchMove={false}
          navigation
          slidesPerView={1}
          spaceBetween={100}
          speed={700}
          mousewheel
          initialSlide={videos.length - 1}
        >
          <ConditionalRender condition={videos.length > 2}>
            <SwiperArrow
              onClick={onClickMoveToLast}
              $disabled={
                swiperInstance?.activeIndex === (swiperInstance?.slides.length as number) - 1
              }
            >
              <i className="fas fa-chevrons-down" />
            </SwiperArrow>
          </ConditionalRender>
          {videos.map((video) => (
            <SwiperSlide key={video.id}>
              <motion.div
                layout
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{
                  duration: 1
                }}
              >
                <PlaygroundSingleVideo videoId={video.id} key={video.id} />
              </motion.div>
            </SwiperSlide>
          ))}
        </StyledNavigationSwiper>
      </AnimatePresence>
    </H1_FlexColumn>
  );
};

export default PlaygroundVideosList;
