import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  playgroundDownloadVideo,
  playgroundGenerate,
  playgroundGetVideos,
  previewAudio,
  sharePlaygroundVideo
} from "app/services/serviceV2Apis";
import { ThunkApi } from "app/types";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import {
  PlayGroundDownloadResponse,
  PlayGroundRequest,
  PlayGroundResponse
} from "app/types/playground";
import { paymentsActions } from "app/store/slices/payments.slice";

const prefix = "[Playground]";

const generateRequest = createAsyncThunk<PlayGroundResponse, PlayGroundRequest, ThunkApi>(
  `${prefix} generateRequest`,
  async (body, thunkAPI) => {
    const result = await playgroundGenerate(body);
    thunkAPI.dispatch(paymentsActions.getCreditsRequest());
    return result;
  },
  thunkOptions
);

const playgroundShareVideoRequest = createAsyncThunk<void, { videoId: string }, ThunkApi>(
  `${prefix} playgroundShareVideoRequest`,
  async ({ videoId }) => {
    await sharePlaygroundVideo(videoId);
  },
  thunkOptions
);
const playgroundGetVideosRequest = createAsyncThunk<PlayGroundResponse[], void, ThunkApi>(
  `${prefix} playgroundGetVideosRequest`,
  async () => {
    const result = await playgroundGetVideos();
    return result;
  },
  thunkOptions
);
const playgroundDownloadVideoRequest = createAsyncThunk<
  PlayGroundDownloadResponse,
  { videoId: string },
  ThunkApi
>(
  `${prefix} playgroundDownloadVideoRequest`,
  async ({ videoId }) => {
    const result = await new Promise<PlayGroundDownloadResponse>((resolve, reject) => {
      let retries = 0;

      const execute = async () => {
        try {
          const result = await playgroundDownloadVideo(videoId);
          if (retries === 4) {
            return reject(new Error("link generating failed, try again"));
          }
          if (result.status === "ready") {
            resolve(result);
          } else {
            retries++;
            setTimeout(execute, 5000);
          }
        } catch (err) {
          reject(err);
        }
      };
      execute();
    });

    return result;
  },
  thunkOptions
);

const previewTranscriptRequest = createAsyncThunk<
  any,
  { orderId: string; voiceId: string; sml: string },
  ThunkApi
>(
  `${prefix} previewTranscriptRequest`,
  async ({ orderId, voiceId, sml }) => {
    const synthesis = [
      {
        type: "text",
        text: sml,
        version: 1
      }
    ];
    const result = await previewAudio(
      orderId,
      "text",
      voiceId,
      undefined,
      undefined,
      undefined,
      synthesis
    );

    return result;
  },
  thunkOptions
);

export default {
  generateRequest,
  previewTranscriptRequest,
  playgroundGetVideosRequest,
  playgroundShareVideoRequest,
  playgroundDownloadVideoRequest
};
