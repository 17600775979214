import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import useModal, { ModalName } from "app/hooks/useModal";
import { voicesActions } from "app/store/slices/voices.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import ScenesPlayer from "app/pages/editor/ScenesPlayerModal/ScenesPlayer";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { fetchingStatus } from "app/utils/helpers";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import { defineMessages, useIntl } from "react-intl";
import { H1_TextMidHeadline, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { v4 as uuidv4 } from "uuid";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import useTimeoutOnLoading from "app/hooks/useTimeoutOnLoading";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CreateVideoButton from "app/components/editor/CreateVideoButton";
import usePermissions from "app/hooks/usePermissions";
import { onQuickVideoPreviewModalOpen } from "app/store/thunks/analyticsEvents.thunk";
import { scenesActions } from "app/store/slices/scenes.slice";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { FramePreviewQuality } from "app/types";
import { StyledModal } from "app/components/common/StyledModal";

const StyledFlexColumn = styled(H1_FlexColumn)<{ $height: number }>`
  min-height: ${({ $height }) => $height}px;
`;

const Modal = styled(StyledModal)`
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 4px; /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light white border */
  padding: 20px; /* Adjust as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  /* Additional styling as needed */
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 30px 36px;
    flex-shrink: 0;
  }
  .ant-modal-content {
    border-radius: 4px;
  }
  && {
    padding-bottom: 20px;
  }
`;
const TIMEOUT = 60;
const WIDTH = 770;
const HEIGHT = 620;
const messages = defineMessages({
  title: {
    id: "scenes-player.title",
    defaultMessage: "Quick Preview"
  },
  descriptionPartOne: {
    id: "scenes-player.descriptionPart1",
    defaultMessage: "Preview your video before you hit ‘Create Video’"
  },
  descriptionPartTwo: {
    id: "scenes-player.descriptionPart2",
    defaultMessage: "Using sample frames and audio clips."
  },
  timeoutError: {
    id: "scenes-player.timeout-error",
    defaultMessage: "Audio creating taking too long, please try again."
  },
  createButton: {
    id: "scenes-player.create-video",
    defaultMessage: "Create Video"
  }
});
const ScenesPlayerModal = () => {
  const theme = useTheme();
  const [voiceLoading, setVoiceLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { closeModal, editorModalOpen } = useModal();
  const { notifyError } = useErrors();
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const onTimeout = () => {
    notifyError({
      general: buildGeneralMessage(`Something went wrong`, formatMessage(messages.timeoutError))
    });
    closeModal();
  };

  useTimeoutOnLoading({ loading: voiceLoading, timeoutSec: TIMEOUT, onTimeout });
  const currentTemplate = useAppSelector((state) => state.templates.currentTemplate);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const voicePreviewStatus = useAppSelector((state) => state.voices.voicePreviewStatus);
  const framePreviewLoading = useAppSelector((state) => state.scenes.framePreviewLoading);
  const url = useAppSelector((state) => state.voices.voicePreviewUrl);
  const { isWriteWorkspaceAllow } = usePermissions();
  const visible = editorModalOpen?.open === ModalName.scenesPlayer;

  const width = currentTemplate?.render_config?.width;
  const height = currentTemplate?.render_config?.height;

  const aspectRatio = height && width ? width / height : 1;
  const textHeight = 82;
  const videoSize = useMemo(() => {
    const height = aspectRatio > 1 ? 373 : 400;

    return {
      width: Math.round(height * aspectRatio),
      height: height
    };
  }, [aspectRatio, textHeight]);
  const isAnyFramePreviewLoading = Object.values(framePreviewLoading).some(
    ({ loading }) => loading
  );
  const loading = voiceLoading || isAnyFramePreviewLoading;
  useEffect(() => {
    if (visible) {
      dispatch(onQuickVideoPreviewModalOpen());
      const sceneIds = scenes.map((scene) => scene.id);
      dispatch(
        scenesActions.scenePreviewRequest({
          draftId: currentDraft.id as string,
          sceneIds,
          quality: FramePreviewQuality.high
        })
      );
      dispatch(
        voicesActions.createFullPreviewV2Request({
          orderId: uuidv4(),
          draftId: currentDraft.id as string,
          voiceId: currentDraft?.attributes?.voice?.voice?.voice_id as string
        })
      );
      setVoiceLoading(true);
    } else {
      setVoiceLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    if (voicePreviewStatus === fetchingStatus.succeeded) {
      setVoiceLoading(false);
    } else if (voicePreviewStatus === fetchingStatus.failed) {
      setVoiceLoading(false);
      closeModal();
    }
  }, [voicePreviewStatus]);

  return (
    <Modal
      width={`${WIDTH}px`}
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: `${HEIGHT}px` }}
      onCancel={closeModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn padding={"0px 10px 10px 0px"} flex={"0 0 82px"} align={"center"} ref={ref}>
        <H1_TextMidHeadline>{formatMessage(messages.title)}</H1_TextMidHeadline>
        <H1_TextXs color={theme.gray7}>{formatMessage(messages.descriptionPartOne)}</H1_TextXs>
        <H1_TextXs color={theme.gray7}>{formatMessage(messages.descriptionPartTwo)}</H1_TextXs>
      </H1_FlexColumn>
      <H1_FlexColumn align={"center"} justify={"center"} flex={"1"}>
        <ConditionalRender condition={loading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!!url && !loading}>
          <StyledFlexColumn padding={"10px 0px 0px 0px"} $height={videoSize.height}>
            <ScenesPlayer
              url={url as string}
              width={videoSize.width}
              height={videoSize.height}
              aspectRatio={aspectRatio}
            />
          </StyledFlexColumn>
        </ConditionalRender>
        <H1_FlexRow width="100%" justify="flex-end" align={"end"} flex={"1"}>
          <ConditionalRender condition={isWriteWorkspaceAllow && !loading}>
            <CreateVideoButton source="quickPreview" />
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexColumn>
    </Modal>
  );
};

export default ScenesPlayerModal;
