import { useMemo } from "react";

import { useIntl } from "react-intl";

import "app/pages/HomePage/HomePage.scss";
import { useAuth } from "app/auth/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import useModal, { ModalName } from "app/hooks/useModal";
import { accountMessages } from "app/pages/Header/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { capitalize } from "lodash-es";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Location, PlanEnum } from "app/types";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import useSmallScreen from "app/hooks/useSmallScreen";
import usePermissions from "app/hooks/usePermissions";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { onOpenKnowledgeBase, onOpenZendesk } from "app/utils/zendesk/zendesk";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import useIsFirstVideoCreated from "app/hooks/useIsFirstVideoCreated";
import { Button } from "@nextui-org/react";
import AvatarInitials from "app/components/common/AvatarInitials";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import ConditionalRender from "app/components/common/ConditionalRender";

const Icon = styled.i`
  color: ${(props: { $color: string }) => props.$color};
`;
const IconContainer = styled(H1_FlexRow)`
  background-color: ${(props: { $backgroundColor: string }) => props.$backgroundColor};
  border-radius: 50%;
`;

const MarginLeftFlexColumn = styled(H1_FlexColumn)`
  margin-left: 17px;
`;

const StyledNextDropdown = styled(NextDropdown)`
  li[data-hover="true"] {
    span {
      color: white; /* Its inside a blue4 background */
    }
  }
`;

enum MenuKeys {
  profile = "profile",
  profileLink = "profile-link-page",
  profileSettings = "profile-settings",
  workspaceSettings = "workspace-settings",
  getHelp = "get-help",
  knowledgeBase = "knowledge-base",
  profileLogout = "profile-logout",
  inbox = "inbox",
  inboxMessages = "inbox-messages",
  newVideo = "new-video"
}

const AccountAndNewVideoHeader = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { user, logout, isAuthenticated } = useAuth();
  const { currentPlan } = useCurrentPlan();
  const { isSmallScreen } = useSmallScreen();
  const isFirstVideoCreated = useIsFirstVideoCreated();

  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const logoutRedirect = useAppSelector((state) => state.user.logoutRedirect);

  const isRouteTemplates = location.pathname.includes(Location.Templates);
  const isRoutePlayground = location.pathname.includes(Location.Playground);

  const { isWriteWorkspaceAllow } = usePermissions();
  const userName =
    user?.given_name && user?.family_name
      ? `${capitalize(user?.given_name)} ${capitalize(user?.family_name)}`
      : capitalize(user?.nickname);

  const onLogout = () => {
    logout(logoutRedirect);
  };
  const onCreateVideo = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "header",
        type: NewVideoType.video
      })
    );
    navigate(Location.Templates);
  };

  const onClickFromVideoWizard = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "header",
        type: NewVideoType.videoWizard
      })
    );
    openModal(ModalName.videoWizard, { source: "topbar" });
  };

  const onImportPresentation = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "header",
        type: NewVideoType.presentation
      })
    );
    openModal(ModalName.presentationUploader);
  };
  const onStartFromDocument = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "header",
        type: NewVideoType.importDocument
      })
    );
    openModal(ModalName.startFromDocumentModal);
  };
  const onStartFromScript = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "header",
        type: NewVideoType.script
      })
    );
    openModal(ModalName.startFromScriptModal);
  };

  const onRouteToProfile = () => {
    dispatch(
      analyticsEvents.navigate({
        source: "header",
        value: "profile"
      })
    );
    const route = `/profile`;
    navigate(route);
  };

  const onClickWorkspaceSettings = () => {
    dispatch(
      analyticsEvents.navigate({
        source: "header",
        value: "workspace-settings"
      })
    );
    const route = `/workspaces/${currentWorkspace?.id}`;
    navigate(route);
  };

  const onClickGetHelp = () => {
    dispatch(
      analyticsEvents.navigate({
        source: "header",
        value: "get-help"
      })
    );
    onOpenZendesk();
  };

  const onClickHelpCenter = () => {
    onOpenKnowledgeBase();
  };

  const profileItems: any = useMemo(() => {
    if (!user || !isAuthenticated) {
      return [];
    }
    return [
      {
        onClick: { onRouteToProfile },
        className: "profile-menu",
        key: MenuKeys.profileLink,
        label: (
          <H1_FlexRow height="65px" align="center" onClick={onRouteToProfile}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <AvatarInitials src={user?.picture} text={user?.nickname || ""} />
            <MarginLeftFlexColumn>
              <H1_TextSmall fontWeight={600} color={theme.gray11}>
                {userName}
              </H1_TextSmall>
              <H1_TextXs color={theme.gray7}>{user?.email}</H1_TextXs>
            </MarginLeftFlexColumn>
          </H1_FlexRow>
        ),
        showDivider: true
      },
      {
        key: MenuKeys.profileSettings,
        label: <H1_TextSmall>{formatMessage(accountMessages.profileSettings)}</H1_TextSmall>,
        onClick: onRouteToProfile
      },
      !isSmallScreen && {
        key: MenuKeys.workspaceSettings,
        label: <H1_TextSmall>{formatMessage(accountMessages.workspaceSettings)}</H1_TextSmall>,
        onClick: onClickWorkspaceSettings
      },
      {
        key: MenuKeys.getHelp,
        label: <H1_TextSmall>{formatMessage(accountMessages.getHelp)}</H1_TextSmall>,
        onClick: onClickGetHelp
      },
      {
        key: MenuKeys.knowledgeBase,
        label: <H1_TextSmall>{formatMessage(accountMessages.helpCenter)}</H1_TextSmall>,
        onClick: onClickHelpCenter
      },
      {
        key: MenuKeys.profileLogout,
        label: <H1_TextSmall>{formatMessage(accountMessages.signout)}</H1_TextSmall>,
        onClick: onLogout
      }
    ];
  }, [user, isAuthenticated, theme.mode]);

  const newVideoItems = useMemo(() => {
    if (
      isRouteTemplates ||
      isRoutePlayground ||
      !isFirstVideoCreated ||
      !user ||
      !isAuthenticated
    ) {
      return [];
    }
    return [
      {
        className: "new-video-menu clickable",
        key: "From Template",
        label: <H1_TextSmall>{formatMessage(accountMessages.fromTemplate)}</H1_TextSmall>,
        startContent: (
          <IconContainer
            flex="0 0 33px"
            width="33px"
            height="33px"
            justify="center"
            align="center"
            $backgroundColor={theme.green1}
          >
            <Icon $color={theme.gray7} className="fa-regular fa-table-layout" />
          </IconContainer>
        ),
        onClick: onCreateVideo
      },
      {
        className: "new-video-menu clickable",
        key: "Video Wizard",
        label: <H1_TextSmall>{formatMessage(accountMessages.videoWizard)}</H1_TextSmall>,
        startContent: (
          <IconContainer
            flex="0 0 33px"
            width="33px"
            height="33px"
            justify="center"
            align="center"
            $backgroundColor={theme.blue1}
          >
            <Icon $color={theme.blue4} className="fa-regular fa-wand-magic-sparkles" />
          </IconContainer>
        ),
        onClick: onClickFromVideoWizard
      },
      {
        className: "new-video-menu clickable",
        key: "Import Presentation",
        label: <H1_TextSmall>{formatMessage(accountMessages.importPresentation)}</H1_TextSmall>,
        startContent: (
          <IconContainer
            width="33px"
            height="33px"
            justify="center"
            align="center"
            $backgroundColor={theme.orange1}
          >
            <Icon $color={theme.orange4} className="fa-regular fa-presentation-screen" />
          </IconContainer>
        ),
        onClick: onImportPresentation
      },
      {
        className: "new-video-menu clickable",
        key: "StartFromDocument",
        label: <H1_TextSmall>{formatMessage(accountMessages.startFromDocument)}</H1_TextSmall>,
        startContent: (
          <IconContainer
            width="33px"
            height="33px"
            justify="center"
            align="center"
            $backgroundColor={theme.pink1}
          >
            <Icon $color={theme.pink4} className="fa-light fa-file" />
          </IconContainer>
        ),
        onClick: onStartFromDocument
      },
      {
        className: "new-video-menu clickable",
        key: "StartFromScript",
        label: <H1_TextSmall>{formatMessage(accountMessages.startFromScript)}</H1_TextSmall>,
        startContent: (
          <IconContainer
            width="33px"
            height="33px"
            justify="center"
            align="center"
            $backgroundColor={theme.gray6}
          >
            <Icon $color={theme.gray1} className="fa-light fa-text" />
          </IconContainer>
        ),
        onClick: onStartFromScript
      }
    ];
  }, [user, isAuthenticated, isRouteTemplates, theme.mode]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <ConditionalRender condition={!isSmallScreen || currentPlan !== PlanEnum.onBoarding}>
        <H1_FlexRow className="mx-5">
          <StyledNextDropdown color="primary" items={newVideoItems}>
            <Button
              isDisabled={!isWriteWorkspaceAllow}
              data-auto-id="homepage-header-new-video-button"
              color="primary"
            >
              {formatMessage(accountMessages.newVideo)}
            </Button>
          </StyledNextDropdown>
        </H1_FlexRow>
      </ConditionalRender>
      <H1_FlexRow className="mr-5">
        <NextDropdown withWhiteBackground color="primary" items={profileItems}>
          <H1_FlexRow height="65px" align="center">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <AvatarInitials src={user?.picture} text={user?.nickname || ""} />
          </H1_FlexRow>
        </NextDropdown>
      </H1_FlexRow>
    </>
  );
};

export default AccountAndNewVideoHeader;
