import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "app/components/projects-managment/projectsManagment.scss";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { FeatureFlag, Folder, Location, MediaFileType, Video, VideoResolution } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";
import { voicesActions } from "app/store/slices/voices.slice";
import { charactersActions } from "app/store/slices/characters.slice";
import { foldersActions } from "app/store/slices/folders.slice";
import useDrawer from "app/hooks/useDrawer";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import HourOneBreadcrumb, { BreadcrumbElement } from "app/components/common/HourOneBreadcrumb";
import ConditionalRender from "app/components/common/ConditionalRender";
import { TablePaginationConfig, Tooltip } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { folderTableMessages } from "app/pages/FoldersPage/messages";
import DeleteFolderModal from "app/pages/FoldersPage/DeleteFolderModal";
import { draftPageActionMessages, folderContentTableMessages } from "app/pages/DraftsPage/messages";
import { useIntl } from "react-intl";
import PageTableSkeleton from "app/components/common/DraftsAndFolders/PageTableSkeleton";
import PageGridSkeleton from "app/components/common/DraftsAndFolders/PageGridSkeleton";
import DraftsGrid from "app/components/common/DraftsAndFolders/DraftsGrid";
import DraftsTable from "app/components/common/DraftsAndFolders/DraftsTable";
import { userActions } from "app/store/slices/user.slice";
import useDropdownHandler from "app/hooks/useDropdownHandler";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { getDraftIdsWithReadyVideos } from "app/store/selectorsV2/drafts.selectors";
import { Button } from "@nextui-org/react";
import { playlistsActions } from "app/store/slices/playlists.slice";
import { playListMessages } from "app/pages/PlaylistsPage/messages";
import { draftsGlobalSelectors, playlistsGlobalSelectors } from "app/store/adapters/adapters";
import { useFlags } from "launchdarkly-react-client-sdk";
import usePermissions from "app/hooks/usePermissions";
import useDownload from "app/hooks/useDownload";
import useConfirmModal from "app/hooks/useConfirmModal";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
`;

const StyledHourOneBreadcrumb = styled(HourOneBreadcrumb)`
  && {
    padding-left: 0;
    font-size: 1rem;
  }
  &&&.ant-breadcrumb {
    a,
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.gray7};
    }
    > :last-child a {
      color: ${(props) => props.theme.gray7};
    }
    .ant-breadcrumb-link {
      color: ${(props) => props.theme.gray9};
    }
    &&& .ant-breadcrumb-link:hover {
      a {
        color: ${(props) => props.theme.gray8};
      }
    }
  }
`;

const DraftsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { closeDrawer } = useDrawer();
  useDropdownHandler({
    goFoldersOnDelete: false,
    goDraftOnDuplicate: false,
    activeApiExampleHandler: true
  });
  const { isEnterprise } = usePermissions();
  const { formatMessage } = useIntl();
  const flags = useFlags();
  const { folderId } = useParams<{ folderId: string }>();
  const { downloadVideo } = useDownload({ resolution: VideoResolution["1080p"] });
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const preferences = useAppSelector((state) => state.user.preferences);
  const allDrafts = useAppSelector(draftsGlobalSelectors.selectAll);
  const selectedDraftIds = useAppSelector((state) => state.drafts.selectedDraftIds);
  const selectedDraftWithVideosIds = useAppSelector(getDraftIdsWithReadyVideos);
  const currentFolder: Folder = useAppSelector((state) => state.folders.currentFolder);
  const folderStatus = useAppSelector((state) => state.folders.folderContentStatus);
  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const deleteBulkDraftsStatus = useAppSelector((state) => state.drafts.deleteBulkDraftsStatus);
  const createPlaylistStatus = useAppSelector((state) => state.playlists.createPlaylistStatus);
  const playlists = useAppSelector(playlistsGlobalSelectors.selectAll);
  const folderName = currentFolder?.name || "";
  const isDeleteBulkDraftsLoading = deleteBulkDraftsStatus === fetchingStatus.loading;
  const isCreatePlaylistLoading = createPlaylistStatus === fetchingStatus.loading;
  const isLoading =
    draftsStatus === fetchingStatus.loading || folderStatus === fetchingStatus.loading;
  const isGrid = preferences.draft_page_grid;
  const isPlaylistAvailable = flags[FeatureFlag.playlists] && isEnterprise;
  const isMultipleVideos = selectedDraftWithVideosIds.length > 1;
  const isMultipleDrafts = selectedDraftIds.length > 1;

  useEffect(() => {
    dispatch(charactersActions.getCharactersListRequest());
    dispatch(voicesActions.getVoicesListRequest());
    return () => {
      dispatch(draftsActions.cleanSelectedDraftIds());
      dispatch(draftsActions.resetDraftStore());
      closeDrawer();
    };
  }, []);

  useEffect(() => {
    dispatch(
      foldersActions.getFolderContentRequest({ folderId: folderId as string, fileType: "draft" })
    );
  }, [folderId]);

  useEffect(() => {
    if (createPlaylistStatus === fetchingStatus.succeeded) {
      dispatch(playlistsActions.updateCreatePlaylistStatusToIdle());
      navigate(`${Location.Playlists}/${playlists[0].id}`);
    }
    if (createPlaylistStatus === fetchingStatus.failed) {
      dispatch(playlistsActions.updateCreatePlaylistStatusToIdle());
    }
  }, [createPlaylistStatus]);

  const onClickGrid = () => {
    dispatch(userActions.updateUserPreferencesRequest({ draft_page_grid: true }));
  };

  const onClickTable = () => {
    dispatch(userActions.updateUserPreferencesRequest({ draft_page_grid: false }));
  };

  const onClickDownloadBulkDrafts = () => {
    if (selectedDraftWithVideosIds.length <= 6) {
      for (let i = 0; i < selectedDraftWithVideosIds.length; i++) {
        const draftId = selectedDraftWithVideosIds[i];
        const theDraft = allDrafts.find((draft) => draftId === draft.id);
        if (theDraft && theDraft.last_video) {
          downloadVideo({
            source: "Download_bulk_drafts_page",
            video: theDraft.last_video as Video,
            fileType: MediaFileType.mp4,
            draftId
          });
        }
      }
    } else {
      openConfirmModal({
        type: "danger",
        title: formatMessage(draftPageActionMessages.downloadVideosWarningTitle),
        content: formatMessage(draftPageActionMessages.downloadVideosWarningContent),
        cancelText: formatMessage(draftPageActionMessages.cancel),
        confirmText: formatMessage(draftPageActionMessages.ok),
        onCancel: closeConfirmModal,
        withCancelButton: false
      });
    }
  };

  const onClickDeleteBulkDrafts = () => {
    if (selectedDraftIds.length > 0) {
      openConfirmModal({
        onCancel: closeConfirmModal,
        title: isMultipleDrafts
          ? formatMessage(draftPageActionMessages.deleteVideosConfirmTitle, {
              number: selectedDraftIds.length
            })
          : formatMessage(draftPageActionMessages.deleteVideoConfirmTitle),
        content: isMultipleDrafts
          ? formatMessage(draftPageActionMessages.deleteVideosConfirmContent, {
              number: selectedDraftIds.length
            })
          : formatMessage(draftPageActionMessages.deleteVideoConfirmContent),
        cancelText: formatMessage(draftPageActionMessages.cancel),
        confirmText: formatMessage(draftPageActionMessages.ok),
        onConfirm: () => {
          dispatch(
            analyticsEvents.deleteBulkDrafts({
              draftsIds: selectedDraftIds
            })
          );
          dispatch(
            draftsActions.deleteBulkDraftsRequest({
              draftsIds: selectedDraftIds
            })
          );
        }
      });
    }
  };

  const onClickCreatePlaylist = () => {
    const title = folderName || "New Playlist";
    if (selectedDraftWithVideosIds.length === selectedDraftIds.length) {
      dispatch(
        analyticsEvents.createNewPlaylist({
          source: "drafts_page",
          folderId: currentFolder?.id,
          title
        })
      );
      dispatch(
        playlistsActions.createPlaylistRequest({
          draftsIds: selectedDraftWithVideosIds,
          title
        })
      );
    } else {
      openConfirmModal({
        onCancel: closeConfirmModal,
        title: formatMessage(playListMessages.continueCreatePlaylistTitle),
        content: formatMessage(playListMessages.continueCreatePlaylistContent),
        cancelText: formatMessage(playListMessages.cancel),
        confirmText: formatMessage(playListMessages.ok),
        onConfirm: () => {
          dispatch(
            analyticsEvents.createNewPlaylist({
              source: "drafts_page",
              folderId: currentFolder?.id,
              title
            })
          );
          dispatch(
            playlistsActions.createPlaylistRequest({
              draftsIds: selectedDraftWithVideosIds,
              title
            })
          );
        }
      });
    }
  };

  const onCreateNewVideo = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "empty-folder",
        type: NewVideoType.video
      })
    );
    navigate(Location.Templates);
  };

  const breadcrumbs: BreadcrumbElement[] = useMemo(() => {
    const res: BreadcrumbElement[] = [
      {
        id: "folders",
        name: formatMessage(folderTableMessages.projects),
        url: "/folders"
      },
      {
        id: "folderName",
        name: folderName,
        isLoading: !folderName
      }
    ];
    return res;
  }, [folderName, currentFolder]);

  return (
    <BackgroundFlexColumn padding="1rem 0 1.875rem 1.875rem" flex="1" overflow="auto">
      <H1_FlexColumn gap="2.125rem" flex="1">
        <H1_FlexRow
          height="1.375rem"
          flex="0 0 2.375rem"
          align="center"
          justify="space-between"
          gap="0.3125rem"
          padding="0 1.875rem 0 0"
        >
          <StyledHourOneBreadcrumb breadcrumbs={breadcrumbs} separator="/" />
          <H1_FlexRow align="center">
            <ConditionalRender condition={selectedDraftIds.length > 0}>
              <H1_FlexRow flex="0 0 auto" justify="flex-end" gap="20px" padding="0 10px 0 0">
                <ConditionalRender condition={selectedDraftWithVideosIds.length > 0}>
                  <Button
                    onClick={onClickDownloadBulkDrafts}
                    startContent={<i className="fas fa-arrow-down-to-line" />}
                  >
                    {isMultipleVideos
                      ? formatMessage(draftPageActionMessages.downloadVideosBtn)
                      : formatMessage(draftPageActionMessages.downloadVideoBtn)}
                  </Button>
                </ConditionalRender>
                <Button
                  isLoading={isDeleteBulkDraftsLoading}
                  onClick={onClickDeleteBulkDrafts}
                  startContent={<i className="fas fa-trash" />}
                >
                  {isMultipleDrafts
                    ? formatMessage(draftPageActionMessages.deleteVideosBtn)
                    : formatMessage(draftPageActionMessages.deleteVideoBtn)}
                </Button>
                <ConditionalRender
                  condition={isPlaylistAvailable && selectedDraftWithVideosIds.length > 0}
                >
                  <Button
                    isLoading={isCreatePlaylistLoading}
                    onClick={onClickCreatePlaylist}
                    startContent={<i className="fas fa-list-music" />}
                  >
                    {formatMessage(folderContentTableMessages.createPlaylist)}
                  </Button>
                </ConditionalRender>
              </H1_FlexRow>
            </ConditionalRender>
            <Tooltip title={formatMessage(folderContentTableMessages.table)}>
              <>
                <Button
                  variant="light"
                  color="primary"
                  size="sm"
                  onClick={onClickTable}
                  startContent={<i className="fa-solid fa-list" />}
                  isIconOnly
                />
              </>
            </Tooltip>
            <Tooltip title={formatMessage(folderContentTableMessages.grid)}>
              <>
                <Button
                  variant="light"
                  color="primary"
                  size="sm"
                  onClick={onClickGrid}
                  startContent={<i className="fa-solid fa-grid" />}
                  isIconOnly
                />
              </>
            </Tooltip>
          </H1_FlexRow>
        </H1_FlexRow>
        <ConditionalRender condition={isLoading && isGrid}>
          <PageGridSkeleton />
        </ConditionalRender>
        <ConditionalRender condition={isLoading && !isGrid}>
          <PageTableSkeleton />
        </ConditionalRender>
        <ConditionalRender condition={!isLoading}>
          <H1_FlexColumn gap="0.5rem" padding="0 1.875rem 0 0" flex="1">
            <H1_FlexColumn overflow="auto" flex="1 1 auto" padding="0 1.875rem 0 0">
              <ConditionalRender condition={!isGrid}>
                <H1_FlexColumn gap="0.5rem" flex="1">
                  <DraftsTable
                    withMultipleSelectCheckbox
                    onCreateNewVideo={onCreateNewVideo}
                    pagination={
                      { position: ["bottomCenter"], size: "default" } as TablePaginationConfig
                    }
                  />
                </H1_FlexColumn>
              </ConditionalRender>
              <ConditionalRender condition={isGrid}>
                <DraftsGrid onCreateNewVideo={onCreateNewVideo} source="draftsPage" />
              </ConditionalRender>
            </H1_FlexColumn>
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
      <DeleteFolderModal />
    </BackgroundFlexColumn>
  );
};
export default DraftsPage;
