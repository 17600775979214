/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import uiV2Selectors from "app/store/selectorsV2/uiV2.selectors";

export interface TrayItem {
  showLoader?: boolean;
  showButton?: boolean;
  headline?: string;
  onExpand?: () => void;
}

export interface ConfirmModalState {
  visible: boolean;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  withCancelButton?: boolean;
  type?: "default" | "primary" | "secondary" | "success" | "warning" | "danger";
}

const initialState: {
  isNoticeableLoaded: boolean;
  isOnline: boolean;
  developerMode: boolean;
  tray: Record<string, TrayItem>;
  showEditorDrawer: boolean;
  isWorkflowMode: boolean;
  confirmModal: ConfirmModalState;
} = {
  isNoticeableLoaded: false,
  isOnline: true,
  developerMode: false,
  tray: {},
  showEditorDrawer: true,
  isWorkflowMode: false,
  confirmModal: { visible: false }
};

export const uiSlice = createSlice({
  name: "ui-v2",
  initialState,
  reducers: {
    addToTray(state, action) {
      state.tray = {
        ...state.tray,
        ...action.payload
      };
      return state;
    },
    removeFromTray(state, action) {
      if (action.payload in state.tray) {
        delete state.tray[action.payload];
      }

      state.tray = Object.assign(state.tray);
      return state;
    },
    setIsWorkflowMode(state, action) {
      state.isWorkflowMode = action.payload;
    },
    setShowEditorDrawer(state, action) {
      state.showEditorDrawer = action.payload;
    },
    setIsNoticeableLoaded(state, action) {
      state.isNoticeableLoaded = action.payload;
      return state;
    },
    setIsOnline(state, action) {
      state.isOnline = action.payload;
      return state;
    },
    setDeveloperMode(state, action) {
      state.developerMode = action.payload;
      return state;
    },
    setConfirmModal(state, action) {
      state.confirmModal = action.payload;
      return state;
    }
  }
});

export default uiSlice.reducer;

export const uiActionsV2 = {
  ...uiSlice.actions,
  getTrayElmByKey: uiV2Selectors.getTrayElmByKey
};
