import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import { AssetKeyType } from "app/types/media";
import { AssetRestrictions, DevModeSize, PatchOperation, PlanEnum } from "app/types";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { useAppSelector } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import ConditionalRender from "app/components/common/ConditionalRender";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { OnDraftAttributeChange } from "app/components/editor/sideDrawers/CommonDrawerTypes";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import TitledInput from "app/components/common/TitledInput";
import MediaChooseBox from "app/components/MediaChooseBox";
import { startCase } from "lodash";
import DevModePopover from "app/components/DevModePopover";
import { Button } from "@nextui-org/react";

const MarginFlexColumn = styled(H1_FlexColumn)`
  margin-bottom: 10px;
  flex: 1 0 auto;
`;

export interface VideoDrawerSingleMediaProps {
  title: string;
  assetKey: string;
  url: string;
  onDraftAttributeChange?: OnDraftAttributeChange;
  restrictions?: AssetRestrictions;
  upgradeText?: string;
}
const INTRO_KEY = "url_intro";
const OUTRO_KEY = "url_outro";
const INTRO_OUTRO_KEYS = [INTRO_KEY, OUTRO_KEY];
const TextButton = styled(Button)`
  padding: unset;
  height: unset;
  text-decoration: underline;
  &&:hover {
    text-decoration: underline;
  }
`;
const VideoDrawerSingleMedia = ({
  title,
  assetKey,
  url,
  onDraftAttributeChange,
  restrictions,
  upgradeText
}: VideoDrawerSingleMediaProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const showPaymentModal = useUpgradeModal();

  const currentValue = url || "";
  const { displayUrls } = useDisplayUrls([currentValue]);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const currentBrandkit = useAppSelector((state) => state.brandKit.currentBrandkit);

  const previewUrl: MediaUrl | undefined = useMemo(() => {
    return displayUrls[currentValue] ? displayUrls[currentValue] : undefined;
  }, [displayUrls, currentValue]);
  const isIntroOutro = INTRO_OUTRO_KEYS.includes(assetKey);
  const showCrown = useShowCrown({
    requestedFeature: isIntroOutro ? PlanFeature.IntroOutro : undefined
  });

  const isRequired = restrictions?.required;

  const isUpgradeRequired = () => {
    const upgradeRequired =
      isIntroOutro &&
      showPaymentModal({
        source: "upgrade_video_drawer",
        requestedFeature: PlanFeature.IntroOutro,
        targetPlan: PlanEnum.business,
        upgradeText: upgradeText || intl.formatMessage(sceneDrawerMessages.mediaUpdateText)
      });
    return upgradeRequired;
  };

  const onImageClick = () => {
    if (!isUpgradeRequired()) {
      dispatch(
        analyticsEvents.chooseImage({
          draftId: currentDraft?.id as string,
          context: title,
          origin: "VideoDrawer"
        })
      );
      openModal(ModalName.mediaLibraryModal, { assetKey, assetKeyType: AssetKeyType.draft });
    }
  };

  const removeMedia = () => {
    const operations: PatchOperation[] = [{ op: "delete", path: `attributes.media.${assetKey}` }];
    dispatch(
      analyticsEvents.removeVideoFromDraft({
        draftId: currentDraft?.id as string,
        context: title
      })
    );
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId: currentDraft?.id as string,
        operations
      })
    );
  };

  const onUseBrandkitLogo = (key: string) => {
    if (key === INTRO_KEY) {
      onDraftAttributeChange &&
        onDraftAttributeChange(`attributes.media.${key}`, currentBrandkit.intro);
    } else if (key === OUTRO_KEY) {
      onDraftAttributeChange &&
        onDraftAttributeChange(`attributes.media.${key}`, currentBrandkit.outro);
    }
  };

  return (
    <MarginFlexColumn gap="10px">
      <TitledInput
        title={title}
        rightElement={
          <ConditionalRender
            condition={
              (assetKey === INTRO_KEY &&
                !!currentBrandkit.intro &&
                url !== currentBrandkit.intro.url) ||
              (assetKey === OUTRO_KEY &&
                !!currentBrandkit.outro &&
                url !== currentBrandkit.outro.url)
            }
          >
            <TextButton variant="light" size="sm" onClick={() => onUseBrandkitLogo(assetKey)}>
              Use Brand kit
            </TextButton>
          </ConditionalRender>
        }
      >
        <DevModePopover
          size={DevModeSize.large}
          placement="left"
          left={"22px"}
          right={"unset"}
          value={{ id: assetKey, url: url || "{{URL}}" }}
          path={"global_media_elements[#]"}
        />
        <MediaChooseBox
          onClick={onImageClick}
          showCrown={showCrown}
          upgradeButtonText={intl.formatMessage(sceneDrawerMessages.mediaUploadText, {
            title: startCase(title)
          })}
          replaceToolTip={intl.formatMessage(sceneDrawerMessages.replaceTooltip, { title })}
          removeToolTip={intl.formatMessage(sceneDrawerMessages.removeTooltip, { title })}
          previewUrl={previewUrl}
          removeMedia={isRequired ? undefined : removeMedia}
          insertText={intl.formatMessage(sceneDrawerMessages.mediaUploadText, { title })}
          source={assetKey}
          icon={<i className="fa-regular fa-cloud-arrow-up"></i>}
        />
      </TitledInput>
    </MarginFlexColumn>
  );
};

export default VideoDrawerSingleMedia;
