import LeftNavScenesList from "app/pages/editor/LeftNavScenesList";
import CenteredEditor from "app/pages/editor/CenteredEditor";
import ConditionalRender from "app/components/common/ConditionalRender";
import { EditorView, FeatureFlag, FetchStatus } from "app/types";
import { Button, Tooltip } from "antd";
import { draftPageMessages } from "app/pages/EditDraftPage/messages";
import EditingProjectFooter from "app/components/editor/EditingProjectFooter";
import RightNavAttributesList from "app/pages/editor/RightNavAttributesList";
import React, { useCallback, useRef } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { css } from "styled-components";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import uiActions from "app/store/actions/ui.actions";
import { ReactComponent as ViewMultiple } from "app/assets/editor/view-multiple.svg";
import { ReactComponent as ViewSingle } from "app/assets/editor/view-single.svg";
import EditingPageDraftContentLiveBlocksOthers from "app/pages/EditDraftPage/EditingPageDraftContentLiveBlocksOthers";
import { useUpdateMyPresence } from "app/services/liveBlocksClient";
import { RootState } from "app/store/store";
import useDrawer from "app/hooks/useDrawer";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import useSmallScreen from "app/hooks/useSmallScreen";
import usePermissions from "app/hooks/usePermissions";
import { Preferences } from "app/types/preferences";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import VideoPreviewModal from "app/pages/EditDraftPage/VideoPreviewModal";
import { ThemeMode } from "app/utils/theme";

const BlurFlexRow = styled(H1_FlexRow)<{ $isBlurBackground: boolean; $isReversedEditor: boolean }>`
  pointer-events: ${(props: { $isBlurBackground: boolean }) =>
    props.$isBlurBackground ? "none" : "auto"};
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: ${({ $isReversedEditor }) => ($isReversedEditor ? "row-reverse" : "row")};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
  }
`;

const DrawerOverlay = styled.div<{ $active: boolean }>`
  display: none;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: ${({ $active }) => ($active ? "block" : "none")};
    position: fixed;
    top: 60px;
    z-index: 1000;
    height: 100vh;
    width: 100%;
    background: rgb(93, 100, 123);
    opacity: 0.75;
  }
`;

const BorderRightMenu = styled(H1_FlexColumn)<{
  $active: boolean;
  $isReversedEditor: boolean;
  $showEditorDrawer: boolean;
}>`
  border-left: ${({ $isReversedEditor, theme }) =>
    $isReversedEditor
      ? "none"
      : `1px solid ${theme.mode === ThemeMode.Light ? theme.gray5 : theme.gray12}`};
  border-right: ${({ $isReversedEditor, theme }) =>
    $isReversedEditor
      ? `1px solid ${theme.mode === ThemeMode.Light ? theme.gray5 : theme.gray12}`
      : "none"};
  z-index: ${({ $showEditorDrawer }) => ($showEditorDrawer ? 1 : -1)};
  opacity: ${({ $showEditorDrawer }) => ($showEditorDrawer ? 1 : 0)};
  transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  ${({ $showEditorDrawer }) =>
    !$showEditorDrawer &&
    css`
      width: 0;
    `};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    transition: flex 0.3s ease-in-out;
    flex: ${({ $active }) => ($active ? "1 0 70%" : "0 1 auto")};
  }
`;

const StyledLeftMenu = styled(H1_FlexColumn)`
  border-right: 1px solid ${(props) => props.theme.gray5};
  z-index: 1;
  display: none;
  ${({ show }: { show: boolean }) =>
    show &&
    css`
      display: flex;
    `}
`;

const OverflowXFlexColumn = styled(H1_FlexColumn)`
  overflow-x: auto;
  cursor: default;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    padding-bottom: 50px;
  }
`;

const CenteredFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray3 : props.theme.gray12};
`;

const HiddenFlexRow = styled.div`
  display: none;
  height: 0;
  max-height: 0;
`;

const PaddingFlexRow = styled(H1_FlexRow)`
  z-index: -1; /* Hidden!! */
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const IconButton = styled(Button)`
  &&,
  &:focus {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: ${({ theme }) => theme.gray7};
    border: none;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    fill: ${(props) => props.theme.gray8};
  }
  ${({ $active }: { $active: boolean }) =>
    $active &&
    css`
      svg {
        fill: ${(props) => props.theme.blue3};
      }
    `}
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
    transform: scale(1.1);
    background-color: ${(props) => props.theme.gray3};
  }
  &:active {
    background-color: ${(props) => props.theme.gray2};
    transform: scale(1);
  }
`;

const EditingDraftPageContent = () => {
  const updateMyPresence = useUpdateMyPresence();
  const ref = useRef<HTMLDivElement>(null);
  const overflowRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const { isSmallScreen } = useSmallScreen();
  const dispatch = useAppDispatch();
  const flags = useFlags();

  const { closeDrawer, editorDrawerOpen } = useDrawer();
  const { isWriteWorkspaceAllow, isEnterprise } = usePermissions();
  const topicToDraftStatus: FetchStatus = useAppSelector(
    (state: RootState) => state.drafts.topicToDraftStatus
  );
  const preferences: Preferences = useAppSelector((state) => state.user.preferences);
  const isReversedEditor = preferences.is_reversed_editor;
  const isFirstVideoCreated =
    preferences.is_first_video_created === undefined || preferences.is_first_video_created;

  const isTopicToDraftLoading = topicToDraftStatus === fetchingStatus.loading;
  // @ts-ignore handels userUI store typing
  const view = useAppSelector((state) => state.userUi.view);
  // @ts-ignore handels userUI store typing
  const ftuVideoPreview: boolean = useAppSelector((state) => state.userUi.ftuVideoPreview);
  const showEditorDrawer = useAppSelector((state) => state.uiV2.showEditorDrawer);

  const isSingleViewSelected = view === EditorView.Single;
  const isMultipleViewsSelected = view === EditorView.Multiple;
  const isDrawerClosed = !editorDrawerOpen;
  const showFtuVideoPreviewModal =
    !isFirstVideoCreated && ftuVideoPreview && flags[FeatureFlag.ftuVideoPreview];

  const onClickSingleViewMode = () => {
    dispatch(
      analyticsEvents.changeView({
        view: EditorView.Single
      })
    );
    dispatch(uiActions.setView(EditorView.Single));
  };

  const onClickMultipleViewsMode = () => {
    dispatch(
      analyticsEvents.changeView({
        view: EditorView.Multiple
      })
    );
    dispatch(uiActions.setView(EditorView.Multiple));
  };

  const onPointerMove = useCallback(
    (event: React.MouseEvent) => {
      // Update the user cursor position on every pointer move
      updateMyPresence({
        scrollTop: overflowRef.current?.scrollTop,
        dimensions: {
          width: ref.current?.offsetWidth || 0,
          height: ref.current?.offsetHeight || 0
        },
        cursor: {
          x: Math.round(event.clientX),
          y: Math.round(event.clientY)
        }
      });
    },
    [overflowRef.current?.scrollTop, ref.current?.offsetWidth, ref.current?.offsetHeight]
  );

  const onCloseDrawer = () => {
    if (isSmallScreen) {
      closeDrawer();
    }
  };

  const onCloseVideoPreviewModal = () => {
    dispatch(uiActions.setFtuVideoPreview(false));
  };

  const onFinishVideoPreviewModal = () => {
    dispatch(uiActions.setFtuVideoPreview(false));
    dispatch(analyticsEvents.takeVideoPreviewTour());
  };

  return (
    <H1_FlexColumn flex="1 1 auto">
      <ConditionalRender condition={showFtuVideoPreviewModal}>
        <VideoPreviewModal
          visible={ftuVideoPreview}
          onClose={onCloseVideoPreviewModal}
          onFinish={onFinishVideoPreviewModal}
        />
      </ConditionalRender>
      <H1_FlexRow position="relative" flex="1 1 auto" height="100%">
        {/* ------------------------------------------------------------------------------------------------------------------- */}
        {/* Notice that every new component inside the BlurFlexRow will render a large amount of times, so add React.memo to it */}
        {/* ------------------------------------------------------------------------------------------------------------------- */}
        <BlurFlexRow
          $isReversedEditor={!!isReversedEditor}
          $isBlurBackground={isTopicToDraftLoading}
          width="100%"
          height="100%"
          ref={ref}
          // @ts-ignore Don't understand it
          onPointerMove={onPointerMove}
        >
          <EditingPageDraftContentLiveBlocksOthers
            fullScreenWidth={ref.current?.offsetWidth || 0}
            scrollTop={overflowRef.current?.scrollTop || 0}
          />
          <ConditionalRender condition={isSingleViewSelected}>
            <StyledLeftMenu show={isSingleViewSelected} flex="0 0 148px" width="148px">
              <LeftNavScenesList />
            </StyledLeftMenu>
          </ConditionalRender>
          <OverflowXFlexColumn flex="1 1 auto">
            <CenteredFlexColumn
              data-container-id="scroller-editor-container"
              position="relative"
              flex="1 1 auto"
              overflow="auto"
              ref={overflowRef}
            >
              <CenteredEditor viewMode={view} />
            </CenteredFlexColumn>
            <PaddingFlexRow align="center" flex="0 0 0" height="0" justify="space-between">
              {/* currently disabled */}
              <div style={{ display: "none" }}>
                <H1_FlexRow>
                  <Tooltip title={formatMessage(draftPageMessages.largeViewTooltip)}>
                    <IconButton
                      $active={isSingleViewSelected}
                      onClick={onClickSingleViewMode}
                      data-auto-id="draft-large-view"
                    >
                      <ViewSingle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={formatMessage(draftPageMessages.storyboardViewTooltip)}>
                    <IconButton
                      $active={isMultipleViewsSelected}
                      onClick={onClickMultipleViewsMode}
                      data-auto-id="draft-storyboard-view"
                    >
                      <ViewMultiple />
                    </IconButton>
                  </Tooltip>
                </H1_FlexRow>
              </div>
              <HiddenFlexRow>
                <EditingProjectFooter />
              </HiddenFlexRow>
            </PaddingFlexRow>
          </OverflowXFlexColumn>
          <ConditionalRender condition={isWriteWorkspaceAllow || isEnterprise}>
            <BorderRightMenu
              flex={showEditorDrawer ? "0 0 469px" : "0 0 0"}
              $active={!isDrawerClosed}
              $isReversedEditor={!!isReversedEditor}
              $showEditorDrawer={showEditorDrawer}
            >
              <DrawerOverlay onClick={onCloseDrawer} $active={!isDrawerClosed} />
              <RightNavAttributesList />
            </BorderRightMenu>
          </ConditionalRender>
        </BlurFlexRow>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default React.memo(EditingDraftPageContent, (prevProps, nextProps) => false);
