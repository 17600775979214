import React from "react";
import { message, Popover } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useAppSelector } from "app/hooks";
import { TooltipPlacement } from "antd/es/tooltip";
import { DevModeSize } from "app/types";
import styled, { useTheme } from "styled-components";
import useCopy from "app/hooks/useCopy";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import DeveloperButton from "app/components/DeveloperButton";
import JsonViewer from "./JsonViewer";
import usePermissions from "app/hooks/usePermissions";

import "./dev-popover.scss";
import { Button } from "@nextui-org/react";

interface DevModePopoverProps {
  value: any;
  path: string;
  left?: string;
  right?: string;
  top?: string;
  position?: "relative" | "absolute";
  padding?: string;
  placement?: TooltipPlacement;
  size?: DevModeSize;
}

const CornerButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 0;
  margin-right: 5px;
`;

const StyledPopover = styled(Popover)`
  z-index: 10;
  &&& .ant-popover-inner {
    border-radius: 6px;
    overflow: hidden;
  }
  &&& .ant-popover-inner-content {
    padding: 0;
  }
`;

const Wrapper = styled("div")<{
  position?: "relative" | "absolute";
  zIndex: string;
  padding?: string;
  left?: string;
  top?: string;
  right?: string;
}>`
  position: ${({ position }) => position || "absolute"};
  z-index: ${({ zIndex }) => zIndex || "10"};
  padding: ${({ padding }) => padding || "5px"};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right || "0px"};
`;
const DevModePopover: React.FC<DevModePopoverProps> = ({
  value,
  path,
  left,
  right,
  top,
  position,
  padding,
  placement,
  size = DevModeSize.default
}) => {
  const theme = useTheme();

  const { triggerCopy } = useCopy();
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);
  const { isAllowCreateApiCurl } = usePermissions();

  const handleCopyToClipboard = (value: string) => {
    triggerCopy({ copyContent: value, shouldNotify: false });
    message.success("Copied to clipboard");
  };

  const content = (
    <H1_FlexColumn padding="0" width={"350px"} gap={"5px"} position="relative">
      <CornerButton
        size="sm"
        color="primary"
        variant="light"
        isIconOnly
        startContent={<i className="far fa-copy" />}
        onClick={() => handleCopyToClipboard(JSON.stringify(value, null, 4))}
      />
      <H1_FlexColumn height="39px" justify="center">
        <H1_FlexRow justify="center" align="center">
          <H1_TextSmall color={theme.gray11}>{path}</H1_TextSmall>
        </H1_FlexRow>
      </H1_FlexColumn>

      <H1_FlexRow flex={"1"}>
        <JsonViewer value={value} />
      </H1_FlexRow>
    </H1_FlexColumn>
  );
  const onClick = (e: any) => {
    e.stopPropagation();
  };

  if (!developerMode || !isAllowCreateApiCurl) {
    return null;
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <Wrapper
      onClick={onClick}
      position={position}
      zIndex={"1"}
      padding={padding}
      left={left}
      top={top}
      right={right}
    >
      <StyledPopover
        content={content}
        trigger={"hover"}
        placement={placement}
        showArrow={false}
        overlayClassName="dev-container-popover"
      >
        {/*must have div because of popover*/}
        <H1_FlexRow height="fit-content">
          <DeveloperButton
            onClick={onClick}
            size={size === DevModeSize.default ? "18px" : "24px"}
          />
        </H1_FlexRow>
      </StyledPopover>
    </Wrapper>
  );
};

export default DevModePopover;
