import React, { ReactNode } from "react";

import { Character, CharacterType } from "app/types/character";
import styled, { css, useTheme } from "styled-components";
import PreviewTag from "app/components/common/PreviewTag";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import usePlansConverter from "app/hooks/usePlansConverter";
import { addPolicyToUrlIfFileStackMedia } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import { Tooltip } from "antd";
import { defineMessages, useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import OwnCharacter from "app/assets/images/own-presenter.png";
import { mobileBannerMessages } from "app/components/editor/sideDrawers/EditingCharacterDiscover/messages";
import { FeatureFlag } from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import VideoWithHover from "app/components/common/VideoWithHover";
import { uniqBy } from "lodash-es";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import DevModePopover from "app/components/DevModePopover";

const messages = defineMessages({
  unsupportedCharacter: {
    id: "presenter-grid.tooltip.unsupported-character-template",
    defaultMessage:
      "The current template is not compatible with this character. Please switch to a different template to use it."
  }
});
export interface PresentersGridProps {
  characters: Character[];
  selectedId?: string;
  onSelect: (characterId: string) => void;
  showMobileCharacterLink: boolean;
}

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

const StyledVideoWithPoster = styled(VideoWithHover)<{ $selected?: boolean; $disabled?: boolean }>`
  transition: 0.5s;
  background: ${({ theme }) => theme.gray3};
  border-radius: 8px;
  max-height: 109px;
  video {
    max-height: 109px;
    max-width: 160px;
    height: 109px;
    width: 160px;
  }
  ${({ $selected }) =>
    $selected &&
    css`
      max-height: 113px;
      border: 2px solid ${({ theme }) => theme.blue4};
      border-color: ${({ theme }) => theme.blue4};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      && {
        background-color: ${({ theme }) => theme.gray7};
        opacity: 0.5;
      }
    `}
`;

const PreviewTagContainer = styled.div`
  position: absolute;
  right: 6px;
  text-transform: uppercase;
`;

interface TitledPosterProps {
  color?: string;
  title: string;
  children: ReactNode;
}

const StyledTitlePosterFlexColumn = styled(H1_FlexColumn)`
  position: relative;
`;

const StyledFlexColumn = styled(H1_FlexColumn)<{ $greyedOut?: boolean }>`
  opacity: ${(props) => props.$greyedOut && "50%"};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex: inherit;
  }
`;

const ImageContainerFlexColumn = styled(H1_FlexColumn)`
  transition: 0.5s;
  background: var(--gray-03);
  border-radius: 8px;
  max-height: 109px;
  img {
    transition: 0.5s;
    margin-left: 16px; /* We want the circle to be centered, and the plus pushes it to the left. So its a fix */
  }
  &:hover img {
    transform: scale(1.1);
  }
`;

const TitledPoster = ({ color = "", title, children }: TitledPosterProps) => {
  return (
    <StyledTitlePosterFlexColumn width="160px" height="137px" justify="space-between">
      {children}
      <H1_TextSmall margin="0 0 0 8px" color={color} ellipsis fontWeight={600}>
        {title}
      </H1_TextSmall>
    </StyledTitlePosterFlexColumn>
  );
};

const PresentersGrid = ({
  characters,
  selectedId,
  onSelect,
  showMobileCharacterLink
}: PresentersGridProps) => {
  const currentTemplate = useAppSelector((state) => state.templates.currentTemplate);
  const plansConverter = usePlansConverter();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { openModal } = useModal();
  const flags = useFlags();
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);

  const openMobileModal = () => {
    openModal(ModalName.mobileQrModal);
  };

  return (
    <StyledFlexRow width="330px" gap="10px" wrap="wrap">
      <ConditionalRender condition={showMobileCharacterLink && flags[FeatureFlag.mobileQr]}>
        <TitledPoster title={formatMessage(mobileBannerMessages.yourCharacter)}>
          <ImageContainerFlexColumn
            flex="1"
            justify="center"
            align="center"
            gap="3px"
            onClick={openMobileModal}
          >
            <img height="80px" src={OwnCharacter} alt="" />
          </ImageContainerFlexColumn>
        </TitledPoster>
      </ConditionalRender>
      {uniqBy(characters, "character_id").map((character) => {
        const isTemplateSupported =
          currentTemplate?.character_types && character.type
            ? currentTemplate?.character_types?.includes(character.type)
            : true;
        return (
          <StyledFlexColumn
            onClick={() => isTemplateSupported && onSelect(character.character_id as string)}
            flex="40%"
            key={character.character_id}
            $greyedOut={!isTemplateSupported}
          >
            <TitledPoster title={character.title}>
              <DevModePopover
                placement="left"
                value={{ character_id: character.id }}
                path={"root"}
              />

              <ConditionalRender condition={!developerMode && isTemplateSupported && character.pro}>
                <PreviewTagContainer>
                  <PreviewTag icon={<CrownIcon />} background={theme.orange3}>
                    {plansConverter(character?.plans?.[0])}
                    {currentTemplate?.character_types &&
                      !currentTemplate?.character_types?.includes(character.type as CharacterType)}
                  </PreviewTag>
                </PreviewTagContainer>
              </ConditionalRender>
              <ConditionalRender
                condition={!developerMode && !character.pro && !!character.tags?.length}
              >
                <PreviewTagContainer>
                  <PreviewTag background="var(--ho-primary)">{character?.tags?.[0]}</PreviewTag>
                </PreviewTagContainer>
              </ConditionalRender>
              <Tooltip
                title={!isTemplateSupported ? formatMessage(messages.unsupportedCharacter) : ""}
              >
                <div>
                  <StyledVideoWithPoster
                    $selected={selectedId === character.character_id}
                    disableIcon
                    video={character.video}
                    image={addPolicyToUrlIfFileStackMedia(character.image, filestackPolicy)}
                    shouldRestartOnLeave
                  />
                </div>
              </Tooltip>
            </TitledPoster>
          </StyledFlexColumn>
        );
      })}
    </StyledFlexRow>
  );
};

export default PresentersGrid;
