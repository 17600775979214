import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";

import { useIntl } from "react-intl";
import {
  contentTypeMessages,
  customerSupportContentMessages,
  howDidYouHearAboutUsMessages,
  humanResourcesContentMessages,
  inviteTeamMembersMessages,
  learningDevelopmentContentMessages,
  marketingContentMessages,
  personalContentTypeMessages,
  pricingMessages,
  productManagementContentMessages,
  salesContentMessages,
  setWorkspaceMessages,
  teamTypeMessages,
  userResearchContentMessages,
  userTypeMessages
} from "app/pages/onBoardingV2Page/messages";
import {
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { containsUrl, removeDuplicatedStrings } from "app/utils/helpers";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { joinExperiment } from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { Steps } from "antd";
import EmailAddressesSection from "app/components/teams/emailAddressesSection";
import {
  Draft,
  FeatureFlag,
  Location,
  OnBoardingSteps,
  RouteInterface,
  UseCases,
  UseCaseSegmentType,
  UserType,
  WorkspaceRole
} from "app/types";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useAuth } from "app/auth/useAuth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { userActions } from "app/store/slices/user.slice";
import PricingContent from "app/pages/pricing/PricingContent";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@nextui-org/react";
import { CountrySelector, usePhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const StyledHourOneLogo = styled(HourOneLogo)`
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

const BoxFlexColumn = styled(H1_FlexColumn)<{ $selected?: boolean; $isRow: boolean }>`
  flex-direction: ${({ $isRow }) => ($isRow ? "row" : "column")};
  border-radius: 14px;
  background: ${({ theme }) => theme.gray1};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3), 0px 2px 30px 0px rgba(0, 0, 0, 0.08),
    0px 0px 15px 0px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease-in-out;
  @media (max-height: 600px) {
    height: 150px;
    width: 170px;
  }
  &:hover {
    border: none;
    background-color: ${({ theme }) => theme.gray2};
  }
  &:active {
    box-shadow: none;
    transform: scale(0.95);
  }
`;

const StyledSteps = styled(Steps)`
  &&& .ant-steps-item-finish .ant-steps-item-title::after {
    background-color: ${(props) => props.theme.blue4};
  }
  &&& .ant-steps-item {
    width: 73px;
    padding-left: 8px;
    &:last-of-type {
      display: none;
    }
  }
  .ant-steps-item-content {
    width: 100%;
  }

  .ant-steps-item-container {
    width: 100%;
  }

  .ant-steps-item-title {
    width: inherit;
  }

  .ant-steps-item-title::after {
    left: 0;
    width: 65px;
    height: 5px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.blue1};
  }
  .ant-steps-item-icon {
    display: none;
  }
  .ant-steps-icon {
    color: ${(props) => props.theme.blue4};
  }
`;

const OnBoardingV2Page = () => {
  const { user } = useAuth();
  const intl = useIntl();
  const flags = useFlags();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [userAnswers, setUserAnswers] = useState<Record<string, any>>({});
  const [currentStep, setCurrentStep] = useState<OnBoardingSteps>(OnBoardingSteps.userType);
  const navigate = useNavigate();
  const [firstDraftTopic, setFirstDraftTopic] = useState<UseCaseSegmentType>(
    UseCaseSegmentType.SocialMediaContent
  );
  const [teamMembers, setTeamMembers] = useState<string[]>([]);
  const [workspaceNameValue, setWorkspaceNameValue] = useState<string>("");
  const [companyWebsiteValue, setCompanyWebsiteValue] = useState<string>("");
  const [userType, setUserType] = useState<UserType | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const phoneInput = usePhoneInput({
    defaultCountry: "us",
    value: userAnswers["phone_number"],
    onChange: (data) => {
      setUserAnswers({ ...userAnswers, phone_number: data.phone });
    }
  });

  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const preferences = useAppSelector((state) => state.user.preferences);
  const showOnboardingV2 = preferences.show_on_boarding_survey_v2;
  const expFtuVideoPreviewOnBoarding = flags[FeatureFlag.ftuVideoPreview];
  const expSkipEditorOnBoarding = flags[FeatureFlag.expSkipEditorOnBoarding];
  const stepHowDidYouHereAboutUs = currentStep === OnBoardingSteps.howDidYouHearAboutUs;
  const isValid = isPhoneValid(phoneInput.phone);

  useEffect(() => {
    if (expFtuVideoPreviewOnBoarding) {
      dispatch(
        joinExperiment({
          id: user?.sub as string,
          email: user?.email as string,
          experimentName: "exp_onBoardingFtuVideoPreview"
        })
      );
    }
  }, [expFtuVideoPreviewOnBoarding]);

  useEffect(() => {
    if (expSkipEditorOnBoarding) {
      dispatch(
        joinExperiment({
          id: user?.sub as string,
          email: user?.email as string,
          experimentName: "exp_onBoardingEditorSkip"
        })
      );
    }
  }, [expSkipEditorOnBoarding]);

  useEffect(() => {
    if (currentWorkspace) {
      setWorkspaceNameValue(currentWorkspace.name);
    }
  }, [currentWorkspace]);

  useEffect(() => {
    if (showOnboardingV2) {
      dispatch(
        googleEvents.onBoarding({
          status: "start"
        })
      );
    }
  }, [showOnboardingV2]);
  const sendOnBoardingEvents = (userResponseKey: string, userResponseValue: string) => {
    switch (userResponseKey) {
      case "user_type_v2":
        dispatch(googleEvents.segmentUseFor({ useFor: userResponseValue }));
        break;
      case "team_type":
        dispatch(googleEvents.segmentWorkTeam({ team: userResponseValue }));
        break;
      case "content_type":
        dispatch(googleEvents.segmentWorkContent({ content: userResponseValue }));
        break;
      case "how_did_you_hear_v2":
        dispatch(googleEvents.segmentHowDidYouHear({ howDidYouHear: userResponseValue }));
        break;
    }
  };
  const createFirstDraftByTopic = () => {
    const draft: Draft = {
      title: "My First Draft",
      description: "My First Draft",
      type: "usage_segment",
      usage_segment: firstDraftTopic
    };

    dispatch(draftsActions.createFirstDraftRequest({ draft }));
  };
  const createStepsList = () => {
    if (userType === "work") {
      return Array.from(Array(8)).map(() => ({}));
    }
    return Array.from(Array(4)).map(() => ({}));
  };

  const onFinishPricing = () => {
    onDoneOnBoarding();
  };

  const oncloseInviteMembers = (teamMembers: string[]) => {
    if (teamMembers.length > 0) {
      setTeamMembers(teamMembers);
    }
    setCurrentStep(OnBoardingSteps.howDidYouHearAboutUs);
  };

  const sendInviteToTeamMembers = () => {
    const filterEmpties = teamMembers.filter((email: string) => email !== "");
    const members = removeDuplicatedStrings(filterEmpties);
    dispatch(
      analyticsEvents.inviteMembers({
        workspaceId: currentWorkspace?.id as string,
        workspaceName: currentWorkspace?.name as string,
        members
      })
    );
    dispatch(
      workspacesActions.inviteMembersToWorkspaceRequest({
        workspaceId: currentWorkspace?.id as string,
        members: members.map((member) => ({ email: member, role: WorkspaceRole.member }))
      })
    );
  };

  const onChangeWorkspaceName = (value: string) => {
    if (value) {
      setWorkspaceNameValue(value);
      dispatch(googleEvents.segmentSetupWorkspace({ workspaceName: value }));
    }
  };
  const onChangeCompanyWebsite = (value: string) => {
    if (value) {
      setCompanyWebsiteValue(value);
    }
  };

  const updateWorkspace = () => {
    if (workspaceNameValue !== currentWorkspace?.name) {
      dispatch(
        workspacesActions.updateWorkspacePropertiesRequest({
          workspaceId: currentWorkspace?.id as string,
          workspace: { name: workspaceNameValue }
        })
      );
    }
  };
  const onDoneOnBoarding = () => {
    if (userType === UserType.work) {
      updateWorkspace();
      if (teamMembers.length > 0) {
        sendInviteToTeamMembers();
      }
    }
    dispatch(userActions.onBoardingRequest(userAnswers));
    createFirstDraftByTopic();
    dispatch(
      googleEvents.onBoarding({
        status: "done"
      })
    );
    navigate(Location.Home);
  };
  const onClickNextstep = (stepResponse: Record<string, any>, nextStep?: OnBoardingSteps): void => {
    const userResponseKeys = Object.keys(stepResponse)[0];
    const userResponseValue = Object.values(stepResponse)[0];
    sendOnBoardingEvents(userResponseKeys, userResponseValue);
    const gotArrValue = Object.keys(stepResponse).find((key) => Array.isArray(stepResponse[key]));
    if (gotArrValue) {
      setUserAnswers((prevUserAnswers) => ({
        ...prevUserAnswers,
        [gotArrValue]: [
          ...(prevUserAnswers[gotArrValue] || []),
          ...(stepResponse[gotArrValue] as string[])
        ]
      }));
    } else {
      setUserAnswers({ ...userAnswers, ...stepResponse });
    }
    if (userResponseKeys === "team_type") {
      const useCase: string = userResponseValue;
      setFirstDraftTopic(UseCases[useCase]);
    }
    if (userResponseKeys === "user_type_v2") {
      setUserType(userResponseValue);
    }

    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };
  const onClickContinueButton = (nextStep: OnBoardingSteps): void => {
    if (nextStep === OnBoardingSteps.inviteTeamMembers) {
      if (!isValid) {
        return;
      }
      if (phoneInput.inputValue.length > 4) {
        dispatch(googleEvents.segmentPhoneNumber({ phoneNumber: phoneInput.inputValue }));
      }
      if (!!companyWebsiteValue && containsUrl(companyWebsiteValue)) {
        setUserAnswers({ ...userAnswers, company_website: companyWebsiteValue });
        dispatch(googleEvents.segmentCompanyWebsite({ companyWebsite: companyWebsiteValue }));
      }
    }
    setCurrentStep(nextStep);
  };
  const routes: RouteInterface = {
    userType: {
      headlineTitle: intl.formatMessage(userTypeMessages.headLineTitle),
      buttonList: [
        {
          headlineTitle: intl.formatMessage(userTypeMessages.workHeadLineTitle),
          subTitle: intl.formatMessage(userTypeMessages.workSubTitle),
          icon: "fa-solid fa-briefcase",
          onClick: () => onClickNextstep({ user_type_v2: UserType.work }, OnBoardingSteps.teamType)
        },
        {
          headlineTitle: intl.formatMessage(userTypeMessages.personalHeadLineTitle),
          subTitle: intl.formatMessage(userTypeMessages.personalSubLineTitle),
          icon: "fa-solid fa-laptop",
          onClick: () =>
            onClickNextstep({ user_type_v2: UserType.personal }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(userTypeMessages.studentHeadLineTitle),
          subTitle: intl.formatMessage(userTypeMessages.studentSubLineTitle),
          icon: "fa-solid fa-graduation-cap",
          onClick: () =>
            onClickNextstep({ user_type_v2: UserType.student }, OnBoardingSteps.pricingList)
        }
      ]
    },
    contentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 2,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(personalContentTypeMessages.socialMediaContentTitle),
          icon: "fa-solid fa-thumbs-up",
          onClick: () => onClickNextstep({ content_type: ["social_media_content"] })
        },
        {
          headlineTitle: intl.formatMessage(personalContentTypeMessages.videoPresentationsTitle),
          icon: "fa-solid fa-presentation-screen",
          onClick: () => onClickNextstep({ content_type: ["video_presentations"] })
        },
        {
          headlineTitle: intl.formatMessage(personalContentTypeMessages.videoGreetingTitle),
          icon: "fa-solid fa-circle-video",
          onClick: () => onClickNextstep({ content_type: ["video_greeting"] })
        },
        {
          headlineTitle: intl.formatMessage(personalContentTypeMessages.videoAdTitle),
          icon: "fa-solid fa-play",
          onClick: () => onClickNextstep({ content_type: ["video_ad"] })
        },
        {
          headlineTitle: intl.formatMessage(personalContentTypeMessages.brandVideoTitle),
          icon: "fa-solid fa-swatchbook",
          onClick: () => onClickNextstep({ content_type: ["brand_video"] })
        },
        {
          headlineTitle: intl.formatMessage(personalContentTypeMessages.otherTitle),
          icon: "fa-solid fa-clipboard-question",
          onClick: () => onClickNextstep({ content_type: ["other"] })
        }
      ]
    },
    teamType: {
      headlineTitle: intl.formatMessage(teamTypeMessages.headLineTitle),
      currentStep: 2,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.marketingTitle),
          icon: "fa-solid fa-megaphone",
          onClick: () =>
            onClickNextstep({ team_type: "marketing" }, OnBoardingSteps.marketingContentType)
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.productManagementTitle),
          icon: "fa-solid fa-bars-progress",
          onClick: () =>
            onClickNextstep(
              { team_type: "product_management" },
              OnBoardingSteps.productManagementContentType
            )
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.salesTitle),
          icon: "fa-sharp fa-solid fa-coins",
          onClick: () => onClickNextstep({ team_type: "sales" }, OnBoardingSteps.salesContentType)
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.customerSupportTitle),
          icon: "fa-solid fa-circle-info",
          onClick: () =>
            onClickNextstep(
              { team_type: "customer_support" },
              OnBoardingSteps.customerSupportContentType
            )
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.humanResourcesTitle),
          icon: "fa-solid fa-user",
          onClick: () =>
            onClickNextstep(
              { team_type: "human_resources" },
              OnBoardingSteps.humanResourcesContentType
            )
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.userResearchTitle),
          icon: "fa-regular fa-magnifying-glass",
          onClick: () =>
            onClickNextstep({ team_type: "user_research" }, OnBoardingSteps.userResearchContentType)
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.learningDevelopmentTitle),
          icon: "fa-solid fa-book",
          onClick: () =>
            onClickNextstep(
              { team_type: "learning_development" },
              OnBoardingSteps.learningDevelopmentContentType
            )
        },
        {
          headlineTitle: intl.formatMessage(teamTypeMessages.otherTitle),
          icon: "fa-solid fa-clipboard-question",
          onClick: () => onClickNextstep({ team_type: "other" }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    marketingContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.videoAdsTitle),
          icon: "fa-solid fa-play",
          onClick: () =>
            onClickNextstep({ content_type: ["video_ads"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.socialMediaClipsTitle),
          icon: "fa-solid fa-thumbs-up",
          onClick: () =>
            onClickNextstep({ content_type: ["social_media_clips"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.productHowTosTitle),
          icon: "fa-solid fa-head-side-gear",
          onClick: () =>
            onClickNextstep({ content_type: ["product_how_tos"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.creatingWebinarsTitle),
          icon: "fa-solid fa-screen-users",
          onClick: () =>
            onClickNextstep({ content_type: ["creating_webinars"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.videoPresentationsTitle),
          icon: "fa-solid fa-presentation-screen",
          onClick: () =>
            onClickNextstep({ content_type: ["video_presentations"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.teamCommunicationTitle),
          icon: "fa-solid fa-people-group",
          onClick: () =>
            onClickNextstep({ content_type: ["team_communication"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.brandedContentTitle),
          icon: "fa-solid fa-copyright",
          onClick: () =>
            onClickNextstep({ content_type: ["branded_content"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(marketingContentMessages.otherTitle),
          icon: "fa-solid fa-clipboard-question",
          onClick: () => onClickNextstep({ content_type: ["other"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    productManagementContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(
            productManagementContentMessages.videoPresentationsTitle
          ),
          icon: "fa-solid fa-file-video",
          onClick: () =>
            onClickNextstep({ content_type: ["video_presentations"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(
            productManagementContentMessages.teamCommunicationTitle
          ),
          icon: "fa-solid fa-people-group",
          onClick: () =>
            onClickNextstep({ content_type: ["team_communication"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(productManagementContentMessages.featureReleasesTitle),
          icon: "fa-solid fa-puzzle-piece",
          onClick: () =>
            onClickNextstep({ content_type: ["feature_releases"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    salesContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(salesContentMessages.productTutorialsTitle),
          icon: "fa-solid fa-circle-video",
          onClick: () =>
            onClickNextstep({ content_type: ["product_tutorials"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(salesContentMessages.videoPresentationsTitle),
          icon: "fa-solid fa-file-video",
          onClick: () =>
            onClickNextstep({ content_type: ["video_presentations"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(salesContentMessages.supportTrainingTitle),
          icon: "fa-solid fa-headset",
          onClick: () =>
            onClickNextstep({ content_type: ["support_training"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(salesContentMessages.teamCommunicationTitle),
          icon: "fa-solid fa-people-group",
          onClick: () =>
            onClickNextstep({ content_type: ["team_communication"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(salesContentMessages.prospectingPitchesTitle),
          icon: "fa-solid fa-file-lines",
          onClick: () =>
            onClickNextstep({ content_type: ["prospecting_pitches"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(salesContentMessages.otherTitle),
          icon: "fa-solid fa-circle-info",
          onClick: () => onClickNextstep({ content_type: ["other"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    customerSupportContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(customerSupportContentMessages.helpDeskContentTitle),
          icon: "fa-solid fa-bars-progress",
          onClick: () =>
            onClickNextstep({ content_type: ["help_desk_content"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(customerSupportContentMessages.supportTrainingTitle),
          icon: "fa-solid fa-headset",
          onClick: () =>
            onClickNextstep({ content_type: ["support_training"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(customerSupportContentMessages.teamCommunicationTitle),
          icon: "fa-solid fa-people-group",
          onClick: () =>
            onClickNextstep({ content_type: ["team_communication"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    humanResourcesContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(
            humanResourcesContentMessages.trainingComplianceContentTitle
          ),
          icon: "fa-solid fa-chalkboard-user",
          onClick: () =>
            onClickNextstep({ content_type: ["training_compliance"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(humanResourcesContentMessages.videoPresentationsTitle),
          icon: "fa-solid fa-presentation-screen",
          onClick: () =>
            onClickNextstep({ content_type: ["video_presentations"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(humanResourcesContentMessages.recruitingTitle),
          icon: "fa-solid fa-calendar-circle-user",
          onClick: () =>
            onClickNextstep({ content_type: ["recruiting"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(humanResourcesContentMessages.employeeDevelopmentTitle),
          icon: "fa-solid fa-briefcase",
          onClick: () =>
            onClickNextstep(
              { content_type: ["employee_development"] },
              OnBoardingSteps.setWorkspace
            )
        },
        {
          headlineTitle: intl.formatMessage(humanResourcesContentMessages.otherTitle),
          icon: "fa-solid fa-circle-info",
          onClick: () => onClickNextstep({ content_type: ["other"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    learningDevelopmentContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(
            learningDevelopmentContentMessages.productTutorialsContentTitle
          ),
          icon: "fa-solid fa-circle-video",
          onClick: () =>
            onClickNextstep({ content_type: ["product_tutorials"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(
            learningDevelopmentContentMessages.creatingWebinarsTitle
          ),
          icon: "fa-solid fa-screen-users",
          onClick: () =>
            onClickNextstep({ content_type: ["creating_webinars"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(
            learningDevelopmentContentMessages.trainingComplianceContentTitle
          ),
          icon: "fa-solid fa-chalkboard-user",
          onClick: () =>
            onClickNextstep({ content_type: ["training_compliance"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(
            learningDevelopmentContentMessages.videoPresentationsTitle
          ),
          icon: "fa-solid fa-presentation-screen",
          onClick: () =>
            onClickNextstep({ content_type: ["video_presentations"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(learningDevelopmentContentMessages.otherTitle),
          icon: "fa-solid fa-circle-info",
          onClick: () => onClickNextstep({ content_type: ["other"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    userResearchContentType: {
      headlineTitle: intl.formatMessage(contentTypeMessages.headLineTitle),
      currentStep: 3,

      buttonList: [
        {
          headlineTitle: intl.formatMessage(userResearchContentMessages.teamCommunicationTitle),
          icon: "fa-solid fa-people-group",
          onClick: () =>
            onClickNextstep({ content_type: ["team_communication"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(userResearchContentMessages.userResearchTitle),
          icon: "fa-regular fa-magnifying-glass",
          onClick: () =>
            onClickNextstep({ content_type: ["user_research"] }, OnBoardingSteps.setWorkspace)
        },
        {
          headlineTitle: intl.formatMessage(userResearchContentMessages.otherTitle),
          icon: "fa-solid fa-circle-info",
          onClick: () => onClickNextstep({ content_type: ["other"] }, OnBoardingSteps.setWorkspace)
        }
      ]
    },
    setWorkspace: {
      headlineTitle: intl.formatMessage(setWorkspaceMessages.headLineTitle),
      subTitle: intl.formatMessage(setWorkspaceMessages.subTitle),
      continueButtonTitle: intl.formatMessage(contentTypeMessages.continueButton),
      continueButton: () => onClickContinueButton(OnBoardingSteps.inviteTeamMembers),
      currentStep: 4,
      inputList: [
        {
          inputTitle: intl.formatMessage(setWorkspaceMessages.workspaceName),
          defaultInput: intl.formatMessage(setWorkspaceMessages.workspaceInitialValueName),
          value: workspaceNameValue,
          onClick: onChangeWorkspaceName
        },
        {
          inputTitle: intl.formatMessage(setWorkspaceMessages.companyWebsite),
          defaultInput: intl.formatMessage(setWorkspaceMessages.companyHourOneWebsite),
          value: userAnswers["company_website"],
          onClick: onChangeCompanyWebsite
        }
      ]
    },
    inviteTeamMembers: {
      headlineTitle: intl.formatMessage(inviteTeamMembersMessages.headLineTitle),
      subTitle: intl.formatMessage(inviteTeamMembersMessages.subTitle),
      currentStep: 5
    },
    howDidYouHearAboutUs: {
      headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.headLineTitle),
      currentStep: 6,
      buttonList: [
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.google),
          icon: "fa-brands fa-google",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "google" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.wordOfMouth),
          icon: "fa-solid fa-lips",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "wordOfMouth" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.twitter),
          icon: "fa-brands fa-twitter",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "twitter" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.linkedin),
          icon: "fa-brands fa-linkedin",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "linkedin" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.facebook),
          icon: "fa-brands fa-facebook",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "facebook" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.article),
          icon: "fa-solid fa-newspaper",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "article" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.youtube),
          icon: "fa-brands fa-youtube",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "youtube" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.tikTok),
          icon: "fa-brands fa-tiktok",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "tikTok" }, OnBoardingSteps.pricingList)
        },
        {
          headlineTitle: intl.formatMessage(howDidYouHearAboutUsMessages.other),
          icon: "fa-solid fa-clipboard-question",
          onClick: () =>
            onClickNextstep({ how_did_you_hear_v2: "other" }, OnBoardingSteps.pricingList)
        }
      ]
    },
    pricingList: {
      headlineTitle: intl.formatMessage(pricingMessages.headLineTitle),
      currentStep: userType === "work" ? 7 : 3
    }
  };

  const items = createStepsList();

  return (
    <H1_FlexColumn justify="center" height="100%">
      <H1_FlexRow padding="20px 0 0 0 " justify="space-between" width="100%" flex="0 0 50px">
        <StyledHourOneLogo height={38} />
        <H1_FlexRow>
          <ConditionalRender condition={currentStep !== OnBoardingSteps.userType}>
            <StyledSteps current={routes[currentStep].currentStep} items={items} />
          </ConditionalRender>
        </H1_FlexRow>
        <H1_FlexRow flex="0 0 136px" />
      </H1_FlexRow>
      <H1_FlexColumn justify="center" align="center" flex="1 1 auto" gap="45px" padding="0 0 58px">
        <ConditionalRender condition={!!routes[currentStep]}>
          <H1_FlexColumn justify="center" align="center" gap="10px">
            <H1_FlexRow alignSelf="center">
              <H1_TextHeadline>
                {(routes[currentStep] as Record<string, any>).headlineTitle}
              </H1_TextHeadline>
            </H1_FlexRow>
            <ConditionalRender condition={!!routes[currentStep].subTitle}>
              <H1_FlexRow alignSelf="center" width="516px">
                <H1_TextSmall textAlign="center" whiteSpace="normal">
                  {(routes[currentStep] as Record<string, any>).subTitle}
                </H1_TextSmall>
              </H1_FlexRow>
            </ConditionalRender>
          </H1_FlexColumn>
          <ConditionalRender condition={!!routes[currentStep].buttonList}>
            <H1_FlexRow
              width={(routes[currentStep].buttonList?.length || 0) > 6 ? "887px" : "687px"}
              justify="center"
            >
              <H1_FlexRow
                wrap="wrap"
                width="100%"
                gap="29px"
                justify={
                  (routes[currentStep].buttonList?.length || 0) > 4 && !stepHowDidYouHereAboutUs
                    ? "flex-start"
                    : "center"
                }
              >
                {routes[currentStep]?.buttonList?.map(
                  (buttonData: {
                    icon: string;
                    headlineTitle: string;
                    subTitle?: string;
                    onClick: () => void;
                  }) => {
                    return (
                      <BoxFlexColumn
                        gap={buttonData.subTitle || stepHowDidYouHereAboutUs ? "17px" : "0"}
                        $isRow={stepHowDidYouHereAboutUs}
                        key={buttonData.headlineTitle}
                        height={stepHowDidYouHereAboutUs ? "68px" : "200px"}
                        width={stepHowDidYouHereAboutUs ? "273px" : "200px"}
                        onClick={buttonData.onClick}
                        padding="20px"
                        // selected
                      >
                        <H1_FlexColumn
                          flex={stepHowDidYouHereAboutUs ? "0 0 auto" : "1 1 auto"}
                          justify="center"
                          align="center"
                        >
                          <H1_Icon
                            icon={buttonData.icon}
                            size="33px"
                            color={theme.blue2}
                            isCursorPointer
                          />
                        </H1_FlexColumn>
                        <H1_FlexColumn
                          flex={!buttonData.subTitle ? "0 0 34px" : "0 0 70px"}
                          gap="7px"
                          align={stepHowDidYouHereAboutUs ? "flex-start" : "center"}
                        >
                          <H1_TextMiddle
                            textAlign="center"
                            fontWeight={buttonData.subTitle ? 700 : 400}
                          >
                            {buttonData.headlineTitle}
                          </H1_TextMiddle>
                          <ConditionalRender condition={!!buttonData.subTitle}>
                            <H1_TextXs whiteSpace="normal" textAlign="center">
                              {buttonData.subTitle}
                            </H1_TextXs>
                          </ConditionalRender>
                        </H1_FlexColumn>
                      </BoxFlexColumn>
                    );
                  }
                )}
              </H1_FlexRow>
            </H1_FlexRow>
          </ConditionalRender>

          <ConditionalRender condition={!!routes[currentStep].inputList}>
            <H1_FlexColumn gap="20px" width="250px">
              {routes[currentStep]?.inputList?.map((inputData, index) => {
                return (
                  <H1_FlexColumn key={index} gap="7px">
                    <H1_TextSmall>{inputData.inputTitle}</H1_TextSmall>
                    <H1_FlexColumn>
                      <Input
                        size="sm"
                        value={inputData.value}
                        variant="bordered"
                        placeholder={inputData.defaultInput}
                        onValueChange={(text) => inputData.onClick(text)}
                        className="max-w-xs"
                      />
                    </H1_FlexColumn>
                  </H1_FlexColumn>
                );
              })}
              <H1_FlexColumn gap="7px">
                <H1_TextSmall>{intl.formatMessage(setWorkspaceMessages.phoneNumber)}</H1_TextSmall>
                <H1_FlexRow gap="5px">
                  <CountrySelector
                    selectedCountry={phoneInput.country.iso2}
                    onSelect={(country) => phoneInput.setCountry(country.iso2)}
                    renderButtonWrapper={({ children, rootProps }) => (
                      <Button radius="sm" size="lg" isIconOnly variant="bordered" {...rootProps}>
                        {children}
                      </Button>
                    )}
                  />
                  <Input
                    size="sm"
                    placeholder="Phone number"
                    value={phoneInput.inputValue}
                    onChange={phoneInput.handlePhoneValueChange}
                    ref={inputRef}
                    name="phone"
                    autoComplete="tel"
                    color={isValid ? "default" : "danger"}
                  />
                </H1_FlexRow>
              </H1_FlexColumn>
            </H1_FlexColumn>
          </ConditionalRender>
          <ConditionalRender condition={currentStep === OnBoardingSteps.pricingList}>
            <PricingContent onFinish={onFinishPricing} onBoardingPricingMode />
          </ConditionalRender>
          <ConditionalRender condition={currentStep === OnBoardingSteps.inviteTeamMembers}>
            <EmailAddressesSection onClose={oncloseInviteMembers} numberOfInitialTeamMembers={3} />
          </ConditionalRender>

          <ConditionalRender condition={!!routes[currentStep].continueButton}>
            <Button
              color="primary"
              onClick={routes[currentStep].continueButton}
              isDisabled={currentStep === OnBoardingSteps.setWorkspace && !isValid}
            >
              {routes[currentStep].continueButtonTitle}
            </Button>
          </ConditionalRender>
        </ConditionalRender>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default OnBoardingV2Page;
