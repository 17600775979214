/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash-es";
import asyncThunks from "app/store/thunks/drafts.thunk";
import { fetchingStatus } from "app/utils/helpers";
import {
  Draft,
  DraftApplySettings,
  DraftHistory,
  FetchStatus,
  Pronunciation,
  SearchResults,
  VideoWizardWorkflow,
  VoiceSpeechConfig,
  WorkflowResult,
  WorkflowSubmission
} from "app/types";
import { draftsAdapter } from "app/store/adapters/adapters";

interface DraftsSliceState {
  currentDraft: Draft;
  duplicatedDraftResult?: Draft;
  draftsStatus: FetchStatus;
  deleteBulkDraftsStatus: FetchStatus;
  apiExampleStatus: FetchStatus;
  createDraftStatus: FetchStatus;
  bulkSubmissionsStatus: FetchStatus;
  csvExportStatus: FetchStatus;
  createFirstDraftStatus: FetchStatus;
  createWorkflowSubmissionStatus: FetchStatus;
  refreshDraftAndScenesStatus: FetchStatus;
  createRealsMeDraftStatus: FetchStatus;
  saveDraftAsRecipeStatus: FetchStatus;
  draftRecipeStatus: FetchStatus;
  clearScenesStatus: FetchStatus;
  revertDraftStatus: FetchStatus;
  patchRealsMeToEditorDraftStatus: FetchStatus;
  deleteDraftStatus: FetchStatus;
  draftVersionStatus: FetchStatus;
  draftHistoryStatus: FetchStatus;
  duplicationStatus: FetchStatus;
  topicToDraftStatus: FetchStatus;
  segmentToSceneStatus: FetchStatus;
  workspaceSearchStatus: FetchStatus;
  languageDetectionStatus: FetchStatus;
  meetingStatus: FetchStatus;
  draftGlobalCharacterStatus: FetchStatus;
  draftTranslationStatus: FetchStatus;
  workflowCategoriesStatus: FetchStatus;
  createWorkflowCategoriesStatus: FetchStatus;
  workflowSubmissionStatus: FetchStatus;
  runSubmissionStatus: FetchStatus;
  workflowLiveStatus: FetchStatus;
  currentDraftVersion?: DraftHistory;
  draftHistory: DraftHistory[];
  workflowCategories?: VideoWizardWorkflow;
  workflowLiveMode: boolean;
  draftsHistoryVersions: string[];
  apiExampleResult?: { curl: string; json_request: string };
  patchRealsMeToEditorDraftId?: string;
  deletedDraftId?: string;
  duplicatedDraftId?: string;
  topicToDraftResultId?: string;
  topicToDraftOrderId?: string;
  segmentToScenesOrderId?: string;
  draftTranslationOrderId?: string;
  originDraftTranslationOrderId?: string;
  draftTranslationStatusCode?: string;
  summaryId?: string;
  applySettings: DraftApplySettings;
  workspaceSearchResults: Partial<SearchResults>;
  createDraftGeneratorOrderId?: string;
  draftGeneratorId?: string;
  summaryOrderId?: string;
  summaryDraftId?: string;
  runSubmissionResultDraftId?: string;
  createDraftGeneratorStatus: FetchStatus;
  summaryStatus: FetchStatus;
  addVariableStatus: FetchStatus;
  publishWorkflowStatus: FetchStatus;
  summaryLeaveStatus: FetchStatus;
  getWorkflowStatus: FetchStatus;
  recoverStatus: FetchStatus;
  tempWorkflowDraft?: Draft;
  workflowResult?: WorkflowResult;
  workflowSubmissions: WorkflowSubmission[];
  workflowPlayingVideoId?: string;
  selectedDraftIds: string[];
}

export const draftsSlice = createSlice({
  name: "Drafts",
  initialState: draftsAdapter.getInitialState<DraftsSliceState>({
    currentDraft: {},
    duplicatedDraftResult: undefined,
    createWorkflowSubmissionStatus: fetchingStatus.idle as FetchStatus,
    refreshDraftAndScenesStatus: fetchingStatus.idle as FetchStatus,
    getWorkflowStatus: fetchingStatus.idle as FetchStatus,
    draftsStatus: fetchingStatus.idle as FetchStatus,
    deleteBulkDraftsStatus: fetchingStatus.idle as FetchStatus,
    createDraftStatus: fetchingStatus.idle as FetchStatus,
    createFirstDraftStatus: fetchingStatus.idle as FetchStatus,
    draftRecipeStatus: fetchingStatus.idle as FetchStatus,
    createRealsMeDraftStatus: fetchingStatus.idle as FetchStatus,
    summaryStatus: fetchingStatus.idle as FetchStatus,
    saveDraftAsRecipeStatus: fetchingStatus.idle as FetchStatus,
    topicToDraftStatus: fetchingStatus.idle as FetchStatus,
    workflowCategoriesStatus: fetchingStatus.idle as FetchStatus,
    createWorkflowCategoriesStatus: fetchingStatus.idle as FetchStatus,
    clearScenesStatus: fetchingStatus.idle as FetchStatus,
    patchRealsMeToEditorDraftStatus: fetchingStatus.idle as FetchStatus,
    deleteDraftStatus: fetchingStatus.idle as FetchStatus,
    draftVersionStatus: fetchingStatus.idle as FetchStatus,
    bulkSubmissionsStatus: fetchingStatus.idle as FetchStatus,
    csvExportStatus: fetchingStatus.idle as FetchStatus,
    draftHistoryStatus: fetchingStatus.idle as FetchStatus,
    duplicationStatus: fetchingStatus.idle as FetchStatus,
    revertDraftStatus: fetchingStatus.idle as FetchStatus,
    apiExampleStatus: fetchingStatus.idle as FetchStatus,
    segmentToSceneStatus: fetchingStatus.idle as FetchStatus,
    languageDetectionStatus: fetchingStatus.idle as FetchStatus,
    draftGlobalCharacterStatus: fetchingStatus.idle as FetchStatus,
    workspaceSearchStatus: fetchingStatus.idle as FetchStatus,
    draftTranslationStatus: fetchingStatus.idle as FetchStatus,
    meetingStatus: fetchingStatus.idle as FetchStatus,
    workflowLiveStatus: fetchingStatus.idle as FetchStatus,
    workflowSubmissionStatus: fetchingStatus.idle as FetchStatus,
    recoverStatus: fetchingStatus.idle as FetchStatus,
    workflowLiveMode: false,
    publishWorkflowStatus: fetchingStatus.idle as FetchStatus,
    runSubmissionStatus: fetchingStatus.idle as FetchStatus,
    summaryLeaveStatus: fetchingStatus.idle as FetchStatus,
    runSubmissionResultDraftId: undefined,
    currentDraftVersion: undefined,
    draftHistory: [],
    draftsHistoryVersions: [],
    topicToDraftOrderId: undefined,
    segmentToScenesOrderId: undefined,
    draftTranslationOrderId: undefined,
    originDraftTranslationOrderId: undefined,
    draftTranslationStatusCode: undefined,
    applySettings: DraftApplySettings.ALL_SCENES,
    workspaceSearchResults: {},
    createDraftGeneratorOrderId: undefined,
    draftGeneratorId: undefined,
    createDraftGeneratorStatus: fetchingStatus.idle as FetchStatus,
    addVariableStatus: fetchingStatus.idle as FetchStatus,
    workflowSubmissions: [],
    selectedDraftIds: []
  }),
  reducers: {
    saveCurrentDraftToTemp(state) {
      state.tempWorkflowDraft = state.currentDraft;
      return state;
    },
    restoreTempDraft(state) {
      if (state.tempWorkflowDraft) {
        state.currentDraft = state.tempWorkflowDraft;
        state.workflowLiveMode = false;
        state.workflowPlayingVideoId = undefined;
      }
      return state;
    },
    updateCurrentDraftPronunciations(state, action: PayloadAction<Pronunciation[]>) {
      if (state.currentDraft) {
        state.currentDraft.pronunciations = action.payload;
      }

      return state;
    },
    updateCurrentDraftApplySettings(state, action: PayloadAction<DraftApplySettings>) {
      state.applySettings = action.payload;
      return state;
    },
    setAllDrafts(state, action: PayloadAction<Draft[]>) {
      draftsAdapter.setAll(state, action.payload);
      return state;
    },
    setCreateDraftStatusToIdle(state) {
      state.createDraftStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setSummaryLeaveStatusToIdle(state) {
      state.summaryLeaveStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setCreateWorkflowSubmissionStatusToIdle(state) {
      state.createWorkflowSubmissionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setWorkflowSubmissionStatusToIdle(state) {
      state.workflowSubmissionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setRunSubmissionStatusToIdle(state) {
      state.runSubmissionResultDraftId = undefined;
      state.runSubmissionStatus = fetchingStatus.idle;
      return state;
    },
    setPublishWorkflowStatusToIdle(state) {
      state.publishWorkflowStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setCreateDraftGeneratorStatusToIdle(state) {
      state.createDraftGeneratorStatus = fetchingStatus.idle as FetchStatus;
      state.createDraftGeneratorOrderId = undefined;
      return state;
    },
    setSaveDraftAsRecipeStatusToIdle(state) {
      state.saveDraftAsRecipeStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    clearWorkspaceSearchResults(state) {
      state.workspaceSearchResults = {};
      return state;
    },
    updateCreateRealsMeDraftsStatusToIdle(state) {
      state.createRealsMeDraftStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateCreateWorkflowCategoriesToIdle(state) {
      state.createWorkflowCategoriesStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateWorkflowCategoriesToIdle(state) {
      state.workflowCategoriesStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateClearScenesStatusToIdle(state) {
      state.clearScenesStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateDraftsStatusToIdle(state) {
      state.draftsStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateDeleteDraftStatusToIdle(state) {
      state.deleteDraftStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updatePatchRealsMeToEditorDraftStatus(state) {
      state.patchRealsMeToEditorDraftStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateDuplicateDraftStatusToIdle(state) {
      state.duplicationStatus = fetchingStatus.idle as FetchStatus;
      state.duplicatedDraftResult = undefined;
      return state;
    },
    updateTopicToDraftStatusToIdle(state) {
      state.topicToDraftStatus = fetchingStatus.idle as FetchStatus;
      state.topicToDraftResultId = undefined;
      state.topicToDraftOrderId = undefined;
      return state;
    },
    updateSegmentToScenesStatusToIdle(state) {
      state.segmentToSceneStatus = fetchingStatus.idle as FetchStatus;
      state.segmentToScenesOrderId = undefined;
      return state;
    },
    updateSegmentToScenesStatusToFailure(state) {
      state.segmentToSceneStatus = fetchingStatus.failed as FetchStatus;
      state.segmentToScenesOrderId = undefined;
      return state;
    },
    updateRevertDraftStatusToIdle(state) {
      state.revertDraftStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateDraftLanguageDetectionStatusToIdle(state) {
      state.languageDetectionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateDraftTranslationStatusTo(state, action: PayloadAction<FetchStatus>) {
      state.draftTranslationStatus = action.payload;
      return state;
    },
    updateDraftGlobalCharacterStatusToIdle(state) {
      state.draftGlobalCharacterStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    cleanDraftTranslationId(state) {
      state.draftTranslationOrderId = undefined;
      state.originDraftTranslationOrderId = undefined;
      return state;
    },
    cleanDraft(state) {
      state.currentDraft = {};
      state.tempWorkflowDraft = undefined;
      state.workflowLiveMode = false;
      state.workflowSubmissions = [];
      state.workflowLiveStatus = fetchingStatus.idle as FetchStatus;
      state.workflowSubmissionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateCurrentDraft(state, action) {
      state.currentDraft = action.payload;
      state.entities[action.payload.id] = action.payload;
      return state;
    },
    resetDraftStore(state) {
      state.currentDraft = {};
      state.deleteDraftStatus = fetchingStatus.idle as FetchStatus;
      state.draftsStatus = fetchingStatus.idle as FetchStatus;
      draftsAdapter.removeAll(state);
      return state;
    },
    cleanDraftHistory(state) {
      state.currentDraftVersion = undefined;
      state.draftVersionStatus = fetchingStatus.idle as FetchStatus;
      state.draftHistory = [];
      state.draftHistoryStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateCurrentDraftVoiceAdjustment(state, action: PayloadAction<VoiceSpeechConfig[]>) {
      if (state.currentDraft?.attributes?.voice?.voice) {
        state.currentDraft.attributes.voice.voice.voice_adjustments = action.payload;
      }
      return state;
    },
    addDraftToList(state, action: PayloadAction<Draft>) {
      draftsAdapter.addOne(state, action.payload);
      return state;
    },
    addDraftFirst: (state, action) => {
      state.ids.unshift(action.payload.id);
      state.entities[action.payload.id] = action.payload;
      return state;
    },
    upsertDraft: (state, action) => {
      draftsAdapter.upsertOne(state, action.payload);
      return state;
    },
    removeDraftFromList(state, action: PayloadAction<string>) {
      draftsAdapter.removeOne(state, action.payload);
      return state;
    },
    setApiExampleStatusToIdle(state) {
      state.apiExampleStatus = fetchingStatus.idle;
      return state;
    },
    resetPatchRealsMeToEditor(state) {
      state.patchRealsMeToEditorDraftStatus = fetchingStatus.idle;
      state.patchRealsMeToEditorDraftId = undefined;
      return state;
    },
    setRefreshDraftAndScenesStatusToIdle(state) {
      state.refreshDraftAndScenesStatus = fetchingStatus.idle;
      return state;
    },
    setDraftCharacterAndVoice(
      state,
      action: PayloadAction<{ voiceId: string; characterId: string; onlySelectedScene?: boolean }>
    ) {
      const { voiceId, characterId } = action.payload;
      state.currentDraft!.attributes!.voice!.voice!.voice_id = voiceId;
      state.currentDraft!.attributes!.character!.character!.character_id = characterId;

      return state;
    },
    setDraftGenerator(state, action) {
      const { draftId, orderId, status } = action.payload;
      if (orderId === state.createDraftGeneratorOrderId) {
        if (status === "failed") {
          state.createDraftGeneratorStatus = fetchingStatus.failed;
        } else if (status === "ready") {
          state.createDraftGeneratorStatus = fetchingStatus.succeeded;
          state.draftGeneratorId = draftId;
        }
      }

      return state;
    },
    setSummaryToIdle(state) {
      state.summaryOrderId = undefined;
      state.summaryDraftId = undefined;
      state.summaryStatus = fetchingStatus.idle;
      return state;
    },
    setSummary(state, action) {
      const { draftId, orderId, status } = action.payload;
      if (orderId === state.summaryOrderId) {
        if (status === "failed") {
          state.summaryStatus = fetchingStatus.failed;
        } else if (status === "ready") {
          state.summaryStatus = fetchingStatus.succeeded;
          state.summaryDraftId = draftId;
        }
      }

      return state;
    },
    setWorkflowPlayingVideoId(state, action) {
      state.workflowPlayingVideoId = action.payload;
      return state;
    },
    setSelectedDraftIds(state, action) {
      state.selectedDraftIds = action.payload;
      return state;
    },
    cleanSelectedDraftIds(state) {
      state.selectedDraftIds = [];
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getDraftsRequest.fulfilled, (state, action) => {
      draftsAdapter.setAll(state, action.payload);
      state.draftsStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getDraftsRequest.pending, (state) => {
      state.draftsStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getDraftsRequest.rejected, (state) => {
      state.draftsStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createDraftGenerator.pending, (state, action) => {
      state.createDraftGeneratorStatus = fetchingStatus.loading as FetchStatus;
      state.createDraftGeneratorOrderId = action.meta.arg.order_id;
    });
    builder.addCase(asyncThunks.createDraftGenerator.rejected, (state) => {
      state.createDraftGeneratorStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.startWorkingOnDraft.fulfilled, (state, action) => {
      state.currentDraft = action.payload;
      state.draftsStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.startWorkingOnDraft.pending, (state) => {
      state.draftsStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.startWorkingOnDraft.rejected, (state) => {
      state.draftsStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.refreshDraftAndScenes.fulfilled, (state, action) => {
      state.currentDraft = action.payload;
      state.refreshDraftAndScenesStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.refreshDraftAndScenes.pending, (state) => {
      state.refreshDraftAndScenesStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.refreshDraftAndScenes.rejected, (state) => {
      state.refreshDraftAndScenesStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.recoverDraftRequest.fulfilled, (state) => {
      state.recoverStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.recoverDraftRequest.pending, (state) => {
      const draftIds = state.selectedDraftIds;
      draftsAdapter.removeMany(state, draftIds);
      state.recoverStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.recoverDraftRequest.rejected, (state) => {
      state.recoverStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.patchDraftRequest.pending, (state, action) => {
      const { operations, draftId } = action.meta.arg;
      // eslint-disable-next-line no-restricted-syntax
      for (const operation of operations) {
        if (state.currentDraft && state.currentDraft.id === draftId) {
          set(state.currentDraft, operation.path, operation.value);
          state.currentDraft.updated_at = new Date().toISOString();
        }
        if (state.entities && state.entities[draftId]) {
          set(state.entities[draftId] as Draft, operation.path, operation.value);
          (state.entities[draftId] as Draft).updated_at = new Date().toISOString();
        }
      }
    });

    builder.addCase(asyncThunks.patchRealsMeToEditorDraftRequest.pending, (state, action) => {
      state.patchRealsMeToEditorDraftStatus = fetchingStatus.loading as FetchStatus;
      state.patchRealsMeToEditorDraftId = action.meta.arg.draftId;
    });
    builder.addCase(asyncThunks.patchRealsMeToEditorDraftRequest.rejected, (state) => {
      state.patchRealsMeToEditorDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.patchRealsMeToEditorDraftRequest.fulfilled, (state) => {
      state.patchRealsMeToEditorDraftStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.createDraftRequest.pending, (state) => {
      state.createDraftStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createDraftRequest.rejected, (state) => {
      state.createDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createDraftRequest.fulfilled, (state, action) => {
      state.createDraftStatus = fetchingStatus.succeeded as FetchStatus;
      state.currentDraft = action.payload;
      draftsAdapter.addOne(state, action.payload);
    });

    builder.addCase(asyncThunks.createFirstDraftRequest.pending, (state) => {
      state.createFirstDraftStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createFirstDraftRequest.rejected, (state) => {
      state.createFirstDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createFirstDraftRequest.fulfilled, (state, action) => {
      state.createFirstDraftStatus = fetchingStatus.succeeded as FetchStatus;
      draftsAdapter.addOne(state, action.payload);
    });

    builder.addCase(asyncThunks.duplicateDraftsRequest.pending, (state, action) => {
      state.duplicationStatus = fetchingStatus.loading as FetchStatus;
      state.duplicatedDraftId = action.meta.arg.draftId;
    });
    builder.addCase(asyncThunks.duplicateDraftsRequest.fulfilled, (state, action) => {
      state.duplicationStatus = fetchingStatus.succeeded as FetchStatus;
      draftsAdapter.addOne(state, action.payload);
      state.duplicatedDraftResult = action.payload;
    });
    builder.addCase(asyncThunks.duplicateDraftsRequest.rejected, (state) => {
      state.duplicationStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.deleteDraftRequest.pending, (state, action) => {
      state.deleteDraftStatus = fetchingStatus.loading as FetchStatus;
      state.deletedDraftId = action.meta.arg;
      draftsAdapter.removeOne(state, action.meta.arg);
    });
    builder.addCase(asyncThunks.deleteDraftRequest.rejected, (state) => {
      state.deleteDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteDraftRequest.fulfilled, (state) => {
      state.deleteDraftStatus = fetchingStatus.succeeded as FetchStatus;
      // state.currentDraft = action.payload;
    });

    builder.addCase(asyncThunks.getDraftHistoryRequest.pending, (state) => {
      state.draftHistoryStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getDraftHistoryRequest.rejected, (state) => {
      state.draftHistoryStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getDraftHistoryRequest.fulfilled, (state, action) => {
      state.draftHistoryStatus = fetchingStatus.succeeded as FetchStatus;
      state.draftsHistoryVersions = action.payload.map(({ version }: Draft) => version);
      state.draftHistory = action.payload;
    });

    builder.addCase(asyncThunks.getDraftVersionRequest.pending, (state) => {
      state.draftVersionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getDraftVersionRequest.rejected, (state) => {
      state.draftVersionStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getDraftVersionRequest.fulfilled, (state, action) => {
      state.draftVersionStatus = fetchingStatus.succeeded as FetchStatus;
      state.currentDraftVersion = action.payload[0] as DraftHistory;
    });

    builder.addCase(asyncThunks.createApiVideoExampleRequest.pending, (state) => {
      state.apiExampleStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createApiVideoExampleRequest.rejected, (state) => {
      state.apiExampleStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createApiVideoExampleRequest.fulfilled, (state, action) => {
      state.apiExampleStatus = fetchingStatus.succeeded as FetchStatus;
      state.apiExampleResult = action.payload;
    });

    builder.addCase(asyncThunks.createNewRealsMeDraftRequest.pending, (state) => {
      state.createRealsMeDraftStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createNewRealsMeDraftRequest.rejected, (state) => {
      state.createRealsMeDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createNewRealsMeDraftRequest.fulfilled, (state) => {
      state.createRealsMeDraftStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.saveAsRecipeRequest.rejected, (state) => {
      state.saveDraftAsRecipeStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.saveAsRecipeRequest.fulfilled, (state) => {
      state.saveDraftAsRecipeStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.saveAsRecipeRequest.pending, (state) => {
      state.saveDraftAsRecipeStatus = fetchingStatus.loading as FetchStatus;
    });

    builder.addCase(asyncThunks.changeCurrentDraftRecipeRequest.pending, (state) => {
      state.draftRecipeStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.changeCurrentDraftRecipeRequest.rejected, (state) => {
      state.draftRecipeStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.changeCurrentDraftRecipeRequest.fulfilled, (state, action) => {
      state.draftRecipeStatus = fetchingStatus.succeeded as FetchStatus;
      state.currentDraft = action.payload;
      draftsAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(asyncThunks.topicToDraftRequest.pending, (state, action) => {
      state.topicToDraftOrderId = action.meta.arg.orderId;
      state.topicToDraftStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.topicToDraftRequest.rejected, (state) => {
      state.topicToDraftStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.handleVideoWizardResult.fulfilled, (state, action) => {
      if (action.payload) {
        state.topicToDraftStatus = action.payload.status;
        state.topicToDraftResultId = action.payload.draft_id;
      }
    });

    builder.addCase(asyncThunks.handleSegmentToScenesResults.fulfilled, (state, action) => {
      if (action.payload) {
        state.segmentToSceneStatus = fetchingStatus.succeeded as FetchStatus;
      }
    });
    builder.addCase(asyncThunks.handleSegmentToScenesResults.rejected, (state, action) => {
      if (action.payload) {
        state.segmentToSceneStatus = fetchingStatus.failed as FetchStatus;
      }
    });

    builder.addCase(asyncThunks.segmentToScenesRequest.pending, (state, action) => {
      state.segmentToScenesOrderId = action.payload;
      state.segmentToSceneStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(
      asyncThunks.segmentToScenesRequest.fulfilled,
      (state, action: PayloadAction<{ orderId: string }>) => {
        state.segmentToScenesOrderId = action.payload.orderId;
      }
    );
    builder.addCase(asyncThunks.segmentToScenesRequest.rejected, (state) => {
      state.segmentToSceneStatus = fetchingStatus.failed as FetchStatus;
      state.segmentToScenesOrderId = undefined;
    });

    builder.addCase(asyncThunks.clearScenesRequest.pending, (state) => {
      state.clearScenesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.clearScenesRequest.rejected, (state) => {
      state.clearScenesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.clearScenesRequest.fulfilled, (state) => {
      state.clearScenesStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.revertDraftRequest.pending, (state) => {
      state.revertDraftStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.revertDraftRequest.rejected, (state) => {
      state.revertDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.revertDraftRequest.fulfilled, (state, action) => {
      state.currentDraft = action.payload;
      state.revertDraftStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.revertWorkflowRequest.pending, (state) => {
      state.revertDraftStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.revertWorkflowRequest.rejected, (state) => {
      state.revertDraftStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.revertWorkflowRequest.fulfilled, (state, action) => {
      state.currentDraft = action.payload;
      state.revertDraftStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.detectDraftLanguageRequest.pending, (state) => {
      state.languageDetectionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.detectDraftLanguageRequest.rejected, (state) => {
      state.languageDetectionStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.detectDraftLanguageRequest.fulfilled, (state, action) => {
      state.currentDraft.language_detected = action.payload;
      state.languageDetectionStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.workspaceSearchRequest.pending, (state) => {
      state.workspaceSearchStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.workspaceSearchRequest.rejected, (state) => {
      state.workspaceSearchStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.workspaceSearchRequest.fulfilled, (state, action) => {
      state.workspaceSearchResults = action.payload;
      state.workspaceSearchStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.updateDraftGlobalCharacterRequest.pending, (state, action) => {
      state.currentDraft.global_character = action.meta.arg.globalCharacter;
      state.draftGlobalCharacterStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.updateDraftGlobalCharacterRequest.rejected, (state) => {
      state.draftGlobalCharacterStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateDraftGlobalCharacterRequest.fulfilled, (state) => {
      state.draftGlobalCharacterStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.translateDraftRequest.pending, (state) => {
      state.draftTranslationStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(
      asyncThunks.translateDraftRequest.fulfilled,
      (state, action: PayloadAction<{ callbackId: string; originDraft: string }>) => {
        state.draftTranslationOrderId = action.payload.callbackId;
        state.originDraftTranslationOrderId = action.payload.originDraft;
      }
    );
    builder.addCase(asyncThunks.translateDraftRequest.rejected, (state, action) => {
      state.draftTranslationStatus = fetchingStatus.failed as FetchStatus;
      state.draftTranslationOrderId = undefined;
      state.originDraftTranslationOrderId = undefined;
      // @ts-ignore: status does not exists on error {}
      state.draftTranslationStatusCode = action.error.status;
    });

    builder.addCase(
      asyncThunks.handleDraftTranslationResults.fulfilled,
      (
        state,
        action: PayloadAction<
          undefined | { draftId?: string; orderId: string; status: FetchStatus }
        >
      ) => {
        if (action.payload?.status) {
          state.draftTranslationStatus = action.payload?.status as FetchStatus;
        }
      }
    );

    builder.addCase(asyncThunks.getWorkflowCategoriesRequest.pending, (state) => {
      state.workflowCategoriesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getWorkflowCategoriesRequest.rejected, (state) => {
      state.workflowCategoriesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getWorkflowCategoriesRequest.fulfilled, (state, action) => {
      state.workflowCategoriesStatus = fetchingStatus.succeeded as FetchStatus;
      state.workflowCategories = action.payload;
    });
    builder.addCase(
      asyncThunks.createVideoFromWorkflowCategoriesRequest.pending,
      (state, action) => {
        state.createWorkflowCategoriesStatus = fetchingStatus.loading as FetchStatus;
        state.topicToDraftOrderId = action.meta.arg.orderId;
        state.topicToDraftStatus = fetchingStatus.loading as FetchStatus;
      }
    );
    builder.addCase(asyncThunks.createVideoFromWorkflowCategoriesRequest.rejected, (state) => {
      state.createWorkflowCategoriesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createVideoFromWorkflowCategoriesRequest.fulfilled, (state) => {
      state.createWorkflowCategoriesStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getMeetingsDraftsRequest.pending, (state) => {
      state.meetingStatus = fetchingStatus.loading as FetchStatus;
      state.currentDraft = {};
    });
    builder.addCase(asyncThunks.getMeetingsDraftsRequest.fulfilled, (state, action) => {
      state.meetingStatus = fetchingStatus.succeeded as FetchStatus;
      if (action.payload) {
        state.currentDraft = action.payload;
      }
    });
    builder.addCase(asyncThunks.getMeetingsDraftsRequest.rejected, (state) => {
      state.meetingStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.summaryRequest.pending, (state, action) => {
      state.summaryOrderId = action.meta.arg.orderId;
      state.summaryStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.summaryRequest.fulfilled, (state, action) => {
      // status changed to succeeded by pusher
      state.summaryId = action.payload.id;
    });
    builder.addCase(asyncThunks.summaryRequest.rejected, (state) => {
      state.summaryOrderId = undefined;
      state.summaryStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.addVariableRequest.pending, (state) => {
      state.addVariableStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.addVariableRequest.rejected, (state) => {
      state.addVariableStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.addVariableRequest.fulfilled, (state, action) => {
      state.addVariableStatus = fetchingStatus.succeeded;
      if (state.currentDraft.variables) {
        state.currentDraft.variables = [...state.currentDraft.variables, action.payload];
      } else {
        state.currentDraft.variables = [action.payload];
      }
    });
    builder.addCase(asyncThunks.updateVariableRequest.pending, (state, action) => {
      const { variableId, variable } = action.meta.arg;
      if (state.currentDraft && state.currentDraft.variables) {
        const index = state.currentDraft.variables.findIndex(
          (variable) => variable.id === variableId
        );
        if (index !== -1) {
          state.currentDraft.variables[index] = {
            ...state.currentDraft.variables[index],
            ...variable
          };
        }
      }
    });
    builder.addCase(asyncThunks.updateWorkflowFormRequest.pending, (state, action) => {
      const { form } = action.meta.arg;
      if (state.currentDraft.workflow) {
        state.currentDraft.workflow.form = { ...state.currentDraft.workflow.form, ...form };
      }
    });
    builder.addCase(asyncThunks.publishWorkflowRequest.pending, (state) => {
      state.publishWorkflowStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.publishWorkflowRequest.fulfilled, (state) => {
      state.publishWorkflowStatus = fetchingStatus.succeeded;
      if (state.currentDraft?.workflow) {
        state.currentDraft.workflow.published = true;
      }
    });
    builder.addCase(asyncThunks.publishWorkflowRequest.rejected, (state) => {
      state.publishWorkflowStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.getLiveWorkflowRequest.pending, (state) => {
      state.workflowLiveStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getLiveWorkflowRequest.rejected, (state) => {
      state.workflowLiveStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.getLiveWorkflowRequest.fulfilled, (state, action) => {
      state.workflowLiveStatus = fetchingStatus.succeeded;
      state.workflowLiveMode = true;
      state.workflowPlayingVideoId = undefined;
      state.currentDraft = action.payload;
    });

    builder.addCase(asyncThunks.getWorkflowRequest.pending, (state) => {
      state.getWorkflowStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getWorkflowRequest.rejected, (state) => {
      state.getWorkflowStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.getWorkflowRequest.fulfilled, (state, action) => {
      state.getWorkflowStatus = fetchingStatus.succeeded;
      state.workflowResult = action.payload;
    });
    //

    builder.addCase(asyncThunks.createWorkflowSubmissionRequest.pending, (state) => {
      state.createWorkflowSubmissionStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.createWorkflowSubmissionRequest.rejected, (state) => {
      state.createWorkflowSubmissionStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.createWorkflowSubmissionRequest.fulfilled, (state, action) => {
      state.createWorkflowSubmissionStatus = fetchingStatus.succeeded;
      state.workflowSubmissions.unshift(action.payload);
      if (action.payload?.video?.id) {
        state.workflowPlayingVideoId = action.payload.video.id;
      }
    });
    builder.addCase(asyncThunks.getWorkflowSubmissionsRequest.pending, (state) => {
      state.workflowSubmissionStatus = fetchingStatus.loading;
      // state.work
    });
    builder.addCase(asyncThunks.getWorkflowSubmissionsRequest.rejected, (state) => {
      state.workflowSubmissionStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.getWorkflowSubmissionsRequest.fulfilled, (state, action) => {
      state.workflowSubmissions = action.payload;
      state.workflowSubmissionStatus = fetchingStatus.succeeded;
    });

    builder.addCase(asyncThunks.runSubmissionRequest.pending, (state) => {
      state.runSubmissionStatus = fetchingStatus.loading;
      // state.work
    });
    builder.addCase(asyncThunks.runSubmissionRequest.rejected, (state) => {
      state.runSubmissionStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.runSubmissionRequest.fulfilled, (state, action) => {
      if (action.payload.draft) {
        state.runSubmissionResultDraftId = action.payload.draft.id;
      }
      state.runSubmissionStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.summaryLeaveRequest.pending, (state) => {
      state.summaryLeaveStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.summaryLeaveRequest.fulfilled, (state) => {
      state.summaryLeaveStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.summaryLeaveRequest.rejected, (state) => {
      state.summaryLeaveStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.bulkSubmissionsRequest.pending, (state) => {
      state.bulkSubmissionsStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.bulkSubmissionsRequest.fulfilled, (state) => {
      state.bulkSubmissionsStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.bulkSubmissionsRequest.rejected, (state) => {
      state.bulkSubmissionsStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.connectDraftToDatastoreRequest.fulfilled, (state, action) => {
      const variables = action.payload.variables_created;
      if (state.currentDraft.variables) {
        state.currentDraft.variables = [...state.currentDraft.variables, ...variables];
      } else {
        state.currentDraft.variables = variables;
      }
    });
    builder.addCase(asyncThunks.csvExportRequest.pending, (state) => {
      state.csvExportStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.csvExportRequest.rejected, (state) => {
      state.csvExportStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.csvExportRequest.fulfilled, (state) => {
      state.csvExportStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.deleteBulkDraftsRequest.pending, (state, action) => {
      state.deleteBulkDraftsStatus = fetchingStatus.loading;
      const { draftsIds } = action.meta.arg;
      draftsAdapter.removeMany(state, draftsIds);
    });
    builder.addCase(asyncThunks.deleteBulkDraftsRequest.rejected, (state) => {
      state.deleteBulkDraftsStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.deleteBulkDraftsRequest.fulfilled, (state) => {
      state.deleteBulkDraftsStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.cleanDraftRequest.pending, (state, action) => {
      const draftsIds: string[] = action.meta.arg;
      draftsAdapter.removeMany(state, draftsIds);
    });
  }
});

export default draftsSlice.reducer;

export const draftsActions = {
  startWorkingOnDraft: asyncThunks.startWorkingOnDraft,
  patchDraftRequest: asyncThunks.patchDraftRequest,
  patchRealsMeToEditorDraftRequest: asyncThunks.patchRealsMeToEditorDraftRequest,
  createDraftRequest: asyncThunks.createDraftRequest,
  createFirstDraftRequest: asyncThunks.createFirstDraftRequest,
  getDraftsRequest: asyncThunks.getDraftsRequest,
  deleteDraftRequest: asyncThunks.deleteDraftRequest,
  loadDraftResources: asyncThunks.loadDraftResources,
  getDraftVersionRequest: asyncThunks.getDraftVersionRequest,
  getDraftHistoryRequest: asyncThunks.getDraftHistoryRequest,
  duplicateDraftsRequest: asyncThunks.duplicateDraftsRequest,
  createApiVideoExampleRequest: asyncThunks.createApiVideoExampleRequest,
  createNewRealsMeDraftRequest: asyncThunks.createNewRealsMeDraftRequest,
  saveAsRecipeRequest: asyncThunks.saveAsRecipeRequest,
  changeCurrentDraftRecipeRequest: asyncThunks.changeCurrentDraftRecipeRequest,
  topicToDraftRequest: asyncThunks.topicToDraftRequest,
  segmentToScenesRequest: asyncThunks.segmentToScenesRequest,
  clearScenesRequest: asyncThunks.clearScenesRequest,
  revertDraftRequest: asyncThunks.revertDraftRequest,
  refreshDraftAndScenes: asyncThunks.refreshDraftAndScenes,
  handleSegmentToScenesResults: asyncThunks.handleSegmentToScenesResults,
  handleDraftTranslationResults: asyncThunks.handleDraftTranslationResults,
  handleVideoWizardResult: asyncThunks.handleVideoWizardResult,
  translateDraftRequest: asyncThunks.translateDraftRequest,
  workspaceSearchRequest: asyncThunks.workspaceSearchRequest,
  detectDraftLanguageRequest: asyncThunks.detectDraftLanguageRequest,
  updateDraftGlobalCharacterRequest: asyncThunks.updateDraftGlobalCharacterRequest,
  getWorkflowCategoriesRequest: asyncThunks.getWorkflowCategoriesRequest,
  createVideoFromWorkflowCategoriesRequest: asyncThunks.createVideoFromWorkflowCategoriesRequest,
  createDraftGenerator: asyncThunks.createDraftGenerator,
  getMeetingsDraftsRequest: asyncThunks.getMeetingsDraftsRequest,
  summaryRequest: asyncThunks.summaryRequest,
  addVariableRequest: asyncThunks.addVariableRequest,
  updateVariableRequest: asyncThunks.updateVariableRequest,
  updateWorkflowFormRequest: asyncThunks.updateWorkflowFormRequest,
  publishWorkflowRequest: asyncThunks.publishWorkflowRequest,
  getLiveWorkflowRequest: asyncThunks.getLiveWorkflowRequest,
  getWorkflowRequest: asyncThunks.getWorkflowRequest,
  createWorkflowSubmissionRequest: asyncThunks.createWorkflowSubmissionRequest,
  getWorkflowSubmissionsRequest: asyncThunks.getWorkflowSubmissionsRequest,
  revertWorkflowRequest: asyncThunks.revertWorkflowRequest,
  runWorkflowSubmissionRequest: asyncThunks.runSubmissionRequest,
  summaryLeaveRequest: asyncThunks.summaryLeaveRequest,
  connectDraftToDatastoreRequest: asyncThunks.connectDraftToDatastoreRequest,
  bulkSubmissionsRequest: asyncThunks.bulkSubmissionsRequest,
  csvExportRequest: asyncThunks.csvExportRequest,
  deleteBulkDraftsRequest: asyncThunks.deleteBulkDraftsRequest,
  recoverDraftRequest: asyncThunks.recoverDraftRequest,
  cleanDraftRequest: asyncThunks.cleanDraftRequest,
  ...draftsSlice.actions
};
