import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Alert, Button } from "antd";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import useVideoRecorder from "app/pages/PlayGround/useVideoRecorder";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { StyledModal } from "app/components/common/StyledModal";

const FlexColumnDisplay = styled(H1_FlexColumn)<{ $display?: string }>`
  display: ${({ $display }) => ($display ? $display : "flex")};
`;
interface RecordCharacterModalProps {
  open: boolean;
  closeModal: () => void;
  onSelectFile: (file: File) => void;
}
const MAX_TIME_IN_SEC = 60;

const StyledVideo = styled("video")`
  width: 100%;
  align-self: center;
  min-height: 375px;
`;
const RecordCharacterModal = ({ open, closeModal, onSelectFile }: RecordCharacterModalProps) => {
  const theme = useTheme();

  const {
    videoRef,
    videoFile,
    handleStartStopRecording,
    startCapture,
    reset,
    ready,
    isRecording,
    error
  } = useVideoRecorder({ maxRecordingDurationSec: MAX_TIME_IN_SEC });

  useEffect(() => {
    if (open) {
      startCapture();
    } else {
      reset();
    }
  }, [open]);

  const onFinish = () => {
    onSelectFile(videoFile as File);
    closeModal();
  };
  return (
    <StyledModal
      width="600px"
      open={open}
      footer={null}
      centered
      onCancel={closeModal}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="50px"
      $gap="10px"
    >
      <H1_TextMiddle whiteSpace="break-spaces" color={theme.gray7}>
        Create Presenter using your own camera. Record max time is: {MAX_TIME_IN_SEC} seconds.
      </H1_TextMiddle>
      <ConditionalRender condition={!!error}>
        <Alert
          message="Error"
          description="Unable to access your camera. It appears that your browser does not support this feature."
          type="error"
        />
      </ConditionalRender>
      <FlexColumnDisplay $display={!ready ? "none" : "flex"} gap={"5px"}>
        <StyledVideo
          ref={videoRef}
          autoPlay
          muted
          controls={!isRecording && !!videoFile}
          loop={!isRecording}
          controlsList={"nodownload"}
          disablePictureInPicture={true}
        />
        <Button onClick={handleStartStopRecording}>
          {isRecording ? "Stop" : videoFile ? "Record again" : "Record"}
        </Button>
        <H1_FlexRow gap={"5px"} alignSelf={"flex-end"} padding={"10px 0 0 0 "}>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={onFinish} disabled={!videoFile}>
            Done
          </Button>
        </H1_FlexRow>
      </FlexColumnDisplay>
    </StyledModal>
  );
};

export default RecordCharacterModal;
