import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { ReactNode } from "react";
import { MenuItemProps } from "@nextui-org/menu/";
import { OverlayPlacement } from "@nextui-org/aria-utils";
import styled, { css } from "styled-components";

const StyledNextDropdown = styled(Dropdown)<{ $withWhiteBackground: boolean }>`
  ${({ $withWhiteBackground }) =>
    $withWhiteBackground &&
    css`
      li i {
        color: ${({ theme }) => theme.gray11};
        &.text-pink-4 {
          color: ${({ theme }) => theme.pink4};
        }
      }
      li[data-hover="true"] {
        span {
          color: white; /* Its inside a blue4 background */
        }
        i {
          color: white; /* Its inside a blue4 background */
        }
      }
    `};
`;

interface NextDropdownProps {
  items: any[];
  children: ReactNode;
  className?: string;
  color?: MenuItemProps["color"];
  placement?: OverlayPlacement;
  withWhiteBackground?: boolean;
  isDisabled?: boolean;
}
const NextDropdown = ({
  items,
  children,
  className,
  color = "primary",
  placement = "bottom-start",
  withWhiteBackground = false,
  isDisabled = false
}: NextDropdownProps) => {
  return (
    <StyledNextDropdown
      $withWhiteBackground={withWhiteBackground}
      className={className}
      placement={placement}
      isDisabled={isDisabled}
    >
      <DropdownTrigger>{children}</DropdownTrigger>
      <DropdownMenu color={color} aria-label="Static Actions" items={items}>
        {(item) => (
          <DropdownItem
            onClick={item.onClick}
            key={item.key}
            startContent={item.startContent}
            showDivider={item.showDivider || false}
            isDisabled={item.isDisabled || false}
          >
            {item.label}
          </DropdownItem>
        )}
      </DropdownMenu>
    </StyledNextDropdown>
  );
};

export default NextDropdown;
