import { useAppDispatch, useAppSelector } from "app/hooks";
import usePermissions from "app/hooks/usePermissions";
import { Draft, InternalScene } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { scenesActions } from "app/store/slices/scenes.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneActionOrigin } from "app/store/thunks/analyticsEvents.thunk";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { useIntl } from "react-intl";
import { Tooltip } from "antd";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import SkipButton from "app/components/editor/scene/SkipButton";
import * as scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { DEFAULT_MAX_SCENES } from "app/config/planFeature";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const HoverButton = styled(Button)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  &:disabled {
    opacity: 0.3;
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
`;

export const OutsideButtons = styled(H1_FlexRow)<{ $selected: boolean }>`
  position: absolute;
  opacity: ${({ $selected }) => ($selected ? 1 : 0)};
  z-index: ${({ $selected }) => ($selected ? 2 : -1)};
  transition: 0.3s ease-in-out all;
  border-radius: 10px 10px 0 0;
  top: -15px;
  right: 40px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  && i {
    color: ${({ $selected, theme }) => ($selected ? theme.blue2 : theme.blue2)};
  }
  button {
    height: 28px;
  }
`;

interface SceneOutsideToolbarProps {
  idx: number;
  sceneId: string;
}
const SceneOutsideToolbar = ({ idx, sceneId }: SceneOutsideToolbarProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { isWriteWorkspaceAllow } = usePermissions();

  const numberOfScenes = useAppSelector(scenesSelectors.getTotalNumberOfScenes);
  const scene = useAppSelector((state) =>
    scenesGlobalSelectors.selectById(state, sceneId)
  ) as InternalScene;
  const isSkipDisabled = useAppSelector((state) => scenesSelectors.isSkipDisabled(state, sceneId));
  const selectedSceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const scenes = useAppSelector(scenesSelectors.getScenesWithCharacterAndVoice);
  const limits = useAppSelector(getLimits);
  const maxScenesLimit: number = limits?.scenes ?? DEFAULT_MAX_SCENES;
  const draftId: string = draft?.id as string;
  const sceneName = scene?.name;
  const isDeleteLoading = deleteSceneStatus === fetchingStatus.loading;
  const isSceneLoading = scenesStatus === fetchingStatus.loading;
  const isCreateSceneLoading = createSceneStatus === fetchingStatus.loading;
  const disableAllButtons =
    isSceneLoading || isDeleteLoading || isCreateSceneLoading || !isWriteWorkspaceAllow;

  const onDeletingScene = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      analyticsEvents.deleteScene({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: idx
        },
        action: SceneActionOrigin.Scene
      })
    );
    dispatch(
      scenesActions.deleteDraftSceneRequest({
        draftId,
        sceneId
      })
    );
  };

  const onDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      analyticsEvents.duplicateScene({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: idx
        },
        action: SceneActionOrigin.Scene
      })
    );
    dispatch(scenesActions.addDraftSceneRequest({ draftId, parentId: sceneId, scene }));
  };

  if (!isWriteWorkspaceAllow) {
    return null;
  }

  return (
    <OutsideButtons padding="0 10px" $selected={selectedSceneId === sceneId}>
      <Tooltip title={formatMessage(sceneMessages.duplicateSceneTooltip)}>
        <>
          <HoverButton
            isDisabled={scenes.length >= maxScenesLimit || disableAllButtons}
            onClick={onDuplicate}
            variant="light"
            isIconOnly
            startContent={<i className="far fa-copy" />}
          />
        </>
      </Tooltip>
      <SkipButton sceneId={sceneId} disabled={isSkipDisabled || disableAllButtons} />
      <Tooltip title={formatMessage(sceneMessages.deleteSceneTooltip)}>
        <>
          <HoverButton
            isDisabled={disableAllButtons || numberOfScenes === 1}
            onClick={onDeletingScene}
            variant="light"
            isIconOnly
            startContent={<i className="far fa-trash" />}
          />
        </>
      </Tooltip>
    </OutsideButtons>
  );
};

export default SceneOutsideToolbar;
