/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DevModeSize, Recipe } from "app/types";
import { useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import styled, { css, useTheme } from "styled-components";
import React, { useRef, useState } from "react";
import { isMediaPlaying } from "app/utils/helpers";
import { Tooltip, Typography } from "antd";
import { useIntl } from "react-intl";
import { templateDrawerMessages } from "app/components/editor/sideDrawers/TemplatesDrawer/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import PreviewTag from "app/components/common/PreviewTag";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import DevModePopover from "app/components/DevModePopover";
import useConfirmModal from "app/hooks/useConfirmModal";

const TagsFlexRow = styled(H1_FlexRow)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const ContainerFlexColumn = styled(H1_FlexColumn)<{ $pointerCursor: boolean; $greyedOut: boolean }>`
  opacity: ${({ $greyedOut }) => $greyedOut && "50%"};
  cursor: ${({ $pointerCursor }) => ($pointerCursor ? "pointer" : "default")};
`;

const BorderedFlexColumn = styled(H1_FlexColumn)`
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
  ${({ isDisplayBorder }: { isDisplayBorder?: boolean }) =>
    isDisplayBorder &&
    css`
      border: 2px solid ${({ theme }) => theme.blue4};
      margin-top: 0;
      margin-bottom: 0;
      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        transition: border 0.15s ease-in-out;
        border: 2px solid white;
      }
    `};
`;

const TemplateVideo = styled.video`
  border-radius: 4px;
  max-height: ${(props: { aspectRatio: number }) => (props.aspectRatio > 1 ? "none" : "90px")};
  width: ${(props: { aspectRatio: number }) => (props.aspectRatio > 1 ? "auto" : "100%")};
  height: ${(props: { aspectRatio: number }) => (props.aspectRatio > 1 ? "100%" : "auto")};
`;

interface SingleTemplateProps {
  recipeId: string;
  onSelectedRecipeId?: (recipeId: string) => void;
  isSelected: boolean;
  width?: string;
  isNameDisplayed?: boolean;
  isTemplateSupported: boolean;
  size?: DevModeSize;
}
const SingleTemplate = ({
  recipeId,
  onSelectedRecipeId,
  isSelected,
  isTemplateSupported,
  width = "155px",
  isNameDisplayed = true,
  size = DevModeSize.default
}: SingleTemplateProps) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const { isWriteWorkspaceAllow } = usePermissions();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const videoRef = useRef<HTMLVideoElement>(null);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const recipe: Recipe = useAppSelector((state) =>
    recipesSelectors.getRecipeByRecipeId(state, recipeId)
  ) as Recipe;
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);
  if (!recipe) {
    return null;
  }

  const badgeText = recipe.pro ? String(recipe.plans[0] ?? "").toUpperCase() : undefined;
  const badgeIcon = <CrownIcon />;
  const { tags } = recipe;

  const onVideoLoaded = async () => {
    setVideoLoaded(true);
    setAspectRatio(videoRef.current!.videoWidth / videoRef.current!.videoHeight);
  };

  const onMouseEnter = () => {
    if (videoLoaded) {
      videoRef.current!.play();
    }
  };

  const onMouseLeave = () => {
    if (videoLoaded && isMediaPlaying(videoRef.current!)) {
      videoRef.current!.pause();
    }
    return false;
  };

  const onSelectVideo = () => {
    if (!isWriteWorkspaceAllow || !onSelectedRecipeId || !isTemplateSupported) {
      return;
    }
    openConfirmModal({
      onCancel: closeConfirmModal,
      title: formatMessage(templateDrawerMessages.selectTemplateConfirmTitle),
      content: formatMessage(templateDrawerMessages.selectTemplateConfirmBody),
      onConfirm() {
        onSelectedRecipeId(recipe.id);
      },
      confirmText: formatMessage(templateDrawerMessages.continue),
      cancelText: formatMessage(templateDrawerMessages.cancel)
    });
  };

  return (
    <ContainerFlexColumn
      $pointerCursor={!!onSelectedRecipeId}
      onClick={onSelectVideo}
      width={width}
      $greyedOut={!isTemplateSupported}
    >
      <BorderedFlexColumn isDisplayBorder={isSelected} position="relative">
        <DevModePopover
          size={size}
          placement="left"
          value={{ template_id: recipeId }}
          path={"root"}
        />
        <Tooltip
          title={
            !isTemplateSupported ? formatMessage(templateDrawerMessages.unsupportedCharacter) : ""
          }
        >
          <TemplateVideo
            src={recipe?.video}
            aspectRatio={aspectRatio}
            ref={videoRef}
            poster={recipe?.image}
            muted
            loop
            onCanPlay={() => onVideoLoaded()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Tooltip>
        <TagsFlexRow gap="5px">
          <ConditionalRender condition={!developerMode && !!badgeText}>
            <PreviewTag icon={badgeIcon} background={theme.orange3}>
              {badgeText}
            </PreviewTag>
          </ConditionalRender>
          <ConditionalRender condition={!developerMode && !!tags?.length}>
            <PreviewTag background={theme.pink4}>{tags?.[0]}</PreviewTag>
          </ConditionalRender>
        </TagsFlexRow>
      </BorderedFlexColumn>
      <ConditionalRender condition={isNameDisplayed}>
        <Typography.Text ellipsis={{ tooltip: recipe?.title }}>{recipe.title}</Typography.Text>
      </ConditionalRender>
    </ContainerFlexColumn>
  );
};

export default SingleTemplate;
