/* eslint-disable camelcase */
import Config from "app/config/Config";
import { fetchSecured } from "app/store/security";
import {
  Audience,
  AugmentedModification,
  AugmentedSelection,
  AugmentedType,
  ChangeRoleRequest,
  CreateFolder,
  Draft,
  DraftDuplicationRequest,
  DraftExtended,
  draftGeneratorRequest,
  DraftRevertRequest,
  DraftVariable,
  DraftViewMode,
  Favorite,
  FavoriteType,
  FoldersContext,
  FramePreviewQuality,
  GeneratedTranscriptResponse,
  GenerateTranscript,
  ImportDataStoreRequest,
  InviteMember,
  MediaFileType,
  Member,
  MFATypes,
  PaletteColor,
  PaletteTypes,
  PartialScene,
  PatchOperation,
  RecoverBulkDraftRequest,
  Scene,
  SupportedTranslationLanguages,
  SynthesisMarkupLanguage,
  TranslateSubtitlesRequest,
  UpdateDataStoreRequest,
  Video,
  VideoFeedbackReaction,
  VideoRatingReaction,
  VideoReactionTypes,
  VideoResolution,
  VideoVariable,
  VideoWizardV2Properties,
  WebHookRequest,
  WorkflowForm,
  WorkflowFormVariableResult,
  WorkflowResult,
  WorkflowSubmission,
  WorkflowVariableValue,
  Workspace
} from "app/types";
import {
  CreateMedia,
  Media,
  MediaUpdateRequest,
  ServerFolderMedia,
  StockMedia
} from "app/types/media";
import { BecomeCharacter, Character } from "app/types/character";
import { Preferences } from "app/types/preferences";
import { BackgroundMusic } from "app/types/music";
import { BioInformation, RealsMeDraftInfo, Source, SourceName } from "app/types/realsMe";

import { SlackChannel, SlackInstall } from "app/types/slack";
import { BrandKit } from "app/types/brandkit";
import { PlayGroundRequest } from "app/types/playground";
import {
  CancelResponse,
  MinuteAnalytic,
  SubscriptionStatus,
  UsageMetadataResponse
} from "app/types/payments";
import { AvatarRequest } from "app/types/virtualTwin";
import { AxiosResponse } from "axios";
import { Brief, BriefRequest } from "app/types/brief";
import { Trash, TrashType } from "app/types/trash";

const serverBaseUrl = Config.serverUrl;

interface Fetch {
  method: string;
  rejectOnFailure: boolean;
  body?: string;
}

export const getUserData = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/users/me`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const registerUser = async (body: { journey?: string; extra?: { hubspotutk?: string } }) => {
  const req: Fetch = {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(body)
  };

  await fetchSecured(`${serverBaseUrl}/v2/users/register`, req);
};
export const updateUserPreferences = async (body: Preferences) => {
  const req: Fetch = {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(body)
  };

  await fetchSecured(`${serverBaseUrl}/v2/users/preferences`, req);
};

export const getDraft = async (draftId: string, detectLanguage = false) => {
  let url = `${serverBaseUrl}/v2/drafts/${draftId}`;
  if (detectLanguage) {
    url += `?detect_language=1`;
  }
  const res = await fetchSecured(url, {
    method: "GET",
    rejectOnFailure: true
  });

  // todo simulate failure to see if error is thrown here - or should change the fetch config
  const result = res.data;
  return result;
};

export const patchDraft = async (draftId: string, operations: PatchOperation[]) => {
  await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}`, {
    method: "PATCH",
    body: JSON.stringify(operations),
    rejectOnFailure: true
  });
};

export const getRecipes = async (type?: "workflow") => {
  const query = type ? `?type=${type}` : "";
  const res = await fetchSecured(`${serverBaseUrl}/v2/recipe-categories${query}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};
export const getRecipesExamples = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/recipe-categories/examples`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};
export const getRecommendedRecipes = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/recipe-categories/recommended-recipes`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const deleteRecipe = async (recipeId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/recipes/${recipeId}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const postTemplatesRequest = async (description: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/templates/template-request`, {
    method: "POST",
    body: JSON.stringify({ description }),
    rejectOnFailure: true
  });
  return res;
};

export const upsertPalette = async (name: string, draftId: string, colors: PaletteColor[]) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/templates/palettes`, {
    method: "PUT",
    body: JSON.stringify({ name, draft_id: draftId, colors, type: PaletteTypes.Custom }),
    rejectOnFailure: true
  });
  return res.data;
};

export const getPalettes = async (templateId: string, draftId: string) => {
  const res = await fetchSecured(
    `${serverBaseUrl}/v2/templates/${templateId}/palettes?draft_id=${draftId}`,
    {
      method: "GET",
      rejectOnFailure: true
    }
  );
  return res.data;
};

export const createDraft = async (draft: Draft) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts`, {
    method: "POST",
    body: JSON.stringify(draft),
    rejectOnFailure: true
  });
  return res.data;
};

export const getDraftScenes = async (draftId: string): Promise<Scene[]> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/scenes`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const saveAsRecipe = async (
  draftId: string,
  title: string,
  owner: string,
  description?: string
): Promise<any> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/recipes`, {
    method: "POST",
    body: JSON.stringify({ draft_id: draftId, title, description, owner }),
    rejectOnFailure: true
  });
  return res.data;
};

export const changeCurrentDraftRecipe = async (
  draftId: string,
  recipeId: string
): Promise<DraftExtended> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/recipe/${recipeId}`, {
    method: "PUT",
    body: JSON.stringify({}),
    rejectOnFailure: true
  });
  return res.data;
};

export const addDraftScene = async (
  draftId: string,
  scene: PartialScene,
  parentId?: string
): Promise<Scene> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/scenes`, {
    method: "POST",
    body: JSON.stringify({
      ...scene,
      parent_id: parentId
    }),
    rejectOnFailure: true
  });
  return res.data;
};

export const patchScene = async (sceneId: string, operations: PatchOperation[]) => {
  await fetchSecured(`${serverBaseUrl}/v2/scenes/${sceneId}`, {
    method: "PATCH",
    body: JSON.stringify(operations),
    rejectOnFailure: true
  });
};
export const patchSceneLayout = async (sceneId: string, layoutId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/scenes/${sceneId}/layout/${layoutId}`, {
    method: "PUT",
    body: JSON.stringify({}),
    rejectOnFailure: true
  });
  return res.data;
};

export const deleteDraftScene = async (draftId: string, sceneId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/scenes/${sceneId}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};
export const createVideo = async (
  draftId: string,
  destinations?: string[],
  variables?: VideoVariable[],
  datastore_content_id?: string
) => {
  let body: any = { draft_id: draftId, variables, datastore_content_id };
  if (destinations !== undefined) {
    body = { ...body, destinations };
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos`, {
    method: "POST",
    body: JSON.stringify(body),
    rejectOnFailure: true
  });

  return res.data;
};
export const getVideos = async (
  draftId: string,
  pagination?: {
    limit?: number;
    page?: number;
  }
) => {
  let url = `${serverBaseUrl}/v2/videos?draft_id=${draftId}`;
  if (pagination) {
    url = `${url}&page=${pagination.page || 1}&limit=${pagination.limit || 10}`;
  }
  const res = await fetchSecured(url, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const searchVideosApi = async (
  text: string | undefined = undefined,
  limit = 10,
  source?: SourceName,
  audience = Audience.private,
  expand = false
) => {
  const body: { text?: string; sources?: SourceName[] } = {};
  if (source) {
    body.sources = [source];
  }
  if (text) {
    body.text = text;
  }
  const res = await fetchSecured(
    `${serverBaseUrl}/v2/videos/search?limit=${limit}&audience=${audience}&expand=${expand}`,
    {
      method: "POST",
      body: JSON.stringify(body),
      rejectOnFailure: true
    }
  );

  return res.data;
};

export const deleteVideo = async (videoId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const getVideosByReqId = async (reqId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos?req_id=${reqId}`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const updateVideoRatingReaction = async (
  videoId: string,
  reaction: VideoRatingReaction,
  active: boolean,
  channelName: string
) => {
  await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/reactions`, {
    method: "PUT",
    body: JSON.stringify({
      reaction,
      active,
      channel_name: channelName,
      reaction_type: "video_rating"
    }),
    rejectOnFailure: true
  });
};

export const updateVideoFeedbackReaction = async (
  videoId: string,
  reaction: VideoFeedbackReaction
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/reactions`, {
    method: "PUT",
    body: JSON.stringify({
      reaction,
      active: true,
      reaction_type: "video_feedback"
    }),
    rejectOnFailure: true
  });
  return res.data;
};

export const getVideoReaction = async (
  videoId: string,
  reactionType?: VideoReactionTypes,
  onlyMine?: boolean
) => {
  const params = new URLSearchParams();
  if (onlyMine) {
    params.set("me", "1");
  }

  if (reactionType) {
    params.set("reaction_type", reactionType);
  }

  const url = `${serverBaseUrl}/v2/videos/${videoId}/reactions?${params.toString()}`;
  const res = await fetchSecured(url, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const updateVideo = async (
  videoId: string,
  operations: PatchOperation[]
): Promise<Video> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}`, {
    method: "PATCH",
    body: JSON.stringify(operations),
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const createPlaylist = async (title: string, folderId?: string, draftsIds?: string[]) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/playlists`, {
    method: "POST",
    body: JSON.stringify({ title, draft_ids: draftsIds, folder_id: folderId }),
    rejectOnFailure: true
  });

  return res.data;
};

export const createMergePlaylist = async (id: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/playlists/${id}/videos`, {
    method: "POST",
    body: JSON.stringify({}),
    rejectOnFailure: true
  });

  return res.data;
};

export const updatePlaylist = async (id: string, title: string, draftsIds: string[]) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/playlists/${id}`, {
    method: "PUT",
    body: JSON.stringify({ title, draft_ids: draftsIds }),
    rejectOnFailure: true
  });

  return res.data;
};

export const deletePlaylist = async (id: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/playlists/${id}`, {
    method: "DELETE",
    rejectOnFailure: true
  });

  return res.data;
};

export const getPlaylists = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/playlists`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const getPlaylistById = async (playlistId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/playlists/${playlistId}`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const getLayouts = async (templateId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/templates/${templateId}/layouts`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const clearDraftScenes = async (draftId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/clear`, {
    method: "PUT",
    rejectOnFailure: true
  });
};

export const downloadVideo = async (videoId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/download`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const downloadTranscodeVideo = async (
  videoId: string,
  fileType: MediaFileType,
  resolution: VideoResolution,
  orderId: string
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/transcode`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      format: fileType,
      resolution,
      order_id: orderId,
      compressed: false
    })
  });

  return res.data;
};

export const downloadScormVideo = async (videoId: string, orderId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/scorm`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      order_id: orderId
    })
  });

  return res.data;
};

export const sharePlaygroundVideo = async (videoId: string) => {
  await fetchSecured(`${serverBaseUrl}/api/v1/playground/videos/${videoId}/share`, {
    method: "PUT",
    rejectOnFailure: true
  });
};

export const publishVideo = async (videoId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/publish`, {
    method: "PUT",
    rejectOnFailure: true
  });
};

export const workspaceSearch = async (searchValue: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workspaces/search`, {
    method: "POST",
    body: JSON.stringify({ text: searchValue }),
    rejectOnFailure: true
  });

  return res.data;
};

export const getTemplates = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/templates`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};
export const getTemplateById = async (templateId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/templates/${templateId}`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const getDrafts = async (
  limit: number,
  page: number,
  ids?: string[],
  type?: "workflow",
  viewMode?: DraftViewMode.me | undefined
) => {
  const limitParam = `?page=${page}&limit=${limit}${viewMode ? `&view_mode=${viewMode}` : ""}`;
  const utlObj = new URL(`${serverBaseUrl}/v2/drafts${limitParam}`);
  if (ids) {
    utlObj.searchParams.set("ids", ids.join(","));
  }
  if (type) {
    utlObj.searchParams.set("type", type);
  }
  const res = await fetchSecured(utlObj.toString(), {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const duplicateDraft = async (
  draftId: string,
  source?: SourceName,
  transcriptLang?: SupportedTranslationLanguages
) => {
  const body: DraftDuplicationRequest = {};

  if (source) {
    body.destination_source = source;
  }

  if (transcriptLang) {
    body.destination_language = transcriptLang;
  }

  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/duplicate`, {
    method: "POST",
    body: JSON.stringify(body),
    rejectOnFailure: true
  });
  return res.data;
};

export const deleteDraft = async (draftId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const updateDraftGlobalCharacter = async (value: boolean, draftId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}`, {
    method: "PUT",
    body: JSON.stringify({
      global_character: value
    }),
    rejectOnFailure: true
  });

  return res.data;
};

export const detectDraftLanguage = async (draftId: string) => {
  const lang = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/language`, {
    method: "GET",
    rejectOnFailure: true
  });

  return lang.data || "";
};

export const getDraftHistory = async (draftId: string, expand?: boolean, version?: string) => {
  const params = new URLSearchParams();
  if (expand !== undefined) {
    params.set("expand", expand.toString());
  }
  if (version) {
    params.set("version", version as string);
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/history?${params}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const getWorkspaces = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workspaces`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const createWorkspace = async (workspace: Partial<Workspace>) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workspaces`, {
    method: "POST",
    body: JSON.stringify(workspace),
    rejectOnFailure: true
  });
  return res.data;
};

export const changeUserWorkspaceRole = async (changeRole: ChangeRoleRequest) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/${changeRole.workspaceId}/roles`, {
    method: "PUT",
    body: JSON.stringify({
      user_id: changeRole.userId,
      role: changeRole.role
    }),
    rejectOnFailure: true
  });
};

export const getUserInvites = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/users/invites`, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const reviewInvite = async (inviteId: string, action: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/invites/${inviteId}/review`, {
    method: "POST",
    body: JSON.stringify({ action }),
    rejectOnFailure: true
  });
};

export const ackInvite = async (inviteId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/invites/${inviteId}/ack`, {
    method: "PUT",
    body: JSON.stringify({}),
    rejectOnFailure: true
  });
};

export const submitInvite = async (inviteId: string, action: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/invites/${inviteId}/submit`, {
    method: "POST",
    body: JSON.stringify({ action }),
    rejectOnFailure: true
  });
};

export const getWorkspaceById = async (workspaceId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workspaces/${workspaceId}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const createInvitesV2 = async (invites: InviteMember[]) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workspaces/invites`, {
    method: "POST",
    body: JSON.stringify({ invites }),
    rejectOnFailure: true
  });

  return res.data;
};

export const inviteMembersToWorkspace = async (workspaceId: string, members: Member[]) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/${workspaceId}/members/add`, {
    method: "PUT",
    body: JSON.stringify({ members }),
    rejectOnFailure: true
  });
};

export const removeMembersToWorkspace = async (workspaceId: string, members: Member[]) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/${workspaceId}/members/remove`, {
    method: "PUT",
    body: JSON.stringify({ members }),
    rejectOnFailure: true
  });
};

export const updateWorkspaceProperties = async (
  workspaceId: string,
  workspace: Partial<Workspace>
) => {
  await fetchSecured(`${serverBaseUrl}/v2/workspaces/${workspaceId}`, {
    method: "PUT",
    body: JSON.stringify(workspace),
    rejectOnFailure: true
  });
};

export const getChannels = async (name?: string) => {
  const params = new URLSearchParams();
  if (name) {
    params.append("name", name);
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/channels?${params}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const createChannel = async (name: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/channels`, {
    method: "PUT",
    body: JSON.stringify({
      op: "add",
      channel: {
        name
      }
    }),
    rejectOnFailure: true
  });
  return res.data;
};

export const removeChannel = async (name: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/channels`, {
    method: "PUT",
    body: JSON.stringify({
      op: "remove",
      channel: {
        name
      }
    }),
    rejectOnFailure: true
  });
  return res.data;
};

export const previewAudio = async (
  orderId: string,
  type: string,
  voiceId: string,
  draftId?: string,
  sceneId?: string,
  text?: string,
  sml?: SynthesisMarkupLanguage[]
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/voices/preview`, {
    method: "POST",
    body: JSON.stringify({
      type,
      text,
      order_id: orderId,
      scene_id: sceneId,
      draft_id: draftId,
      voice_id: voiceId,
      synthesis_markup_language: sml
        ? {
            nodes: sml
          }
        : undefined
    }),
    rejectOnFailure: true
  });
  return res;
};
/*
 * Payments
 * */

export const createPaymentSession = async (plan: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/session`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ plan })
  });
  return res;
};

export const executeSession = async ({
  subscriptionId,
  sessionId,
  invoiceId
}: {
  subscriptionId?: string;
  sessionId?: string;
  invoiceId?: string;
}) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/execute-session`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      subscription_id: subscriptionId,
      session_id: sessionId,
      invoice_id: invoiceId
    })
  });
  return res;
};

export const createSubscription = async ({
  planId,
  coupon,
  paymentMethod
}: {
  planId: string;
  paymentMethod?: string;
  coupon?: string;
}) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/subscription`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ plan_id: planId, payment_method: paymentMethod, coupon })
  });
  return res;
};
export const getSubscription = async (): Promise<SubscriptionStatus[]> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/subscription`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const updateCreditCard = async (subscriptionId: string): Promise<{ url: string }> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/setup-session`, {
    method: "POST",
    body: JSON.stringify({ subscription_id: subscriptionId }),
    rejectOnFailure: true
  });
  return res.data;
};

export const getAnalyticsMetadata = async (): Promise<UsageMetadataResponse> => {
  const url = new URL(`${serverBaseUrl}/v2/analytics/metadata`);
  const res = await fetchSecured(url.toString(), {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};
export const getMinutesAnalytics = async (
  year: number,
  month: number
): Promise<MinuteAnalytic[]> => {
  const url = new URL(`${serverBaseUrl}/v2/analytics/summary`);
  const params = new URLSearchParams({
    year: year.toString(),
    month: month.toString()
  });
  url.search = params.toString();

  const res = await fetchSecured(url.toString(), {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const cancelSubscription = async (): Promise<CancelResponse> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/subscription/cancel`, {
    method: "POST",
    rejectOnFailure: true
  });
  return res.data;
};
export const addOnPayment = async ({
  featureId,
  paymentMethod,
  videoId
}: {
  featureId: string;
  paymentMethod?: string;
  videoId?: string;
}) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/add-on`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      feature_id: featureId,
      payment_method: paymentMethod,
      video_id: videoId
    })
  });
  return res;
};

export const createSetup = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/setup`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res;
};

export const verifyCoupon = async ({ coupon, planId }: { coupon: string; planId: string }) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/verify-coupon`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ coupon, plan_id: planId })
  });
  return res;
};

export const getPricingAndPlans = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/pricing`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res;
};
export const getFeaturesPricing = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/payments/features-pricing`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res;
};

/*
 * folders
 * */

export const getFilesFolders = async (
  context?: FoldersContext | undefined,
  path = "/"
): Promise<AxiosResponse<ServerFolderMedia[]>> => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/folders?path=${path}`, {
    method: "GET",
    headers,
    rejectOnFailure: true
  });
  return res;
};

export const getFolderContent = async (
  folderId: string,
  fileType?: string,
  context?: FoldersContext | undefined
) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const params = new URLSearchParams();
  if (fileType) {
    params.append("file_type", fileType);
  }
  const res = await fetchSecured(
    `${serverBaseUrl}/v2/folders/${folderId}/content?${params.toString()}`,
    {
      method: "GET",
      headers,
      rejectOnFailure: true
    }
  );
  return res;
};

export const createFolder = async (body: CreateFolder, context?: FoldersContext | undefined) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/folders`, {
    method: "POST",
    rejectOnFailure: true,
    headers,
    body: JSON.stringify({
      ...body,
      type: "folder"
    })
  });
  return res;
};

export const editFolder = async (folderId: string, name: string, context?: FoldersContext) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/folders/${folderId}`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify({
      name
    }),
    headers
  });
  return res;
};

export const deleteFolder = async (folderId: string, context?: FoldersContext) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  await fetchSecured(`${serverBaseUrl}/v2/folders/${folderId}`, {
    method: "DELETE",
    rejectOnFailure: true,
    headers
  });
};

export const deleteMultipleMediaAndFolders = async (files: string[], context?: FoldersContext) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  await fetchSecured(`${serverBaseUrl}/v2/folders/bulk-delete`, {
    method: "DELETE",
    rejectOnFailure: true,
    body: JSON.stringify(files.map((fileId) => ({ id: fileId }))),
    headers
  });
};

export const getFolder = async (folderId: string, context?: FoldersContext) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const response = await fetchSecured(`${serverBaseUrl}/v2/folders/${folderId}`, {
    method: "GET",
    rejectOnFailure: true,
    headers
  });
  return response.data;
};

export const moveToFolderMultiple = async (
  arr: {
    id: string;
    parent_id: string;
  }[],
  context?: FoldersContext | undefined
) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/folders/bulk-move`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(arr),
    headers
  });
  return res;
};

export const moveToFolder = async (
  pathId: string,
  parentId: string,
  context?: FoldersContext | undefined
) => {
  const headers: any = {};
  if (context) {
    headers["context"] = context;
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/folders/${pathId}/move`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify({
      parent_id: parentId
    }),
    headers
  });
  return res;
};

export const getDataStore = async (id: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/data-stores/${id}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res;
};

export const getCharactersList = async (options?: {
  type?: string;
  scope?: "me";
  character_id?: string;
}) => {
  const withType = options?.type ? `type=${options.type}&` : ``;
  const withScope = options?.scope ? `scope=${options.scope}&` : ``;
  const character_id = options?.character_id ? `character_id=${options.character_id}&` : ``;
  const res = await fetchSecured(
    `${serverBaseUrl}/v2/characters?${withType}${withScope}${character_id}`,
    {
      method: "GET",
      rejectOnFailure: true
    }
  );
  return res.data;
};

export const framePreview = async (
  draftId: string,
  sceneIds: string[],
  quality?: FramePreviewQuality
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/frame-preview`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      draft_id: draftId,
      scene_ids: sceneIds,
      quality
    })
  });
  return res.data;
};

export const getSceneById = async (sceneId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/scenes?ids=${sceneId}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const moveSceneRequest = async (draftId: string, sceneId: string, parentId?: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/move-scene`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify({
      scene_id: sceneId,
      dest_parent_id: parentId
    })
  });
  return res;
};

// current v1:
export const getVoicesList = async () => {
  // todo temp on v1
  const res = await fetchSecured(`${serverBaseUrl}/v2/voices`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res;
};

export const getFonts = async (template_id?: string) => {
  const url = template_id
    ? `${serverBaseUrl}/v2/templates/${template_id}/fonts`
    : `${serverBaseUrl}/v2/templates/fonts`;
  const res = await fetchSecured(url, {
    method: "GET",
    rejectOnFailure: true
  });

  return res.data;
};

export const cloneVoice = async (
  displayName: string,
  url: string,
  order_id: string,
  description?: string
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/voices/clone-voice`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      clone_voice_url: url,
      order_id,
      description,
      display_name: displayName
    })
  });
  return res.data;
};

export const updateMediaItem = async (
  mediaId: string,
  mediaUpdate: MediaUpdateRequest
): Promise<Media> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/media/${mediaId}`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(mediaUpdate)
  });
  const { data } = res;
  return data as Media;
};

export const createMedia = async (
  url: string,
  handle: string,
  parentId?: string
): Promise<Media> => {
  const res = await fetchSecured(`${serverBaseUrl}/v1/media`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ url, handle, parent_id: parentId })
  });
  return res.data;
};
export const createMediaBulk = async (request: CreateMedia[]): Promise<Media[]> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/media/bulk`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(request)
  });
  return res.data;
};

export const createSDMediaGeneration = async (
  text: string,
  orderId: string,
  seed?: number
): Promise<string> => {
  await fetchSecured(`${serverBaseUrl}/v2/media/generate`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ text, seed, order_id: orderId })
  });
  return orderId;
};

export const deleteMedia = async (handle: string): Promise<void> => {
  await fetchSecured(`${serverBaseUrl}/v1/media/${handle}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const deleteProfile = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v1/users/me`, {
    method: "DELETE",
    rejectOnFailure: true
  });

  return res;
};

export const becomeCharacter = async (data: BecomeCharacter): Promise<void> => {
  await fetchSecured(`${serverBaseUrl}/v1/characters/become`, {
    method: "POST",
    body: JSON.stringify(data),
    rejectOnFailure: true
  });
};

export const getSources = async (): Promise<Source[]> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/sources`, {
    method: "GET",
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const getBio = async (): Promise<BioInformation> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/bio`, {
    method: "GET",
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const generateTranscript = async (
  body: GenerateTranscript
): Promise<GeneratedTranscriptResponse> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/services/generate-transcript`, {
    method: "POST",
    body: JSON.stringify(body),
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const revertDraft = async (
  draftId: string,
  body: DraftRevertRequest
): Promise<DraftExtended> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/revert`, {
    method: "PUT",
    body: JSON.stringify(body),
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const getWorkflowCategories = async () => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/services/workflows-categories`, {
    method: "GET",
    rejectOnFailure: true
  });
  const result = response.data;

  return result;
};

export const createVideoFromWorkflowCategories = async (
  properties: Partial<VideoWizardV2Properties>,
  orderId: string
) => {
  const body = JSON.stringify({
    type: "create_draft_from_category",
    order_id: orderId,
    content: {
      title: "Video Wizard draft",
      category_id: properties.category,
      sub_category_id: properties.subCategory as string,
      variables: properties.variablesValues,
      video_length: properties.duration,
      recipe_id: properties.recipeId
    }
  });
  const response = await fetchSecured(`${serverBaseUrl}/v2/services/workflow-to-draft`, {
    method: "POST",
    body,
    rejectOnFailure: true
  });
  const result = response.data;

  return result;
};

export const topicToDraft = async (
  type: string,
  prompt: string,
  numberOfScenes: number,
  orderId: string,
  title?: string,
  bump?: boolean,
  draftId?: string,
  targetPlatform?: string
): Promise<{ orderId: string }> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/services/topic-to-draft/async`, {
    method: "POST",
    body: JSON.stringify({
      order_id: orderId,
      type,
      content: {
        topic: prompt,
        scenes_amount: numberOfScenes,
        title: title,
        draft_id: draftId,
        bump_version: bump,
        target_platform: targetPlatform
      }
    }),
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const segmentToScene = async ({
  sceneId,
  orderId
}: {
  sceneId: string;
  orderId: string;
}) => {
  return await fetchSecured(`${Config.serverUrl}/v2/services/augment-scene`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      order_id: orderId,
      scene_id: sceneId,
      augmentation_type: AugmentedType.split
    })
  });
};

export const updateCharactersDetails = async (
  characterId: string,
  operations: PatchOperation[]
): Promise<Character> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/characters/${characterId}`, {
    method: "PATCH",
    body: JSON.stringify(operations),
    rejectOnFailure: true
  });

  const result = response.data;
  return result;
};

export const updateUserBio = async (linkedInUrl: string): Promise<void> => {
  await fetchSecured(`${serverBaseUrl}/v2/bio`, {
    method: "PUT",
    body: JSON.stringify({ linkedin_url: linkedInUrl }),
    rejectOnFailure: true
  });
};

export const updateSceneWithAugmentedText = async (body: {
  scene_id: string;
  augmentation_type: AugmentedType;
  augmentation_modification?: AugmentedSelection | AugmentedModification;
}): Promise<any> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/services/augment-scene`, {
    method: "POST",
    body: JSON.stringify({ ...body }),
    rejectOnFailure: true
  });
  const scene = response.data;
  return scene;
};

export const getBackgroundMusic = async (): Promise<BackgroundMusic[]> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/background-music`, {
    method: "GET",
    rejectOnFailure: true
  });

  const music = response.data;
  return music;
};

export const uploadBackgroundMusic = async (
  music: Partial<BackgroundMusic>
): Promise<BackgroundMusic> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/background-music`, {
    method: "POST",
    body: JSON.stringify(music),
    rejectOnFailure: true
  });

  const { data } = response;
  return data;
};

export const updateBackgroundMusic = async (
  music: Partial<BackgroundMusic>
): Promise<BackgroundMusic> => {
  const response = await fetchSecured(`${serverBaseUrl}/v2/background-music/${music.id}`, {
    method: "PUT",
    body: JSON.stringify(music),
    rejectOnFailure: true
  });

  const { data } = response;
  return data;
};

export const deleteBackgroundMusic = async ({ id }: { id: string }): Promise<void> => {
  await fetchSecured(`${serverBaseUrl}/v2/background-music/${id}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const getDraftsBySources = async ({
  sources,
  videoCreated = true
}: RealsMeDraftInfo): Promise<Draft[]> => {
  const sourcesList: string = sources.map(({ name }) => `source=${name}`).join("&");
  const response = await fetchSecured(
    `${serverBaseUrl}/v2/drafts?${sourcesList}&expand=true&video_created=${videoCreated}`,
    {
      method: "GET",
      rejectOnFailure: true
    }
  );

  const res = response.data;
  return res;
};

export const createApiExample = async (
  type: "create" | "override" | "create_video_from_workflow",
  draftId?: string,
  workflowId?: string
): Promise<{ curl: string; json_request: any }> => {
  const response = await fetchSecured(`${serverBaseUrl}/api/v1/examples`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ type, draft_id: draftId, workflow_id: workflowId })
  });

  const data = response.data;
  return data;
};

/* api management */

export const getKeys = async (): Promise<{ key: string }[]> => {
  const req: Fetch = {
    method: "GET",
    rejectOnFailure: true
  };

  const response = await fetchSecured(`${serverBaseUrl}/api/v1/keys`, req);

  const data = response.data;
  return data;
};

export const createKeys = async (): Promise<{ key: string }> => {
  const req: Fetch = {
    method: "POST",
    rejectOnFailure: true
  };

  const response = await fetchSecured(`${serverBaseUrl}/api/v1/keys`, req);

  const data = response.data;
  return data;
};

export const deleteKey = async (id: string): Promise<void> => {
  const req: Fetch = {
    method: "DELETE",
    rejectOnFailure: true
  };

  await fetchSecured(`${serverBaseUrl}/api/v1/keys/${id}`, req);
};

export const slackBind = async (bindId: string): Promise<void> => {
  const req: Fetch = {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ binding_id: bindId })
  };

  await fetchSecured(`${serverBaseUrl}/v2/integrations/slack/binding`, req);
};

export const slackInstallUrl = async (): Promise<SlackInstall> => {
  const req: Fetch = {
    method: "GET",
    rejectOnFailure: true
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/integrations/slack/install`, req);
  return res.data;
};

export const getSlackChannels = async (): Promise<{ data: SlackChannel[] }> => {
  const req: Fetch = {
    method: "GET",
    rejectOnFailure: true
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/integrations/slack/channels`, req);
  return res.data;
};

export const setFavorite = async (
  assetId: string,
  type: FavoriteType,
  favorite: boolean
): Promise<void> => {
  const req: Fetch = {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify({
      asset_id: assetId,
      asset_type: type,
      active: favorite
    })
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/favorites`, req);
  return res.data;
};

export const setFavoritesBulk = async (favorites: Favorite[]): Promise<void> => {
  const req: Fetch = {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(favorites)
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/favorites/bulk`, req);
  return res.data;
};

export const updateCharacterTitle = async (
  id: string,
  operations: PatchOperation[]
): Promise<void> => {
  const req: Fetch = {
    method: "PATCH",
    rejectOnFailure: true,
    body: JSON.stringify(operations)
  };

  await fetchSecured(`${serverBaseUrl}/v2/characters/${id}`, req);
};

export const deleteCharacter = async (id: string): Promise<void> => {
  const req: Fetch = {
    method: "DELETE",
    rejectOnFailure: true
  };

  await fetchSecured(`${serverBaseUrl}/v2/characters/${id}`, req);
};

export const summaryGenerator = async (
  type: "audio_recorded" | "zoom_meeting",
  url: string,
  orderId: string
): Promise<{ id?: string }> => {
  const req: Fetch = {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      url,
      type: type,
      order_id: orderId
    })
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/services/summary`, req);
  return res.data;
};
export const summaryLeave = async (id: string): Promise<void> => {
  const req: Fetch = {
    method: "POST",
    rejectOnFailure: true
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/services/summary/${id}/leave`, req);
  return res.data;
};
export const shareSlackChannel = async (channelId: string, videoId: string): Promise<void> => {
  const req: Fetch = {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      channel_id: channelId,
      video_id: videoId,
      type: "slack"
    })
  };

  await fetchSecured(`${serverBaseUrl}/v2/integrations/share`, req);
};

export const getBrandKit = async (): Promise<BrandKit[]> => {
  const req: Fetch = {
    method: "GET",
    rejectOnFailure: true
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/brand-kits`, req);
  return res.data;
};
export const upsertBrandKit = async (brandkit: Partial<BrandKit>): Promise<BrandKit> => {
  const req: Fetch = {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(brandkit)
  };

  const res = await fetchSecured(`${serverBaseUrl}/v2/brand-kits`, req);
  return res.data;
};

export const getFeaturedVideosRequest = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/featured?featured_status=featured`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const getCredits = async () => {
  const res = await fetchSecured(`${Config.serverUrl}/v1/credits`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const getMediaPolicy = async (type = "read") => {
  const policyResponse = await fetchSecured(`${Config.serverUrl}/v1/media/policy?type=${type}`, {
    method: "GET",
    rejectOnFailure: true
  });
  const policyResult = policyResponse.data;
  return {
    policy: policyResult.data.policy,
    signature: policyResult.data.signature
  };
};

export const playgroundGenerate = async (body: PlayGroundRequest) => {
  const policyResponse = await fetchSecured(`${Config.serverUrl}/api/v1/playground/generate`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(body)
  });
  return policyResponse.data;
};
export const playgroundGetVideos = async () => {
  const policyResponse = await fetchSecured(`${Config.serverUrl}/api/v1/playground/videos`, {
    method: "GET",
    rejectOnFailure: true
  });
  return policyResponse.data;
};

export const playgroundDownloadVideo = async (videoId: string) => {
  const policyResponse = await fetchSecured(
    `${Config.serverUrl}/api/v1/playground/videos/${videoId}/download`,
    {
      method: "GET",
      rejectOnFailure: true
    }
  );
  return policyResponse.data;
};

export const cancelVideoRendering = async ({ videoId }: { videoId: string }) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/cancel`, {
    method: "PUT",
    body: JSON.stringify({}),
    rejectOnFailure: true
  });
  return res.data;
};

export const createAvatar = async (body: AvatarRequest) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/avatars`, {
    method: "POST",
    body: JSON.stringify(body),
    rejectOnFailure: true
  });
  return res.data;
};

export const getAvatarConsentSession = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/avatars/consent`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const getAvatars = async (statuses?: string[]) => {
  const params = new URLSearchParams();
  if (statuses && statuses.length) {
    for (const stat in statuses) {
      params.append("status", statuses[stat]);
    }
  }
  const res = await fetchSecured(`${serverBaseUrl}/v2/avatars?${params.toString()}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const getVideoSubtitle = async (videoId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/subtitle`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const translateVideoSubtitles = async (
  videoId: string,
  translateSubtitlesRequest: TranslateSubtitlesRequest
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/subtitles`, {
    method: "POST",
    body: JSON.stringify(translateSubtitlesRequest),
    rejectOnFailure: true
  });
  return res.data;
};

export const fetchVideoProtection = async ({ videoId }: { videoId: string }) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/protect`, {
    method: "GET"
  });
  return res.status === 200 ? res.data : null;
};

export const setVideoPasswordProtection = async ({
  videoId,
  password
}: {
  videoId: string;
  password: string;
}) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/protect`, {
    method: "PUT",
    body: JSON.stringify({ password }),
    rejectOnFailure: true
  });
  return res.data;
};

export const removeVideoProtection = async ({ videoId }: { videoId: string }) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/${videoId}/protect`, {
    method: "DELETE",
    rejectOnFailure: true
  });
  return res.data;
};

export const getVideosFeed = async (skip: number, limit: number) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/videos/feed?skip=${skip}&limit=${limit}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const userConnect = async ({
  gcpToken,
  msToken
}: {
  gcpToken?: string;
  msToken?: string;
}) => {
  const headers: Record<string, string> = {};
  if (gcpToken) {
    headers["x-gcp-marketplace-token"] = gcpToken;
  }
  if (msToken) {
    headers["x-ms-marketplace-token"] = msToken;
  }

  const res = await fetchSecured(`${serverBaseUrl}/v2/users/connect`, {
    method: "POST",
    headers: headers,
    rejectOnFailure: true
  });
  return res;
};

export const importData = async (importDataStoreRequest: ImportDataStoreRequest) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/data-stores`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(importDataStoreRequest)
  });
  return res.data;
};

export const updateImportData = async (updateDataStoreRequest: UpdateDataStoreRequest) => {
  await fetchSecured(`${serverBaseUrl}/v2/data-stores/${updateDataStoreRequest.dataStoreId}`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(updateDataStoreRequest.updateRequest)
  });
};

export const getImportData = async (dataStoreId: string, includeContent = false) => {
  const res = await fetchSecured(
    `${serverBaseUrl}/v2/data-stores/${dataStoreId}?include_content=${includeContent}`,
    {
      method: "GET",
      rejectOnFailure: true
    }
  );
  return res.data;
};

export const draftGenerator = async (draftGeneratorRequest: draftGeneratorRequest) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/services/draft-generator`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(draftGeneratorRequest)
  });
  return res.data;
};
export const onBoarding = async () => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/users/onboarding`, {
    method: "POST",
    rejectOnFailure: true
  });
  return res.data;
};

export const mFAEnroll = async (type: MFATypes) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/auth/mfa/factors`, {
    method: "POST",
    body: JSON.stringify({ type }),
    rejectOnFailure: true
  });
  return res.data;
};

export const deleteMfa = async (id: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/auth/mfa/factors/${id}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const createVariable = async (draftId: string, variable: Omit<DraftVariable, "id">) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/variables`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(variable)
  });
  return res.data;
};

export const updateVariable = async (
  draftId: string,
  variableId: string,
  variable: Partial<DraftVariable>
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/variables/${variableId}`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(variable)
  });
  return res.data;
};

export const updateWorkflowForm = async (workflowId: string, form: Partial<WorkflowForm>) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/form`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(form)
  });
  return res.data;
};

export const publishWorkflow = async (workflowId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/publish`, {
    method: "PUT",
    rejectOnFailure: true
    // body: JSON.stringify(form)
  });
  return res.data;
};

export const getWorkflowLive = async (workflowId: string) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/live`, {
    method: "GET",
    rejectOnFailure: true
    // body: JSON.stringify(form)
  });
  return res.data;
};
export const createWorkflowSubmission = async (
  workflowId: string,
  variables: WorkflowVariableValue[] | undefined,
  action: "create_draft" | "create_video"
) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/submissions`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ variables, action, type: "variables" })
  });
  return res.data;
};
export const getWorkflow = async (workflowId: string): Promise<WorkflowResult> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}`, {
    method: "GET",
    rejectOnFailure: true
    // body: JSON.stringify(form)
  });
  return res.data;
};

export const getWorkflowSubmissions = async (
  workflowId: string
): // variables: WorkflowVariableValue[],
// action: "create_draft" | "create_video"
Promise<WorkflowSubmission[]> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/submissions`, {
    method: "GET",
    rejectOnFailure: true
    // body: JSON.stringify({ variables, action })
  });
  return res.data;
};

export const revertWorkflow = async (workflowId: string): Promise<DraftExtended> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/revert`, {
    method: "PUT",
    rejectOnFailure: true
  });
  return res.data;
};

export const connectDraftToDatastore = async (
  dataStoreId: string,
  draftId: string
): Promise<{ variables_created: WorkflowFormVariableResult[] }> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/drafts/${draftId}/connect`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({ datastore_id: dataStoreId, create_variables: true })
  });

  return res.data;
};
export const bulkSubmissions = async (
  dataStoreContentIds: string[],
  workflowId: string,
  dataStoreId: string
): Promise<
  {
    datastore_content_id: string;
    detail?: string;
    error?: string;
    index?: string;
    success: boolean;
    video?: Partial<Video>;
  }[]
> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/submissions/bulk`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(
      dataStoreContentIds.map((data) => ({
        datastore_content_id: data,
        type: "datastore_content",
        datastore_id: dataStoreId
      }))
    )
  });

  return res.data;
};

export const deleteBulkDrafts = async (draftsIds: string[]) => {
  await fetchSecured(`${serverBaseUrl}/v2/drafts/bulk-delete`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      draft_ids: draftsIds
    })
  });
};

export const csvExport = async (
  dataStoreContentIds: string[],
  workflowId: string,
  dataStoreId: string
): Promise<string> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/workflows/${workflowId}/export`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify({
      datastore_content_ids: dataStoreContentIds,
      type: "data_content",
      export_type: "csv",
      datastore_id: dataStoreId
    })
  });

  return res.data;
};

export const runSubmission = async (
  workflowId: string,
  submissionId: string,
  type: "create_draft" | "create_video"
): Promise<{ video?: Video; draft?: Draft; type: "create_draft" | "create_video" }> => {
  const res = await fetchSecured(
    `${serverBaseUrl}/v2/workflows/${workflowId}/submissions/${submissionId}/create`,
    {
      method: "POST",
      rejectOnFailure: true,
      body: JSON.stringify({ type })
    }
  );
  return res.data;
};

export const createBriefs = async (briefs: BriefRequest) => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/briefs`, {
    method: "POST",
    body: JSON.stringify(briefs),
    rejectOnFailure: true
  });
  return res.data;
};

export const getBriefs = async (): Promise<Brief[]> => {
  const res = await fetchSecured(`${serverBaseUrl}/v2/briefs`, {
    method: "GET",
    rejectOnFailure: true
  });
  return res.data;
};

export const deleteBriefs = async (briefId: string) => {
  await fetchSecured(`${serverBaseUrl}/v2/briefs/${briefId}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
};

export const getHourneMedia = async (): Promise<StockMedia[]> => {
  const result = await fetchSecured(`${serverBaseUrl}/v2/stock-media`, {
    method: "GET",
    rejectOnFailure: true
  });
  return result.data;
};

export const getTrashItemsByType = async (TrashItemType: TrashType): Promise<Trash[]> => {
  let url = `${serverBaseUrl}/v2/trash`;
  if (TrashItemType) {
    url += `?trash_type=${TrashItemType}`;
  }
  const result = await fetchSecured(url, {
    method: "GET",
    rejectOnFailure: true
  });
  return result.data;
};

export const recoverDraftsBulk = async (
  recoverDrafts: RecoverBulkDraftRequest[]
): Promise<void> => {
  await fetchSecured(`${serverBaseUrl}/v2/drafts/bulk-recover`, {
    method: "PUT",
    body: JSON.stringify(recoverDrafts),
    rejectOnFailure: true
  });
};
export const cleanDraftsBulk = async (cleanedDrafts: { item_id: string }[]): Promise<void> => {
  await fetchSecured(`${serverBaseUrl}/v2/trash/clean`, {
    method: "PUT",
    body: JSON.stringify(cleanedDrafts),
    rejectOnFailure: true
  });
};

export const getWebhooks = async () => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks`, {
    method: "GET",
    rejectOnFailure: true
  });
  return response.data;
};

export const getWebhook = async (webhookId: string) => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks/${webhookId}`, {
    method: "GET",
    rejectOnFailure: true
  });
  return response.data;
};
export const createWebhook = async (body: WebHookRequest) => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks`, {
    method: "POST",
    rejectOnFailure: true,
    body: JSON.stringify(body)
  });
  return response.data;
};

export const updateWebhook = async (webhookId: string, body: WebHookRequest) => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks/${webhookId}`, {
    method: "PUT",
    rejectOnFailure: true,
    body: JSON.stringify(body)
  });
  return response.data;
};

export const deleteWebhook = async (webhookId: string) => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks/${webhookId}`, {
    method: "DELETE",
    rejectOnFailure: true
  });
  return response.data;
};

export const togglePauseWebhook = async (webhookId: string) => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks/${webhookId}/pause`, {
    method: "PUT",
    rejectOnFailure: true
  });
  return response.data;
};

export const toggleStatusWebhook = async (webhookId: string) => {
  const response = await fetchSecured(
    `${Config.serverUrl}/api/v1/webhooks/${webhookId}/toggle-status`,
    {
      method: "PUT",
      rejectOnFailure: true
    }
  );
  return response.data;
};
export const createWebhookSecret = async (webhookId: string) => {
  const response = await fetchSecured(`${Config.serverUrl}/api/v1/webhooks/${webhookId}/secret`, {
    method: "PUT",
    rejectOnFailure: true
  });
  return response.data;
};
