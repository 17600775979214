import React from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { H1_TextMiddle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Orientation, PlanEnum, Recipe } from "app/types";
import { SwiperSlide } from "swiper/react";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import SingleTemplateItem from "app/pages/Templates/SingleTemplateItem";
import { useAppDispatch, useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import { recipesActions } from "app/store/slices/recipes.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useIntl } from "react-intl";
import { templatesPageMessages } from "app/pages/Templates/messages";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { Button } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { ThemeMode } from "app/utils/theme";

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: calc(100% - 24px);
  flex: 0 0 auto;
  margin-left: 0;
  .swiper-slide {
    display: flex;
    width: fit-content;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    top: 50%;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.gray5};
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${(props) => props.theme.gray8};
    background-color: ${(props) => props.theme.gray2};
    transition-property: border-color, color, opacity;
    transition-duration: 0.3s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    svg {
      fill: ${(props) => props.theme.gray8};
      width: 16px;
      height: 16px;
    }
    &.swiper-button-disabled {
      pointer-events: auto;
    }
    &:hover {
      border-color: ${(props) => props.theme.gray5};
    }
    &:active {
      transform: scale(0.95);
    }
    &::after {
      content: "";
    }
  }
  && .swiper-button-prev {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    left: 15px;
  }

  && .swiper-button-next {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    right: 15px;
  }
  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const BorderFlexColumn = styled(H1_FlexColumn)`
  border: 3px dashed ${({ theme }) => theme.gray5};
  border-radius: 4px;
  &:hover {
    border-color: ${({ theme }) => theme.blue4};
    i,
    span {
      color: ${({ theme }) => theme.blue4};
    }
  }
`;

const PlusIcon = styled.i`
  color: ${({ theme }) => theme.gray7};
  position: absolute;
  top: -2px;
  left: 7px;
  font-size: 20px;
`;

const TemplateIcon = styled.i`
  color: ${({ theme }) => theme.gray7};
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 20px;
`;

const CustomTemplateText = styled(H1_TextXs)`
  width: 120px;
`;

const SPACE_BETWEEN_TEMPLATES = 28;

interface FullTemplatesPageCollectionProps {
  orientation: Orientation;
  sectionName: string;
  sectionId?: string;
  numOfSlides: number;
  showCustomTemplate?: boolean;
}

const FullTemplatesPageCollection = ({
  orientation,
  sectionName,
  sectionId,
  numOfSlides,
  showCustomTemplate = false
}: FullTemplatesPageCollectionProps) => {
  const showPaymentModal = useUpgradeModal();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const filteredTemplates = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipesByOrientationAndId(state, orientation, undefined)
  );
  const templates = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipesBySectionName(state, filteredTemplates, sectionName)
  );

  const onClickSeeAll = () => {
    dispatch(analyticsEvents.templateCategorySelect({ categoryName: sectionName as string }));
    dispatch(
      recipesActions.updateSelectedSectionNames({
        name: sectionName
      })
    );
  };

  const onClickCustomTemplate = () => {
    const upgradeText = formatMessage(templatesPageMessages.createCustomTemplate);
    const upgradeSubText = formatMessage(templatesPageMessages.createCustomTemplateSubtitle);
    dispatch(analyticsEvents.requestTemplate());
    showPaymentModal({
      upgradeText,
      upgradeSubText,
      source: "upgrade_custom_template",
      requestedPlans: [PlanEnum.enterprise]
    });

    return;
  };

  if (templates.length === 0) {
    return null;
  }

  return (
    <H1_FlexRow
      key={sectionId || sectionName}
      height="100%"
      width="100%"
      position="relative"
      padding="0 30px 42px 50px"
      flex="0 0 235px"
    >
      <H1_FlexColumn gap="10px" flex="1 1 100%" width="100%">
        <H1_FlexRow justify="space-between" align="flex-end">
          <H1_TextMiddle color={theme.gray8}>{sectionName}</H1_TextMiddle>
          <Button variant="light" onClick={onClickSeeAll}>
            <H1_TextMiddle>{formatMessage(templatesPageMessages.seeAll)}</H1_TextMiddle>
            <H1_Icon color={theme.gray11} icon="far fa-chevron-right" />
          </Button>
        </H1_FlexRow>
        <H1_FlexRow position="relative" width="100%" flex="0 0 160px" gap="28px">
          <StyledSwiper
            navigation
            speed={700}
            slidesPerView="auto"
            spaceBetween={SPACE_BETWEEN_TEMPLATES}
            slidesPerGroup={numOfSlides}
          >
            {showCustomTemplate && (
              <SwiperSlide key="custom-template">
                <BorderFlexColumn
                  onClick={onClickCustomTemplate}
                  height="160px"
                  width="calc(160px * 16 / 9)"
                  flex="0 0 auto"
                  align="center"
                  justify="center"
                >
                  <H1_FlexRow position="relative" height="34px" width="58px">
                    <PlusIcon className="far fa-plus" />
                    <TemplateIcon className="far fa-table-layout" />
                  </H1_FlexRow>
                  <CustomTemplateText color={theme.gray7} whiteSpace="normal" textAlign="center">
                    {formatMessage(templatesPageMessages.createCustomTemplate, { br: <br /> })}
                  </CustomTemplateText>
                </BorderFlexColumn>
              </SwiperSlide>
            )}
            {templates.map((recipe: Recipe) => (
              <SwiperSlide key={recipe.id}>
                <SingleTemplateItem recipe={recipe} />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </H1_FlexRow>
      </H1_FlexColumn>
    </H1_FlexRow>
  );
};

export default FullTemplatesPageCollection;
