import styled from "styled-components";
import { Button } from "antd";

const CardButton = styled(Button)<{ $selected?: boolean }>`
  width: 100%;
  height: auto;
  color: ${(props) => (props.$selected === true ? props.theme.pink4 : "#7e7e7e")};
  box-shadow: ${(props) => (props.$selected === true ? "0px 0px 4px rgba(0, 0, 0, 0.25)" : "none")};
  border: none;
  &:focus,
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border: none;
  }
`;

export default CardButton;
