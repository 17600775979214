import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { getTextsAssetFromLayoutWithoutTranscript } from "app/store/selectorsV2/scenes.selectors";
import { LayoutAsset, LayoutCard, LayoutElementType } from "app/types/layout";
import { useAppSelector } from "app/hooks";
import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import styled, { css, useTheme } from "styled-components";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useDispatch } from "react-redux";
import { CharacterRecord, Draft, FeatureFlag, FetchStatus, PatchOperation, Voice } from "app/types";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";

import SingleMediaSection from "app/components/editor/sideDrawers/SceneDrawer/SingleMediaSection";
import {
  fetchingStatus,
  isSceneAssetGenerationLoading,
  replaceFlagSeparator
} from "app/utils/helpers";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import useErrors from "app/hooks/useErrors";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import * as voiceSelectors from "app/store/selectorsV2/voices.selectors";
import TextAsset from "app/components/editor/sideDrawers/SceneDrawer/TextAsset";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { Tooltip, Typography } from "antd";
import { getEmotionEmoji } from "app/types/character";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import CardSection from "app/components/editor/sideDrawers/SceneDrawer/CardSection";
import layoutSelectors from "app/store/selectorsV2/layout.selectors";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const NameText = styled(Typography.Text)`
  font-size: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.gray8};
  text-align: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.gray4};
`;
const EyeButton = styled(Button)`
  i {
    cursor: pointer;
    font-size: 11px;
  }
`;
const CharacterContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
`;
const EntityBorderFlexRow = styled(H1_FlexRow)<{ $visible?: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.gray5};
  opacity: ${({ $visible }) => ($visible === true || $visible === undefined ? 1 : 0.2)};
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;
const FlagImg = styled.img`
  border-radius: 3px;
  width: 42px;
  height: 32px;
  object-fit: cover;
`;
const CharacterImg = styled.img`
  border-radius: 4px;
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: top 7% left 0;
  background-color: rgba(211, 211, 211, 0.5);
  transform: scale(1.5);
`;
const LayoutContentFlexColumn = styled(H1_FlexColumn)`
  opacity: 1;
  transition: flex 0.3s ease-in, opacity 0.3s ease-in;
`;

const BorderBottomFlexColumn = styled(H1_FlexColumn)`
  padding-bottom: 20px;
  ${({ isTextAvailable }: { isTextAvailable: boolean }) =>
    isTextAvailable &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.gray4};
    `};
`;
const MediaText = styled(H1_TextSmall)`
  line-height: 22px;
`;

const ChildFlexColumn = styled(H1_FlexColumn)`
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-04);
    padding-bottom: 10px;
  }
`;

const ContentSection = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { notifyError } = useErrors();
  const dispatch = useDispatch();
  const { openDrawer } = useDrawer();
  const flags = useFlags();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();

  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const sceneAssetsGenerationStatus: Record<string, FetchStatus> = useAppSelector(
    (state) => state.scenes.genrateSceneAssetStatus
  );

  const textAssets = useAppSelector((state) =>
    getTextsAssetFromLayoutWithoutTranscript(state, sceneId, true)
  );
  const cards = useAppSelector((state) =>
    layoutSelectors.getLayoutCardsById(state, scene?.layout?.id)
  );
  const selectedCharacter = useAppSelector(characterSelectors.getDrawerCharacterBySelectedScene);
  const selectedVoice: Voice | undefined = useAppSelector(voiceSelectors.getSelectedVoiceForDrawer);
  const flagSvg = replaceFlagSeparator(selectedVoice?.local);

  const mediaAsset = scene?.layout?.assets?.media;
  const visualAsset = scene?.layout?.assets?.visual;
  const layoutCharacter = scene?.layout?.assets?.character;
  const visualForeground = useMemo(
    () =>
      visualAsset?.filter(
        (visualAsset) =>
          visualAsset.element_type === LayoutElementType.foreground && !visualAsset.card_id
      ),
    [visualAsset]
  );
  const visualBackground = useMemo(
    () =>
      visualAsset?.filter(
        (visualAsset) =>
          visualAsset.element_type === LayoutElementType.background && !visualAsset.card_id
      ),
    [visualAsset]
  );

  const isMediaExists =
    (mediaAsset && mediaAsset.length > 0) || (visualAsset && visualAsset.length > 0);
  const attributeType =
    visualAsset !== undefined && Object.keys(visualAsset).length > 0 ? "visual" : "media";
  const sceneName = scene?.name as string;
  const draftId = draft?.id as string;
  const isTextAssets = textAssets.length > 0;

  const characterPresetOverride = useMemo(
    () =>
      scene?.attributes?.character &&
      (scene?.attributes?.character?.character as CharacterRecord)?.preset_override,
    [sceneId, scene?.attributes?.character]
  );

  const isLayoutCharacterPreset = useMemo(
    () => layoutCharacter?.some((character) => !!character.preset),
    []
  );
  const currentCharacterVisible =
    layoutCharacter &&
    ((characterPresetOverride?.has_character === undefined &&
      (layoutCharacter[0].preset?.has_character ||
        layoutCharacter[0].preset?.has_character === undefined)) ||
      characterPresetOverride?.has_character === true);

  useEffect(() => {
    [...textAssets, ...(mediaAsset || [])].forEach((asset) => {
      const genTitle = sceneAssetsGenerationStatus[`${sceneId}-attributes.text.${asset.key}.text`];
      const genMedia =
        sceneAssetsGenerationStatus[`${sceneId}-attributes.${attributeType}.${asset.key}`];
      if (genMedia === fetchingStatus.succeeded || genTitle === fetchingStatus.succeeded) {
        dispatch(scenesActions.updateGenerateSceneAssetsStatusToIdle);
      }

      if (genMedia === fetchingStatus.failed || genTitle === fetchingStatus.failed) {
        notifyError({
          general: buildGeneralError(undefined, intl)
        });
        dispatch(scenesActions.updateGenerateSceneAssetsStatusToIdle);
      }
    });
  }, [sceneAssetsGenerationStatus, textAssets, mediaAsset]);

  const onTextChange = (key: string, value: string, textTitle: string): void => {
    const attribute: { text: string } | undefined =
      scene?.attributes?.text && (scene?.attributes?.text[key] as { text: string });
    if (attribute && attribute.text === value) {
      return;
    }

    const operations = [
      { op: "replace", path: `attributes.text.${key}.text`, value }
    ] as PatchOperation[];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
    dispatch(
      analyticsEvents.changeTitle({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        },
        title: {
          name: textTitle,
          value
        }
      })
    );
  };

  const onClickAvatarEye = () => {
    if (!layoutCharacter) {
      return;
    }
    const operations = [
      {
        op: "replace",
        path: `attributes.character.character.preset_override.has_character`,
        value: !currentCharacterVisible
      }
    ] as PatchOperation[];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const onClickAvatar = () => {
    if (currentCharacterVisible) {
      openDrawer(Drawer.Presenter);
    }
  };

  const onClickVoice = () => {
    openDrawer(Drawer.Voices);
  };

  const onClickGenerateTitle = (key: string, textTitle: string) => {
    const operations = [
      { op: "generate", path: `attributes.text.${key}.text` }
    ] as PatchOperation[];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
    dispatch(
      analyticsEvents.generateTitle({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        },
        title: {
          name: textTitle
        }
      })
    );
  };

  const avatarEyeTooltip = useMemo(() => {
    if (!layoutCharacter) {
      return intl.formatMessage(sceneDrawerMessages.avatarEyeNotSupportedTooltip);
    }
    if (currentCharacterVisible) {
      return intl.formatMessage(sceneDrawerMessages.avatarEyeHideTooltip);
    } else {
      return intl.formatMessage(sceneDrawerMessages.avatarEyeShowTooltip);
    }
  }, [layoutCharacter, currentCharacterVisible]);

  return (
    <LayoutContentFlexColumn
      padding="0 30px 20px 0"
      flex="0 1 auto"
      width="auto"
      overflow="auto"
      data-auto-id="title-media-section"
    >
      <ConditionalRender condition={flags[FeatureFlag.multiplePresenters] && !!layoutCharacter}>
        <H1_FlexColumn flex="0 0 auto" gap="15px" padding="0 0 15px">
          <H1_TextSmall>{intl.formatMessage(sceneDrawerMessages.avatarAndVoiceTitle)}</H1_TextSmall>
          <H1_FlexRow align="center" justify="space-between">
            <H1_FlexRow align="center" gap="8px" width="100%">
              <ConditionalRender condition={!!isLayoutCharacterPreset}>
                <Tooltip title={avatarEyeTooltip}>
                  <>
                    <EyeButton
                      onClick={onClickAvatarEye}
                      isDisabled={!layoutCharacter}
                      startContent={
                        <H1_Icon
                          color={theme.gray7}
                          icon={currentCharacterVisible ? "fas fa-eye" : "fas fa-eye-slash"}
                        />
                      }
                      isIconOnly
                      size="sm"
                      variant="light"
                    />
                  </>
                </Tooltip>
              </ConditionalRender>
              <EntityBorderFlexRow
                data-auto-id="avatar-drawer-selected-avatar"
                flex="0 0 136px"
                width="136px"
                height="48px"
                padding="5px 6px"
                gap="10px"
                align="center"
                $visible={!!currentCharacterVisible}
                onClick={onClickAvatar}
              >
                <CharacterContainerFlexRow
                  overflow="hidden"
                  width="32px"
                  height="32px"
                  flex="0 0 32px"
                >
                  <CharacterImg src={selectedCharacter?.thumbnails?.page_banner.image as string} />
                </CharacterContainerFlexRow>
                <NameText ellipsis={{ tooltip: selectedCharacter?.title }}>
                  {selectedCharacter?.title}
                </NameText>
                <H1_FlexRow flex="0 0 22px" align="center">
                  {getEmotionEmoji(selectedCharacter?.emotion)}
                </H1_FlexRow>
              </EntityBorderFlexRow>
              <EntityBorderFlexRow
                flex="0 0 136px"
                width="136px"
                height="48px"
                padding="5px 6px"
                gap="5px"
                align="center"
                onClick={onClickVoice}
              >
                <FlagImg src={`https://flagcdn.com/${flagSvg}.svg`} />
                <NameText ellipsis={{ tooltip: selectedVoice?.display_name }}>
                  {selectedVoice?.display_name}
                </NameText>
              </EntityBorderFlexRow>
            </H1_FlexRow>
          </H1_FlexRow>
          <Divider />
        </H1_FlexColumn>
      </ConditionalRender>

      {/* Media selection */}
      <ConditionalRender
        condition={isMediaExists && (!!mediaAsset?.length || !!visualForeground?.length)}
      >
        <BorderBottomFlexColumn
          isTextAvailable={!!isTextAssets}
          flex="0 0 auto"
          data-auto-id="media-section"
        >
          <H1_FlexRow justify="space-between" align="baseline">
            <MediaText fontSize="14px" color={theme.gray11}>
              {intl.formatMessage(sceneDrawerMessages.mediaTitle)}
            </MediaText>
          </H1_FlexRow>
          {mediaAsset?.map((currentMedia: LayoutAsset) => (
            <H1_FlexColumn key={currentMedia.key} align="flex-end">
              <ChildFlexColumn padding="10px 0 0 0" width="100%">
                <SingleMediaSection
                  loading={
                    sceneAssetsGenerationStatus[
                      `${sceneId}-attributes.media.${currentMedia.key}`
                    ] === fetchingStatus.loading
                  }
                  media={currentMedia}
                  mediaUrl={
                    (scene?.attributes?.media &&
                      scene?.attributes?.media[currentMedia.key as string]?.url) ||
                    ""
                  }
                  key={currentMedia.key}
                />
              </ChildFlexColumn>
            </H1_FlexColumn>
          ))}
          {visualForeground?.map((currentMedia: LayoutAsset) => (
            <H1_FlexColumn key={currentMedia.key} align="flex-end">
              <ChildFlexColumn padding="10px 0 0 0" width="100%">
                <SingleMediaSection
                  loading={isSceneAssetGenerationLoading(
                    sceneAssetsGenerationStatus,
                    sceneId,
                    currentMedia.key
                  )}
                  media={currentMedia}
                  mediaUrl={
                    (scene?.attributes?.visual &&
                      scene?.attributes?.visual[currentMedia.key as string]?.preset_override
                        ?.media_url) ||
                    currentMedia.preset?.media_url ||
                    ""
                  }
                  key={currentMedia.key}
                />
              </ChildFlexColumn>
            </H1_FlexColumn>
          ))}
        </BorderBottomFlexColumn>
      </ConditionalRender>

      {/* Texts selection */}
      <ConditionalRender condition={isTextAssets}>
        <H1_TextSmall
          margin={visualForeground?.length || mediaAsset?.length ? "15px 0 13px 0" : "0"}
          fontSize="14px"
          color={theme.gray11}
        >
          {intl.formatMessage(sceneDrawerMessages.titlesTitle)}
        </H1_TextSmall>
        <BorderBottomFlexColumn
          isTextAvailable={!!visualBackground?.length}
          gap="13px"
          flex="0 0 auto"
        >
          {textAssets.map((text: LayoutAsset) => (
            <TextAsset
              key={text.key}
              asset={text}
              onClickGenerateTitle={onClickGenerateTitle}
              onTextChange={onTextChange}
              draftId={draftId}
              sceneAssetsGenerationStatus={sceneAssetsGenerationStatus}
            />
          ))}
        </BorderBottomFlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!!cards?.length}>
        {cards?.map((card: LayoutCard) => (
          <CardSection
            key={card.id}
            cardId={card.id}
            name={card.name}
            onClickGenerateTitle={onClickGenerateTitle}
            onTextChange={onTextChange}
          />
        ))}
      </ConditionalRender>
      <ConditionalRender condition={!!visualBackground?.length}>
        <H1_FlexRow flex="1 0 auto" justify="space-between" align="baseline" padding="10px 0 0 0">
          <MediaText fontSize="14px" color={theme.gray11}>
            {intl.formatMessage(sceneDrawerMessages.background)}
          </MediaText>
        </H1_FlexRow>
        {visualBackground?.map((currentMedia: LayoutAsset) => (
          <H1_FlexColumn key={currentMedia.key} align="flex-end" flex="1 0 auto">
            <ChildFlexColumn padding="10px 0 0 0" width="100%" flex="1 0 auto">
              <SingleMediaSection
                loading={isSceneAssetGenerationLoading(
                  sceneAssetsGenerationStatus,
                  sceneId,
                  currentMedia.key
                )}
                media={currentMedia}
                mediaUrl={
                  (scene?.attributes?.visual &&
                    scene?.attributes?.visual[currentMedia.key as string]?.preset_override
                      ?.media_url) ||
                  currentMedia.preset?.media_url ||
                  ""
                }
                key={currentMedia.key}
              />
            </ChildFlexColumn>
          </H1_FlexColumn>
        ))}
      </ConditionalRender>
    </LayoutContentFlexColumn>
  );
};

export default ContentSection;
