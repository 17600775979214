import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";
import uiActions from "app/store/actions/ui.actions";
import { validateServerError } from "app/components/editor/validations";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import { VALIDATION_ERROR } from "app/store/thunks/thunkCommon";
import { ModalName } from "app/hooks/useModal";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import messages from "app/components/editor/validations-messages";
import axios from "axios";
import { userActions } from "app/store/slices/user.slice";
import { CreditsType } from "app/types/pusherMessages";

export const errorHandlerMiddleware: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (action.error) {
      const { intl } = getState().userUi;
      if (action.error === "Rejected") {
        if (action.payload.key === VALIDATION_ERROR) {
          dispatch(uiActions.setErrors(action.payload.errors));
        }
      } else if (action.error.status) {
        const detail = action.error.message;

        if (action?.error?.status === 402) {
          if (!action?.meta?.arg?.skip402ErrorHandling) {
            if (detail.includes("your account is paused")) {
              dispatch(
                uiActions.setErrors({
                  general: buildGeneralError("Your account is paused", intl)
                })
              );
            } else if (detail === "Workspace has reached maximum number of seats for role member") {
              dispatch(
                uiActions.setEditorModalOpen(
                  ModalName.maximumWorkspaceSeats,
                  {},
                  action?.meta?.arg?.backModal
                )
              );
            } else {
              if (detail === "insufficient credits for operation for credits type llm") {
                dispatch(
                  userActions.setCreditsWarningMsg({
                    spent_percentage: 100,
                    credits_type: CreditsType.LLM
                  })
                );
              } else {
                dispatch(
                  uiActions.setEditorModalOpen(
                    ModalName.noEnoughCredits,
                    { action, ...action.meta?.arg },
                    action?.meta?.arg?.backModal
                  )
                );
              }
            }
          }
        } else if (action?.error?.status === 401) {
          if (!action?.meta?.arg?.skip401ErrorHandling) {
            Sentry.captureMessage(`user got 401 from our api`);
            dispatch(uiActions.setTokenRefreshError());
          }
        } else if (action?.error?.status === 403 && detail === "workspace not authorized") {
          dispatch(uiActions.setEditorModalOpen(ModalName.noAccessToWorkspace));
        } else {
          const sanitized = typeof detail === "object" ? JSON.stringify(detail) : detail;
          const errors = validateServerError(sanitized, intl);
          dispatch(uiActions.setErrors(errors));
          if (
            action.error.message === intl.formatMessage(messages.generic) ||
            !action.error.message
          ) {
            captureException(
              new Error(`unhandled status code ${action.status}. action type: ${action.type}`),
              {
                extra: {
                  action,
                  error: action.error,
                  isOnline: getState().uiV2.isOnline
                }
              }
            );
            // @ts-ignore -> useAppDispatch is not leagal
            dispatch(
              analyticsEvents.somethingWentWrongEvent({
                stack: action.error.stack,
                status: action?.error?.status
              })
            );
          }
        }
      } else {
        const isAxiosError = axios.isAxiosError(action.error.originalError);
        console.error("unresolved error", action.error);
        if (!isAxiosError) {
          dispatch(
            analyticsEvents.somethingWentWrongEvent({
              stack: action.error.stack,
              status: action?.error?.status
            })
          );
          dispatch(
            uiActions.setErrors({
              general: buildGeneralError("Reach out to support if the issue persists", intl)
            })
          );
        }

        captureException(new Error(`unresolved error ${action?.error?.message}`), {
          extra: {
            action,
            error: action.error,
            isAxiosError: isAxiosError,
            isOnline: getState().uiV2.isOnline
          }
        });
      }
    }
    return next(action);
  };
export default errorHandlerMiddleware;
