import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import {
  charactersGlobalSelectors,
  scenesGlobalSelectors,
  voicesGlobalSelectors
} from "app/store/adapters/adapters";

import * as characterSelector from "app/store/selectorsV2/character.selectors";
import { Voice, VoiceAll, VoiceType } from "app/types";
import { mapLanguageToLocale } from "app/utils/iso6393To1";
import { capitalize } from "lodash";

const currentOpenedScene = (state: RootState) => state.scenes.selectedSceneId;
const currentDraft = (state: RootState) => state.drafts.currentDraft;
export const template = (state: RootState) => state.templates.currentTemplate;
export const createDraftStatus = (state: RootState) => state.drafts.createDraftStatus;
const sceneEntities = (state: RootState) => state.scenes.entities;
const filters = (state: RootState) => state.voices.filters;

export const filteredTemplateCharacters = createSelector(
  [voicesGlobalSelectors.selectAll, template],
  (allVoices, currentTemplate) => {
    if (currentTemplate?.voices?.length) {
      return allVoices.filter((v) => currentTemplate?.voices?.includes(v.id));
    }
    return allVoices;
  }
);

export const getDraftVoiceId = createSelector(
  [currentDraft, voicesGlobalSelectors.selectEntities],
  (draft, voices) => {
    const voiceObject = voices[draft?.attributes?.voice?.voice?.voice_id as string];
    return voiceObject?.id;
  }
);

export const getDraftOrSceneVoice = createSelector(
  [
    currentDraft,
    voicesGlobalSelectors.selectEntities,
    sceneEntities,
    (state, sceneId: string) => sceneId
  ],
  (draft, voices, scenesEntities, sceneId) => {
    let voiceObject = voices[draft?.attributes?.voice?.voice?.voice_id as string];
    if (!draft.global_character) {
      const currentSceneId = sceneId;
      const currentScene = scenesEntities[currentSceneId];
      voiceObject = voices[currentScene?.attributes?.voice?.voice?.voice_id as string];
    }
    return voiceObject;
  }
);

export const getSelectedVoiceForDrawer = createSelector(
  [
    scenesGlobalSelectors.selectEntities,
    voicesGlobalSelectors.selectEntities,
    filteredTemplateCharacters,
    charactersGlobalSelectors.selectAll,
    currentOpenedScene,
    currentDraft
  ],
  (scenes, voices, filteredVoices, characters, openedScene, draft) => {
    if (openedScene && !draft.global_character) {
      const scene = scenes[openedScene];
      if (scene) {
        const voice = (voices[scene.attributes?.voice?.voice?.voice_id as string] ||
          voices[draft?.attributes?.voice?.voice?.voice_id as string]) as Voice;
        if (!voice) {
          return undefined;
        }

        if (filteredVoices.includes(voice)) {
          return voice;
        } else {
          const voiceGender = voice.gender;
          return filteredVoices.filter((voice) => voice.gender === voiceGender)[0];
        }
      }
    }
    if (draft && draft.global_character) {
      const voice = voices[draft?.attributes?.voice?.voice?.voice_id as string] as Voice;
      if (filteredVoices.includes(voice)) {
        return voice;
      } else {
        const characterId = draft.attributes?.character?.character?.character_id as string;
        const character = characters.find((character) => character.character_id === characterId);
        const characterGender = character?.gender;
        return filteredVoices.filter((voice) => voice.gender === characterGender)[0];
      }
    }
    return undefined;
  }
);

export const makeGetProjectCharacterOptionalVoiceData = createSelector(
  [characterSelector.getDrawerCharacterBySelectedScene, filteredTemplateCharacters],
  (characterData, allVoices) => {
    if (!characterData || !characterData.gender) return allVoices;
    return allVoices.filter(
      (voice) =>
        voice.gender?.toLowerCase() === characterData.gender.toLowerCase() ||
        voice.type === VoiceType.cloned
    );
  }
);

const filterAndSortVoices = createSelector(
  [makeGetProjectCharacterOptionalVoiceData],
  (voicesList: Voice[]) => {
    const usVoices = voicesList.filter((voice) => voice.local === "us");
    const gbVoices = voicesList.filter((voice) => voice.local === "gb");
    const deVoices = voicesList.filter((voice) => voice.local === "de");
    const frVoices = voicesList.filter((voice) => voice.local === "fr");
    const esVoices = voicesList.filter((voice) => voice.local === "es");
    const saVoices = voicesList.filter((voice) => voice.local === "sa");
    const languagesVoices = [
      ...usVoices,
      ...gbVoices,
      ...deVoices,
      ...frVoices,
      ...esVoices,
      ...saVoices
    ];
    const nonUsVoices = voicesList
      .filter((voice) => !languagesVoices.includes(voice))
      .sort((a, b) => a.local.localeCompare(b.local));

    return [...languagesVoices, ...nonUsVoices].sort((a, b) => {
      if (a.type === VoiceType.cloned && b.type !== VoiceType.cloned) {
        return -1;
      }
      if (b.type === VoiceType.cloned && a.type !== VoiceType.cloned) {
        return 1;
      }
      return 0;
    });
  }
);

const getLocalesLanguages = (query: string) => {
  const filteredLanguagesByQuery = Object.keys(mapLanguageToLocale).filter((language: string) =>
    language.toLowerCase().includes(query.toLowerCase())
  );
  const languagesLocales = filteredLanguagesByQuery.flatMap((lang) => mapLanguageToLocale[lang]);
  return languagesLocales;
};

export const getFilteredVoicesBySearch = createSelector(
  [filterAndSortVoices, filters, (state, query: string) => query],
  (voicesList, filters, query) => {
    let res = voicesList;

    if (filters.age !== VoiceAll) {
      res = res.filter((v: Voice) => v.age === filters.age);
    }
    if (filters.useCase !== VoiceAll) {
      res = res.filter((v: Voice) => v.use_case?.includes(filters.useCase as string));
    }
    if (filters.tone !== VoiceAll) {
      res = res.filter((v: Voice) => v.tone?.includes(filters.tone as string));
    }
    if (filters.pause) {
      res = res.filter((v: Voice) => v.pause_supported);
    }
    if (query) {
      const languagesLocales = getLocalesLanguages(query);
      res = res.filter(
        (voice: Voice) =>
          voice.display_name?.toLowerCase().includes(query.toLowerCase()) ||
          languagesLocales.includes(voice.local)
      );
    }

    return res;
  }
);

export const isUserHasMoreAtLeastTwoClonedVoices = createSelector(
  [voicesGlobalSelectors.selectAll],
  (voices) => {
    return voices.filter((voice) => voice.type === VoiceType.cloned)?.length >= 2;
  }
);

export const getUniqueDropdownValuesByProperties = createSelector(
  [
    voicesGlobalSelectors.selectAll,
    (state, properties: string[], allText: string) => ({
      properties,
      allText
    })
  ],
  (allVoices, { properties, allText }) => {
    const result: any = {};

    properties.forEach((property) => {
      // Use flatMap to handle both strings and string arrays
      const values = allVoices.flatMap((item) => {
        const value = item[property as keyof Voice];
        if (Array.isArray(value)) {
          return value.filter((v): v is string => typeof v === "string"); // Filter out non-string values
        } else if (typeof value === "string") {
          return [value];
        } else {
          return []; // Exclude non-string and non-array values
        }
      });

      // Create a set to remove duplicates, then convert back to array
      const uniqueValues = [...new Set(values)]
        .filter((item): item is string => item !== undefined) // Filter out undefined values
        .sort((a, b) => a.localeCompare(b)) // Sort the values
        .map((item: string) => ({
          // Map to desired structure
          value: item,
          label: capitalize(item).replace("_", " ")
        }));

      // Add 'all' option at the beginning
      uniqueValues.unshift({ value: VoiceAll, label: allText });

      result[property] = uniqueValues;
    });

    return result;
  }
);

export const getUniqueDropdownValuesByPropertiesNextUI = createSelector(
  [
    voicesGlobalSelectors.selectAll,
    (state, properties: string[], allText: string) => ({
      properties,
      allText
    })
  ],
  (allVoices, { properties, allText }) => {
    const result: any = {};

    properties.forEach((property) => {
      // Use flatMap to handle both strings and string arrays
      const values = allVoices.flatMap((item) => {
        const value = item[property as keyof Voice];
        if (Array.isArray(value)) {
          return value.filter((v): v is string => typeof v === "string"); // Filter out non-string values
        } else if (typeof value === "string") {
          return [value];
        } else {
          return []; // Exclude non-string and non-array values
        }
      });

      // Create a set to remove duplicates, then convert back to array
      const uniqueValues = [...new Set(values)]
        .filter((item): item is string => item !== undefined) // Filter out undefined values
        .sort((a, b) => a.localeCompare(b)) // Sort the values
        .map((item: string) => ({
          // Map to desired structure
          key: item,
          label: capitalize(item).replace("_", " ")
        }));

      // Add 'all' option at the beginning
      uniqueValues.unshift({ key: VoiceAll, label: allText });

      result[property] = uniqueValues;
    });

    return result;
  }
);
