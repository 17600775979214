import { ReactNode } from "react";
import styled, { css, useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Tag } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledButton = styled(Button)<{ $active: boolean }>`
  flex-direction: column;
  border-radius: 4px;
  padding: 0;
  gap: 2px;
  margin: 0;
  width: 61px;
  max-width: 61px;
  min-width: 61px;
  height: 51px;
  color: ${({ theme }) => theme.gray8};
  background-color: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
  display: inline-flex;
  font-size: 12px;
  line-height: 12px;
  i {
    border-radius: 7px;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: ${({ theme }) => theme.gray8};
    width: 27px;
    height: 23px;
  }
  &:hover,
  &:active {
    span {
      color: ${({ theme }) => theme.gray8};
    }
    background-color: ${({ theme }) => theme.gray2};
    &::before {
      content: "";
      background: linear-gradient(180deg, #7a7a7a 0%, rgba(149, 149, 149, 0.9) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    i {
      background: linear-gradient(180deg, #7a7a7a 0%, rgba(149, 149, 149, 0.9) 100%);
      background-color: ${({ theme }) => theme.gray2};
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
  ${({ theme }) =>
    theme.mode === ThemeMode.Dark &&
    css`
      && {
        span {
          color: ${({ theme }) => theme.gray8};
        }
        &::before {
          content: "";
          background: linear-gradient(180deg, #7a7a7a 0%, rgba(149, 149, 149, 0.9) 100%);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
        && i {
          background: linear-gradient(180deg, #7a7a7a 0%, rgba(149, 149, 149, 0.9) 100%);
          background-color: ${({ theme }) => theme.gray2};
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    `};
  ${({ $active }) =>
    $active &&
    css`
      && {
        background: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue1 : theme.blue4)};
        color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};

        && i {
          color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};
        }

        span {
          color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};
        }
      }
    `};
`;

const RenderFlexColumn = styled(H1_FlexColumn)`
  ${({ isHidden }: { isHidden: boolean }) => css`
    display: ${isHidden ? "none" : "inherit"};
  `};
  align-items: center;
`;

const StyledTag = styled(Tag)`
  color: white;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 20px;
  padding: 0 2px;
  font-size: 8px;
  line-height: 10px;
  margin: 0;
  height: 12px;
  z-index: 2;
`;

export interface AttributeIconProps {
  onClick: () => void;
  drawerActive: boolean;
  title: string;
  dataAutoId?: string;
  isHidden?: boolean;
  tag?: string;
  children: ReactNode;
}

const AttributeIcon = ({
  onClick,
  drawerActive,
  title,
  dataAutoId,
  isHidden,
  tag,
  children
}: AttributeIconProps) => {
  const theme = useTheme();
  return (
    <RenderFlexColumn data-auto-id={dataAutoId} isHidden={isHidden || false} position="relative">
      <ConditionalRender condition={!!tag}>
        <StyledTag color={theme.blue2}>{tag}</StyledTag>
      </ConditionalRender>
      <StyledButton onClick={onClick} $active={drawerActive} startContent={children}>
        {title}
      </StyledButton>
    </RenderFlexColumn>
  );
};

export default AttributeIcon;
