import React, { Key, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "app/ThemeProvider";
import AccountAndNewVideoHeader from "app/pages/Header/AccountAndNewVideoHeader";
import { BoostProvider, FeatureFlag, HeaderTab, Location, Video } from "app/types";
import { Link, useLocation } from "react-router-dom";
import { businessMessages } from "app/pages/Header/messages";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { HeaderContext } from "app/hoc/HeaderContext";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { ReactComponent as HourOneNewLogo } from "app/assets/sider/hourone-new-logo.svg";
import useSmallScreen from "app/hooks/useSmallScreen";
import DevModeSwitch from "app/components/DevModeSwitch";
import WorkspaceSearch from "app/components/common/Layout/WorkspaceSearch";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import HeaderCommentsNotifications from "app/components/common/Layout/HeaderCommentsNotifications";
import { useFlags } from "launchdarkly-react-client-sdk";
import usePermissions from "app/hooks/usePermissions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { workspaceSupportsLiveBlocks } from "app/store/selectorsV2/workspaces.selectors";
import LightningModal from "app/components/common/LightningModal/LightningModal";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useUpgradeToEnterprise } from "app/hooks/useUpgradeModal";
import { Button, Tabs } from "@nextui-org/react";
import NovuNotificationCenter from "app/hoc/NovuNotificationCenter";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import { Tab } from "@nextui-org/tabs";
import { ThemeMode } from "app/utils/theme";
import { userActions } from "app/store/slices/user.slice";
import NextUITooltip from "app/components/common/NextUI/Tooltip/NextUITooltip";

const MarginLink = styled(Link)`
  margin-left: 30px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const OrangeHourOneNewLogo = styled(HourOneNewLogo)`
  fill: ${({ theme }) => theme.orange4};
`;

const StyledLink = styled(Link)`
  height: 32px;
  display: none;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: block;
    margin-right: 10px;
  }
`;

const StyledInput = styled(H1_Input)`
  background-color: transparent;
  input {
    background-color: ${({ theme }) => theme.gray3};
    border-radius: 10px;
    height: 40px;
    border: none;
    box-shadow: none;
    &:hover {
      border: none;
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.blue1};
    }
    &::placeholder {
      color: ${(props) => props.theme.gray6};
    }
  }
`;

const BackgroundFlexRow = styled(H1_FlexRow)`
  background-color: ${({ $color }: { $color: string }) => $color};
`;

const LightningCircle = styled(H1_FlexRow)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.gray2};
`;

const LeftTextMiddle = styled(H1_TextMiddle)`
  padding-left: 20px;
`;

const TabsContainer = styled(H1_FlexRow)`
  position: absolute;
  left: calc(50% - 50px);
  top: 15px;
  &&& button[data-selected="true"] {
    div {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2)};
    }
    span {
      background-color: ${({ theme }) =>
        theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2};
    }
  }
`;

const headerHeight = "80px";

const HeaderContainer = () => {
  const [isOpenLightningModal, setIsOpenLightningModal] = useState<boolean>(false);
  const { theme, toggleTheme } = useTheme();
  const location = useLocation();
  const { searchValue, setSearchValue, activeHeaderTab, setActiveHeaderTab } =
    useContext(HeaderContext);
  const { formatMessage } = useIntl();
  const { isSmallScreen } = useSmallScreen();
  const flags = useFlags();
  const { isEnterprise } = usePermissions();
  const upgradeToEnterprise = useUpgradeToEnterprise();
  const dispatch = useAppDispatch();

  const preferences = useAppSelector((state) => state.user.preferences);
  const readyToPlayVideos: Video[] = useAppSelector(videosSelectors.getReadyToPlayVideos);
  const isLiveBlocksSupported = useAppSelector(workspaceSupportsLiveBlocks);

  const { boost_config } = preferences;
  const isRouteHome = location.pathname.includes(Location.Home);
  const isRoutePricing = location.pathname.includes(Location.Pricing);
  const isRouteProjects = location.pathname === Location.Projects;
  const isRouteDrafts = location.pathname.includes(`${Location.Projects}/`);
  const isRouteTemplates = location.pathname.includes(Location.Templates);
  const isRouteBriefs = location.pathname.includes(Location.Briefs);
  const isRouteWorkflows = location.pathname.includes(Location.Workflows);
  const isRouteProfile = location.pathname.includes(Location.Profile);
  const isRouteEditorInner = location.pathname.includes(Location.IneerEditor);
  const isRoutePlayer = location.pathname.includes(Location.Player);
  const isRouteOnBoardingV2 = location.pathname.includes(Location.OnBoardingV2);
  const isRouteAvatars = location.pathname.includes(Location.Avatars);
  const isRoutePlayground = location.pathname.includes(Location.Playground);

  const isBoostSet = !!preferences.boost_config;

  const grayBackgroundRoutes = [Location.Editor, Location.Channels, Location.Community];
  const isGrayBackgroundRoutes = grayBackgroundRoutes.some((routes) =>
    location.pathname.includes(routes)
  );

  useEffect(() => {
    if (location.pathname) {
      setSearchValue("");
    }
  }, [location.pathname]);

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onClickToggleTheme = () => {
    dispatch(
      userActions.updateUserPreferencesRequest({ dark_mode: theme.mode !== ThemeMode.Dark })
    );
    toggleTheme();
  };

  const onOpenLightningModal = () => {
    setIsOpenLightningModal(true);
  };
  const onCloseLightningModal = (boost?: {
    scale: number;
    time: number;
    provider: BoostProvider;
  }) => {
    setIsOpenLightningModal(false);
    if (boost) {
      upgradeToEnterprise({ source: "upgrade_boost_rendering" });
    }
  };

  const onChangeTab = (tab: Key) => {
    setActiveHeaderTab(tab as HeaderTab);
  };

  if (isSmallScreen || isRouteEditorInner || isRoutePlayer || isRouteOnBoardingV2) {
    return null;
  }

  return (
    <BackgroundFlexRow
      $color={
        isGrayBackgroundRoutes
          ? theme.gray4
          : theme.mode === ThemeMode.Light
          ? theme.gray1
          : theme.gray12
      }
      flex="0 0 auto"
      justify={isRoutePricing || isRouteProjects ? "space-between" : "flex-end"}
      align="center"
      height={headerHeight}
      padding="0 0 0 30px"
    >
      <LightningModal
        onFinish={onCloseLightningModal}
        visible={isOpenLightningModal}
        boostProperties={boost_config}
      />
      <H1_FlexRow
        align="center"
        justify={isRouteProjects ? "center" : "flex-start"}
        margin="0 auto 0 0"
      >
        <ConditionalRender condition={isRoutePricing}>
          <MarginLink to={Location.Home}>
            <H1_TextMiddle color={theme.gray11}>
              {formatMessage(businessMessages.home)}
            </H1_TextMiddle>
          </MarginLink>
        </ConditionalRender>
        <StyledLink to={Location.Home}>
          <OrangeHourOneNewLogo height={32} width={32} />
        </StyledLink>
        <ConditionalRender condition={isRouteTemplates}>
          <LeftTextMiddle color={theme.gray7}>
            {formatMessage(businessMessages.templates)}
          </LeftTextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={isRouteWorkflows}>
          <LeftTextMiddle color={theme.gray7}>
            {formatMessage(businessMessages.workflows)}
          </LeftTextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={isRouteAvatars}>
          <LeftTextMiddle color={theme.gray7}>
            {formatMessage(businessMessages.avatars)}
          </LeftTextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={isRouteBriefs}>
          <LeftTextMiddle color={theme.gray7}>
            {formatMessage(businessMessages.briefs)}
          </LeftTextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={isRouteProfile && !isSmallScreen}>
          <LeftTextMiddle color={theme.gray7}>
            {formatMessage(businessMessages.profile)}
          </LeftTextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={isRouteProjects || isRouteHome}>
          <H1_FlexRow padding={isRouteHome ? "0 0 0 20px" : "0"}>
            <WorkspaceSearch />
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={isRouteDrafts}>
          <StyledInput
            minWidth="355px"
            placeholder={formatMessage(businessMessages.projectsPlaceholder)}
            onChange={onSearchChanged}
            initialValue={searchValue}
          />
        </ConditionalRender>
      </H1_FlexRow>
      <DevModeSwitch />
      <ConditionalRender condition={flags[FeatureFlag.dark]}>
        <NextUITooltip
          content={formatMessage(
            theme.mode === ThemeMode.Light ? businessMessages.darkMode : businessMessages.lightMode
          )}
        >
          <H1_FlexRow margin="0 0 0 10px">
            <Button
              variant="light"
              startContent={
                <H1_Icon
                  isCursorPointer
                  color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray11}
                  icon={theme.mode === ThemeMode.Light ? "fas fa-sun" : "fas fa-moon"}
                  size="16px"
                />
              }
              onClick={onClickToggleTheme}
              isIconOnly
            />
          </H1_FlexRow>
        </NextUITooltip>
      </ConditionalRender>
      <ConditionalRender condition={flags[FeatureFlag.headerLightning] && false}>
        <LightningCircle
          justify="center"
          align="center"
          onClick={onOpenLightningModal}
          width="41px"
          height="41px"
          margin="0 0 0 10px"
        >
          <H1_Icon
            size="20px"
            icon="fas fa-bolt-lightning"
            color={isBoostSet ? theme.orange4 : theme.gray4}
            isCursorPointer
          />
        </LightningCircle>
      </ConditionalRender>
      <ConditionalRender
        condition={
          flags[FeatureFlag.commentsNotifications] && isLiveBlocksSupported && isEnterprise
        }
      >
        <HeaderCommentsNotifications />
      </ConditionalRender>
      <ConditionalRender condition={flags[FeatureFlag.notificationInbox] && !isRoutePlayground}>
        <H1_FlexRow margin="0 0 0 10px">
          <NovuNotificationCenter />
        </H1_FlexRow>
      </ConditionalRender>
      <AccountAndNewVideoHeader />
      <ConditionalRender
        condition={
          flags[FeatureFlag.homepageTabs] &&
          isRouteHome &&
          (readyToPlayVideos.length > 0 || activeHeaderTab === HeaderTab.workspace)
        }
      >
        <TabsContainer>
          <Tabs
            variant="underlined"
            color="primary"
            selectedKey={activeHeaderTab}
            onSelectionChange={onChangeTab}
          >
            <Tab key={HeaderTab.home} title={formatMessage(businessMessages.homeTab)}></Tab>
            <Tab
              key={HeaderTab.workspace}
              title={formatMessage(businessMessages.workspaceTab)}
            ></Tab>
          </Tabs>
        </TabsContainer>
      </ConditionalRender>
    </BackgroundFlexRow>
  );
};
export default HeaderContainer;
