import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useIntl } from "react-intl";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/messages";
import {
  allowedDefaultMediaFiles,
  MediaLibraryTabs,
  MediaType,
  SelectedImageContext,
  ServerFolderMedia
} from "app/types/media";
import { useAppDispatch, useAppSelector } from "app/hooks";
import MediaLibraryItem from "app/pages/mediaLibrary/MediaLibraryItem";
import styled, { useTheme } from "styled-components";
import { getMediaListSelect } from "app/store/selectorsV2/media.selectors";
import { AnimatePresence, motion } from "framer-motion";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { CloudUploadOutlined } from "@ant-design/icons";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import ConditionalRender from "app/components/common/ConditionalRender";
import { FetchStatus, FolderType, MimeType } from "app/types";
import {
  addPolicyToUrlIfFileStackMedia,
  executeBlobDownload,
  fetchingStatus
} from "app/utils/helpers";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import UploadFileContent from "app/components/common/UploadFileContent";
import EmptyState from "app/components/common/EmptyState";
import { IconFlexRow } from "./MediaItemActions";
import MediaLibraryFolderItem from "app/pages/mediaLibrary/MediaLibraryFolderItem";
import MediaLibraryBreadCrumbs from "app/pages/mediaLibrary/MediaLibraryBreadCrumbs";
import DragAndDropContainer from "app/pages/mediaLibrary/DragAndDropContainer";
import Draggable from "./Draggable";
import Droppable from "./Droppable";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import FilterPopover from "app/pages/mediaLibrary/FilterPopover";
import SortPopover from "app/pages/mediaLibrary/SortPopover";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { useDebounce } from "@react-hook/debounce";
import Selecto from "react-selecto";
import { Button } from "@nextui-org/react";
import MediaLibraryMoveToFolderModal from "app/pages/mediaLibrary/MediaLibraryMoveToFolderModal";

const WrapFlexRow = styled(H1_FlexRow)`
  flex-wrap: wrap;
  &::-webkit-scrollbar-thumb {
    width: 1px;
  }
`;

const BorderedFlexRow = styled(H1_FlexRow)`
  border: 2px solid ${(props) => props.theme.gray5};
  border-radius: 10px;
`;

const StyledAnimatePresence = styled(AnimatePresence)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 35px;
`;

const StyledSearch = styled(H1_Input)`
  margin-left: 15px;
  &&& {
    background-color: transparent;
    input {
      border: none;
      border-radius: 50px;
      height: 28px;
      box-shadow: none;
      background-color: ${({ theme }) => theme.gray3};
    }
  }
  .ant-input-affix-wrapper:focus {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: inherit;
  }
`;

const BoxFlexColumn = styled(H1_FlexColumn)`
  user-select: none;
  max-width: 164px;
  &&&:hover ${IconFlexRow} {
    opacity: 1;
  }
`;

const FileListElms = ({
  search = "",
  mimetypes = [],
  importLoading,
  onSelectFile,
  onImageSelect,
  selectedUrl,
  onZoomItem,
  onDeleteItem,
  onMoveItem,
  onEditIntent,
  selectedFiles,
  onUpdateSelectedFiles,
  mediaModalOpen
}: {
  search: string;
  mimetypes?: MimeType[];
  importLoading?: boolean;
  onSelectFile: (file: File[]) => void;
  onImageSelect: (value: string, context?: SelectedImageContext) => void;
  selectedUrl?: string;
  onZoomItem: (event: React.MouseEvent, url: string, mediaType?: MediaType) => void;
  onDeleteItem: (event: React.MouseEvent, file: ServerFolderMedia) => void;
  onMoveItem: (event: React.MouseEvent, file: ServerFolderMedia) => void;
  onEditIntent: (file: ServerFolderMedia) => void;
  selectedFiles: Set<string>;
  onUpdateSelectedFiles: (ids: string[]) => void;
  mediaModalOpen: boolean;
}) => {
  const [hasFileFound, setHasFileFound] = useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const content = useAppSelector(mediaGlobalSelectors.selectAll);
  const folderTargetLoadingId = useAppSelector((state) => state.media.folderTargetLoadingId);
  const createFolderStatus = useAppSelector((state) => state.media.createFolderStatus);

  const fileList: ServerFolderMedia[] = useAppSelector((state) =>
    getMediaListSelect(state, search, mimetypes)
  );

  useEffect(() => {
    if (mediaModalOpen && fileList.length > 0 && !hasFileFound) {
      selectedFiles.forEach((selected) => {
        const element = document.querySelector(`[data-id="${selected}"]`);
        if (element) {
          setHasFileFound(true);
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
    if (!mediaModalOpen) {
      setHasFileFound(false);
    }
  }, [mediaModalOpen, selectedFiles]);

  useEffect(() => {
    if (createFolderStatus === fetchingStatus.succeeded) {
      dispatch(mediaActions.updateCreateFolderStatusToIdle());
    }
    if (createFolderStatus === fetchingStatus.failed) {
      dispatch(mediaActions.updateCreateFolderStatusToIdle());
    }
  }, [createFolderStatus]);

  useEffect(() => {
    const result = fileList.find(
      (file) => file.data?.url === selectedUrl && file.type !== FolderType.folder
    );
    if (result && selectedFiles.size === 0) {
      onSelectSingleFile(result.id);
    }
  }, [selectedUrl, selectedFiles]);

  const onSelectSingleFile = (id: string) => {
    if (id) {
      const newSelectedFiles = new Set(selectedFiles);
      if (newSelectedFiles.has(id)) {
        newSelectedFiles.delete(id);
      } else {
        newSelectedFiles.add(id);
      }
      onUpdateSelectedFiles(Array.from(newSelectedFiles));
    }
  };

  const onDownload = (event: React.MouseEvent, file: ServerFolderMedia, name: string) => {
    event.stopPropagation();
    const policyUrl = addPolicyToUrlIfFileStackMedia(file.data?.url as string, filestackPolicy);
    executeBlobDownload(policyUrl, name);
  };

  const onElementDoubleClick = (e: MouseEvent<HTMLButtonElement>, file: ServerFolderMedia) => {
    if (folderTargetLoadingId === file.id) {
      return;
    }
    if (file.type === FolderType.folder) {
      onUpdateSelectedFiles([]);
      dispatch(
        mediaActions.pushFolderStack({ name: file.name, id: file.id, previousContent: content })
      );
      dispatch(mediaActions.getFolderMediaContentRequest({ folderId: file.id }));
    } else if (file.data?.url) {
      onZoomItem(e, file.data?.url as string, file.data?.media_type as MediaType);
    }
  };

  return (
    <>
      <ConditionalRender condition={fileList.length === 0 && !importLoading}>
        <EmptyState
          text={formatMessage(mediaLibraryMessages.emptyStateText)}
          description={formatMessage(mediaLibraryMessages.emptyStateDescription)}
          icon="fa-light fa-image"
          width="820px"
          withButton
          button={
            <UploadFileContent fileTypes={allowedDefaultMediaFiles} onSelectFile={onSelectFile}>
              <Button
                color="primary"
                isDisabled={importLoading}
                isLoading={importLoading}
                startContent={<CloudUploadOutlined />}
              >
                {formatMessage(mediaLibraryMessages.uploadButton)}
              </Button>
            </UploadFileContent>
          }
        />
      </ConditionalRender>
      {/*todo*/}
      {fileList.map((file: ServerFolderMedia) => (
        <motion.div
          layout
          key={file.id}
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{
            default: {
              duration: 0.5
            },
            scale: {
              damping: 2
            }
          }}
        >
          <BoxFlexColumn
            className="box-flex-column"
            position="relative"
            gap="7px"
            flex="0 0 auto"
            onClick={(e: React.MouseEvent) => {
              if (e.shiftKey) {
                onSelectSingleFile(file.id);
                onImageSelect("");
              } else {
                onUpdateSelectedFiles([file.id]);
                if (file.type === FolderType.file) {
                  onImageSelect(file.data?.url as string, {
                    handle: file.data?.handle as string,
                    url: file.data?.url as string,
                    tab: MediaLibraryTabs.MyMedia,
                    mediaType: file.data?.media_type as MediaType,
                    mediaId: file.data?.id
                  });
                } else {
                  onImageSelect("");
                }
              }
            }}
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            onDoubleClick={(e) => {
              onElementDoubleClick(e, file);
            }}
          >
            <ConditionalRender condition={file.type === "folder"}>
              <Droppable id={file.id}>
                <Draggable id={file.id} type={file.type} title={file.name as string}>
                  <MediaLibraryFolderItem
                    fileId={file.id}
                    onEditIntent={onEditIntent}
                    onDeleteItem={(e) => onDeleteItem(e, file)}
                    onMoveItem={(e) => onMoveItem(e, file)}
                    loading={folderTargetLoadingId === file.id}
                    isSelected={selectedFiles.has(file.id as string)}
                  />
                </Draggable>
              </Droppable>
            </ConditionalRender>
            <ConditionalRender condition={file.type === FolderType.file}>
              <Draggable id={file.id} type={file.type} title={file.name as string}>
                <MediaLibraryItem
                  onEditIntent={onEditIntent}
                  id={file.id as string}
                  onDeleteItem={(e) => onDeleteItem(e, file)}
                  onZoomItem={(e) =>
                    onZoomItem(e, file.data?.url as string, file.data?.media_type as MediaType)
                  }
                  onMoveItem={(e) => onMoveItem(e, file)}
                  onDownload={onDownload}
                  isSelected={selectedFiles.has(file.id as string)}
                />
              </Draggable>
            </ConditionalRender>
          </BoxFlexColumn>
        </motion.div>
      ))}
    </>
  );
};
export interface MediaLibraryTabMyMediaProps {
  onImageSelect: (value: string, context?: SelectedImageContext) => void;
  selectedUrl?: string;
  onZoomItem: (event: React.MouseEvent, url: string, mediaType?: MediaType) => void;
  progress?: number;
  importLoading?: boolean;
  mimetypes?: MimeType[];
  onSelectFile: (file: File[]) => void;
  mediaModalOpen: boolean;
}

const MediaLibraryTabMyMedia = ({
  onImageSelect,
  selectedUrl,
  onZoomItem,
  progress,
  importLoading,
  mimetypes,
  onSelectFile,
  mediaModalOpen
}: MediaLibraryTabMyMediaProps) => {
  const [isSelectoAvailable, setIsSelectoAvailable] = useState<boolean>(false);
  const [isMoveToFolderModalOpen, setIsMoveToFolderModalOpen] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  const [searchDebounce, setSearchDebounce] = useDebounce("", 500);
  const { formatMessage } = useIntl();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mediaStatus: FetchStatus = useAppSelector((state) => state.media.mediaStatus);
  const createFolderStatus = useAppSelector((state) => state.media.createFolderStatus);
  const foldersStack = useAppSelector((state) => state.media.foldersStack);
  const rootFolderId = useAppSelector((state) => state.media.rootFolderId);
  const allMediaFiles = useAppSelector(mediaGlobalSelectors.selectAll);
  const createFolderLoading = createFolderStatus === fetchingStatus.loading;

  useEffect(() => {
    if (mediaModalOpen) {
      setTimeout(() => {
        setIsSelectoAvailable(true);
      }, 1000);
    } else {
      setIsSelectoAvailable(false);
    }
  }, [mediaModalOpen]);

  const onEditIntent = (file: ServerFolderMedia) => {
    dispatch(
      analyticsEvents.editMediaIntent({
        name: file.data?.name || (file.name as string),
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        }
      })
    );
  };

  const onMoveItem = (event: React.MouseEvent, file: ServerFolderMedia) => {
    event.stopPropagation();
    const newSet = new Set([file.id]);
    setSelectedFiles(newSet);
    setIsMoveToFolderModalOpen(true);
  };
  const onDeleteItem = (event: React.MouseEvent, file: ServerFolderMedia) => {
    event.stopPropagation();
    dispatch(
      analyticsEvents.deleteMedia({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        media: {
          url: file.data?.url as string,
          title: file.data?.name || (file.name as string)
        }
      })
    );
    if (file.type === FolderType.folder) {
      dispatch(mediaActions.deleteMediaFolderRequest(file.id));
    } else {
      dispatch(
        mediaActions.deleteMediaRequest({ fileId: file.id, mediaId: file?.data?.id as string })
      );
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toString();
    dispatch(
      analyticsEvents.searchMedia({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        query
      })
    );
    setSearchDebounce(query);
  };

  const onClickMove = () => {
    setIsMoveToFolderModalOpen(true);
  };

  const onClickDeleteMultiple = () => {
    dispatch(mediaActions.deleteMultipleMediaRequest({ files: Array.from(selectedFiles) }));
    setSelectedFiles(new Set());
  };

  const onCreateNewFolder = () => {
    dispatch(mediaActions.createFolderRequest({ name: "New Folder" }));
  };

  const onFinishDrop = (id: string, overId: string) => {
    dispatch(mediaActions.moveToFolderRequest({ pathId: id, parentId: overId }));
    if (overId === rootFolderId) {
      const mediaFile = allMediaFiles.find((currentMediaFile) => currentMediaFile.id === id);
      if (mediaFile) {
        dispatch(mediaActions.pushToFolderStack({ index: 0, file: mediaFile }));
      }
    } else {
      const currFolderIndex = foldersStack.findIndex((folder) => folder.id === overId);
      if (currFolderIndex > -1) {
        const mediaFile = allMediaFiles.find((currentMediaFile) => currentMediaFile.id === id);
        if (mediaFile) {
          dispatch(mediaActions.pushToFolderStack({ index: currFolderIndex + 1, file: mediaFile }));
          foldersStack[currFolderIndex].previousContent.push(mediaFile);
        }
      }
    }
  };

  const onUpdateSelectedFiles = (ids: string[]) => {
    if (ids.length === 0) {
      setSelectedFiles(new Set());
      onImageSelect("");
      return;
    }
    const newSet = new Set<string>(ids);
    setSelectedFiles(newSet);
  };
  const onCloseMoveToFolderModal = (isMoved?: boolean) => {
    setIsMoveToFolderModalOpen(false);
    if (isMoved) {
      // If the file was moved, we should clear the selected files
      onUpdateSelectedFiles([]);
    }
  };

  return (
    <>
      <MediaLibraryMoveToFolderModal
        visible={isMoveToFolderModalOpen}
        rootFolderId={rootFolderId}
        selectedFiles={selectedFiles || []}
        onCloseModal={onCloseMoveToFolderModal}
      />
      <UploadFileContent
        width="100%"
        disabled={importLoading}
        openFileDialogOnClick={false}
        fileTypes={allowedDefaultMediaFiles}
        onSelectFile={onSelectFile}
      >
        <H1_FlexColumn flex="1 1 auto" height="100%" overflow="hidden" gap="13px" width="100%">
          <DragAndDropContainer onFinishDrop={onFinishDrop}>
            <H1_FlexRow justify="space-between" width="100%">
              <MediaLibraryBreadCrumbs />
              <H1_FlexRow align="center">
                <ConditionalRender condition={selectedFiles.size > 1}>
                  <H1_FlexRow align="center" gap="10px">
                    <Button
                      startContent={<i className="far fa-folder-arrow-up" />}
                      size="sm"
                      onClick={onClickMove}
                    >
                      {formatMessage(mediaLibraryMessages.moveMedia)}
                    </Button>
                    <Button
                      startContent={<i className="far fa-trash" />}
                      size="sm"
                      onClick={onClickDeleteMultiple}
                    >
                      {formatMessage(mediaLibraryMessages.deleteMedia)}
                    </Button>
                  </H1_FlexRow>
                </ConditionalRender>

                <Tooltip title={formatMessage(mediaLibraryMessages.createFolder)}>
                  <>
                    <Button
                      variant="light"
                      isIconOnly
                      startContent={<i className="fa-light fa-folder-plus"></i>}
                      onClick={onCreateNewFolder}
                      isLoading={createFolderLoading}
                    />
                  </>
                </Tooltip>
                <FilterPopover />
                <SortPopover />

                <StyledSearch
                  placeholder={formatMessage(mediaLibraryMessages.searchMedia)}
                  allowClear
                  onChange={onSearch}
                  initialValue={searchDebounce}
                  minWidth="173px"
                  isFixedWidth
                />
              </H1_FlexRow>
            </H1_FlexRow>
            <ConditionalRender condition={mediaStatus === fetchingStatus.loading}>
              <CircleLoader />
            </ConditionalRender>
            <ConditionalRender condition={mediaStatus !== fetchingStatus.loading}>
              <WrapFlexRow
                padding="20px 0 0 4px"
                gap="18px"
                height="100%"
                overflow="auto"
                flex="1 1 auto"
                className="wrap-container"
              >
                <ConditionalRender condition={importLoading}>
                  <H1_FlexColumn
                    width="158px"
                    height="185px"
                    align="center"
                    flex="0 0 auto"
                    justify="center"
                    padding="8px 0 0 0"
                    gap="15px"
                  >
                    <BorderedFlexRow width="138px" height="135px" padding="4px">
                      <CircleLoader />
                    </BorderedFlexRow>
                    <H1_TextXs color={theme.blue4}>{progress}%</H1_TextXs>
                  </H1_FlexColumn>
                </ConditionalRender>
                <ConditionalRender condition={isSelectoAvailable}>
                  <Selecto
                    dragContainer={".wrap-container"}
                    selectableTargets={[".item-card"]}
                    hitRate={0}
                    selectByClick={true}
                    selectFromInside={false}
                    ratio={0}
                    toggleContinueSelect={["shift"]}
                    onSelect={(e) => {
                      const newSelectedElements = new Set<string>();
                      e.selected.forEach((el) => {
                        const id = el.getAttribute("data-id");
                        if (id) {
                          newSelectedElements.add(id);
                        }
                      });
                      setSelectedFiles(newSelectedElements);
                    }}
                    onDragStart={(e) => {
                      if (!e.inputEvent.shiftKexy) {
                        setSelectedFiles(new Set());
                        onImageSelect("");
                      }
                      // Prevent starting the drag on inputs, buttons, etc.
                      const target = e.inputEvent.target;
                      if (
                        target.tagName === "INPUT" ||
                        target.tagName === "BUTTON" ||
                        target.isContentEditable
                      ) {
                        e.stop();
                      }
                      if (target.closest(".multi-selectable")) {
                        // Prevent Selecto from handling this event
                        return false;
                      }
                      return true;
                    }}
                  />
                </ConditionalRender>
                <StyledAnimatePresence>
                  <FileListElms
                    onEditIntent={onEditIntent}
                    search={searchDebounce}
                    mimetypes={mimetypes}
                    importLoading={importLoading}
                    onImageSelect={onImageSelect}
                    selectedUrl={selectedUrl}
                    onZoomItem={onZoomItem}
                    onDeleteItem={onDeleteItem}
                    onMoveItem={onMoveItem}
                    onSelectFile={onSelectFile}
                    selectedFiles={selectedFiles}
                    onUpdateSelectedFiles={onUpdateSelectedFiles}
                    mediaModalOpen={mediaModalOpen}
                  />
                </StyledAnimatePresence>
              </WrapFlexRow>
            </ConditionalRender>
          </DragAndDropContainer>
        </H1_FlexColumn>
      </UploadFileContent>
    </>
  );
};

export default MediaLibraryTabMyMedia;
