import TitledInput from "app/components/common/TitledInput";
import { Webhook } from "app/types";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import { Chip, Input, Select, SelectedItems, SelectItem } from "@nextui-org/react";
import { Selection } from "@react-types/shared/src/selection";
import { useIntl } from "react-intl";
import { WebhookFormMessages } from "app/pages/webhooks/messages";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const MaxFlexColumn = styled(H1_FlexColumn)`
  max-width: 500px;
`;
interface WebhookFormProps {
  name?: string;
  description?: string;
  url?: string;
  events?: string[];
  disabled?: boolean;
  onChange: (prop: keyof Webhook, data: any) => void;
  errors: Record<keyof Webhook, string>;
}
const WebhookForm = ({
  name,
  description,
  url,
  events,
  onChange,
  disabled,
  errors
}: WebhookFormProps) => {
  const { formatMessage } = useIntl();
  const onChangeName = (value: string) => {
    onChange("name", value);
  };
  const onChangeUrl = (value: string) => {
    onChange("url", value);
  };
  const onChangeDescription = (value: string) => {
    onChange("description", value);
  };

  const onChangeEvent = (events: Selection) => {
    onChange("events", Array.from(events));
  };

  return (
    <MaxFlexColumn width="100%" gap={"5px"}>
      <TitledInput gap={"5px"} title={formatMessage(WebhookFormMessages.title)} error={errors.name}>
        <Input
          classNames={{ mainWrapper: "w-full" }}
          fullWidth
          labelPlacement="outside-left"
          isDisabled={disabled}
          value={name}
          onChange={(e) => onChangeName(e.target.value)}
        />
      </TitledInput>
      <TitledInput gap={"5px"} title={formatMessage(WebhookFormMessages.description)}>
        <Input
          classNames={{ mainWrapper: "w-full" }}
          fullWidth
          labelPlacement="outside-left"
          isDisabled={disabled}
          value={description}
          onChange={(e) => onChangeDescription(e.target.value)}
        />
      </TitledInput>
      <TitledInput
        gap={"5px"}
        title={formatMessage(WebhookFormMessages.endpointUrl)}
        error={errors.url}
      >
        <Input
          classNames={{ mainWrapper: "w-full" }}
          fullWidth
          labelPlacement="outside-left"
          isDisabled={disabled}
          value={url}
          onChange={(e) => onChangeUrl(e.target.value)}
          placeholder={"https://"}
        />
      </TitledInput>
      <TitledInput
        gap={"5px"}
        title={formatMessage(WebhookFormMessages.events)}
        error={errors.events}
      >
        <Select
          size="sm"
          isDisabled={disabled}
          selectionMode="multiple"
          placeholder={formatMessage(WebhookFormMessages.eventPlaceholder)}
          selectedKeys={events}
          onSelectionChange={onChangeEvent}
          renderValue={(items: SelectedItems<string>) => {
            return (
              <div className="flex flex-wrap gap-2">
                {items.map((item) => (
                  <Chip isCloseable key={item.key}>
                    {item.textValue}
                  </Chip>
                ))}
              </div>
            );
          }}
        >
          <SelectItem key="video.ready" textValue={formatMessage(WebhookFormMessages.videoReady)}>
            <H1_TextSmall>{formatMessage(WebhookFormMessages.videoReady)}</H1_TextSmall>
          </SelectItem>
          <SelectItem key="video.failed" textValue={formatMessage(WebhookFormMessages.videoFailed)}>
            <H1_TextSmall>{formatMessage(WebhookFormMessages.videoFailed)}</H1_TextSmall>
          </SelectItem>
        </Select>
      </TitledInput>
    </MaxFlexColumn>
  );
};

export default WebhookForm;
