import { Layout, Space } from "antd";
import VideoProgress from "app/components/editor/sideDrawers/RenderPanel/VideoProgress";
import { Video } from "app/types";
import CardButton from "app/components/common/CardButton";

interface RenderingContentProps {
  onExport: (video: Video) => void;
  onRevert: (video: Video) => void;
  onVideoSelect: (videoId: string) => void;
  selectedVideo?: Video;
  videos: Video[];
}

const RenderingContent = ({
  selectedVideo,
  videos,
  onExport,
  onRevert,
  onVideoSelect
}: RenderingContentProps) => {
  return (
    <Layout>
      <Layout.Content style={{ overflow: "auto", background: "#ffffff", paddingBottom: "20px" }}>
        <Space
          direction="vertical"
          style={{ width: "100%", marginLeft: "5px", paddingRight: "10px" }}
        >
          {" "}
          {videos.map((video) => {
            return (
              <CardButton
                $selected={selectedVideo?.id === video.id}
                key={video.id}
                onClick={() => onVideoSelect(video.id)}
              >
                <VideoProgress video={video} onExport={onExport} onRevert={onRevert} />
              </CardButton>
            );
          })}
        </Space>
      </Layout.Content>
    </Layout>
  );
};

RenderingContent.defaultProps = {
  selectedVideo: undefined
};
export default RenderingContent;
