import VideoPlayer from "app/pages/PlayGround/VideoPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { playgroundActions } from "app/store/slices/playground.slice";
import { playgroundGlobalSelectors } from "app/store/adapters/adapters";
import { PlaygroundStatus } from "app/types/playground";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { format } from "date-fns";
import { Video } from "app/types";
import { useAuth } from "app/auth/useAuth";
import useModal, { ModalName } from "app/hooks/useModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import PlaygroundShareVideoMenu from "app/pages/PlayGround/PlaygroundShareVideoMenu";
import { fetchingStatus } from "app/utils/helpers";
import AvatarInitials from "app/components/common/AvatarInitials";
import { Button } from "@nextui-org/react";

const PLAYGROUND_SOURCE = "playground";
const WhiteButton = styled(Button)`
  span {
    color: ${({ theme }) => theme.gray1};
  }
  & {
    background-color: #343445;
    color: ${({ theme }) => theme.gray1};
  }
  min-width: 39px;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  &:hover {
    background-color: #343445;
  }
  &:disabled {
    color: ${({ theme }) => theme.gray8};
    span {
      color: ${({ theme }) => theme.gray8};
    }
  }
`;

interface PlaygroundSingleVideoProps {
  videoId: string;
}
const PlaygroundSingleVideo = ({ videoId }: PlaygroundSingleVideoProps) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const video = useAppSelector((state) => playgroundGlobalSelectors.selectById(state, videoId));
  const videos = useAppSelector((state) => playgroundGlobalSelectors.selectAll(state));
  const playgroundShareVideoStatus = useAppSelector((state) => state.playground.shareVideoStatus);
  const isShareVideoStatusLoading = playgroundShareVideoStatus === fetchingStatus.loading;
  const currentTime = format(new Date(video?.created_at || Date.now()), "Pp");

  const renderingLoading =
    video?.status === PlaygroundStatus.started ||
    video?.status === PlaygroundStatus.processing ||
    video?.status === PlaygroundStatus.submitted_preprocessing;

  useEffect(() => {
    if (videos.length === 0) {
      dispatch(playgroundActions.playgroundGetVideosRequest());
    }
  }, [videos.length]);

  const onCopyLink = () => {
    dispatch(googleEvents.playgroundCopyLinkIntent({ videoId }));
    openModal(ModalName.playgroundPublishVideo, { videoId });
  };
  const onDownload = () => {
    dispatch(googleEvents.playgroundDownloadIntent({ videoId }));
    dispatch(playgroundActions.playgroundDownloadVideoRequest({ videoId: videoId as string }));
  };

  if (!video) {
    return null;
  }

  return (
    <H1_FlexColumn flex="1 0 auto" height="100%" position="relative" align="center">
      <H1_FlexColumn width="100%" flex="1" justify={"center"} gap={"30px"}>
        <ConditionalRender condition={!!(renderingLoading || video?.playback_id)}>
          <H1_FlexColumn flex={"0 0 100%"} gap="20px">
            <VideoPlayer
              loading={renderingLoading}
              progress={video?.progress}
              playbackId={video?.playback_id}
            />
            <H1_FlexRow width="100%" align="center" justify="space-between">
              <H1_FlexRow align="center" gap="6px">
                <AvatarInitials src={user?.picture} text={user?.nickname || ""} size="md" />
                <H1_FlexColumn>
                  <H1_TextSmall color={theme.gray1}>{video?.title}</H1_TextSmall>
                  <H1_TextXs color={theme.gray1}>{currentTime}</H1_TextXs>
                </H1_FlexColumn>
              </H1_FlexRow>
              <H1_FlexRow gap="10px" align="center">
                <WhiteButton
                  isDisabled={renderingLoading}
                  isIconOnly
                  variant="light"
                  startContent={<i className="far fa-link" />}
                  onClick={onCopyLink}
                />
                <PlaygroundShareVideoMenu
                  disabled={renderingLoading}
                  loading={isShareVideoStatusLoading}
                  withText={false}
                  video={{ id: video.id, req_id: video.req_id, status: video.status } as Video}
                  source={PLAYGROUND_SOURCE}
                />
                <WhiteButton
                  isDisabled={renderingLoading}
                  isIconOnly
                  variant="light"
                  startContent={<i className="far fa-arrow-down-to-line" />}
                  onClick={onDownload}
                />
              </H1_FlexRow>
            </H1_FlexRow>
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default PlaygroundSingleVideo;
