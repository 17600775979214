import * as filestack from "filestack-js";
import Config from "app/config/Config";
import { FilestackPolicy, FitOptions } from "app/types";
import redux from "app/store/store";
import { FilterType, MediaType } from "app/types/media";
import { Crop } from "react-image-crop";
import { addPolicyToUrlIfFileStackMedia, retryPromiseFunction } from "app/utils/helpers";

const filestackClient: Record<string, filestack.Client | undefined> = {};
const defaultClientType = "read";

export const init = (filestackPolicy: FilestackPolicy, type?: string) => {
  filestackClient[type || defaultClientType] = filestack.init(Config.filestackApi, {
    security: {
      signature: filestackPolicy.signature as string,
      policy: filestackPolicy.policy as string
    }
  });
};

export const getClient = (type?: string) => {
  return filestackClient[type || defaultClientType];
};

export const createVideoThumbnail = async (filestackPolicy: FilestackPolicy, handle: string) => {
  return fetch(
    // eslint-disable-next-line max-len
    `https://process.filestackapi.com/${Config.filestackApi}/security=policy:${filestackPolicy.policy},signature:${filestackPolicy.signature}/video_convert=access:private,preset:thumbnail/${handle}`,
    {
      headers: {},
      method: "GET"
    }
  );
};

export const getImageThumbnailUrl = (filestackPolicy: FilestackPolicy, handle: string) => {
  if (redux.store.getState().user.featureFlags.disableFilestackThumbnail) {
    return "";
  }
  // eslint-disable-next-line max-len
  const modifiedUrl = `https://cdn.filestackcontent.com/${Config.filestackApi}/security=policy:${filestackPolicy?.policy},signature:${filestackPolicy?.signature}/resize=height:200/${handle}`;
  return modifiedUrl;
};

export const createImageThumbnail = async (filestackPolicy: FilestackPolicy, handle: string) => {
  if (redux.store.getState().user.featureFlags.disableFilestackThumbnail) {
    return "";
  }
  try {
    const modifiedUrl = getImageThumbnailUrl(filestackPolicy, handle);
    return fetch(modifiedUrl);
  } catch (err) {
    console.error("failed modified image", err);
    return "";
  }
};

export const convertPdfToImages = async (filestackPolicy: FilestackPolicy, handle: string) => {
  // eslint-disable-next-line max-len
  const modifiedUrl = `https://cdn.filestackcontent.com/${Config.filestackApi}/security=policy:${filestackPolicy?.policy},signature:${filestackPolicy?.signature}/run_workflow=id:${Config.presentationWorkflowId}/${handle}`;
  const response = await fetch(modifiedUrl);
  const flowResult = await response.json();

  if (flowResult.jobid) {
    const workflowStatus = `https://cdn.filestackcontent.com/${Config.filestackApi}/security=policy:${filestackPolicy?.policy},signature:${filestackPolicy?.signature}/workflow_status=job_id:${flowResult.jobid}`;
    const execute = async () => {
      const response = await fetch(workflowStatus);
      const result = await response.json();
      if (result.status === "Finished") {
        return result;
      } else {
        throw new Error("not finished");
      }
    };

    const jobResult = await retryPromiseFunction(execute, 150, 5);
    return jobResult;
  } else {
    throw new Error("missing job id");
  }
};

export const createImageFitLink = ({
  resolvedUrl,
  fitType,
  imageWidth,
  imageHeight,
  crop,
  filterType,
  filestackPolicy
}: {
  resolvedUrl: string;
  fitType?: FitOptions;
  imageWidth: number;
  imageHeight: number;
  crop?: Omit<Crop, "unit">;
  filterType?: FilterType;
  filestackPolicy: FilestackPolicy;
}) => {
  const handle = resolvedUrl.split("/").pop();
  let resize: string;
  switch (fitType) {
    case FitOptions.crop:
      resize = `/crop=dim:[${crop?.x},${crop?.y},${crop?.width},${crop?.height}]`;
      break;
    case FitOptions.smart_crop:
      resize = `/smart_crop=width:${imageWidth},height:${imageHeight}`;
      break;
    case FitOptions.scale:
    default:
      resize = "";
  }

  let filters = "";
  switch (filterType) {
    case FilterType.monochrome:
      filters = "monochrome/";
      break;
    case FilterType.sepia:
      filters = "sepia=tone:80/";
      break;
    case FilterType.oil_paint:
      filters = "oil_paint=amount:10/";
      break;
    default:
      filters = "";
  }

  // eslint-disable-next-line max-len
  return `https://cdn.filestackcontent.com/${Config.filestackApi}/security=policy:${filestackPolicy?.policy},signature:${filestackPolicy?.signature}${resize}/${filters}${handle}`;
};

export const extractFilestackHandle = (url: string) => {
  return url.split("/").pop() || "";
};

export const getThumbnailUrl = async (
  url: string,
  isVideo: boolean,
  filestackPolicy: FilestackPolicy
): Promise<{
  url: string;
  type: MediaType;
}> => {
  const handle = extractFilestackHandle(url);
  let thumbnail = url;

  try {
    if (isVideo) {
      const videoThumbnailResponse = await createVideoThumbnail(filestackPolicy, handle);
      const thumbnailResource = await videoThumbnailResponse.json();
      if (thumbnailResource.status === "completed") {
        thumbnail = thumbnailResource.data.url;
        return {
          url: addPolicyToUrlIfFileStackMedia(thumbnail, filestackPolicy),
          type: MediaType.image
        };
      } else {
        console.warn("thumbnail not completed", thumbnailResource);
        return { url: addPolicyToUrlIfFileStackMedia(url, filestackPolicy), type: MediaType.video };
      }
    } else {
      thumbnail = getImageThumbnailUrl(filestackPolicy, handle);
      return {
        url: addPolicyToUrlIfFileStackMedia(thumbnail, filestackPolicy),
        type: MediaType.image
      };
    }
  } catch (err) {
    console.warn("Failed to get thumbnail", err);
    return {
      url: addPolicyToUrlIfFileStackMedia(url, filestackPolicy),
      type: isVideo ? MediaType.video : MediaType.image
    };
  }
};
