import React, { ReactNode, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Space, Tabs, TabsProps, Tag } from "antd";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { voicesGlobalSelectors } from "app/store/adapters/adapters";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Character, CharacterType } from "app/types/character";
import { Voice } from "app/types";
import { fetchingStatus } from "app/utils/helpers";

import "app/components/editor/Editor.scss";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import PresentersGrid from "app/components/editor/sideDrawers/EditingCharacterDiscover/PresentersGrid";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled, { useTheme } from "styled-components";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import CircleLoader from "app/components/common/Loaders/CircleLoader";

export const messages = defineMessages({
  tabPresentersTitle: {
    id: "editor.drawer.chatacter.discover.tab.presenters.title",
    defaultMessage: "Reals Presenters"
  },
  tabMyPresenterTitle: {
    id: "editor.drawer.chatacter.discover.tab.my-presenter.title",
    defaultMessage: "<span>My Presenter</span><tag>New</tag>"
  },
  tabMyPresenterContentTitle: {
    id: "editor.drawer.chatacter.discover.tab.my-presenter.content.title",
    defaultMessage: "Create your own presenter"
  },
  tabMyPresenterContentTxt: {
    id: "editor.drawer.chatacter.discover.tab.my-presenter.content.text",
    defaultMessage: "Begin the the process of creating your own presenter"
  },
  joinNow: {
    id: "editor.drawer.chatacter.discover.tab.my-presenter.button.text",
    defaultMessage: "Join now"
  },
  upgradeCharacter: {
    id: "editor.drawer.chatacter.discover.tab.my-presenter.upgrade-text",
    defaultMessage: "Upgrade to use this presenter"
  }
});

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 340px;
  display: block;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-more {
    display: none;
  }
`;

interface EditingCharacterDiscoverProps {
  onCharacterChange: (arg: { voiceId?: string; characterId: string }) => void;
  character: Character;
  voice?: Voice;
}

const EditingCharacterDiscover = ({
  onCharacterChange,
  character,
  voice
}: EditingCharacterDiscoverProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<"1" | "2">("1");
  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();

  const characterFetchingStatus = useAppSelector((state) => state.characters.charactersStatus);
  const templateFetchingStatus = useAppSelector((state) => state.drafts.deleteDraftStatus);
  const currentTemplate = useAppSelector(CharacterSelectors.template);

  const loading =
    !currentTemplate ||
    characterFetchingStatus === fetchingStatus.loading ||
    templateFetchingStatus === fetchingStatus.loading;
  const publicCharacters = useAppSelector(CharacterSelectors.getPublicCharacters);
  const tailorMadeCharacters = useAppSelector(CharacterSelectors.getTailorMadeCharacters);
  const allVoices = useAppSelector(voicesGlobalSelectors.selectAll);
  const isSupportTemplateMobileOnly =
    currentTemplate?.character_types &&
    currentTemplate.character_types.length === 1 &&
    currentTemplate.character_types.includes(CharacterType.mobile);

  useEffect(() => {
    if (isSupportTemplateMobileOnly) {
      setCurrentTab("2");
    }
  }, [isSupportTemplateMobileOnly]);

  const onSelectCharacter = async (characterId: string) => {
    const chosenCharacter =
      publicCharacters.find((currentCharacter) => currentCharacter.character_id === characterId) ||
      tailorMadeCharacters.find(
        (currentCharacter) => currentCharacter.character_id === characterId
      );

    const genderedVoicesIds = allVoices
      .filter((curVoice) => curVoice.gender === chosenCharacter?.gender)
      .map((curVoice) => curVoice.id);
    let newVoiceId: string | undefined;

    if (!genderedVoicesIds.includes(voice?.id as string) && genderedVoicesIds.length) {
      [newVoiceId] = genderedVoicesIds;
    }

    dispatch(
      googleEvents.chooseCharacter({
        id: characterId,
        name: chosenCharacter?.title as string,
        gender: chosenCharacter?.gender as string,
        pro: chosenCharacter?.pro as boolean
      })
    );

    const upgradeRequired = showPaymentModal({
      source: "upgrade_character_drawer",
      upgradeText: intl.formatMessage(messages.upgradeCharacter),
      requestedPlans: chosenCharacter?.plans ?? []
    });
    if (!upgradeRequired) {
      onCharacterChange({ voiceId: newVoiceId, characterId });
    }
  };

  const onChangeTab = (value: any) => {
    setCurrentTab(value);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      className: "reals-presenters",
      label: intl.formatMessage(messages.tabPresentersTitle),
      children: (
        <>
          <ConditionalRender condition={!loading}>
            <PresentersGrid
              characters={publicCharacters}
              selectedId={character?.character_id}
              onSelect={onSelectCharacter}
              showMobileCharacterLink={false}
            />
          </ConditionalRender>
          <ConditionalRender condition={loading}>
            <LoaderContainer>
              <CircleLoader />
            </LoaderContainer>
          </ConditionalRender>
        </>
      )
    },
    {
      key: "2",
      className: "reals-my-presenters",
      label: (
        <Space size={8} style={{ position: "relative" }}>
          {
            // @ts-ignore handels formmating issue with html tags params
            intl.formatMessage(messages.tabMyPresenterTitle, {
              span: (str) => <span>{str}</span>,
              tag: (str) => (
                <Tag color={theme.pink4} style={{ borderRadius: 6 }}>
                  {str}
                </Tag>
              )
            }) as ReactNode
          }
        </Space>
      ),
      children: (
        <PresentersGrid
          characters={tailorMadeCharacters}
          selectedId={character?.character_id}
          onSelect={onSelectCharacter}
          showMobileCharacterLink
        />
      )
    }
  ];

  return (
    <StyledTabs
      activeKey={currentTab}
      onChange={onChangeTab}
      centered
      animated
      tabBarExtraContent={false}
      defaultActiveKey="1"
      items={items}
    />
  );
};

EditingCharacterDiscover.defaultProps = {
  voice: undefined
};
export default EditingCharacterDiscover;
