import TemplatePreviewThumbnail from "app/components/editor/templateSelect/TemplatePreviewThumbnail";
import React, { useMemo, useState } from "react";
import { FlatRecipe, MyTemplates, Recipe } from "app/types";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import { useAppDispatch } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import MarqueeTextBox from "app/components/common/MarqueeTextBox";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import DevModePopover from "app/components/DevModePopover";

const TEMPLATE_HEIGHT = 160;

const MarqueeContainer = styled(H1_FlexRow)`
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  background: #f5f5f5;
  z-index: 2;
`;

const TemplateContainerFlexColumn = styled(H1_FlexColumn)`
  position: relative;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  .template-video {
    border: none;
    border-radius: 0;
  }
  &:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    .template-video {
      filter: brightness(80%);
    }
  }
  &:active {
    box-shadow: none;
  }
`;

interface SingleTemplateItemProps {
  recipe: Recipe;
  height?: number;
  onClick?: (recipeId: string) => void;
  withTitle?: boolean;
}

const SingleTemplateItem = ({
  recipe,
  height = TEMPLATE_HEIGHT,
  onClick,
  withTitle = true
}: SingleTemplateItemProps) => {
  const [pauseAnimation, setPauseAnimation] = useState<boolean>(true);
  const { openModal } = useModal();

  const dispatch = useAppDispatch();
  const showBadge = height >= TEMPLATE_HEIGHT;

  const onMouseEnter = () => {
    setPauseAnimation(false);
  };
  const onMouseLeave = () => {
    setPauseAnimation(true);
  };
  const selectRecipe = (recipe: Recipe) => {
    dispatch(
      analyticsEvents.templateSelectedModal({
        source: "templates-page",
        templateName: recipe.title
      })
    );
    if (onClick) {
      onClick(recipe.id);
    } else {
      openModal(ModalName.templatePreview, {
        recipeId: recipe.id,
        recipeType: (recipe as FlatRecipe).recipeType,
        recipeCategoryName: (recipe as FlatRecipe).categoryName
      });
    }
  };

  const templateWidth = useMemo(() => {
    const aspectRatio = recipe.aspect_ratio
      ? Number(recipe.aspect_ratio.width) / (Number(recipe.aspect_ratio.height) || 1)
      : 16 / 9;
    if (aspectRatio > 1) {
      return (height * 16) / 9;
    } else if (aspectRatio < 1) {
      return (height * 9) / 16;
    } else {
      return height;
    }
  }, [recipe.aspect_ratio, height]);

  return (
    <TemplateContainerFlexColumn
      onClick={() => selectRecipe(recipe)}
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <DevModePopover value={{ template_id: recipe.id }} path={"root"} />
      <TemplatePreviewThumbnail
        width={`${templateWidth}px`}
        height={`${height}px`}
        badgeText={
          recipe.pro && showBadge ? String(recipe.plans[0] ?? "").toUpperCase() : undefined
        }
        badgeIcon={<CrownIcon />}
        alt=""
        video={recipe.video as string}
        image={recipe.image}
        tags={(recipe.pro && recipe.plans.length > 0) || !showBadge ? [] : recipe.tags}
        borderRadius="9px"
        recipeId={recipe.id}
        categoryName={(recipe as FlatRecipe).categoryName}
        showRecipeDropdown={(recipe as FlatRecipe).categoryName === MyTemplates}
      />
      <ConditionalRender condition={withTitle}>
        <MarqueeContainer height="45px" width="100%" align="center">
          <MarqueeTextBox
            color="black"
            width={`${templateWidth}px`}
            text={recipe.title}
            pauseAnimation={pauseAnimation}
          />
        </MarqueeContainer>
      </ConditionalRender>
    </TemplateContainerFlexColumn>
  );
};

export default SingleTemplateItem;
